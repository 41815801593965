import React, { useEffect, useState } from "react";
import { utils } from "../../../../../_helpers";
import { FormField } from "../../../../common/Form/BaseForm/FormField";

export function Compte(props: any) {
  const { account } = props;
  const fields = account.getAccountFields();
  const [pin, setPin] = useState(account.pin || "");

  const onChange = (e: any) => {
    setPin(e.target.value);
    account.updateValue("pin", e.target.value);
  };
  useEffect(() => {
    setPin(account.pin);
    utils().emitter.emit("value_change_account_type_id", {
      value: account?.accountType?.accountTypeId,
    });
  }, [account, fields]);
  return (
    <>
      <div className="form-group row w-100 m-0">
        <div className="col-md-6" style={{ padding: "0 1rem 0 0" }}>
          <label
            htmlFor="colFormLabelSm"
            className=" col-form-label col-form-label-sm"
          >
            Numéro du compte
          </label>
          <input
            className="form-control form-control-sm"
            name="msisdn"
            type="text"
            value={account.numero || ""}
            disabled={true}
            style={{ backgroundColor: "#eee" }}
          />
        </div>
        <div className="col-md-6" style={{ padding: 0 }}>
          <label
            htmlFor="colFormLabelSm"
            className=" col-form-label col-form-label-sm"
          >
            Code PIN
          </label>
          <input
            className="form-control form-control-sm"
            name="pin"
            type="text"
            value={pin}
            onChange={onChange}
            disabled={!utils().isProfil()}
            style={{ backgroundColor: !utils().isProfil() ? "#eee" : "#fff" }}
          />
        </div>
      </div>
      <div className="form-group row w-100 m-0">
        <div className="col-md-6" style={{ padding: "0 1rem 0 0" }}>
          <FormField
            {...props}
            field={fields[0]}
            customLayoutInput={"col-md-12 p-0 m-0 w-100"}
            customLayoutLabel={"none"}
            customStyles={{ margin: "0 0 1rem 0", width: "100%" }}
          />
        </div>
        {fields[0]?.provider?.profil!=="Admin"&&<div className="col-md-6" style={{ padding: 0 }}>
          <FormField
            {...props}
            field={fields[1]}
            customLayoutInput={"col-md-12 p-0 m-0 w-100"}
            customLayoutLabel={"none"}
            customStyles={{ margin: "0 0 1rem 0", width: "100%" }}
          />
        </div>}
      </div>
      <div className="form-group row w-100 m-0">
        {fields[0]?.provider?.profil!=="Admin"&& <div className="col-md-6" style={{ padding: "0 1rem 0 0" }}>
          <FormField
            {...props}
            field={fields[5]}
            customLayoutInput={"col-md-12 p-0 m-0 w-100"}
            customLayoutLabel={"none"}
            customStyles={{ margin: "0 0 1rem 0", width: "100%" }}
          />
        </div>}
        <div className="col-md-6" style={{ padding: 0 }}>
          <FormField
              {...props}
              field={fields[2]}
              customLayoutInput={"col-md-12 p-0 m-0 w-100"}
              customLayoutLabel={"none"}
              customStyles={{ margin: "0 0 1rem 0", width: "100%" }}
          />
        </div>
        {/* <div className="col-md-6" style={{ padding: "0 1rem 0 0" }}>
          <FormField
            {...props}
            field={fields[6]}
            customLayoutInput={"col-md-12 p-0 m-0 w-100"}
            customLayoutLabel={"none"}
            customStyles={{ margin: "0 0 1rem 0", width: "100%" }}
          />
        </div> */}
      </div>
      <div className="form-group row w-100 m-0">
        <div className="col-md-6" style={{ padding: "0 1rem 0 0" }}>
          <FormField
            {...props}
            field={fields[3]}
            customLayoutInput={"col-md-12 p-0 m-0 w-100"}
            customLayoutLabel={"none"}
            customStyles={{ margin: "0 0 1rem 0", width: "100%" }}
          />
        </div>
        <div className="col-md-6" style={{ padding: 0 }}>
          <FormField
            {...props}
            field={fields[4]}
            customLayoutInput={"col-md-12 p-0 m-0 w-100"}
            customLayoutLabel={"none"}
            customStyles={{ margin: "0 0 1rem 0", width: "100%" }}
          />
        </div>
      </div>
    </>
  );
}

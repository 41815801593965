import { appConstants } from "../_constants";
import { restClient, utils } from "../_helpers";
import { BaseEntity } from "./BaseEntity";
import { FieldType, InputType } from "./enumerations";
import { Field } from "./Field";
import { BaseModel } from "../_models";

const {
  RENDER_ACTIONS,
  RENDER_ID,
  RENDER_STATUS,
  RENDER_DATETIME,
  RENDER_AMOUNT_PRODUIT,
} = appConstants.keys.renderKeys;

class Product extends BaseEntity {
  public static readonly label: any;
  public static readonly className: any;
  public static readonly art: any;
  public static readonly addTitle = "Ajouter un produit/service";
  public static readonly showTitle = "Détails du produit/service";
  public static readonly editTitle = "Modifier un produit/service";
  public static readonly processNewSuccessMsg =
    "Le produit a été créée avec succès. Merci !";
  public static readonly processEditSuccessMsg =
    "Le produit a été modifié avec succès. Merci !";
  public static readonly processNewPath = "product/add";
  public static readonly processEditPath = "product/update/_ID_";
  public static instance: Product = new Product();
  public static instances = [];

  public static relations = [
    {
      dataPath: "currency/all",
      key: "currencies",
      resolve: (res: any) => {
        if (Array.isArray(res?.data)) {
          return res.data.map((v: any) => ({
            label: v.nom,
            value: v.deviseId,
            taux: v.taux,
          }));
        }
        return [];
      },
    },

    {
      dataPath: "product/category/all",
      key: "categorie",
      resolve: (res: any) => {
        if (Array.isArray(res?.data)) {
          return res.data.map((v: any) => ({
            label: v.libelle,
            value: v.productCategoryId,
            taux: v.taux,
          }));
        }
        return [];
      },
    },
  ];

  public static get definitions(): Field[] {
    const model: any = BaseModel.getInstance();
    const aChildAll = [
      {
        label: "Modifier",
        title: "Modifer ce produit",
        action: "editActions",
        color: "text-success",
        icon: "fa fa-edit",
      },
      {
        label: "Supprimer",
        title: "Supprimmer ce produit",
        action: "deleteActions",
        color: "text-danger",
        icon: "fa fa-trash",
      },
    ];
    const aChildMod = [
      {
        label: "Modifier",
        title: "Modifer ce produit",
        action: "editActions",
        color: "text-success",
        icon: "fa fa-edit",
      },
    ];
    const aChildDel = [
      {
        label: "Supprimer",
        title: "Supprimmer ce produit",
        action: "deleteActions",
        color: "text-danger",
        icon: "fa fa-trash",
      },
    ];

    const definitions = [
      new Field({
        name: "pk",
        type: FieldType.NUMBER,
        primary: true,
        creatable: false,
        editable: false,
        serializeNew: false,
        serializeEdit: false,
        label: "#",
        dataPath: "productId",
        renderKey: RENDER_ID,
        provider: Product.instance,
      }),

      new Field({
        name: "productCategory",
        label: "Categorie",
        formRowIndex: 1,
        onTable: false,
        relationKey: "categorie",
        inputType: InputType.INPUT_SELECT,
        provider: Product.instance,
      }),
      new Field({
        name: "reference",
        label: "Référence",
        dataPath: "reference",
        creatable: false,
        editable: false,
        provider: Product.instance,
        serializeCallback: (item: any, v: any) => {
          return {
            ...item,
            reference: utils().uuidShort(),
          };
        },
      }),

      new Field({
        name: "libelle",
        label: "Nom du produit",
        dataPath: "libelle",
        provider: Product.instance,
      }),

      new Field({
        name: "amount",
        label: "Prix",
        inputType: InputType.INPUT_NUMBER,
        renderKey: RENDER_AMOUNT_PRODUIT,
        provider: Product.instance,
        customData: {
          renderOptions: {
            className: "min-w-150",
          },
        },
      }),

      new Field({
        name: "currency",
        label: "Dévise",
        formRowIndex: 1,
        onTable: false,
        relationKey: "currencies",
        inputType: InputType.INPUT_SELECT,
        provider: Product.instance,
      }),

      new Field({
        name: "creationDate",
        label: "Date de creation",
        inputType: InputType.INPUT_DATETIME,
        renderKey: RENDER_DATETIME,
        creatable: false,
        editable: false,
        provider: Product.instance,
        serializeCallback: (item: any, v: any) => {
          const creationDate = new Date().toJSON();
          return {
            ...item,
            creationDate,
            hasAbonnement: Product.instance.getValue("hasAbonnement"),
          };
        },
      }),

      new Field({
        name: "marchand",
        creatable: false,
        editable: false,
        onTable: false,
        serializeCallback: (item: any, v: any) => {
          const marchand = utils().getUserAccountId();
          return {
            ...item,
            marchand,
          };
        },
        provider: Product.instance,
      }),

      new Field({
        name: "virtuel",
        creatable: false,
        editable: false,
        onTable: false,
        serializeCallback: (item: any, v: any) => {
          const virtuel = window.location.href.indexOf("/produits") === -1;
          return {
            ...item,
            virtuel,
          };
        },
        provider: Product.instance,
      }),

      new Field({
        name: "caracteristic",
        label: "Caractéristique",
        dataPath: "caracteristic",
        provider: Product.instance,
      }),

      new Field({
        name: "description",
        label: "Description",
        inputType: InputType.INPUT_TEXTAREA,
        provider: Product.instance,
        customData: {
          checkBoxData: {
            id: "hasAbonnement",
            cls: "form-check-label",
            customLabel: "Peut-on souscrire à un abonnement sur ce produit ?",
            styles: { fontSize: "80%", marginTop: "0px" },
            checked: false,
            eventName: "hasAbonnement",
            customInlineStyle: {
              marginTop: "5px",
              background: "rgb(255, 255, 255)",
            },
          },
        },

        listeners: [
          {
            type: "hasAbonnement",
            behaviour: "customAction",
            resolve: (v: any, item?: any) => {
              Product.instance.updateValue("hasAbonnement", v.value);
              return {
                checkBoxData: { ...item?.checkBoxData, checked: v.value },
              };
            },
          },
        ],
      }),

      new Field({
        name: "image",
        label: "Images",
        required: false,
        onTable: false,
        inputType: InputType.INPUT_FILES,
        provider: Product.instance,
        customData: {
          isImg64: true,
        },
        serializeCallback: (item: any, value: any) => {
          return { ...item, image: value[0], images: value };
        },
      }),

      new Field({
        name: "actif",
        label: "Status",
        renderKey: RENDER_STATUS,
        creatable: false,
        editable: false,
        serializeCallback: (item: any, value: any) => {
          return { ...item, actif: true };
        },
        provider: Product.instance,
      }),

      new Field({
        name: "actions",
        label: "Actions",
        dataPath: "productId",
        creatable: false,
        editable: false,
        serializeNew: false,
        serializeEdit: false,
        serializeView: false,
        renderKey: RENDER_ACTIONS,
        provider: Product.instance,
        customData: {
          renderOptions: {
            clsActions: "justify-content-center",
          },
          actions: [
            {
              label: "Détails",
              title: "Afficher les détails",
              action: "detailsActions",
              color: "btn-info",
              icon: "fa fa-eye",
            },
            {
              type: "btnGroup",
              color: "btn-primary",
              children:
                model.user.user?.profile === null
                  ? aChildAll
                  : model.confCrud.modification && model.confCrud.suppression
                  ? aChildAll
                  : model.confCrud.modification
                  ? aChildMod
                  : model.confCrud.suppression
                  ? aChildDel
                  : [],
            },
          ],
        },
      }),
    ];
    return definitions;
  }

  toView() {
    
    return [
      {
        title: "Informations générales",
        rows: [
          { label: "Product ID", value: this.productId },
          { label: "Reférence du produit", value: this.reference },
          { label: "Libellé", value: this.libelle },
          {
            label: "Montant",
            value: utils().formatAmount(this.devise?.code, this.amount),
          },
          { label: "Status", value: this.actif ? "Actif" : "Inactif" },
          {
            label: "Est ce un produit avec abonnement ?",
            value: this.hasAbonnement ? "Oui" : "Non",
          },
          {
            label: "Date de création",
            value: utils().formatDate(
              "DD/MM/YYYY [à] H:mm:ss",
              this.creationDate
            ),
          },
        ],
      },
      {
        title: "Description du produit",
        rows: [{ label: false, value: this.description }],
      },
      {
        title: "Catégorie du produit",
        rows: [{ label: "Libellé", value: this.productCategory?.libelle }],
      },
      {
        title: "Image du produit",
        rows: [{ label: "img", value: this.image, images: this.images }],
      },
    ];
  }

  getDeleteActionConfig() {
    return {
      path: `/product/delete/${this.productId}`,
      resolve: (res: any) => res?.message === "OK",
    };
  }

  initNewCallBack() {
    Product.instance.updateValue("hasAbonnement", false);
  }

  async initCustom(props: any) {
    return new Promise(async (resolve: any) => {
      const res = await restClient.get(`/subscription/product/${props.data?.produit?.productId}/all`);
      console.log("+++++ res +++++++");
      console.log(res);
      resolve({
        settings: {
          content: "productdetails",
          title: "Détails du produit",
          showSaveBtn: true,
          periodicities: res?.data || [],
          btnTitle: "Acheter ce produit",
          handleSubmit: () => utils().emitter.emit(appConstants.keys.eventKeys.SUBMIT_EVENT)
        },
      });
    });
  }


}

export { Product };

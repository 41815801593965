import React, { Component } from "react";
import { primaryColor } from "../../_helpers";

class HomeTarif extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="features-wrapperr container">
          <div className="features-content">
            <h1 className="features-title">Type de compte</h1>
          </div>
        </div>
        <div className="container">
          <div className="card-deckk row mb-3 text-center">
            <div className="col-lg-3 col-md-6">
              <div className="card mb-3 box-shadow">
                <div className="card-header">
                  <h4 className="my-0 font-weight-normal">Particuliers</h4>
                </div>
                <div className="card-body">
                  <div className="m-3">
                    <div className="offer-desc">
                      <p  className="offer-text" style={{ color: primaryColor()}}>
                        Ce type de compte est pour un usage privée et personnel.
                      </p>
                    </div>
                  </div>
                  <a
                    href="/signin?id=1"
                    type="button"
                    className="btn btn-lg btn-block"
                    style={{ backgroundColor: primaryColor() ,color:"white"}}
                  >
                    Souscrire
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="card mb-3 box-shadow">
                <div className="card-header">
                  <h4 className="my-0 font-weight-normal">Dealers</h4>
                </div>
                <div className="card-body">
                  <div className="m-3">
                    <div className="offer-desc">
                      <p className="offer-text" style={{ color: primaryColor()}}>
                        Ce type de compte est pour les agents qui ont de petits
                        magasins ou cabine (vente en détails).
                      </p>
                    </div>
                  </div>
                  <a
                    href="/signin?id=2"
                    type="button"
                    className="btn btn-lg btn-block btn-primary" 
                    style={{ backgroundColor: primaryColor() ,color:"white"}}
                  >
                    Souscrire
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="card mb-3 box-shadow">
                <div className="card-header">
                  <h4 className="my-0 font-weight-normal">Super Dealer</h4>
                </div>
                <div className="card-body">
                  <div className="m-3">
                    <div className="offer-desc">
                      <p className="offer-text" style={{ color: primaryColor()}}>
                        Ce type de compte est pour les agents qui vendent en
                        gros et détails.
                      </p>
                    </div>
                  </div>
                  <a
                    href="/signin?id=3"
                    type="button"
                    className="btn btn-lg btn-block btn-primary"
                    style={{ backgroundColor: primaryColor() ,color:"white"}}
                  >
                    Souscrire
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="card mb-3 box-shadow">
                <div className="card-header">
                  <h4 className="my-0 font-weight-normal">Entreprise</h4>
                </div>
                <div className="card-body">
                  <div className="m-3">
                    <div className="offer-desc">
                      <p className="offer-text" style={{ color: primaryColor()}}>
                        Ce type de compte vous permattra d'éffectuer le bulk
                        paiement.
                      </p>
                    </div>
                  </div>
                  <a
                    href="/signin?id=4"
                    type="button"
                    className="btn btn-lg btn-block btn-primary"
                    style={{ backgroundColor: primaryColor() ,color:"white"}}
                  >
                    Souscrire
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export { HomeTarif };

import { entreprisePrefix } from "../_config";
import { Transaction, BulkPayment } from "../_entities";

export const entrepriseRoutes = [
  {
    mainPath: entreprisePrefix,
    pageTitle: "Tableau de board entreprise",
    pageType: "kpi",
    provider: undefined,
  },
  {
    mainPath: `${entreprisePrefix}/transactions`,
    pageTitle: "Mes transactions",
    listTitle: "Liste de vos transactions",
    addBtnTitle: "Faire une transaction",
    provider: Transaction.instance,
    dataPath: (meta: any) => {
      const { user } = meta.model;
      const accountId = user?.accountId || 0;
      return Transaction.instance.applyFilters(
        `/account/history/${accountId}`,
        meta
      );
    },
    dataPathCallBack: (res: any, props: any) => {
      const data = Array.isArray(res.data) ? res.data : [];
      return data.filter((d: any) => d.operationType?.operationTypeId !== 14 && d.operationType?.operationTypeId !== 15);
    },
  },
  {
    mainPath: `${entreprisePrefix}/transactions/depots`,
    pageTitle: "Dépôts",
    addBtnTitle: "Faire une transaction",
    listTitle: "Liste des dépôts de fonds",
    provider: Transaction.instance,
    dataPath: (meta: any) => {
      const { user } = meta.model;
      const accountId = user?.accountId || 0;
      return Transaction.instance.applyFilters(
        `/account/history/${accountId}`,
        meta
      );
    },
    dataPathCallBack: (res: any, props: any) => {
      const data = Array.isArray(res.data) ? res.data : [];
      return data.filter((d: any) => d.operationType?.operationTypeId === 1);
    },
  },
  {
    mainPath: `${entreprisePrefix}/transactions/depots`,
    pageTitle: "Dépôts",
    addBtnTitle: "Faire une transaction",
    listTitle: "Liste des dépôts de fonds",
    provider: Transaction.instance,
    dataPath: (meta: any) => {
      const { user } = meta.model;
      const accountId = user?.accountId || 0;
      return Transaction.instance.applyFilters(
        `/account/history/${accountId}`,
        meta
      );
    },
    dataPathCallBack: (res: any, props: any) => {
      const data = Array.isArray(res.data) ? res.data : [];
      return data.filter((d: any) => d.operationType?.operationTypeId === 1);
    },
  },
  {
    mainPath: `${entreprisePrefix}/transactions/retraits`,
    pageTitle: "Retraits",
    addBtnTitle: "Faire une transaction",
    listTitle: "Liste des retraits éffectués",
    provider: Transaction.instance,
    dataPath: (meta: any) => {
      const { user } = meta.model;
      const accountId = user?.accountId || 0;
      return Transaction.instance.applyFilters(
        `/account/history/${accountId}`,
        meta
      );
    },
    dataPathCallBack: (res: any, props: any) => {
      const data = Array.isArray(res.data) ? res.data : [];
      return data.filter((d: any) => d.operationType?.operationTypeId === 2);
    },
  },
  {
    mainPath: `${entreprisePrefix}/transactions/transferts`,
    pageTitle: "Transferts",
    addBtnTitle: "Faire une transaction",
    listTitle: "Liste des transferts éffectués",
    provider: Transaction.instance,
    dataPath: (meta: any) => {
      const { user } = meta.model;
      const accountId = user?.accountId || 0;
      return Transaction.instance.applyFilters(
        `/account/history/${accountId}`,
        meta
      );
    },
    dataPathCallBack: (res: any, props: any) => {
      const data = Array.isArray(res.data) ? res.data : [];
      return data.filter(
        (d: any) =>
          d.operationType?.operationTypeId === 3 ||
          d.operationType?.operationTypeId === 4
      );
    },
  },
  {
    mainPath: `${entreprisePrefix}/transactions/frais`,
    pageTitle: "Frais",
    addBtnTitle: "Faire une transaction",
    listTitle: "Liste des frais prelevés sur vos transactions",
    provider: Transaction.instance,
    dataPath: (meta: any) => {
      const { user } = meta.model;
      const accountId = user?.accountId || 0;
      return Transaction.instance.applyFilters(
        `/account/history/${accountId}`,
        meta
      );
    },
    dataPathCallBack: (res: any, props: any) => {
      const data = Array.isArray(res.data) ? res.data : [];
      return data.filter((d: any) => d.operationType === null);
    },
  },
  {
    mainPath: `${entreprisePrefix}/standards`,
    pageTitle: "Transferts/retraits standards",
    addBtnTitle: "Faire une transaction",
    listTitle: "Liste des transferts et retraits standards",
    provider: Transaction.instance,
    dataPath: (meta: any) => {
      const { user } = meta.model;
      const accountId = user?.accountId || 0;
      return Transaction.instance.applyFilters(
        `entreprise/${accountId}/transferts`,
        meta
      );
    },
  },
  {
    mainPath: `${entreprisePrefix}/standards/transferts`,
    pageTitle: "Transferts standards",
    addBtnTitle: "Faire une transaction",
    listTitle: "Liste des transferts standards",
    provider: Transaction.instance,
    dataPath: (meta: any) => {
      const { user } = meta.model;
      const accountId = user?.accountId || 0;
      return Transaction.instance.applyFilters(
        `entreprise/${accountId}/transferts`,
        meta
      );
    },
    dataPathCallBack: (res: any, props: any) => {
      const data = Array.isArray(res.data) ? res.data : [];
      return data.filter((d: any) => d.operationType?.operationTypeId === 14);
    },
  },
  {
    mainPath: `${entreprisePrefix}/standards/retraits`,
    pageTitle: "Retraits standards",
    addBtnTitle: "Faire une transaction",
    listTitle: "Liste des retraits standards",
    provider: Transaction.instance,
    dataPath: (meta: any) => {
      const { user } = meta.model;
      const accountId = user?.accountId || 0;
      return Transaction.instance.applyFilters(
        `entreprise/${accountId}/transferts`,
        meta
      );
    },
    dataPathCallBack: (res: any, props: any) => {
      const data = Array.isArray(res.data) ? res.data : [];
      return data.filter((d: any) => d.operationType?.operationTypeId === 15);
    },
  },
  {
    mainPath: `${entreprisePrefix}/bulkpayments/historique`,
    pageTitle: "Bulk payments",
    listTitle: "Liste de vos paiements multiple",
    addBtnTitle: "Charger un csv",
    needFilterBlock: false,
    provider: BulkPayment.instance,
    dataPath: (meta: any) => {
      const { user } = meta.model;
      const accountId = user?.accountId || 0;
      return `/operation/bulk/files/account/${accountId}`;
    },
  },
  {
    mainPath: `${entreprisePrefix}/bulkpayments/status`,
    pageTitle: "Suivi des status",
    listTitle: "Liste des transactions liées aux paiements multiple",
    provider: Transaction.instance,
    dataPath: (meta: any) => {
      const { user } = meta.model;
      const accountId = user?.accountId || 0;
      return Transaction.instance.applyFilters(
        `/operation/bulk/all/account/${accountId}`,
        meta
      );
    },
  },
  {
    mainPath: `${entreprisePrefix}/bonvirtuel`,
    pageTitle: "Bon virtuel",
   // addBtnTitle: "Payer un bonvirtuel",
    listTitle: "Liste des paiements de bon virtuel",
    provider: Transaction.instance,
    dataPath: (meta: any) => {
      const { user } = meta.model;
      const accountId = user?.accountId || 0;
      return Transaction.instance.applyFilters(
        `/account/history/${accountId}`,
        meta
      );
    },
    dataPathCallBack: (res: any, props: any) => {
      const data = Array.isArray(res.data) ? res.data : [];
      return data.filter((d: any) => d.operationType?.operationTypeId === 16);
    },
  },

  {
    mainPath: `${entreprisePrefix}/achatDevise`,
    pageTitle: "Achat de dévise",
    provider: null,
    pageType: "achatDevise",
  },

  {
    mainPath: `${entreprisePrefix}/profile`,
    pageTitle: "Mon profile",
    provider: null,
    pageType: "accountProfil",
  },
];

import React from "react";

export function RenderStatus(props: any) {
  return (
    <i
      className={`fa ${
        props.data ? "fa-check text-success" : "fa-times text-danger"
      } `}
      aria-hidden="true"
    ></i>
  );
}

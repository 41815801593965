import React, { Component } from "react";
import { InputSelect } from "../APG/InputSelect";

class CorrTx extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      err: false,
      freeze: true,
      status: "",
      statusList: [
        { id: "PENDING", name: "En cours" },
        { id: "SUCCESS", name: "Succès" },
        { id: "FAILED", name: "Echec" },
        { id: "WAITING_WITHDRAW", name: "En attente de retrait" },
        { id: "WITHDRAW", name: "Retiré" },
      ],
      fundMov: [
        { id: "CREDIT", name: "Remettre l'argent dans le solde du client" },
        { id: "DEBIT", name: "Retirer l'argent dans le solde du client" },
      ],
      fund: "",
    };
    console.log("Les props depuis CorrTx", props);
    console.log(
      "La reference de la transaction depuis CorrTx",
      props.data.data.reference
    );
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.handleChangeFund = this.handleChangeFund.bind(this);
  }

  handleChangeStatus(e) {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({ [name]: value });
    this.props.stateSetter({
      settings: {
        content: "corrtx",
        title: "Corriger une transaction",
        showSaveBtn: true,
        btnTitle: "Corriger",
        action: "correction",
        fund: this.state.fund,
        status: value,
        message: "Attention opération délicate!!!",
      },
    });
  }
  handleChangeFund(e) {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({ [name]: value });
    this.props.stateSetter({
      settings: {
        content: "corrtx",
        title: "Corriger une transaction",
        showSaveBtn: true,
        btnTitle: "Corriger",
        action: "correction",
        fund: value,
        status: this.state.status,
        message: "Attention opération délicate!!!",
      },
    });
  }

  componentDidMount() {}

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <p className="text-danger">{this.props.settings.message}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <table className="table table-borderless">
              <tbody>
                <tr>
                  <td>Statut actuel</td>
                  <td>
                    <span className="alert-warning">
                      {this.props.data.data.status}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Statut à appliquer </td>
                  <td>
                    <InputSelect
                      onChange={this.handleChangeStatus}
                      value={this.state.status}
                      // label={"S*"}
                      name={"status"}
                      // freeze={freeze}
                      items={this.state.statusList}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Action</td>
                  <td>
                    <InputSelect
                      onChange={this.handleChangeFund}
                      value={this.state.fund}
                      // label={"S*"}
                      name={"fund"}
                      // freeze={freeze}
                      items={this.state.fundMov}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export { CorrTx };

import React from "react";
import { TableWrapper } from "../../admin";

export function MainContent(props: any) {
  
  return (
    <TableWrapper
      {...props}
      isDashboard={true}
      id={"client_table"}
      periodValue={""}
    />
  );
}

import React, { useEffect, useState } from "react";
import { utils } from "../../../_helpers";
import { BaseField, InvalidFeedBack, Label } from "../../common";

export function SigninField(props: any) {
  const originalField = props.originalField || props.field;
  const { inlineStyle } = originalField;
  const [isMounted, setIsMounted] = useState(false);
  const [value, setValue] = useState(originalField.value || "");
  const [field, setField] = useState(originalField);
  const [formLayout, setFormLayout] = useState(field.formLayout);
  const [customData, setCustomData] = useState(field.customData);

  useEffect(() => {
    setIsMounted(true);
    let resolving = false;
    setValue(field.value);
    if (field.listeners.length) {
      const handle = (v: any, e: any) => {
        let res: any = {};
        if (
          e.behaviour === "toggleLayout" ||
          e.behaviour === "customAction" ||
          e.behaviour === "customActionAsyncCallback"
        ) {
          if (typeof e.resolve === "function") {
            res = e.resolve(v, customData);
          }
        }

        if (isMounted) {
          if (e.behaviour === "resetValue") {
            setValue(field.defaultValue);
          } else if (e.behaviour === "setValue") {
            setValue(field.value);
          } else if (e.behaviour === "toggleLayout") {
            setFormLayout(res);
          } else if (e.behaviour === "customAction") {
            setCustomData(res?.customData || res);
            if (res?.type) {
              utils().emitter.emit(res?.type, res?.data);
            }
          } else if (e.behaviour === "customActionAsync") {
            if (!resolving) {
              resolving = true;
              e.resolve(v, customData).then((result: any) => {
                setCustomData(result.customData);
                utils().emitter.emit(result?.type, result?.data);
                resolving = false;
              });
            }
          } else if (e.behaviour === "customActionAsyncCallback") {
            setFormLayout(res.formLayout);
            setCustomData(res.customData);
          } else if (e.behaviour === "changeField") {
            e.resolve(v, field).then((res: any) => {
              setField(res);
              setCustomData(res.customData);
            });
          }
        }
      };
      field.listeners.forEach((e: any) => {
        utils().emitter.on(e.type, (v) => handle(v, e));
      });
      return () => {
        setIsMounted(false);
        field.listeners.forEach((e: any) => {
          utils().emitter.off(e.type, handle);
        });
      };
    }
  }, [isMounted]);

  return (
    <div className={formLayout} style={inlineStyle}>
      <div
        className={`field ${props.submitted && !field.isValid && "has-error"}`}
      >
        <Label {...props} />
        <div
          className={`ui input ${field.customClasses}`}
          style={{
            background: customData.bgColor,
          }}
        >
          <BaseField
            {...props}
            customData={customData}
            value={value}
            setValue={setValue}
          />
        </div>
        <InvalidFeedBack {...props} msg={field.validatorMessage} />
      </div>
    </div>
  );
}

import React, { Component, Suspense, lazy } from "react";
import ReactGA from "react-ga";
import { Router, Route, Switch, Redirect, withRouter } from "react-router-dom";
import { CentralPay } from "../pages/CentralPay";
import { CustomModal, Loader } from "../_components";
import { history } from "../_helpers";
import {
  withLogin,
  withSignin,
  withAdmin,
  withMarchand,
  withSuperMarchand,
  withEntreprise,
  withAdminFinancier,
  withAdminErecette,
  withPassReset,
} from "../_hoc";
import { PropsFromRedux, connector } from "../_reducers";
import { PrivateRoute } from "./PrivateRoute";

const Dashboard = lazy(() => import("../pages/Dashboard"));
const Home = lazy(() => import("../pages/Home"));
const Auth = lazy(() => import("../pages/Auth"));
const Admin = lazy(() => import("../pages/Admin"));

class App extends Component<PropsFromRedux, any> {
  constructor(props: PropsFromRedux) {
    super(props);
    this.state = {
      loading: true,
      unlisten: history.listen((location) => {
        // ReactGA.pageview(location.pathname + location.search);
      }),
    };
  }
  componentDidMount() {
    /*ReactGA.initialize("UA-190123860-1", { debug: true });
    ReactGA.pageview(window.location.pathname + window.location.search);
*/
    this.props.refreshUser().then((res) => {
      this.setState({ loading: false });
      /* firebase.initializeApp({
        apiKey: "AIzaSyA0FQK79CBg2KYJvmMSLLcO8BnmUJ4SseU",
        authDomain: "e-mpata.firebaseapp.com",
        databaseURL: "https://e-mpata.firebaseio.com",
        projectId: "e-mpata",
        storageBucket: "e-mpata.appspot.com",
        messagingSenderId: "286925695033",
        appId: "1:286925695033:web:52590b1611d29696ac45e8",
        measurementId: "G-1JY0SW6F5D",
      });

      const an = firebase.analytics();
      an.logEvent("login", { data: "test" });
      console.log(an);*/
    });
  }

  render() {
    const { model } = this.props;
    if (this.state.loading) {
      return <Loader />;
    }
    return (
      <React.Fragment>
        <Loader />
        <CustomModal />
        <Router history={history}>
          <Suspense fallback={<Loader />}>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route
                path="/login"
                render={() => {
                  if (model.isLogin) {
                    return <Redirect to={model.userPath} />;
                  }
                  return React.createElement(withLogin(Auth));
                }}
              />
              <Route
                path="/forget-password"
                render={() => {
                  if (model.isLogin) {
                    return <Redirect to={model.userPath} />;
                  }
                  return React.createElement(withPassReset(Auth));
                }}
              />
              <Route
                path="/signin"
                render={() => {
                  if (model.isLogin) {
                    return <Redirect to={model.userPath} />;
                  }
                  return React.createElement(withSignin(Auth));
                }}
              />
              <PrivateRoute
                path="/dashboard"
                model={model}
                component={withRouter(Dashboard)}
              />
              <PrivateRoute
                path="/marchand"
                model={model}
                component={withMarchand(Admin)}
              />
              <PrivateRoute
                path="/supermarchand"
                model={model}
                component={withSuperMarchand(Admin)}
              />
              <PrivateRoute
                path="/entreprise"
                model={model}
                component={withEntreprise(Admin)}
              />
              <PrivateRoute
                path="/admin/eRecette"
                model={model}
                component={withAdminErecette(Admin)}
              />
              <PrivateRoute
                path="/admin/financier"
                model={model}
                component={withAdminFinancier(Admin)}
              />
              <PrivateRoute
                path="/admin"
                model={model}
                component={withAdmin(Admin)}
              />
              <Route
                path="/success"
                component={() => <CentralPay state={"success"} />}
              />
              <Route
                path="/echec"
                component={() => <CentralPay state={"failed"} />}
              />
              <Route
                exact
                path="/notfound"
                component={() => <h1>404 not found !</h1>}
              />
              <Redirect from="*" to="/notfound" />
            </Switch>
          </Suspense>
        </Router>
      </React.Fragment>
    );
  }
}

const connected = connector(App);

export { connected as App };

import mitt, { Emitter } from "mitt";
import ReactDOMServer from "react-dom/server";
import moment from "moment";
import { BaseModel } from "../_models";
import { restClient } from "./restClient";
import { appConstants } from "../_constants";
import { User } from "../_entities";
import { model, utils } from "./index";
import { adminSidebarItems } from "../_config";
moment.locale("fr");

class Utils {
  private static _instance: Utils | undefined;
  public emitter: Emitter = mitt();

  constructor() {
    if (Utils._instance) {
      throw new Error(
        "Error : Instanciation failed : Use Utils.getInstance() instead of new."
      );
    }
    Utils._instance = this;
  }

  public static getInstance(): Utils {
    if (!Utils._instance) {
      return new Utils();
    }
    return Utils._instance;
  }

  public static reset() {
    Utils._instance = undefined;
  }

  getNumberInputValue(e: React.ChangeEvent<HTMLInputElement>): number {
    let mt = parseFloat(e.target.value);
    if (mt >= 1) {
      e.target.value = e.target.value.replace(/^0+/, "");
      mt = parseFloat(e.target.value);
    }
    if (!(typeof mt === "number" && mt > 0)) {
      mt = 0;
    }
    return mt;
  }

  changePageTitle(newTitle: string) {
    let title = document.getElementsByTagName("title")[0];
    title.innerHTML = `${newTitle} | ` + (model().appTitle || "e-Mpata");
  }

  ajustBody(reset: boolean = true) {
    let body = document.getElementsByTagName("body")[0];
    if (reset) {
      body.classList.remove("modal-open");
      body.style.paddingRight = "0px";
    } else {
      body.classList.add("modal-open");
      body.style.paddingRight = "15px";
    }
  }

  isActiveSidebarItem(item: any) {
    const { pathname } = window.location;
    if (item.path === pathname) {
      return true;
    }
    if (item?.subItems?.length) {
      const { subItems } = item;
      for (let index = 0; index < subItems.length; index++) {
        const element = subItems[index];
        if (element.path === pathname) {
          return true;
        }
      }
    }
    if (
      Array.isArray(item?.relatedPaths) &&
      item?.relatedPaths?.indexOf(pathname) !== -1
    ) {
      return true;
    }
    return false;
  }

  render(component: any) {
    return ReactDOMServer.renderToString(component);
  }

  formatDate(format: string, dt: string) {
    return moment(dt).format(format);
  }

  formatDouble(val: any) {
    let v = val || 0;
    try {
      v = parseFloat(val.toString());
    } catch (error) {
      v = 0;
    }

    if (isNaN(v)) {
      v = 0;
    }
    return `${v
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}`;
  }

  formatAmount(curr: string, val: any) {
    return `${this.formatDouble(val)} ${curr}`;
  }

  getUserPath(user: any) {
    let path = "/";
    if (!user) {
      return path;
    }

    const profil = user?.profil || user?.user?.profil || "User";
    const accountTypeId = user?.accountType?.accountTypeId || 0;
    if (profil === "Admin") {
      path = "/admin";
    } else if (accountTypeId < 4) {
      path = "/dashboard";
    } else if (accountTypeId === 4) {
      path = "/entreprise";
    } else if (accountTypeId === 5) {
      path = "/marchand";
    } else if (accountTypeId === 6) {
      path = "/supermarchand";
    } else if (accountTypeId === 7) {
      path = "/admin/financier";
    } else if (accountTypeId === 8) {
      path = "/admin/eRecette";
    } else {
      path = "/admin";
    }
    return path;
  }

  // checkpath(items: any[], account: User, path:string) {
  //   let a = 1;
  //   let y = 0;
  //   console.log("USER ACCOUNT PROFILE", account.user?.profile);
  //   console.log("ITEMS", items);
  //   items.map((item) => {
  //     if (Array.isArray(item.accessKeys)) {
  //       console.log("AccesKeys de comparaison", item.accessKeys);
  //       if (account.user?.profile?.resource) {
  //         account.user?.profile.resource.map((res) => {
  //           console.log("Tour dans les ressources:",y++);
  //           console.log("Code de comparaison:",res.code);
  //           const i = item.accessKeys.indexOf(res.code);
  //           if (i < 0) {
  //             console.log("Rien trouvé i<0");
  //             if(item.subItems){
  //               console.log("SubItem");
  //               item.subItems.map((sub) =>{
  //                 if(sub.path === path){
  //                   console.log("trouvé");
  //                   a = i;
  //                   return true;
  //                 }
  //               });
  //             }else{
  //               if(item.path === path){
  //                 a = i;
  //                 return true;
  //               }
  //             }
  //           }
  //         });
  //       }
  //     }
  //   });
  //   if (a < 0) {
  //     return false;
  //   }
  //   return true;
  // }

  checkpath(items: any[], account: User, path: string) {
    console.log("Le path", path);
    console.log("USER", account.user);
    for (var i = 0; i < items.length; i++) {
      if (account.user?.profile?.resource) {
        console.log("PROFILE OF USER", account.user?.profile);
        console.log("RESSOURCES OF USER", account.user?.profile.resource);
        for (var j = 0; j < Array(account.user?.profile.resource).length; j++) {
          if (
            items[i].accessKeys?.indexOf(
              account.user?.profile.resource[j]?.code
            ) > 0
          ) {
            return true;
          } else {
            if (items[i].subItems) {
              for (var k = 0; k < items[i].subItems.length; k++) {
                if (
                  items[i].subItems[k].path === path ||
                  path.includes(items[i].subItems[k].path)
                ) {
                  return true;
                }
              }
            } else {
              if (items[i].path === path) {
                return true;
              }
            }
          }
        }
      } else {
        return true;
      }
    }
    return false;
  }

  checkAccess(user: any, path: string) {
    console.log("LE PATH ACCESS", path);

    if (user) {
      if (path.indexOf(this.getUserPath(user)) !== -1) {
        if (this.getUserPath(user) === "/admin") {
          if (this.checkpath(adminSidebarItems, user, path)) {
            return true;
          } else {
            return false;
          }
        }
        return true;
      }
    }
    return false;
  }

  uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }
  uuidShort() {
    return "xxxxxxxx".replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  rand(arg: string | number | any[]) {
    if (Array.isArray(arg)) {
      return arg[this.rand(arg.length)];
    } else if (typeof arg === "number") {
      return Math.floor(Math.random() * arg);
    } else {
      return 4;
    }
  }

  getUserAccountId() {
    return BaseModel.getInstance().user?.accountId || 0;
  }

  getUserAccountMsisdn() {
    return BaseModel.getInstance().user?.msisdn || "";
  }

  async resolveKpis(kpis: any[], props: any) {
    const data: any[] = [];
    kpis.forEach((kpi) => {
      if (Array.isArray(kpi.deps)) {
        kpi.deps.forEach((dep: any) => {
          const i = data.findIndex((d) => d.key === dep.key);
          if (i < 0) {
            data.push(dep);
          }
        });
      }
    });
    const res = await Promise.all(
      data.map(async (dep: any) => {
        const path =
          typeof dep.path === "function" ? dep.path(props) : dep.path;
        const result = await restClient.get(path);
        return { dep, result };
      })
    );
    kpis.forEach((kpi) => {
      const deps: any = { data: [] };
      if (Array.isArray(kpi.deps)) {
        kpi.deps.forEach((dep: any) => {
          const i = res.findIndex((d) => d.dep.key === dep.key);
          if (i > -1) {
            deps.data.push(res[i]);
          }
        });
        const value = kpi.resolve(deps);
        this.emitter.emit(kpi.key, { value, error: false });
      }
    });
  }

  showLoader() {
    this.emitter.emit(appConstants.keys.eventKeys.FULL_LOADING_ACTION, true);
  }
  hideLoader() {
    this.emitter.emit(appConstants.keys.eventKeys.FULL_LOADING_ACTION, false);
  }

  getUrlParam(key: string): any {
    return this.getUrlParams().get(key) || "";
  }

  getUrlParams(): URLSearchParams {
    const parts = window.location.href.split("?");
    const search = parts[1] || "";
    const query = new URLSearchParams(search);
    return query;
  }

  isProfil() {
    return window.location.pathname.indexOf("/profile") !== -1;
  }

  filterSidebarItems(items: any[], account: User) {
    return items.filter((item) => {
      if (Array.isArray(item.accessKeys)) {
        if (account.user?.profile?.resource) {
          let a = -1;
          account.user?.profile.resource.map((res) => {
            const i = item.accessKeys.indexOf(res.code);
            if (i >= 0) {
              a = i;
            }
          });
          if (a < 0) {
            return false;
          }
        }
      }
      return true;
    });
  }
}

export { Utils };

import React from "react";
import { CustomLink } from "../../common/CustomLink/CustomLink";

export function SigninFooter(props: any) {
  return (
    <span
      style={{
        display: "flex",
        flexDirection: "row",
        marginTop: "10px",
        justifyContent: "center",
      }}
    >
      Vous possédez un compte?
      <CustomLink
        cls={"login-lost-password-link"}
        inlineStyle={{
          marginTop: "0px",
          marginLeft: "3px",
        }}
        to={"/login"}
        label={"Connectez-vous"}
      />
    </span>
  );
}

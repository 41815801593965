import { appConstants } from "../_constants";
import { BaseEntity } from "./BaseEntity";
import { FieldType } from "./enumerations";
import { Field } from "./Field";
import {BaseModel} from "../_models";

const { RENDER_ACTIONS, RENDER_ID } = appConstants.keys.renderKeys;

class AccountType extends BaseEntity {
  public static readonly addTitle = "Ajouter un type de compte";
  public static readonly showTitle = "Détails d'un type de compte";
  public static readonly editTitle = "Modifier un type de compte";
  public static readonly saveBtnTitle = "Enregistrer";
  public static readonly idField = "accountTypeId";
  public static readonly processNewSuccessMsg =
    "Le type de compte a été enregistré avec succès !";
  public static readonly processEditSuccessMsg =
    "Le type de compte a été enregistré avec succès !";
  public static readonly processNewPath = "account/type/add";
  public static readonly processEditPath = "account/type/update/_ID_";
  public static instance: AccountType = new AccountType();
  public static instances = [];
  public static relations = [];

  public static get definitions(): Field[] {
    const model: any = BaseModel.getInstance();
    const aChildAll = [
      {
        label: "Modifier",
        title: "Modifer ce type de compte",
        action: "editActions",
        color: "text-success",
        icon: "fa fa-edit",
      },
      {
        label: "Supprimer",
        title: "Supprimmer ce type de compte",
        action: "deleteActions",
        color: "text-danger",
        icon: "fa fa-trash",
      },
    ];
    const aChildMod = [
      {
        label: "Modifier",
        title: "Modifer ce type de compte",
        action: "editActions",
        color: "text-success",
        icon: "fa fa-edit",
      }
    ];
    const aChildDel = [
      {
        label: "Supprimer",
        title: "Supprimmer ce type de compte",
        action: "deleteActions",
        color: "text-danger",
        icon: "fa fa-trash",
      }
    ];
    const definitions = [
      new Field({
        name: "accountTypeId",
        type: FieldType.NUMBER,
        primary: true,
        creatable: false,
        editable: false,
        serializeNew: false,
        serializeEdit: false,
        label: "#",
        renderKey: RENDER_ID,
        provider: AccountType.instance,
      }),
      new Field({
        name: "libelle",
        label: "Intitulé",
        provider: AccountType.instance,
      }),

      new Field({
        name: "actions",
        label: "Actions",
        dataPath: "accountTypeId",
        creatable: false,
        editable: false,
        serializeNew: false,
        serializeEdit: false,
        serializeView: false,
        renderKey: RENDER_ACTIONS,
        provider: AccountType.instance,
        customData: {
          renderOptions: {
            clsActions: "justify-content-center",
          },
          actions: [
            {
              label: "Détails",
              title: "Afficher les détails",
              action: "detailsActions",
              color: "btn-info",
              icon: "fa fa-eye",
            },
            {
              type: "btnGroup",
              color: "btn-primary",
              children:model.user.user?.profile===null?aChildAll:
                  model.confCrud.modification && model.confCrud.suppression?aChildAll:
                      model.confCrud.modification?aChildMod:model.confCrud.suppression?aChildDel:[]
            },
          ],
        },
      }),
    ];
    return definitions;
  }

  toView() {
    return [
      {
        title: false,
        rows: [
          { label: "Account type ID", value: this.accountTypeId },
          { label: "Libellé", value: this.libelle },
        ],
      },
    ];
  }

  getDeleteActionConfig() {
    return {
      path: `/account/type/delete/${this.accountTypeId}`,
      resolve: (res: any) => res?.success,
    };
  }
}

export { AccountType };

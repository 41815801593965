import { adminPrefix } from "../_config";
import {
  Account,
  Transaction,
  User,
  AccountType,
  Affectation,
  AccountTypeQuota,
  AccountTypePrice,
  Abonnement,
  Product,
  Order,
  Profile,
  Fonctionnalite,
  Currency,
  Country,
  OperationType,
  ProductCategory,
  PriceIntervalle,
  Blacklist,
  MarqueBlanche,
  Commission,
  UIItem,
  Client,
} from "../_entities";
import { Workflows } from "../_entities/Workflows";
import { Workflowstep } from "../_entities/Workflowstep";
import { Price } from "../_entities/Price";
import { Permission } from "../_entities/Permission";
import { Validation } from "../_entities/Validation";
import { Card } from "../_entities/Card";

export const adminRoutes = [
  {
    mainPath: adminPrefix,
    pageTitle: "Tableau de board",
    provider: undefined,
    pageType: "kpi",
  },
  {
    mainPath: `${adminPrefix}/workflowstep/validations`,
    pageTitle: "Mes validations",
    listTitle: "Mes validations en cours",
    needFilterBlock: false,
    provider: Workflowstep.instance,
    dataPath: (meta: any) => {
      const { user } = meta.model;
      const idUser = user?.user?.userId || 0;
      return Workflowstep.instance.applyFilters(
        `/workflowstep/find/${idUser}`,
        meta
      );
    },
  },
  {
    mainPath: `${adminPrefix}/account/nfc/getAll`,
    pageTitle: "Toutes les cartes NFC",
    listTitle: "Liste des cartes NFC",
    addBtnTitle: "Ajouter une carte",
    needFilterBlock: false,
    provider: Card.instance,
    dataPath: (meta: any) => "/account/nfc/getAll",
  },
  {
    mainPath: `${adminPrefix}/workflows/validations/all`,
    pageTitle: "Toutes les validations",
    listTitle: "Liste des validations",
    needFilterBlock: false,
    provider: Validation.instance,
    dataPath: (meta: any) => "workflows/validations/all",
  },
  {
    mainPath: `${adminPrefix}/configurations/ets/frais`,
    pageTitle: "Frais Entreprises",
    addBtnTitle: "Ajouter un frais",
    listTitle: "Liste des frais entreprises",
    provider: Price.instance,
    needFilterBlock: false,
    dataPath: (meta: any) => "price/all/partner",
  },
  {
    mainPath: `${adminPrefix}/workflows/new`,
    pageTitle: "Nouveau Workflow",
    provider: null,
    pageType: "workflowNew",
  },
  {
    mainPath: `${adminPrefix}/workflows/model/all`,
    pageTitle: "Workflows",
    listTitle: "Liste des workflows",
    needFilterBlock: false,
    provider: Workflows.instance,
    dataPath: (meta: any) => "workflows/model/all",
  },
  {
    mainPath: `${adminPrefix}/permissions/all`,
    pageTitle: "Permissions",
    listTitle: "Liste des affectations des permissions aux Workflows",
    needFilterBlock: false,
    provider: Permission.instance,
    dataPath: (meta: any) => "permissions/all",
  },
  {
    mainPath: `${adminPrefix}/comptes`,
    pageTitle: "Comptes",
    listTitle: "Liste de tous les comptes",
    dataPath: (meta: any) => {
      return Account.instance.applyFilters("account/all", meta);
    },
    provider: Account.instance,
  },
  {
    mainPath: `${adminPrefix}/comptes/particuliers`,
    pageTitle: "Comptes particuliers",
    listTitle: "Liste des comptes de type particuliers",
    provider: Account.instance,
    dataPath: (meta: any) => {
      return Account.instance.applyFilters("account/all", meta);
    },
    dataPathCallBack: (res: any, props: any) => {
      const data = Array.isArray(res.data) ? res.data : [];
      return data.filter((d: any) => d.accountType.accountTypeId === 1);
    },
  },
  {
    mainPath: `${adminPrefix}/comptes/dealers`,
    pageTitle: "Comptes Dealers",
    listTitle: "Liste des comptes de type dealers",
    provider: Account.instance,
    dataPath: (meta: any) => {
      return Account.instance.applyFilters("account/all", meta);
    },
    dataPathCallBack: (res: any, props: any) => {
      const data = Array.isArray(res.data) ? res.data : [];
      return data.filter((d: any) => d.accountType.accountTypeId === 2);
    },
  },
  {
    mainPath: `${adminPrefix}/comptes/superdealers`,
    pageTitle: "Comptes SuperDealers",
    listTitle: "Liste des comptes de type super dealers",
    provider: Account.instance,
    dataPath: (meta: any) => {
      return Account.instance.applyFilters("account/all", meta);
    },
    dataPathCallBack: (res: any, props: any) => {
      const data = Array.isArray(res.data) ? res.data : [];
      return data.filter((d: any) => d.accountType.accountTypeId === 3);
    },
  },
  {
    mainPath: `${adminPrefix}/comptes/entreprises`,
    pageTitle: "Comptes Entreprises",
    listTitle: "Liste des comptes de type entreprise",
    provider: Account.instance,
    dataPath: (meta: any) => {
      return Account.instance.applyFilters("account/all", meta);
    },
    dataPathCallBack: (res: any, props: any) => {
      const data = Array.isArray(res.data) ? res.data : [];
      return data.filter((d: any) => d.accountType.accountTypeId === 4);
    },
  },
  {
    mainPath: `${adminPrefix}/comptes/details/`,
    pageTitle: "Détails d'un compte",
    pageType: "accountDetails",
    listTitle: "Liste des transactions réalisées sur ce compte",
    provider: Transaction.instance,
    dataPath: (meta: any) => {
      const { user } = meta.model;
      const accountId =
        typeof meta?.getAccountId === "function"
          ? meta?.getAccountId()
          : user?.accountId || 0;
      return Transaction.instance.applyFilters(
        `/account/history/${accountId}`,
        meta
      );
    },
  },

  {
    mainPath: `${adminPrefix}/transactions`,
    pageTitle: "Transactions",
    addBtnTitle: "Faire une transaction",
    listTitle: "Liste des transactions réalisées",
    provider: Transaction.instance,
    dataPath: (meta: any) => {
      return Transaction.instance.applyFilters("operation/all", meta);
    },
  },
  {
    mainPath: `${adminPrefix}/transactions/depots`,
    pageTitle: "Dépôts",
    addBtnTitle: "Faire un dépôt",
    listTitle: "Liste des dépôts d'argent",
    provider: Transaction.instance,
    dataPath: (meta: any) => {
      return Transaction.instance.applyFilters("operation/all", meta);
    },
    dataPathCallBack: (res: any, props: any) => {
      const data = Array.isArray(res.data) ? res.data : [];
      return data.filter((d: any) => d.operationType?.operationTypeId === 1);
    },
  },
  {
    mainPath: `${adminPrefix}/transactions/retraits`,
    pageTitle: "Retraits",
    addBtnTitle: "Faire un retrait",
    listTitle: "Liste des retraits de fonds",
    provider: Transaction.instance,
    dataPath: (meta: any) => {
      return Transaction.instance.applyFilters("operation/all", meta);
    },
    dataPathCallBack: (res: any, props: any) => {
      const data = Array.isArray(res.data) ? res.data : [];
      return data.filter((d: any) => d.operationType?.operationTypeId === 2);
    },
  },
  {
    mainPath: `${adminPrefix}/transactions/transferts`,
    pageTitle: "Transferts",
    addBtnTitle: "Faire un transfert",
    listTitle: "Liste des transferts éffectués",
    provider: Transaction.instance,
    dataPath: (meta: any) => {
      return Transaction.instance.applyFilters("operation/all", meta);
    },
    dataPathCallBack: (res: any, props: any) => {
      const data = Array.isArray(res.data) ? res.data : [];
      return data.filter(
        (d: any) =>
          d.operationType?.operationTypeId === 3 ||
          d.operationType?.operationTypeId === 4
      );
    },
  },
  {
    mainPath: `${adminPrefix}/transactions/frais`,
    pageTitle: "Frais",
    listTitle: "Liste des frais perçus sur les transactions",
    provider: Transaction.instance,
    dataPath: (meta: any) => {
      return Transaction.instance.applyFilters("operation/all", meta);
    },
    dataPathCallBack: (res: any, props: any) => {
      const data = Array.isArray(res.data) ? res.data : [];
      return data.filter((d: any) => d.operationType === null);
    },
  },
  {
    mainPath: `${adminPrefix}/configurations`,
    pageTitle: "Configurations générale",
    provider: null,
    pageType: "adminConfig",
  },
  {
    mainPath: `${adminPrefix}/configurations/types_compte`,
    pageTitle: "Types de compte",
    listTitle: "Liste des type de compte",
    addBtnTitle: "Ajouter un type de compte",
    needFilterBlock: false,
    provider: AccountType.instance,
    dataPath: (meta: any) => "account/type/all",
  },
  {
    mainPath: `${adminPrefix}/marque-blanche`,
    pageTitle: "Marque Blanche",
    listTitle: "Liste des marques blanches disponibles",
    addBtnTitle: "Ajouter une configuration marque blanche",
    needFilterBlock: true,
    provider: MarqueBlanche.instance,
    dataPath: (meta: any) => "account/type/all",
  },
  {
    mainPath: `${adminPrefix}/configurations/types_operations`,
    pageTitle: "Types d'opération",
    listTitle: "Liste des types d'opération",
    addBtnTitle: "Ajouter un type d'opération",
    needFilterBlock: false,
    provider: OperationType.instance,
    dataPath: (meta: any) => "operation/type/all",
  },
  {
    mainPath: `${adminPrefix}/marque_blanches/personnalisations`,
    pageTitle: "Personnalisations",
    listTitle: "Liste des personnalisations disponibles",
    addBtnTitle: "Ajouter une personnalisation",
    needFilterBlock: false,
    provider: UIItem.instance,
    dataPath: (meta: any) => "uiitem/all",
  },
  {
    mainPath: `${adminPrefix}/marque_blanches/clients`,
    pageTitle: "Clients",
    listTitle: "Liste des clients enregistrés",
    addBtnTitle: "Ajouter un client",
    needFilterBlock: false,
    addBtnAction: "customActions",
    provider: Client.instance,
    dataPath: (meta: any) => "client/all",
  },
  {
    mainPath: `${adminPrefix}/configurations/quotas`,
    pageTitle: "Quotas",
    listTitle: "Liste des quotas définis sur les types de compte",
    needFilterBlock: false,
    addBtnTitle: "Ajouter un quota",
    provider: AccountTypeQuota.instance,
    dataPath: (meta: any) => ({
      path: "account/type/all",
      children: {
        path: "account/type/find/_PLACE_HOLDER_",
        id_field: "accountTypeId",
      },
    }),
    dataPathCallBack: (res: any, props: any) => {
      const data = Array.isArray(res.data) ? res.data : [];
      const quotas: any[] = [];
      data.forEach((accountTypesDetail: any) => {
        accountTypesDetail?.data?.content?.accountTypeQuotas?.forEach(
          (accountTypesQuota: any) => {
            quotas.push(accountTypesQuota);
          }
        );
      });
      return quotas;
    },
  },
  {
    mainPath: `${adminPrefix}/configurations/prix`,
    pageTitle: "Gestion des frais",
    listTitle: "Liste des prix définis sur les types de compte",
    needFilterBlock: false,
    addBtnTitle: "Ajouter des frais",
    addBtnAction: "customActions",
    provider: AccountTypePrice.instance,
    dataPath: (meta: any) => ({
      path: "account/type/all",
      children: {
        path: "account/type/find/_PLACE_HOLDER_",
        id_field: "accountTypeId",
      },
    }),
    dataPathCallBack: (res: any, props: any) => {
      const data = Array.isArray(res.data) ? res.data : [];
      const prices: any[] = [];
      data.forEach((accountTypesDetail: any) => {
        accountTypesDetail?.data?.content?.accountTypePrices?.forEach(
          (accountTypePrice: any) => {
            prices.push(accountTypePrice);
          }
        );
      });
      return prices.sort((a, b) => a.price.valMin - b.price.valMin);
    },
  },
  {
    mainPath: `${adminPrefix}/configurations/intervales/prix`,
    pageTitle: "Gestion des intervalles de prix",
    listTitle: "Liste des intervalles de prix",
    needFilterBlock: false,
    addBtnTitle: "Ajouter un intervalle",
    provider: PriceIntervalle.instance,
    dataPath: (meta: any) => "/price/all",
    dataPathCallBack: (res: any, props: any) => {
      return res?.data?.sort((a, b) => a.valMin - b.valMin);
    },
  },
  {
    mainPath: `${adminPrefix}/configurations/commissions`,
    pageTitle: "Gestion des commissions",
    listTitle: "Liste des commissions",
    needFilterBlock: false,
    addBtnTitle: "Ajouter une commission",
    provider: Commission.instance,
    dataPath: (meta: any) => "/commission/all",
  },
  {
    mainPath: `${adminPrefix}/configurations/abonnements`,
    pageTitle: "Abonnements",
    listTitle: "Liste des plan d'abonnements",
    addBtnTitle: "Ajouter un plan",
    needFilterBlock: false,
    provider: Abonnement.instance,
    dataPath: (meta: any) => "product/plan/all",
  },
  {
    mainPath: `${adminPrefix}/produits`,
    pageTitle: "Produits",
    listTitle: "Liste des produits",
    needFilterBlock: false,
    addBtnTitle: "Ajouter un produit",
    provider: Product.instance,
    dataPath: (meta: any) => "product/all",
  },
  {
    mainPath: `${adminPrefix}/produits/categories`,
    pageTitle: "Catégories de produit",
    listTitle: "Liste des catégories de produits",
    needFilterBlock: false,
    addBtnTitle: "Ajouter une catégorie de produit",
    provider: ProductCategory.instance,
    dataPath: (meta: any) => "/product/category/all",
  },
  {
    mainPath: `${adminPrefix}/commandes`,
    pageTitle: "Commandes",
    listTitle: "Liste des commandes",
    needFilterBlock: false,
    dataPath: (meta: any) => "order/all",
    provider: Order.instance,
  },
  {
    mainPath: `${adminPrefix}/utilisateurs`,
    pageTitle: "Utilisateurs",
    listTitle: "Liste des utilisateurs",
    needFilterBlock: false,
    provider: User.instance,
    dataPath: (meta: any) => "user/all",
  },
  {
    mainPath: `${adminPrefix}/utilisateurs/blacklist`,
    pageTitle: "Liste noire",
    listTitle: "Liste des utilisateurs blacklistés",
    addBtnTitle: "Charger un CSV ",
    needFilterBlock: false,
    provider: Blacklist.instance,
    dataPath: (meta: any) => "user/blacklist/all",
  },

  {
    mainPath: `${adminPrefix}/internationalisation/pays`,
    pageTitle: "Pays",
    listTitle: "Liste des pays",
    needFilterBlock: false,
    provider: Country.instance,
    dataPath: (meta: any) => "country/all?blacklist=true",
  },
  {
    mainPath: `${adminPrefix}/internationalisation/devises`,
    pageTitle: "Devises",
    listTitle: "Liste des devises",
    addBtnTitle: "Ajouter une devise",
    needFilterBlock: false,
    provider: Currency.instance,
    dataPath: (meta: any) => "currency/all",
  },
  {
    mainPath: `${adminPrefix}/fonctionnalites`,
    pageTitle: "Fonctionnalités",
    listTitle: "Liste des fonctionnalites disponibles",
    needFilterBlock: false,
    addBtnTitle: "Ajouter une fonctionnalité",
    provider: Fonctionnalite.instance,
    dataPath: (meta: any) => "profile/resources",
  },
  {
    mainPath: `${adminPrefix}/profiles`,
    pageTitle: "Profiles",
    addBtnTitle: "Ajouter un profile",
    listTitle: "Liste des profiles disponibles",
    needFilterBlock: false,
    provider: Profile.instance,
    dataPath: (meta: any) => "profile/find/all",
  },

  {
    mainPath: `${adminPrefix}/profiles/affectations/`,
    pageTitle: "Affectations",
    listTitle: "Affecter des droits à un profile",
    pageType: "affectation",
    provider: Affectation.instance,
    needFilterBlock: false,
    dataPath: (meta: any) => "profile/resources",
  },
  {
    mainPath: `${adminPrefix}/profile`,
    pageTitle: "Mon profile",
    provider: null,
    pageType: "accountProfil",
  },
];

import React, { useState } from "react";
import { Input } from "./Input";

export function InputFiles(props: any) {
  const { setValue, field, customData } = props;
  const [files, setFiles] = useState([] as any[]);
  const onChange = async (e: any) => {
    const fl = e.target.files;
    if (customData.isImg64) {
      const fls: any[] = [];
      const fls2: any[] = [...files];
      for (let index = 0; index < fl.length; index++) {
        const element = fl[index];
        const f = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(element);
          reader.onload = function () {
            if (reader.result && typeof reader.result === "string") {
              const res = reader.result.split(",")[1];
              fls.push(res);
              resolve(reader.result);
            }
          };
          reader.onerror = function () {
            console.log("there are some problems");
          };
        });
        fls2.push(f);
        
      }
      setFiles(fls2);
      setValue(field.updateValue(fls));
    } else {
      setValue(field.updateValue(fl));
    }
  };
  return (
    <>
      <span className="btn btn-default btn-file btn-info btn-sm small pointer">
        Ajouter des fichiers{" "}
        <input type="file" className="pointer" onChange={onChange} multiple />
      </span>
      <div className="row">
        {files.map((f, i) => {
          return (
            <div className="col-md-3 py-2" key={i}>
              <img src={f} style={{ maxWidth: "50px", maxHeight: "50px" }} />
            </div>
          );
        })}
      </div>
    </>
  );
}

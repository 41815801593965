import secure from "../_assets/images/secure.png";
import bestprice from "../_assets/images/bestprice.png";
import rapide from "../_assets/images/rapide.png";
import mobileapp from "../_assets/images/mobileapp.png";
import ussd from "../_assets/images/ussd.png";
import currency from "../_assets/images/currency.png";

export const avantages = [
  {
    img: secure,
    titre: "Sécurisé",
    desc:
      "e-Mpata est le havre de sécurité où toutes vos opérations sont à l'abri de tous soucis.",
  },
  {
    img: bestprice,
    titre: "Meilleur prix",
    desc:
      "Effectuez toutes vos transactions avec e-Mpata à un prix hors concurrence.",
  },
  {
    img: rapide,
    titre: "Rapidité",
    desc:
      "Avec e-Mpata, faites vos transferts d'argent, vos achats d'unités et ceux de vos proches, les achats de produits et services à la vitesse de l'éclair.",
  },
  {
    img: mobileapp,
    titre: "Appli mobile",
    desc:
      "Télécharger l'application mobile e-Mpata sur Play Store et Apple Store."
  },
  {
    img: ussd,
    titre: "Multi opérateur via USSD",
    desc:
      "Une fois votre compte e-Mpata créé, réalisez des opérations simplement via USSD.",
  },
  {
    img: currency,
    titre: "Dévise multiple",
    desc:
      "Faites vos transactions indifférement en Franc Congolais ou en Dollar Américain.",
  },
] as Avantage[];

export interface Avantage {
  img: string;
  titre: string;
  desc: string;
}

import React, {Component} from "react";

class ShowMessage extends Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            err: false,
            freeze: true
        };
        console.log("Les props depuis Show Message", props);
    }

    componentDidMount() {

    }

    render() {
        return (
            <div className='container'>
                <div className='row'>
                    <small className="mb-1 message">
                        {this.props.data.notif.message}
                    </small>
                </div>
            </div>
        );
    }

}

export {ShowMessage};

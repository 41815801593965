import React from "react";

export function InputDetails2(props: any) {
  const { name, tel, email, valText, typeText } = props.customData;
  if (!typeText) {
    return (
      <table className=" w-100">
        <tbody>
          <tr>
            <td colSpan={2}>
              <span className="d-flex p-1">
                <small>
                  <span className="ben-infos-title">{valText || "-"}</span>
                </small>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
  return (
    <table className=" w-100">
      <tbody>
        <tr>
          <td>
            <span className="d-flex p-1">
              <small>
                <span className="font-weight-bolder mr-2">
                  <u> Nom : </u>
                </span>
                <span className="ben-infos-nom">{name || "-"}</span>
              </small>
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <span className="d-flex">
              <small>
                <span className="font-weight-bolder mr-2">
                  <u>Téléphone : </u>
                </span>{" "}
                <span className="ben-infos-prenom">{tel || "-"}</span>
              </small>
            </span>
          </td>
        </tr>
        <tr>
          <td colSpan={2}>
            <span className="d-flex p-1">
              <small>
                <span className="font-weight-bolder mr-2">
                  <u>Email : </u>
                </span>{" "}
                <span className="ben-infos-email">{email || "-"}</span>
              </small>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

import { cloneDeep } from "lodash";
import { routes } from "../_routes";
import { appConstants } from "../_constants";
import { User } from "../_entities";
import { BaseEntity } from "../_entities";
import { restClient, utils } from "../_helpers";

const { TABLE_DATA_CHANGE, TABLE_DATA_LOADING, TABLE_DATA_ERROR } =
  appConstants.keys.eventKeys;

class BaseModel {
  private static _instance: BaseModel | undefined;

  user: User = new User();
  userPath: string = "/";
  wsUrl: string = "";
  fullLoading = false;

  accCrud: any = {};
  txCrud: any = {};
  confCrud: any = {};
  prodCrud: any = {};
  cardCrud: any = {};
  comCrud: any = {};
  userCrud: any = {};
  i18Crud: any = {};
  profCrud: any = {};
  wflCrud: any = {};

  data: any = {};

  constructor() {
    if (BaseModel._instance) {
      throw new Error(
        "Error : Instanciation failed : Use BaseModel.getInstance() instead of new."
      );
    }
    BaseModel._instance = this;
  }

  public static getInstance(): BaseModel {
    if (!BaseModel._instance) {
      return new BaseModel();
    }
    return BaseModel._instance;
  }

  public static reset(): BaseModel {
    BaseModel._instance = undefined;
    return BaseModel.getInstance();
  }

  public get provider(): BaseEntity | undefined {
    return this.config.provider;
  }

  public get config(): any {
    const { pathname } = window.location;
    const i = routes.findIndex((r) => r.mainPath === pathname);
    if (i !== -1) {
      return routes[i];
    }
    return { fatalError: true, provider: undefined };
  }

  public get path(): string {
    return window.location.pathname;
  }

  public get isLogin(): boolean {
    return this.user.userId > 0 || this.user?.user?.userId > 0;
  }

  public get tableData(): any[] {
    if (this.provider) {
      return this.provider.tableData;
    }
    return [];
  }

  public async getResCrud(user: any, id) {
    const res = await restClient.get(
      `/profileresource/find/` + user.profile.id + "/" + id
    );
    return res;
  }

  public get tableConfig(): any {
    if (this.provider) {
      return {
        columns: this.provider.headers,
        data: this.tableData,
      };
    }
    return undefined;
  }

  public get isAdminFinancier(): boolean {
    return window.location.pathname.indexOf("/admin/financier") !== -1;
  }

  public get isAdminErecette(): boolean {
    return window.location.pathname.indexOf("/admin/eRecette") !== -1;
  }

  public get isAdmin(): boolean {
    return window.location.pathname.indexOf("/admin") !== -1;
  }

  public get isClient(): boolean {
    return window.location.pathname.indexOf("/dashboard") !== -1;
  }
  public get isMarchand(): boolean {
    return window.location.pathname.indexOf("/marchand") !== -1;
  }
  public get isMarchandOrSurperMarchand(): boolean {
    return this.isMarchand || this.isSurperMarchand;
  }
  public get isSurperMarchand(): boolean {
    return window.location.pathname.indexOf("/supermarchand") !== -1;
  }
  public get isEntreprise(): boolean {
    return window.location.pathname.indexOf("/entreprise") !== -1;
  }

  public get needPrint(): boolean {
    return true;
  }

  public get isEntrepriseStandart(): boolean {
    if (this.isEntreprise) {
      if (this.path.indexOf("/standards") !== -1) {
        return true;
      }
    }
    return false;
  }

  public get appTitle(): string {
    let titre = this.getCustomization("titre");
    return titre || "e-Mpata";
  }

  public get logo(): string {
    let logo=this.getCustomization("logo");
    return logo;
  }

  public get couleur(): string {
    let couleur=this.getCustomization("couleur")
    return couleur || "#6772e5";
  }

  getCustomization(key: string) {
    localStorage.clear();
    let values: any[] = [];

    if (this.user?.user?.client?.values?.length) {
      values = this.user?.user?.client?.values;
    } else if (this.user?.client?.values?.length) {
      values = this.user?.client?.values;
    }
    const i = values.findIndex((x) => x.item.name === key);
    let valeur:string=key;
    //console.log(values[i]);
    if (i > -1) {
      localStorage.setItem(valeur, values[i].value);
      return values[i].value;
    }else{
      return localStorage.getItem(valeur);
    }
  }
  getValue(key: string, defaultValue: any = "") {
    if (typeof this.data[key] !== "undefined") {
      return this.data[key];
    }
    return defaultValue;
  }

  updateValue(key: string, newValue: any = "") {
    this.data[key] = newValue;
  }

  getKpiMeta(item: any) {
    const defaultVal = "Chargement ... ";
    const value = this.getValue(item.key, defaultVal);
    const loading = value !== defaultVal ? false : true;
    const error = value === false ? true : false;

    return { loading, error, value, initialized: true };
  }

  async refreshDataTableData(props: any, data?: any, filter?: any) {
    if (data) {
      this.provider?.updateTableData(data.data);
      utils().emitter.emit(TABLE_DATA_CHANGE, data);
      return;
    }
    utils().emitter.emit(TABLE_DATA_LOADING, true);
    utils().emitter.emit(TABLE_DATA_ERROR, false);
    if (this.provider) {
      const res = await this.provider.refreshTableData({
        ...props,
        ...this.config,
        filter,
      });
      utils().emitter.emit(TABLE_DATA_CHANGE, res);
      utils().emitter.emit(TABLE_DATA_LOADING, false);
    }
  }

  async initUser(user?: any) {
    let res = user;

    if (!res) {
      res = await restClient.get({
        path: "/auth/status",
        prefix: false,
      });
    }
    if (res.success) {
      this.user.populate(res.data);
      this.userPath = res.path;
      this.wsUrl = res.wsUrl;
    } else {
      console.log(res);
      Object.assign(this.user, {
        user: {
          client: res.settings,
        },
      });
    }
    this.fullLoading = false;
    return true;
  }

  async logout(user?: any) {
    let res = await restClient.get({
      path: "/auth/logout",
      prefix: false,
    });
    this.fullLoading = false;
    return res.success === true;
  }

  public get checkAccess(): boolean {
    return true;
  }

  clone() {
    return cloneDeep(this);
  }
}

export { BaseModel };

import React from "react";
import { PropsFromRedux } from "../../../../_reducers";

export function SearchInput(props: PropsFromRedux & any) {
  return (
    <div id="search_group" className="w-100" data-state="shown">
      <div
        className="flex-column align-items-center"
        style={{ margin: "0 10px" }}
      >
        <div className="row">
          <div className="col-md-12">
            <input
              ref={props.searchRef}
              type="text"
              name="search_input"
              id="search_input"
              className="form-control"
              placeholder="Rechercher ..."
            />
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { Component } from "react";
import { InputSelect } from "../APG/InputSelect";

class ValidationTx extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      err: false,
      freeze: true,
      status: "",
      emetMsg: "",
      destMsg: "",
      fund: "",
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({ [name]: value });
    setTimeout(() => {
      const { emetMsg, destMsg } = this.state;
      this.props.stateSetter({
        settings: {
          ...this.props.settings,
          payload: {
            emetMsg,
            destMsg,
          },
        },
      });
    }, 400);
  }

  componentDidMount() {}

  render() {
    const { emetMsg, destMsg } = this.state;
    return (
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <p className="text-danger">{this.props.settings.message}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <table className="table table-borderless">
              <tbody>
                <tr>
                  <td>Status</td>
                  <td>
                    <span className="alert-warning">
                      {this.props.data.data.status}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Message à envoyer à l'emetteur</td>
                  <td>
                    <textarea
                      className="form-control"
                      name={"emetMsg"}
                      value={emetMsg}
                      onChange={this.handleChange}
                    ></textarea>
                  </td>
                </tr>
                <tr>
                  <td>Message à envoyer au destinataire</td>
                  <td>
                    <textarea
                      className="form-control"
                      name={"destMsg"}
                      value={destMsg}
                      onChange={this.handleChange}
                    ></textarea>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export { ValidationTx };

import React from "react";

export function PrintBtn(props: any) {
  const onClick = (e: any) => {
    e.preventDefault();
    const modal = document.getElementById("modal");
    if (modal) {
      const domClone = modal.cloneNode(true);
      let $printSection = document.getElementById("printSection");
      if (!$printSection) {
        $printSection = document.createElement("div");
        $printSection.id = "printSection";
        document.body.appendChild($printSection);
      }
      $printSection.innerHTML = "";
      $(domClone).find(".btn").hide();
      $(domClone).find(".alert").hide();
      $(domClone).find(".print").show();
      $printSection.appendChild(domClone);
      window.print();
      $("#printSection").empty();
    }
  };

  return (
    <button
      type="button"
      className="btn btn-info btn-sm box-shadow"
      onClick={onClick}
    >
      <i className="fa fa-print mr-1"></i>
      Imprimer
    </button>
  );
}

import { appConstants } from "../_constants";
import { utils } from "../_helpers";
import { BaseEntity } from "./BaseEntity";
import { FieldType, InputType } from "./enumerations";
import { Field } from "./Field";
import {BaseModel} from "../_models";

const {
  RENDER_ACTIONS,
  RENDER_ID,
  RENDER_DOUBLE,
} = appConstants.keys.renderKeys;

class Currency extends BaseEntity {
  public static readonly label: any;
  public static readonly className: any;
  public static readonly art: any;
  public static readonly addTitle = "Ajouter une devise";
  public static readonly editTitle = "Modifier une devise";
  public static readonly showTitle = "Détails d'une devise";
  public static readonly processNewSuccessMsg =
    "La devise a été enregistrée avec succès. Merci !";
  public static readonly processNewPath = "/currency/add";
  public static readonly processEditPath = "/currency/update/_ID_";
  public static readonly idField = "deviseId";
  public static instance: Currency = new Currency();
  public static instances = [];
  public static relations = [];


  public static get definitions(): Field[] {
    const model: any = BaseModel.getInstance();
    const aChild = [
      {
        label: "Modifier",
        title: "Modifer cette devise",
        action: "editActions",
        color: "text-dark",
        icon: "fa fa-edit",
      },
      {
        label: "Supprimer",
        title: "Supprimmer cette devise",
        action: "deleteActions",
        color: "text-danger",
        icon: "fa fa-trash",
      },
    ];
    const definitions = [
      new Field({
        name: "deviseId",
        type: FieldType.NUMBER,
        primary: true,
        creatable: false,
        editable: false,
        serializeNew: false,
        serializeEdit: false,
        label: "#",
        renderKey: RENDER_ID,
        provider: Currency.instance,
      }),
      new Field({
        name: "nom",
        label: "Nom",
        provider: Currency.instance,
      }),
      new Field({
        name: "code",
        label: "Code",
        provider: Currency.instance,
      }),
      new Field({
        name: "symbole",
        label: "Symbole",
        provider: Currency.instance,
      }),
      new Field({
        name: "taux",
        label: "Taux/Dollar",
        renderKey: RENDER_DOUBLE,
        inputType: InputType.INPUT_NUMBER,
        provider: Currency.instance,
        customData: {
          inputData: {
            attributes: {
              step: "0.01",
            },
          },
        },
      }),
      new Field({
        name: "actions",
        label: "Actions",
        dataPath: "deviseId",
        creatable: false,
        editable: false,
        serializeNew: false,
        serializeEdit: false,
        serializeView: false,
        renderKey: RENDER_ACTIONS,
        provider: Currency.instance,
        customData: {
          renderOptions: {
            clsActions: "justify-content-center",
          },
          actions: [
            {
              label: "Détails",
              title: "Afficher les détails",
              action: "detailsActions",
              color: "btn-info",
              icon: "fa fa-eye",
            },
            {
              type: "btnGroup",
              color: "btn-primary",
              children:model.user.user?.profile===null?aChild:model.i18Crud.modification?aChild:[]
            },
          ],
        },
      }),
    ];
    return definitions;
  }

  getDeleteActionConfig() {
    return {
      path: `/currency/delete/${this.deviseId}`,
      resolve: (res: any) => res?.message === "OK",
    };
  }

  toView() {
    return [
      {
        title: false,
        rows: [
          { label: "Devise ID", value: this.deviseId },
          { label: "Nom", value: this.nom },
          { label: "Symbole", value: this.symbole },
          { label: "Taux/Dollar", value: utils().formatDouble(this.taux) },
        ],
      },
    ];
  }
}

export { Currency };

import React, { useEffect, useState } from "react";
import { appConstants } from "../../../../_constants";
import { Account, Transaction, User } from "../../../../_entities";
import { restClient, utils } from "../../../../_helpers";
import { TableWrapper } from "../Table/TableWrapper";
import { AccountKpi } from "./AccountKpi";
import { AccountOwner } from "./AccountOwner";
import { AccountParametres } from "./AccountParametres/AccountParametres";
import { AccountStatus } from "./AccountStatus";
import { ReturnBtn } from "./ReturnBtn";

const { TABLE_DATA_CHANGE, TABLE_DATA_LOADING } = appConstants.keys.eventKeys;

declare var $: any;

export function AccountDetails(props: any) {
  const [data, setData] = useState({
    ...props,
    account: Account.instance,
    loading: true,
    items: [],
    customTableSettings: {
      columns: Transaction.instance.headers,
      data: [],
      loading: true,
    },
  });
  const { loading, account } = data;

  useEffect(() => {
    $(".active2.active3.sidebar-dropdown").find(".sidebar-submenu").slideUp();
    $(".active2.active3.sidebar-dropdown").removeClass("active");
    Promise.all([getAccount(props), getAccountHistory(props)]).then((res) => {
      let items: any[] = [];
      if (res[0]) {
        account.populate(res[0]);
      }
      if (res[1]) {
        const ress = Transaction.instance.updateTableData(res[1] as any[]);
        items = ress.data;
      }
      Promise.all([
        User.instance.initEdit({ ...account?.user }),
        account.initEdit({ ...account }),
      ]).then((res2) => {
        setData({
          ...data,
          account,
          items,
          loading: false,
          customTableSettings: {
            columns: Transaction.instance.headers,
            data: items,
            loading: false,
          },
        });
        utils().emitter.emit(TABLE_DATA_CHANGE, {
          success: true,
          data: items,
        });
        utils().emitter.emit(TABLE_DATA_LOADING, false);
      });
    });
  }, []);
  return (
    <div className="profile-wrapper">
      <div className="profile-header box-shadow d-flex rounded justify-content-between mb-2">
        <div className="d-flex flex-column justify-content-start">
          {!loading && <AccountOwner {...data} />}
          {!loading && <AccountStatus {...data} />}
          {loading && <h6 className="text-muted m-0"> Chargement ... </h6>}
        </div>
        <div className="cta-btn">
          <ReturnBtn {...data} />
        </div>
      </div>
      <div className="row m-0 py-1 bg-white rounded box-shadow mb-2">
        <AccountKpi {...data} />
      </div>

      <div className="profile-recent-operations box-shadow rounded mb-2">
        <TableWrapper
          {...data}
          getAccountId={() => utils().getUrlParam("id")}
          periodValue={""}
          shadow={true}
        />
      </div>
      <div className="profile-content box-shadow rounded mb-2 p-0">
        <AccountParametres
          {...data}
          onClick={(e: any) => onClick(e, !account.active, data, setData)}
          refreshAccount={(acc: Account) => setData({ ...data, account: acc })}
        />
      </div>
    </div>
  );
}

const getAccount = async (props: any) => {
  return new Promise(async (resolve) => {
    const id = utils().getUrlParam("id");
    if (id > 0) {
      const res = await restClient.get(`/account/find/${id}`);
      if (res.data?.accountId) {
        resolve(res.data);
      }
    }
    resolve(false);
  });
};

const getAccountHistory = async (props: any) => {
  return new Promise(async (resolve) => {
    const id = utils().getUrlParam("id");
    if (id > 0) {
      const res = await restClient.get(`/account/history/${id}`);
      if (Array.isArray(res.data)) {
        resolve(res.data);
      }
    }
    resolve(false);
  });
};

const onClick = (e: any, status: boolean, data: any, setData: any) => {
  e.preventDefault();
  utils().showLoader();
  const { account } = data;
  account.switchAction(status).then((res: any) => {
    if (res) {
      account.active = status;
      setData({ ...data, account });
    } else {
      alert("Une érreur s'est produite lors du changement de status !");
    }
    utils().hideLoader();
  });
};

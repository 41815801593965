import React from "react";

const badges = {
  1: "badge-primary",
  2: "badge-info",
  3: "badge-success",
  4: "badge-warning",
  5: "badge-info",
  6: "badge-success",
};
export function RenderProfile(props: any) {
  const { id, data } = props;
  const badge = (badges as any)[id] || "badge-secondary";
  return <span className={`badge ${badge}`}>{data}</span>;
}

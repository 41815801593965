import React, { useEffect, useState } from "react";
import { appConstants } from "../../../../../_constants";
import { utils, history } from "../../../../../_helpers";
import { PropsFromRedux } from "../../../../../_reducers";

const { TABLE_DATA_FILTER } = appConstants.keys.eventKeys;

export function DateFilter(props: PropsFromRedux & any) {
  const { model, periodValue } = props;
  const [config, setConfig] = useState({
    period: typeof periodValue !== "undefined" ? periodValue : "today",
    datedebut: "",
    datefin: "",
  });

  const isTrans = () => {
    return window.location.pathname === "/admin/transactions";
  };

  const onChange = (e: any) => {
    const { id, value } = e.target;
    const { period, datedebut, datefin } = config;
    let cfg = {
      period,
      datedebut,
      datefin,
    };
    if (id === "datedebut") {
      cfg.datedebut = value;
      setConfig(cfg);
      if (datefin !== "") {
        cfg.period = "0";
        utils().emitter.emit(TABLE_DATA_FILTER, cfg);
        model.refreshDataTableData(props, null, cfg);
      }
    } else if (id === "datefin") {
      cfg.datefin = value;
      setConfig(cfg);
      if (datedebut !== "") {
        cfg.period = "0";
        utils().emitter.emit(TABLE_DATA_FILTER, cfg);
        model.refreshDataTableData(props, null, cfg);
      }
    } else if (id === "search-input") {
      cfg.period = value;
      cfg.datefin = "";
      cfg.datedebut = "";
      setConfig(cfg);
      utils().emitter.emit(TABLE_DATA_FILTER, cfg);
      model.refreshDataTableData(props, null, cfg);
    }
  };

  useEffect(() => {
    const unsubscribe = history.listen((location, action) => {
      setConfig({
        period: typeof periodValue !== "undefined" ? periodValue : "today",
        datedebut: "",
        datefin: "",
      });
    });
    return () => {
      unsubscribe();
    };
  }, []);
  const { period, datedebut, datefin } = config;
  return (
    <div className="trier-block w-100">
      <input
        type="date"
        name="datedebut"
        id="datedebut"
        onChange={onChange}
        value={datedebut}
        className="form-control2 mr-2"
      />
      <input
        type="date"
        name="datefin"
        id="datefin"
        value={datefin}
        onChange={onChange}
        className="form-control2"
      />
      <small style={{ marginLeft: "10px", marginRight: "10px" }}>Trier </small>
      <select
        onChange={onChange}
        className="form-control2"
        value={period}
        id="search-input"
      >
        <option value="today">Aujourd'hui</option>
        <option value="yesterday">Hier</option>
        <option value="week">Il y a 7 jours</option>
        <option value="month">Il y a 30 jours</option>
        <option value="">Toutes</option>
        <option value="0">Personnalisé</option>
        {isTrans() && (
          <>
            <option value="deposit">Dépôts</option>
            <option value="withdraw">Retraits</option>
            <option value="transfer">Transferts</option>
            <option value="fees">Frais</option>
          </>
        )}
      </select>
    </div>
  );
}

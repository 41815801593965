import { appConstants } from "../_constants";
import { restClient, utils } from "../_helpers";
import { BaseModel } from "../_models";
import { Account } from "./Account";
import { BaseEntity } from "./BaseEntity";
import { FieldType, InputType } from "./enumerations";
import { Field } from "./Field";

const hostUrl = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;
const {
  RENDER_ID,
  RENDER_LONG_TEXT,
  RENDER_ACTIONS,
  RENDER_STATUS,
} = appConstants.keys.renderKeys;

const isSignin = window.location.pathname.indexOf("signin") !== -1;

class User extends BaseEntity {
  public static readonly showTitle = "Détails d'un utilisateur";
  public static instance: User = new User();
  public static instances = [];
  public static relations = [
    {
      dataPath: "account/type/all",
      key: "accountTypes",
      resolve: (res: any) => {
        if (Array.isArray(res?.data)) {
          return res.data
            .filter((d: any) => {
              if (isSignin) {
                return [1, 2, 3, 4, 6].indexOf(d.accountTypeId) !== -1;
              }
              return true;
            })
            .map((v: any) => ({ label: v.libelle, value: v.accountTypeId }));
        }
        return [];
      },
    },
    {
      dataPath: "country/all",
      key: "countries",
      resolve: (res: any) => {
        if (Array.isArray(res?.data)) {
          return res.data.map((v: any) => ({
            label: v.nomFr,
            value: `${v.code}-${v.devise}-${v.countryCode}`,
          }));
        }
        return [];
      },
    },
    {
      dataPath: "profile/find/all",
      key: "profile",
      resolve: (res: any) => {
        // console.log("Les profiles RES", res);
        if (Array.isArray(res?.data)) {
          return res.data.map((v: any) => ({
            label: v.libelle,
            value: v.id,
          }));
        }
        return [];
      },
    },
    // {
    //   dataPath: "currency/all",
    //   key: "currencies",
    //   resolve: (res: any) => {
    //     if (Array.isArray(res?.data)) {
    //       return res.data.map((v: any) => ({
    //         label: v.nom,
    //         value: v.deviseId,
    //         taux: v.taux,
    //       }));
    //     }
    //     return [];
    //   },
    // },
  ];

  public static get definitions(): Field[] {
    const model: any = BaseModel.getInstance();
    const aChild = [
      {
        actionCallback: (data: any) => {
          if (data.actif) {
            return {
              label: "Désactiver",
              title: "Désactiver ce compte",
              action: "switchActionsDeactivate",
              color: "text-danger",
              icon: "fa fa-times",
            };
          }
          return {
            label: "Activer",
            title: "Activer ce compte",
            action: "switchActionsActivate",
            color: "text-success",
            icon: "fa fa-check",
          };
        },
      },
      {
        actionCallback: (data: any) => {
          if (!data.blacklist) {
            return {
              label: "Blacklist on",
              title: "Mettre dans la liste noire",
              action: "processActions",
              color: "text-danger ",
              icon: "fa fa-times",
              processkey: "blacklist_on",
            };
          }
          return {
            label: "Blacklist off",
            title: " Retirer de la liste noire",
            action: "processActions",
            color: "text-success",
            icon: "fa fa-check",
            processkey: "blacklist_off",
          };
        },
      },
    ];

    const definitions = [
      new Field({
        name: "userId",
        type: FieldType.NUMBER,
        primary: true,
        creatable: false,
        editable: false,
        serializeNew: false,
        serializeEdit: false,
        label: "#",
        renderKey: RENDER_ID,
        provider: User.instance,
      }),
      new Field({
        name: "account_type_id",
        label: "Type de compte",
        formRowIndex: 0,
        formLayout: "col-md-12",
        formColumnIndex: 0,
        onTable: false,
        provider: User.instance,
        inputType: InputType.INPUT_SELECT,
        relationKey: "accountTypes",
        emitters: [{ type: "value_change_account_type_id" }],
        customData: {
          isDisabled: false,
        },
      }),
      new Field({
        name: "profile_id",
        label: "Profile",
        formRowIndex: 0,
        formLayout: "col-md-12",
        formColumnIndex: 0,
        onTable: false,
        creatable: false,
        provider: User.instance,
        inputType: InputType.INPUT_SELECT,
        relationKey: "profile",
        emitters: [{ type: "value_change_profile_id" }],
        customData: {
          isDisabled: false,
        },
      }),
      new Field({
        name: "nomEntreprise",
        label: "Nom de l'entreprise",
        formRowIndex: 1,
        formLayout: "col-md-12 d-none",
        formColumnIndex: 1,
        onTable: false,
        validators: [
          {
            meta: {
              key: "nomEntreprise",
              otherKey: "account_type_id",
              checkCondition: (v: any) => parseInt(v) === 4,
            },
            validate: (meta: any) =>
              User.instance.assertNotNullConditionnal(meta),
          },
        ],
        provider: User.instance,
        listeners: [
          {
            type: "value_change_account_type_id",
            behaviour: "toggleLayout",
            resolve: (v: any, customData?: any) => {
              if (parseInt(v.value) === 4) {
                return "col-md-12";
              }
              return "col-md-12 d-none";
            },
          },
        ],
      }),
      new Field({
        name: "idNational",
        label: "ID national",
        onTable: false,
        inlineStyle: isSignin ? { padding: "0 1rem 0 0" } : { padding: 0 },
        formRowIndex: 2,
        formLayout: "col-md-6 d-none",
        formColumnIndex: 0,
        provider: User.instance,
        listeners: [
          {
            type: "value_change_account_type_id",
            behaviour: "toggleLayout",
            resolve: (v: any, customData?: any) => {
              if (parseInt(v.value) === 4) {
                if (isSignin) {
                  return "col-md-6";
                }
                return "col-md-12";
              }
              if (isSignin) {
                return "col-md-6 d-none";
              }
              return "col-md-12 d-none";
            },
          },
        ],
        validators: [
          {
            meta: {
              key: "idNational",
              otherKey: "account_type_id",
              checkCondition: (v: any) => parseInt(v) === 4,
            },
            validate: (meta: any) =>
              User.instance.assertNotNullConditionnal(meta),
          },
        ],
      }),
      new Field({
        name: "rccm",
        label: "RCCM",
        onTable: false,
        formRowIndex: 2,
        formLayout: "col-md-6 d-none",
        formColumnIndex: 1,
        provider: User.instance,
        listeners: [
          {
            type: "value_change_account_type_id",
            behaviour: "toggleLayout",
            resolve: (v: any, customData?: any) => {
              if (parseInt(v.value) === 4) {
                if (isSignin) {
                  return "col-md-6";
                }
                return "col-md-12";
              }
              if (isSignin) {
                return "col-md-6 d-none";
              }
              return "col-md-12 d-none";
            },
          },
        ],
        validators: [
          {
            meta: {
              key: "rccm",
              otherKey: "account_type_id",
              checkCondition: (v: any) => parseInt(v) === 4,
            },
            validate: (meta: any) =>
              User.instance.assertNotNullConditionnal(meta),
          },
        ],
      }),
      new Field({
        name: "firstName",
        label: "Nom",
        formRowIndex: 3,
        formColumnIndex: 0,
        inlineStyle: isSignin ? { padding: "0 1rem 0 0" } : { padding: 0 },
        provider: User.instance,
      }),
      new Field({
        name: "lastName",
        label: "Prénom",
        formRowIndex: 3,
        formColumnIndex: 1,
        provider: User.instance,
      }),
      new Field({
        name: "country",
        label: "Pays",
        formRowIndex: 4,
        formLayout: "col-md-12",
        onTable: false,
        formColumnIndex: 0,
        provider: User.instance,
        inputType: InputType.INPUT_SELECT,
        relationKey: "countries",
        emitters: [{ type: "value_change_country" }],
        serializeCallback: (item: any, v: any) => {
          const country = v.split("-")[0];
          const currency = v.split("-")[1];
          return {
            ...item,
            country,
            currency,
          };
        },
      }),
      new Field({
        name: "msisdn",
        label: "Téléphone",
        formRowIndex: 5,
        formColumnIndex: 0,
        customClasses: " form-compose d-flex px-0 flex-row",
        inlineStyle: { padding: "0 1rem 0 0" },
        provider: User.instance,
        inputType: InputType.INPUT_TEL, 
        customData: {
          disabled: false,
          // bgColor: "#dedede",
          code: "",
        },
        listeners: [
          {
            type: "value_change_country",
            behaviour: "customAction",
            resolve: (v: any, customData?: any) => {
              let codeCountry = v.value.split("-")[2];
              codeCountry =
                codeCountry !== "null" && codeCountry !== null
                  ? codeCountry
                  : "";
              return {
                disabled: false,
                bgColor: "#fff",
                code: "+" + codeCountry,
              };
            },
          },
        ],
      }),
      new Field({
        name: "email",
        label: "Email",
        formRowIndex: 5,
        formColumnIndex: 1,
        renderKey: RENDER_LONG_TEXT,
        provider: User.instance,
      }),
      new Field({
        name: "actif",
        label: "Status",
        creatable: false,
        renderKey: RENDER_STATUS,
        provider: User.instance,
        editable: false,
        serializeNew: false,
        serializeEdit: false,
      }),

      new Field({
        name: "address",
        label: "Adresse",
        formRowIndex: 6,
        onTable: false,
        formColumnIndex: 0,
        inlineStyle: isSignin ? { padding: "0 1rem 0 0" } : { padding: 0 },
        provider: User.instance,
      }),
      new Field({
        name: "cni",
        label: "CNI",
        formRowIndex: 6,
        onTable: false,
        formColumnIndex: 1,
        required: false,
        provider: User.instance,
        inputType: InputType.INPUT_FILE,
        customData: {
          isImg64: false,
          inputInlineSyle: {
            padding: 0,
          },
        },
      }),
      new Field({
        name: "password",
        label: "Mot de passe",
        formRowIndex: 7,
        formColumnIndex: 0,
        onTable: false,
        customClasses: "d-flex flex-column",
        inlineStyle: { padding: "0 1rem 0 0" },
        provider: User.instance,
        inputType: InputType.INPUT_PASSWORD,
        customData: {
          checkBoxData: {
            id: "passview",
            cls: "form-check-label",
            customLabel: "Afficher le mot de passe.",
            styles: { fontSize: "80%", marginTop: "3px" },
            checked: false,
            eventName: "passview_change",
          },
        },
        listeners: [
          {
            type: "passview_change",
            behaviour: "customAction",
            resolve: (v: any, customData?: any) => {
              return {
                checkBoxData: { ...customData?.checkBoxData, checked: v.value },
              };
            },
          },
        ],
      }),
      new Field({
        name: "password2",
        label: "Confirmer mot de passe",
        formRowIndex: 7,
        formColumnIndex: 1,
        serializeNew: false,
        onTable: false,
        serializeEdit: false,
        formLayout: "col-md-6",
        provider: User.instance,
        inputType: InputType.INPUT_PASSWORD_2,
        customData: {
          checkBoxData: {
            id: "passview",
            cls: "form-check-label",
            customLabel: "Afficher le mot de passe.",
            styles: { fontSize: "80%", marginTop: "3px" },
            checked: false,
            eventName: "passview_change",
          },
        },
        validators: [
          {
            meta: { key: "password2" },
            validate: (meta: any) => User.instance.assertNotNull(meta),
          },
          {
            meta: {
              key: "password2",
              otherKey: "password",
              msg: "Les mots de passe doivent etre identiques",
            },
            validate: (meta: any) => User.instance.assertEquals(meta),
          },
          {
            meta: {
              key: "password2",
              otherKey: "password",
              msg:
                "Le mot de passe doit avoir au moins 1 lettre majuscule, 1 lettre minuscule, 1 chiffre, 1 caractère spécial et contenir au minimum 08 caractères.",
            },
            validate: (meta: any) => User.instance.assertPasswordStrenght(meta),
          },
        ],
        listeners: [
          {
            type: "passview_change",
            behaviour: "customAction",
            resolve: (v: any, customData?: any) => {
              return {
                checkBoxData: { ...customData?.checkBoxData, checked: v.value },
              };
            },
          },
        ],
      }),
      new Field({
        name: "recaptcha",
        label: "",
        onTable: false,
        formRowIndex: 8,
        formColumnIndex: 0,
        validatorMessage: "Veuillez valider le captcha",
        provider: User.instance,
        formLayout: "col-md-12 d-flex justify-content-center",
        inputType: InputType.INPUT_RECAPTCHA,
      }),
      new Field({
        name: "nickName",
        label: "Pseudonyme",
        creatable: false,
        editable: false,
        onTable: false,
        defaultValue: "",
        provider: User.instance,
      }),
      new Field({
        name: "pin",
        label: "",
        creatable: false,
        editable: false,
        onTable: false,
        defaultValue: Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000,
        provider: User.instance,
      }),
      new Field({
        name: "profil",
        label: "",
        creatable: false,
        editable: false,
        onTable: false,
        defaultValue: "User",
        provider: User.instance,
      }),
      new Field({
        name: "raisonSocial",
        label: "",
        creatable: false,
        editable: false,
        onTable: false,
        defaultValue: "",
        provider: User.instance,
      }),
      new Field({
        name: "secondaryAddress",
        label: "Adresse secondaire",
        creatable: false,
        editable: false,
        onTable: false,
        defaultValue: "",
        provider: User.instance,
      }),
      new Field({
        name: "link",
        label: "",
        creatable: false,
        editable: false,
        onTable: false,
        defaultValue: `${hostUrl}/signin/validate`,
        provider: User.instance,
      }),
      new Field({
        name: "validcode",
        label: "",
        creatable: false,
        editable: false,
        onTable: false,
        serializeNew: false,
        serializeEdit: false,
        serializeView: false,
        defaultValue: "",
        formLayout: "col-md-12",
        inlineStyle: {},
        customData: {
          placeholder: "Saisissez le code de validation",
          hasResendCodeLink: true
        },
        provider: User.instance,
      }),
      new Field({
        name: "actions",
        label: "Actions",
        dataPath: "userId",
        creatable: false,
        editable: false,
        serializeNew: false,
        serializeEdit: false,
        serializeView: false,
        renderKey: RENDER_ACTIONS,
        provider: User.instance,
        customData: {
          renderOptions: {
            clsActions: "justify-content-center",
          },
          actions: [
            {
              label: "Détails",
              title: "Afficher les détails",
              action: "detailsActions",
              color: "btn-info",
              icon: "fa fa-eye",
            },
            {
              type: "btnGroup",
              color: "btn-primary",
              children:
                model.user?.user?.profile === null
                  ? aChild
                  : model.userCrud.modification
                  ? aChild
                  : [],
            },
          ],
        },
      }),
    ];
    return definitions;
  }

  public static getPhoneValidationField(): Field[] {
    const i = User.definitions.findIndex((f) => f.name === "validcode");
    if (i > -1) {
      return [User.definitions[i]];
    }
    return [];
  }

  toView() {
    return [
      {
        title: false,
        rows: [
          { label: "User ID", value: this.userId },
          { label: "Nom", value: this.firstName },
          { label: "Prénom", value: this.lastName },
          { label: "Email", value: this.email },
          { label: "Téléphone", value: this.msisdn },
          { label: "Status", value: this.actif ? "Actif" : "Inactif" },
          {
            label: "Date de création",
            value: this.creationDate
              ? utils().formatDate("DD/MM/YYYY [à] HH:mm:ss", this.creationDate)
              : "-",
          },
        ],
      },
    ];
  }

  processNew(payload: any) {
    return new Promise(async (resolve, reject) => {
      console.log(payload);
      const { cni } = payload;
      payload.cni = "";
      const res = await restClient.post(`/user/adduser/?resend=${payload.resend}`, payload);
      const id = res?.data?.accountId || 0;
      const userId = res?.data?.user?.userId || 0;
      let cls = "alert-danger";
      let content = "";
      console.log(res);
      if (payload.resend === "yes") {
        resolve(true);
      } else {
        if (res.success && id > 0) {
          if (cni) {
            await this.uploadFile(payload, cni, userId);
          }
          cls = "alert-info";
          content =
            " Un code vous a été envoyé par SMS. Veuillez le saisir pour valider votre numéro.";
          localStorage.setItem("accountId", id);
          utils().emitter.emit("alert_message", { content, cls });
          resolve({ success: true, data: res.data });
        } else {
          content =
            res?.message ||
            "Une erreur s'est produite lors de l'inscription. Merci de reéssayer.";
          utils().emitter.emit("alert_message", { content, cls });
          resolve({ success: false, data: {} });
        }
      }
      
    });
  }

  buildFilePayload(cni: any, userId: any) {
    let ws_path = `/user/media/upload/${userId}/CNI`;
    console.log("==> buildPayload ws_path ", ws_path);
    const formData = new FormData();
    formData.append("file", cni);
    formData.append("ws_path", encodeURI(ws_path));
    return formData;
  }

  async uploadFile(payload: any, cni: any, userId: any) {
    console.log("==> payload processNew", payload);
    return new Promise(async (resolve) => {
      const body = this.buildFilePayload(cni, userId);
      const multipart =
        "multipart/form-data; boundary=empataSuperCoolBoundary--";
      const res = await restClient.post({
        multipart,
        body,
        prefix: "/multipart",
      });
      console.log("==> uploadFile res", res);
      resolve(res);
    });
  }

  processActivateCode() {
    const field = User.getPhoneValidationField();
    return new Promise(async (resolve, reject) => {
      const pin = field[0].value;
      const accountId = localStorage.getItem("accountId") || 0;
      const id = parseInt(accountId.toString()) || 0;
      const res = await restClient.get(`/account/find/${id}`);
      const idd = res?.data?.accountId || 0;
      const activationCode = res?.data?.activationCode || 0;
      let cls = "alert-danger";
      let content = "";
      console.log(res);
      if (res.success && idd > 0) {
        if (activationCode === pin) {
          cls = "alert-success";
          content =
            " Votre compte a été créé avec succès. L'equipe e-Mpata vous remercie !";
          utils().emitter.emit("alert_message", { content, cls });
          const account = new Account();
          account.populate(res.data);
          localStorage.removeItem("accountId");
          resolve({ success: true, data: account });
        } else {
          content = " Le code d'activation saisie est invalide ! Code = " + pin;
          utils().emitter.emit("alert_message", { content, cls });
          resolve({ success: false, data: {} });
        }
        return;
      }
      content =
        res?.message ||
        "Une erreur s'est produite lors de la validation. Merci de reéssayer.";
      utils().emitter.emit("alert_message", { content, cls });
      resolve({ success: false, data: {} });
    });
  }

  switchAction(status: boolean) {
    return new Promise(async (resolve, reject) => {
      const res = await restClient.get(`/user/change/${this.userId}/${status}`);
      if (res?.success && res?.data?.userId > 0) {
        resolve(true);
        return;
      }
      resolve(false);
    });
  }

  getDefaultValue(field: Field, value: any) {
    if (value) {
      const i = field.options.findIndex(
        (o: any) => o.value.toString().indexOf(`${value}-`) !== -1
      );
      if (i > -1) {
        return field.options[i];
      }
    }
    return undefined;
  }

  getAccountFields() {
    return Account.instance.getAccountFields();
  }

  getProfilFields() {
    return Account.instance.getProfilFields();
  }

  async processAction(props: any, processKey: string) {
    const { data } = props;
    let v = false;
    switch (processKey) {
      case "blacklist_on":
        v = await this.processBlacklist(data, true);
        break;
      case "blacklist_off":
        v = await this.processBlacklist(data, false);
        break;
      default:
        break;
    }
    return v;
  }
  async processBlacklist(user: User, state: boolean) {
    let v = false;
    const res = await restClient.get(`/user/blacklist/${user.userId}/${state}`);
    if (res.success && res?.data?.userId) {
      v = true;
    }
    return v;
  }

  
}

export { User };

import * as allcomponents from ".";

export * from "./Loader/Loader";
export * from "./CTAs/CTAs";
export * from "./Footer/Footer";
export * from "./Footer/CopyRight";
export * from "./CustomLink/CustomLink";
export * from "./Loader/LoadingGif";
export * from "./Alert/Alert";
export * from "./CustomButton/CustomButton";
export * from "./Fields";
export * from "./Error";
export * from "./Modal/CustomModal";
export * from "./Form";
export * from "./CodePinValidator/CodePinValidator";
export * from "./BonVirtuel/BonVirtuel";
export * from "./CentralPay/CentralPay";
export * from "./APG/ApgTransfertForm";
export * from "./EMpata2Wallet/EMpata2WalletForm";
export * from "./Taxe/PayerTaxeForm";
export * from "./Details/CorrTx";
export * from "./Details/ValidationTx";
export * from "./Details/ShowMessage";
export * from "./Details/HierachCompte";
export * from "./BulkPaymentPreview/BulkPaymentPreview";
export * from "../admin/Content/Workflows/ValidateStepForm";
export * from "../admin/Content/Workflows/WorkflowFormUpdate";
export * from "./ProductDetails/ProductDetails";

export const commoncomponents = {
  pinvalidator: allcomponents.CodePinValidator,
  bulkpaymentpreview: allcomponents.BulkPaymentPreview,
  priceform: allcomponents.PriceForm,
  clientform: allcomponents.ClientForm,
  productdetails: allcomponents.ProductDetails,
  bonvirtuelform: allcomponents.CentralPayForm,
  apgtransfertform: allcomponents.ApgTransfertForm,
  empata2wallet: allcomponents.EMpata2WalletForm,
  taxForm: allcomponents.PayerTaxeForm,
  corrtx: allcomponents.CorrTx,
  validationtx: allcomponents.ValidationTx,
  showMsg: allcomponents.ShowMessage,
  hierCompte: allcomponents.HierachCompte,
  validateStep: allcomponents.ValidateStepForm,
  updateWorkflow: allcomponents.WorkflowFormUpdate,
};

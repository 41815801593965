import React, { useEffect, useState } from "react";
import { utils } from "../../../_helpers";

export function InputMontantFrais(props: any) {
  const customData: any = props.customData || {};
  const [data, setData] = useState(customData);
  const { frais, total, tva } = data?.inputFraisData || {};
  let cls = data.cls;

  const { operationType } = props.customData.checkBoxData;
  const { current } = data.checkBoxData?.current
    ? data.checkBoxData
    : data;

  useEffect(() => {
    const handle = (v: any) => {
      console.log("v == ", v);
      setData(v || {});
    } ;
    utils().emitter.on("inputFraisData", (v) => handle(v));
    return () => {
      utils().emitter.off("inputFraisData", handle);
    };
  }, []);
  
  if (!total)  cls = "d-none";
  
  const opt = props?.model?.provider?.getValue("operationType");
  return (
    <div className={`row ${cls}`} style={{ marginTop: "10px" }}>
      {operationType !== 20 && (
        <div className="col-md-6">
          <p className="small" style={{ margin: "0px", fontSize: "12px" }}>
            Frais : {utils().formatAmount(current, frais)}{" "}
            {opt === 14 && (
              <span>
                {" "}
                <br /> TVA : {utils().formatAmount(current, tva)}
              </span>
            )}
          </p>
        </div>
      )}
      <div className="col-md-6">
        <p className="small" style={{ margin: "0px", fontSize: "12px" }}>
          Total : {utils().formatAmount(current, total + tva)}{" "}
        </p>
      </div>
    </div>
  );
}

import React from "react";
import { utils } from "../../../_helpers";
import { PropsFromRedux } from "../../../_reducers";
import { SidebarItemLink } from "./SidebarItemLink";
import { SidebarSubItem } from "./SidebarSubItem";

export function SidebarItem(props: PropsFromRedux & any) {
  const { navItem } = props;
  const active = utils().isActiveSidebarItem(navItem);
  return (
    <li {...getItemAttributes(navItem, active)}>
      <SidebarItemLink {...props} />
      {navItem?.subItems?.length && (
        <div
          className="sidebar-submenu"
          style={{
            display: active ? "block" : "none",
          }}
        >
          <ul>
            {navItem.subItems.map((item: any, i: number) => (
              <SidebarSubItem subItem={item} key={i} {...props} />
            ))}
          </ul>
        </div>
      )}
    </li>
  );
}

const getItemAttributes = (item: any, active: boolean) => {
  let liCls = "";
  let attrLi = {};
  if (active) {
    liCls += " active2 active3 active";
  }
  if (item?.subItems?.length) {
    liCls += " sidebar-dropdown";
  }
  if (liCls) {
    attrLi = { className: liCls };
  }
  return attrLi;
};

import React, { Component } from "react";
import { model, primaryColor } from "../../_helpers";

class HomeHeader extends Component {
  render() {
    return (
      <React.Fragment>
        <main>
          <div className="position-relative">
            <section className="section section-lg section-hero section-shaped">
              <div className="shape  shape-default" style={{ backgroundColor: primaryColor() }}>
                <span className="span-150"></span>
                <span className="span-50"></span>
                <span className="span-50"></span>
                <span className="span-75"></span> 
                <span className="span-100"></span>
                <span className="span-75"></span>
                <span className="span-50"></span>
                <span className="span-100"></span>
                <span className="span-50"></span>
                <span className="span-100"></span>
              </div>
              <div className="shape-container d-flex align-items-center py-lg container">
                <div className="col px-0">
                  <div className="align-items-center justify-content-center row">
                    <div className="text-center col-lg-6">
                      <h1 className="titre_accroche">
                        Mon bon virtuel à solution multiple
                      </h1>
                      <p className="lead text-white">
                        {model().appTitle || "e-Mpata"} : le meilleur choix pour
                        vos transactions électroniques.
                      </p>
                      <div className="btn-wrapper align-items-center justify-content-center row">
                        <a
                          href="https://play.google.com/store/apps/details?id=com.snedac.empata"
                          rel="noopener noreferrer"
                          target="_blank"
                          className="googleplay btn-white btn-icon mb-3 mb-sm-0 btn btn-default btn-lg"
                        >
                          {" "}
                        </a>
                        <a
                          href="/"
                          rel="noopener noreferrer"
                          target="_blank"
                          className="applestore btn-icon mb-3 mb-sm-0 btn btn-github btn-lg"
                        >
                          {" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export { HomeHeader };

import { appConstants } from "../_constants";
import { utils } from "../_helpers";
import { BaseModel } from "../_models";
import { BaseEntity } from "./BaseEntity";
import { FieldType } from "./enumerations";
import { Field } from "./Field";

const {
  RENDER_ACTIONS,
  RENDER_ID,
  RENDER_AMOUNT,
  RENDER_DATETIME,
  RENDER_AMOUNT_TOTAL,
  RENDER_CLIENT_ORDER,
} = appConstants.keys.renderKeys;

class Order extends BaseEntity {
  public static readonly label: any;
  public static readonly className: any;
  public static readonly art: any;
  public static instance: Order = new Order();
  public static instances = [];
  public static relations = [];

  public static get definitions(): Field[] {
    const model: any = BaseModel.getInstance();
    const isClient = model.isClient;
    return [
    new Field({
      name: "pk",
      type: FieldType.NUMBER,
      primary: true,
      creatable: false,
      editable: false,
      serializeNew: false,
      serializeEdit: false,
      label: "#",
      dataPath: "orderId",
      renderKey: isClient ? RENDER_CLIENT_ORDER : RENDER_ID,
      provider: Order.instance,
    }),
    new Field({
      name: "reference",
      label: "Reférence",
      onTable: !isClient,
      provider: Order.instance,
    }),
    new Field({
      name: "pu",
      label: "Prix unitaire",
      renderKey: RENDER_AMOUNT,
      dataPath: "product.amount",
      onTable: !isClient,
      provider: Order.instance,
      customData: {
        renderOptions: {
          className: "min-w-150",
        },
      },
    }),
    new Field({
      name: "qte",
      label: "Quantité",
      onTable: !isClient,
      provider: Order.instance,
    }),
    new Field({
      name: "amount",
      label: "Prix Total",
      onTable: !isClient,
      renderKey: RENDER_AMOUNT_TOTAL,
      provider: Order.instance,
      customData: {
        renderOptions: {
          className: "min-w-150",
        },
      },
    }),
    new Field({
      name: "orderDate",
      onTable: !isClient,
      label: "Date de creation",
      renderKey: RENDER_DATETIME,
      provider: Order.instance,
    }),

    new Field({
      name: "orderState",
      label: "Status",
      onTable: !isClient,
      provider: Order.instance,
    }),

    new Field({
      name: "actions",
      label: "Actions",
      dataPath: "orderId",
      onTable: !isClient,
      creatable: false,
      editable: false,
      serializeNew: false,
      serializeEdit: false,
      serializeView: false,
      renderKey: RENDER_ACTIONS,
      provider: Order.instance,
      customData: {
        renderOptions: {
          clsActions: "justify-content-center",
        },
        actions: [
          {
            label: "Détails",
            title: "Afficher les détails",
            action: "detailsActions",
            color: "btn-info",
            icon: "fa fa-eye",
          },
        ],
      },
    }),
  ];
}

  toView() {
    return [
      {
        title: "Information générales",
        rows: [
          {
            label: "Reférence",
            value: this.reference,
          },
          {
            label: "Quantité",
            value: this.qte,
          },
          {
            label: "Prix unitaire total",
            value: utils().formatAmount("CDF", this.product.amount),
          },
          {
            label: "Montant total",
            value: utils().formatAmount("CDF", this.amount * this.qte),
          },
          {
            label: "Status",
            value: this.orderState,
          },
          {
            label: "Date de la commande",
            value: utils().formatDate("DD/MM/YYYY [à] H:mm:ss", this.orderDate),
          },
        ],
      },
      {
        title: "Produit/Service",
        rows: [
          { label: "Product ID", value: this.product.productId },
          { label: "Reférence du produit", value: this.product.reference },
          { label: "Libellé", value: this.product.libelle },
        ],
      },
    ];
  }
}

export { Order };

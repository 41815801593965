import { appConstants } from "../_constants";
import { restClient, utils } from "../_helpers";
import { BaseModel } from "../_models";
import { BaseEntity } from "./BaseEntity";
import { FieldType, InputType } from "./enumerations";
import { Field } from "./Field";

const {
  RENDER_ACTIONS,
  RENDER_ID,
  RENDER_STATUS,
  RENDER_DATETIME,
} = appConstants.keys.renderKeys;

class SubscriptionPlanPeriod extends BaseEntity {
  public static readonly label: any;
  public static readonly className: any;
  public static readonly art: any;
  public static readonly addTitle = "Faire un reapprovisionnement";
  public static readonly showTitle = "Détails d'un reapprovisionnement";
  public static instance: SubscriptionPlanPeriod = new SubscriptionPlanPeriod();
  public static instances = [];
  public static get relations() {
    return [
      { 
        dataPath: `subscription/marchand/${BaseModel.getInstance().user.accountId}/all`,
        key: "plans",
        resolve: (res: any) => {
          if (Array.isArray(res?.data)) {
            return res?.data.map((v: any) => ({
              label: `${v.product.libelle} (${v.quota.libelle})`,
              value: v.id,
            }));
          }
          return [];
        },
      },
    ]
  };

  public static definitions = [
    new Field({
      name: "id",
      type: FieldType.NUMBER,
      primary: true,
      creatable: false,
      editable: false,
      serializeNew: false,
      serializeEdit: false,
      label: "#",
      renderKey: RENDER_ID,
      provider: SubscriptionPlanPeriod.instance,
    }),
    new Field({
      name: "product",
      label: "Produit/Service",
      creatable: false,
      editable: false,
      dataPath: "subscriptionPlan.product.libelle",
      provider: SubscriptionPlanPeriod.instance,
    }),
    new Field({
      name: "quota",
      label: "Périodicité",
      creatable: false,
      editable: false,
      dataPath: "subscriptionPlan.quota.libelle",
      provider: SubscriptionPlanPeriod.instance,
    }),
    new Field({
      name: "amount",
      label: "Prix",
      creatable: false,
      editable: false,
      dataPath: "subscriptionPlan.amount",
      provider: SubscriptionPlanPeriod.instance,
    }),
    new Field({
      name: "state",
      label: "Status",
      creatable: false,
      editable: false,
      renderKey: RENDER_STATUS,
      provider: SubscriptionPlanPeriod.instance,
    }),
    new Field({
      name: "startDate",
      label: "Date début",
      creatable: false,
      editable: false,
      renderKey: RENDER_DATETIME,
      provider: SubscriptionPlanPeriod.instance,
    }),
    new Field({
      name: "endDate",
      label: "Date fin",
      creatable: false,
      editable: false,
      renderKey: RENDER_DATETIME,
      provider: SubscriptionPlanPeriod.instance,
    }),
    new Field({
      name: "file",
      label: "Fichier",
      inputType: InputType.INPUT_FILE,
      dataPath: "subscriptionPlanPeriodFile.name",
      provider: SubscriptionPlanPeriod.instance,
    }),
    new Field({
      name: "subscriptionPlan",
      label: "Plan souscription",
      onTable: false,
      relationKey: "plans",
      inputType: InputType.INPUT_SELECT,
      formRowIndex: 0,
      provider: SubscriptionPlanPeriod.instance,
    }),
    new Field({
      name: "actions",
      label: "Actions",
      dataPath: "id",
      creatable: false,
      editable: false,
      serializeNew: false,
      serializeEdit: false,
      serializeView: false,
      renderKey: RENDER_ACTIONS,
      provider: SubscriptionPlanPeriod.instance,
      customData: {
        renderOptions: {
          clsActions: "justify-content-center",
        },
        actions: [
          {
            label: "Détails",
            title: "Afficher les détails",
            action: "detailsActions",
            color: "btn-info",
            icon: "fa fa-eye",
          },
          {
            label: "Supprimer",
            title: "Supprimmer ce type de compte",
            action: "deleteActions",
            color: "btn-danger",
            icon: "fa fa-trash",
          },
        ],
      },
    }),
  ];

  toView() {
    return [
      {
        title: false,
        rows: [
          { label: "Reaprovisionnement ID", value: this.id },
          { label: "Product/Service", value: this.subscriptionPlan?.product.libelle },
          { label: "Périodicité", value: this.subscriptionPlan?.quota.libelle },
          { label: "Montant", value: utils().formatAmount(this.subscriptionPlan?.devise ? this.subscriptionPlan?.devise.code : "CDF", this.subscriptionPlan?.amount) },
          { label: "Date de début", value: utils().formatDate("DD/MM/YYYY [à] HH:mm:ss", this.startDate) },
          { label: "Date de fin", value: utils().formatDate("DD/MM/YYYY [à] HH:mm:ss", this.endDate) },
        ],
      },
    ];
  }

  processNew(payload: any) {
    return new Promise(async (resolve, reject) => {
      const { file } = payload;
      
      let res: any = {};
      if (file) {
        res = await this.uploadFile(file);
      }
      
      let cls = "alert-danger";
      let content = "";

      if (res.success) {
        cls = "alert-success";
        content =
          " Fichier uploadé avec succès.";
        utils().emitter.emit("alert_message", { content, cls });
        resolve({ success: true });
      } else {
        content =
          res?.message ||
          "Une erreur s'est produite lors de l'upload. Merci de reéssayer.";
        utils().emitter.emit("alert_message", { content, cls });
        resolve({ success: false, data: {} });
      }
    });
  }

  buildFilePayload(fichier: any) {
    let ws_path = `/subscription/${this.getValue("subscriptionPlan", 0)}/provision`;
    const formData = new FormData();
    formData.append("file", fichier);
    formData.append("ws_path", encodeURI(ws_path));
    return formData;
  }

  async uploadFile(fichier: any) {
    return new Promise(async (resolve) => {
      const body = this.buildFilePayload(fichier);
      const multipart =
        "multipart/form-data; boundary=empataSuperCoolBoundary--";
      const res = await restClient.post({
        multipart,
        body,
        prefix: "/multipart",
      });
      console.log("==> uploadFile res", res);
      resolve(res);
    });
  }

  getDeleteActionConfig() {
    return {
      path: `/subscription/file/delete/${this.id}`,
      resolve: (res: any) => res?.success,
    };
  }
}

export { SubscriptionPlanPeriod };

import { appConstants } from "../_constants";
import { restClient, utils } from "../_helpers";
import { BaseEntity } from "./BaseEntity";
import { FieldType, InputType } from "./enumerations";
import { Field } from "./Field";
import { BaseModel } from "../_models";

const {
  RENDER_ACTIONS,
  RENDER_ID,
  RENDER_STATUS,
  RENDER_ACCOUNT_TYPE,
  RENDER_AMOUNT,
  RENDER_OPERATION_TYPE,
  RENDER_AMOUNT_PRIX,
} = appConstants.keys.renderKeys;

class AccountTypePrice extends BaseEntity {
  public static readonly addTitle = "Ajouter un frais sur un type de compte";
  public static readonly showTitle = "Détails des frais";
  public static readonly saveBtnTitle = "Enregistrer";
  public static readonly processNewSuccessMsg =
    "Les frais ont été enregistrés avec succès. Merci !";
  public static readonly processNewPath = "account/type/addPrice";
  public static readonly processEditPath = "account/type/updatePrice";
  public static instance: AccountTypePrice = new AccountTypePrice();
  public static instances = [];
  public static relations = [
    {
      dataPath: "account/type/all",
      key: "accountTypes",
      resolve: (res: any) => {
        if (Array.isArray(res?.data)) {
          return res?.data.map((v: any) => ({
            label: v.libelle,
            value: v.accountTypeId,
          }));
        }
        return [];
      },
    },
    {
      dataPath: "price/all",
      key: "prices",
      resolve: (res: any) => {
        if (Array.isArray(res?.data)) {
          return res.data
            .sort((a, b) => a.valMin - b.valMin)
            .map((v: any) => ({
              label: `${v.libelle} ( de ${v.valMin} à ${v.valMax})`,
              value: v.priceId,
            }));
        }
        return [];
      },
    },
    {
      dataPath: "operation/type/all",
      key: "operationTypes",
      resolve: (res: any) => {
        if (Array.isArray(res?.data)) {
          return res?.data.map((v: any) => ({
            label: v.libelle,
            value: v.operationTypeId,
            category: v.category,
            hasFees: v.hasFees,
            active: v.active,
          }));
        }
        return [];
      },
    },
  ];
  public static get definitions(): Field[] {
    const model: any = BaseModel.getInstance();
    const aChildAll = [
      {
        label: "Modifier",
        title: "Modifer ce prix",
        action: "editActions",
        color: "text-dark",
        icon: "fa fa-edit",
      },
      {
        actionCallback: (data: any) => {
          if (data.actif) {
            return {
              label: "Désactiver",
              title: "Désactiver ce compte",
              action: "switchActionsDeactivate",
              color: "text-danger ",
              icon: "fa fa-times",
            };
          }
          return {
            label: "Activer",
            title: "Activer ce compte",
            action: "switchActionsActivate",
            color: "text-success",
            icon: "fa fa-check",
          };
        },
      },
      {
        label: "Supprimer",
        title: "Supprimmer ce type de compte",
        action: "deleteActions",
        color: "text-danger",
        icon: "fa fa-trash",
      },
    ];
    const aChildMod = [
      {
        label: "Modifier",
        title: "Modifer ce prix",
        action: "editActions",
        color: "text-dark",
        icon: "fa fa-edit",
      },
      {
        actionCallback: (data: any) => {
          if (data.actif) {
            return {
              label: "Désactiver",
              title: "Désactiver ce compte",
              action: "switchActionsDeactivate",
              color: "text-danger ",
              icon: "fa fa-times",
            };
          }
          return {
            label: "Activer",
            title: "Activer ce compte",
            action: "switchActionsActivate",
            color: "text-success",
            icon: "fa fa-check",
          };
        },
      },
    ];
    const aChildDel = [
      {
        label: "Supprimer",
        title: "Supprimmer ce type de compte",
        action: "deleteActions",
        color: "text-danger",
        icon: "fa fa-trash",
      },
    ];
    const definitions = [
      new Field({
        name: "pk",
        type: FieldType.NUMBER,
        primary: true,
        creatable: false,
        editable: false,
        serializeNew: false,
        serializeEdit: false,
        dataPath: "percent",
        label: "#",
        renderKey: RENDER_ID,
        provider: AccountTypePrice.instance,
      }),
      new Field({
        name: "valMin",
        label: "Montant minimal",
        renderKey: RENDER_AMOUNT,
        dataPath: "price.valMin",
        creatable: false,
        serializeNew: false,
        provider: AccountTypePrice.instance,
        customData: {
          renderOptions: {
            className: "min-w-150",
          },
        },
      }),
      new Field({
        name: "valMax",
        label: "Montant maximun",
        renderKey: RENDER_AMOUNT,
        serializeNew: false,
        creatable: false,
        dataPath: "price.valMax",
        provider: AccountTypePrice.instance,
        customData: {
          renderOptions: {
            className: "min-w-150",
          },
        },
      }),
      new Field({
        name: "operationType",
        label: "Type d'opération",
        renderKey: RENDER_OPERATION_TYPE,
        inputType: InputType.INPUT_SELECT,
        relationKey: "operationTypes",
        formRowIndex: 0,
        formLayout: "col-md-12",
        provider: AccountTypePrice.instance,
      }),
      new Field({
        name: "amount",
        label: "Valeur des frais",
        renderKey: RENDER_AMOUNT_PRIX,
        inputType: InputType.INPUT_NUMBER,
        provider: AccountTypePrice.instance,
        customData: {
          inputData: {
            attributes: {
              step: "0.01",
            },
          },
          checkBoxData: {
            id: "isPercent",
            cls: "form-check-label",
            customLabel: "Cette valeur est définie en pourcentage.",
            styles: { fontSize: "80%", marginLeft: "19px" },
            checked: false,
            eventName: `is_percent_change`,
            customInlineStyle: { marginLeft: "1px", marginTop: "7px" },
            customLayoutInlineStyle: {
              marginTop: "6px",
              padding: 0,
            },
          },
        },
        listeners: [
          {
            type: `is_percent_change`,
            behaviour: "customAction",
            resolve: (v: any, customData?: any) => {
              AccountTypePrice.instance.updateValue("isPercent", v?.value);
              return customData;
            },
          },
        ],
        serializeCallback: (item: any, v: any) => {
          let amount = 0,
            percent = 0;
          const isPercent = AccountTypePrice.instance.getValue(
            "isPercent",
            false
          );
          amount = !isPercent ? v : 0;
          percent = isPercent ? v : 0;
          return { ...item, amount, percent };
        },
      }),
      new Field({
        name: "accountType",
        label: "Type de compte",
        formRowIndex: 1,
        relationKey: "accountTypes",
        renderKey: RENDER_ACCOUNT_TYPE,
        inputType: InputType.INPUT_SELECT,
        dataPath: "accountType.libelle",
        provider: AccountTypePrice.instance,
      }),
      new Field({
        name: "price",
        label: "Intervalle de prix",
        inputType: InputType.INPUT_SELECT,
        formRowIndex: 2,
        relationKey: "prices",
        onTable: false,
        provider: AccountTypePrice.instance,
      }),
      new Field({
        name: "actif",
        label: "Status",
        creatable: false,
        renderKey: RENDER_STATUS,
        provider: AccountTypePrice.instance,
        serializeCallback: (item: any, v: any) => {
          return { ...item, actif: true };
        },
      }),

      new Field({
        name: "actions",
        label: "Actions",
        dataPath: "percent",
        creatable: false,
        editable: false,
        serializeNew: false,
        serializeEdit: false,
        serializeView: false,
        renderKey: RENDER_ACTIONS,
        provider: AccountTypePrice.instance,
        customData: {
          actions: [
            {
              label: "Détails",
              title: "Afficher les détails",
              action: "detailsActions",
              color: "btn-info",
              icon: "fa fa-eye",
            },
            {
              type: "btnGroup",
              color: "btn-primary",
              children:
                model.user.user?.profile === null
                  ? aChildAll
                  : model.confCrud.modification && model.confCrud.suppression
                  ? aChildAll
                  : model.confCrud.modification
                  ? aChildMod
                  : model.confCrud.suppression
                  ? aChildDel
                  : [],
            },
          ],
        },
      }),
    ];
    return definitions;
  }

  toView() {
    return [
      {
        title: "Information générales",
        rows: [
          {
            label: "Intervalle de prix",
            value: `De ${utils().formatAmount(
                this.price.devise?this.price.devise.code:"CDF",
              this.price.valMin
            )} à ${utils().formatAmount(this.price.devise?this.price.devise.code:"CDF", this.price.valMax)}`,
          },
          {
            label: "Valeur des frais ",
            value: this.amount
              ? `${utils().formatAmount(this.price.devise?this.price.devise.code:"CDF", this.amount)}`
              : `${this.percent}%`,
          },
          { label: "Status ", value: this.actif ? "Actif" : "Inactif" },
        ],
      },
      {
        title: "Type d'opération",
        rows: [{ label: false, value: this.operationType.libelle }],
      },
      {
        title: "Type de compte",
        rows: [{ label: false, value: this.accountType.libelle }],
      },
    ];
  }

  getSwitchActionConfig(status: boolean) {
    return {
      path: `/account/type/${this.pk.accountTypeId}/${this.pk.operationTypeId}/state/price/${this.pk.priceId}/${status}`,
      resolve: (res: any) => res?.data?.status === "OK",
    };
  }

  getDeleteActionConfig() {
    return {
      path: `/account/type/${this.pk.accountTypeId}/${this.pk.operationTypeId}/delete/price/${this.pk.priceId}`,
      method: "get",
      resolve: (res: any) => res?.data?.status === "OK",
    };
  }


  async initCustom(props: any) {
    await this.initNew();
    return {
      settings: {
        content: "priceform",
        title: "Ajouter des frais",
        showSaveBtn: true,
      },
    };
  }

  getRows(tab: string) {
    switch (tab) {
      case "tabOne":
        this.updateValue("tab", "tabOne");
        return this.rows;
      case "tabTwo":
        this.updateValue("tab", "tabTwo");
        return this.rows;
      case "tabThree":
        this.updateValue("tab", "tabThree");
        return this.rows;
      case "tabFour":
        this.updateValue("tab", "tabFour");
        return this.rows;

      default:
        break;
    }
    return [];
  }

  filterFieldCallback(fields: Field[]) {
    if (this.pk) {
      return fields;
    }
    const tab = this.getValue("tab", "tabOne");
    const operationTypes = this.getValue("operationTypes", []).filter(
      (o: any) => o.active && o.hasFees
    );
    let filteredOperationTypes = [];
    switch (tab) {
      case "tabOne":
        return fields.filter(
          (f) => ["accountType", "price"].indexOf(f.name) !== -1
        );
      case "tabTwo":
        filteredOperationTypes = operationTypes.filter(
          (o: any) => o.category === 1
        );
        return this.getFields(filteredOperationTypes);

      case "tabThree":
        filteredOperationTypes = operationTypes.filter(
          (o: any) => o.category === 2
        );
        return this.getFields(filteredOperationTypes);

      case "tabFour":
        filteredOperationTypes = operationTypes.filter(
          (o: any) => o.category === 3
        );
        return this.getFields(filteredOperationTypes);

      default:
        break;
    }
    return [];
  }

  getFields(items: any[]) {
    return items.map((item) => {
      return new Field({
        name: `amount_${item.value}`,
        label: `Frais de ${item.label}`,
        renderKey: RENDER_AMOUNT_PRIX,
        inputType: InputType.INPUT_NUMBER,
        provider: AccountTypePrice.instance,
        required: false,
        customData: {
          inputData: {
            attributes: {
              step: "0.01",
            },
          },
          checkBoxData: {
            id: `isPercent_${item.value}`,
            cls: "form-check-label",
            customLabel: "Cette valeur est définie en pourcentage.",
            styles: { fontSize: "80%", marginLeft: "19px" },
            checked: false,
            eventName: `is_percent_change_${item.value}`,
            customInlineStyle: { marginLeft: "1px", marginTop: "7px" },
            customLayoutInlineStyle: {
              marginTop: "6px",
              padding: 0,
            },
          },
        },
        listeners: [
          {
            type: `is_percent_change_${item.value}`,
            behaviour: "customAction",
            resolve: (v: any, customData?: any) => {
              AccountTypePrice.instance.updateValue(
                `isPercent_${item.value}`,
                v?.value
              );
              return customData;
            },
          },
        ],
        serializeCallback: (payload: any, v: any) => {
          let amount = 0,
            percent = 0;
          const isPercent = AccountTypePrice.instance.getValue(
            `isPercent_${item.value}`,
            false
          );
          amount = !isPercent ? v : 0;
          percent = isPercent ? v : 0;
          return { ...payload, amount, percent, operationType: item.value };
        },
      });
    });
  }

  validateCallback(props?: any, submitted: boolean = true) {
    if (this.pk) {
      return true;
    }
    const price = this.getValue("price", 0);
    const accountType = this.getValue("accountType", 0);
    if (price > 0 && accountType > 0) {
      if (!submitted) {
        return true;
      }
      const payload = this.serialize();
      if (payload.length) {
        return true;
      } else {
        props?.errorCallBack(
          "Vous devez renseigner les frais pour au moins un type d'opération."
        );
      }
    }
    return false;
  }

  serializeCallback(payload: any, props: any) {
    if (this.pk) {
      return payload;
    }
    const operationTypes = this.getValue("operationTypes", []).filter(
      (o: any) => o.active && o.hasFees
    );
    const fields = this.getFields(operationTypes);
    const payloads = fields
      .map((f) => {
        if (typeof f.serializeCallback === "function") {
          return f.serializeCallback(payload, this.getValue(f.name, 0));
        }
      })
      .filter((p) => p.amount > 0 || p.percent > 0);
    return payloads;
  }

  async customProcessNew(payloads: any) {
    const res = await Promise.all(
      payloads.map((payload: any) => {
        return new Promise(async (resolve, reject) => {
          const path = this.getProcessNewPath(payload);
          const res = await restClient.post(path, payload);
          resolve(res);
        });
      })
    );
    console.log("==> RESULT ", res);
    return this.toViewFromArray(res);
  }

  toViewFromArray(data: any[]) {
    const item = data[0];
    const rows = data.map((d) => {
      if (d?.data?.pk) {
        return {
          label: `Frais de ${d?.data?.operationType?.libelle}`,
          value: d?.data?.amount
            ? `${utils().formatAmount("CDF", d?.data?.amount)}`
            : `${d?.data?.percent}%`,
        };
      }
    });
    return [
      {
        title: "Type de compte",
        rows: [{ label: false, value: item?.data?.accountType?.libelle }],
      },
      {
        title: `${item?.data?.price?.libelle} ( de ${item?.data?.price?.valMin} à ${item?.data?.price?.valMax})`,
        rows,
      },
    ];
  }

  initEditCallBack(props: any) {
    const i = this.data.findIndex((d) => d.name === "operationType");
    this.data[i].value = this.pk.operationTypeId;
    const j = this.data.findIndex((d) => d.name === "accountType");
    this.data[j].value = this.pk.accountTypeId;
    const k = this.data.findIndex((d) => d.name === "price");
    this.data[k].value = this.pk.priceId;
  }
}

export { AccountTypePrice };

import React, { useEffect, useState } from "react";
import { appConstants } from "../../../_constants";
import { Transaction } from "../../../_entities";
import { primaryColor, utils } from "../../../_helpers";

const { TABLE_DATA_CHANGE, USER_BALANCES_CHANGE } = appConstants.keys.eventKeys;

export function Solde(props: any) {
  const [total, setTotal] = useState(0);
  const [soldes, setSolde] = useState({
    cdf:-1,
    usd:-1,
  });
  useEffect(() => {
    const handleTableDataChangeEvent = (res: any) => {
      setTotal(res?.data?.length || 0);
    };
    utils().emitter.on(TABLE_DATA_CHANGE, (res) => {
      handleTableDataChangeEvent(res);
    });
    const handleSoldeChangeEvent = (data: any) => {
      setSolde(data);
    };
    utils().emitter.on(USER_BALANCES_CHANGE, (res) => {
      handleSoldeChangeEvent(res);
    });
    Transaction.instance.refreshBalances();
    return () => {
      utils().emitter.off(TABLE_DATA_CHANGE, handleTableDataChangeEvent);
      utils().emitter.off(USER_BALANCES_CHANGE, handleSoldeChangeEvent);
    };
  }, []);
  return (
    <main>
      <div className="position-relative">
        <section
          className="section section-shaped"
          style={{ paddingBottom: 0 }}
        >
          <div
            className="shape shape-style-1 shape-default" 
            style={{ maxHeight: "250px" ,backgroundColor:primaryColor()}}
          >
            <span className="span-150"></span>
            <span className="span-50"></span>
            <span className="span-50"></span>
            <span className="span-75"></span>
            <span className="span-100"></span>
            <span className="span-75"></span>
            <span className="span-50"></span>
            <span className="span-100"></span>
            <span className="span-50"></span>
            <span className="span-100"></span>
          </div>
          <div
            className="shape-container d-flex align-items-center container"
            style={{ paddingTop: "2rem", paddingBottom: "2rem" }}
          >
            <div className="col px-0">
              <div className="align-items-left row">
                <div className="text-left col-lg-6 balance-wrapper">
                  <p className="lead text-white your_balance_text">
                    Votre solde CDF est de :
                  </p>
                  <h1 className="titre_accroche">
                    <span className="currency">
                      {soldes.cdf < 0 && "Chargement ... "}
                      {soldes.cdf > -1 && utils().formatAmount("CDF", soldes.cdf)}
                    </span>
                  </h1>
                </div>
                <div className="text-left col-lg-6 balance-wrapper">
                  <p className="lead text-white your_balance_text">
                    Votre solde USD est de :
                  </p>
                  <h1 className="titre_accroche">
                    <span className="currency">
                      {soldes.usd < 0 && "Chargement ... "}
                      {soldes.usd > -1 && utils().formatAmount("USD", soldes.usd)}
                    </span>
                  </h1> 
                </div>
              </div>
              <div className="align-items-center mt-4">
                <small className="lead text-white">
                  {props.isDataTable && (
                    <span className="count_transaction_text">
                      {total} transactions réalisées au total..
                    </span>
                  )}
                  {!props.isDataTable && (
                    <span className="count_transaction_text">
                      {total} produits disponibles.
                    </span>
                  )}
                </small>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
}

import React from "react";
import { utils } from "../../../../_helpers";

const kpis: any[] = [
  {
    cls: "border-left-primary",
    color: "text-primary",
    label: "Solde entreprise",
    icon: "fa fa-money",
    key: "soldeEntreprise",
  },
  {
    cls: "border-left-primary",
    color: "text-primary",
    label: "Solde particulier",
    icon: "fa fa-money",
    key: "solde",
  },
  {
    cls: "border-left-success",
    color: "text-success",
    label: "Dépôts",
    icon: "fa fa-plus",
    key: "total_depot",
  },
  {
    cls: "border-left-danger",
    color: "text-danger",
    label: "Retraits",
    icon: "fa fa-minus",
    key: "total_retrait",
  },
  {
    cls: "border-left-info",
    color: "text-info",
    label: "Reçus",
    icon: "fa fa-download",
    key: "total_recu",
  },
  {
    cls: "border-left-warning",
    color: "text-warning",
    label: "Envoyés",
    icon: "fa fa-upload",
    key: "total_send",
  },
  {
    cls: "border-left-secondary",
    color: "text-secondary",
    label: "Autres",
    icon: "fa fa-random",
    key: "total_other",
  },
];
export function AccountKpi(props: any) {
  const { loading } = props;
  return (
    <>
      {kpis.map((item: any, i: number) => {
        const { cls, color, label, icon } = item;
        return (
          <div className="col-xl-2 col-md-4 col-sm-6 my-1" key={i}>
            <div className={`card2 ${cls} h-100 py-2`}>
              <div className="card-body p-0">
                <div className="row no-gutters align-items-center flex-column justify-content-center">
                  <div className="col-auto">
                    <i className={`${icon} fa-2x text-gray-300`}></i>
                  </div>
                  <div className="col">
                    <div
                      className={`mb-0  text-center my-1 ${
                        loading
                          ? "text-muted"
                          : " text-gray-800 font-weight-bold"
                      }`}
                    >
                      {!loading && getCount(props, item.key)}
                      {loading && "Chargement ..."}
                    </div>
                    <div
                        className={`mb-0  text-center my-1 ${
                            loading
                                ? "text-muted"
                                : " text-gray-800 font-weight-bold"
                        }`}
                    >
                      {!loading && getCountUSD(props, item.key)}
                      {loading && "Chargement ..."}
                    </div>
                    <div
                      className={`text-xs text-center font-weight-bold ${color} text-uppercase mb-1`}
                    >
                      {label}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}

const getCount = (props: any, key: string) => {
  let amount = 0;
  const { account, items } = props;
  switch (key) {
    case "solde":
      amount = account.solde;
      break;
    case "soldeEntreprise":
      amount = account.soldeEntreprise;
      break;
    case "total_depot":
      amount = items
        .filter((i: any) => i.operationType?.operationTypeId === 1)
        .map((o: any) => o.amount)
        .reduce((a: number, b: number) => a + b, 0);
      break;
    case "total_retrait":
      amount = items
        .filter((i: any) => i.operationType?.operationTypeId === 2)
        .map((o: any) => o.amount)
        .reduce((a: number, b: number) => a + b, 0);
      break;
    case "total_recu":
      amount = items
        .filter((i: any) => i.operationType?.operationTypeId === 3)
        .map((o: any) => o.amount)
        .filter((m: any) => m > 0)
        .reduce((a: number, b: number) => a + b, 0);
      break;
    case "total_send":
      amount =
        items
          .filter((i: any) => i.operationType?.operationTypeId === 3)
          .map((o: any) => o.amount)
          .filter((m: any) => m < 0)
          .reduce((a: number, b: number) => a + b, 0) * -1;
      break;
    case "total_other":
      const ids = [1, 2, 3];
      amount = items
        .filter(
          (i: any) =>
            ids.indexOf(i.operationType?.operationTypeId) === -1 ||
            i.operationType === null
        )
        .map((o: any) => o.amount)
        .reduce((a: number, b: number) => a + b, 0);
      break;
    default:
      break;
  }
  return utils().formatAmount("CDF", amount);
};
const getCountUSD = (props: any, key: string) => {
  let amount = 0;
  const { account, items } = props;
  switch (key) {
    case "solde":
      amount = account.soldeUsd;
      break;
    case "soldeEntreprise":
      amount = Number(account.soldeEntreprise)*Number(account.soldeUsd)/Number(account.solde);
      break;
    case "total_depot":
      amount = items
          .filter((i: any) => i.operationType?.operationTypeId === 1 && i.devise?.deviseId === 2)
          .map((o: any) => o.amount)
          .reduce((a: number, b: number) => a + b, 0);
      break;
    case "total_retrait":
      amount = items
          .filter((i: any) => i.operationType?.operationTypeId === 2 && i.devise?.deviseId === 2)
          .map((o: any) => o.amount)
          .reduce((a: number, b: number) => a + b, 0);
      break;
    case "total_recu":
      amount = items
          .filter((i: any) => i.operationType?.operationTypeId === 3 && i.devise?.deviseId === 2)
          .map((o: any) => o.amount)
          .filter((m: any) => m > 0)
          .reduce((a: number, b: number) => a + b, 0);
      break;
    case "total_send":
      amount =
          items
              .filter((i: any) => i.operationType?.operationTypeId === 3 && i.devise?.deviseId === 2)
              .map((o: any) => o.amount)
              .filter((m: any) => m < 0)
              .reduce((a: number, b: number) => a + b, 0) * -1;
      break;
    case "total_other":
      const ids = [1, 2, 3];
      amount = items
          .filter(
              (i: any) =>
                  (ids.indexOf(i.operationType?.operationTypeId) === -1 ||
                  i.operationType === null) && i.devise?.deviseId === 2
          )
          .map((o: any) => o.amount)
          .reduce((a: number, b: number) => a + b, 0);
      break;
    default:
      break;
  }
  return utils().formatAmount("USD", amount);
};

import React, { Component } from "react";
import { primaryColor } from "../../../_helpers";
import { CopyRight } from "./CopyRight";

class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <footer className="footer">
          <div className="container">
            <CopyRight />
            <ul
              className=""
              style={{
                listStyle: "none",
                display: "inline-flex",
                float: "right",
                marginRight: "40px"
              }}
            >
              <li className="nav-item">
                <a href="/#" target="_blank" className="nav-link-icon nav-link" style={{ color: primaryColor()}}>
                  <i className="fa fa-facebook-square"></i>
                </a>
              </li>
              <li className="nav-item">
                <a href="/#" target="_blank" className="nav-link-icon nav-link" style={{ color: primaryColor()}}>
                  <i className="fa fa-instagram"></i>
                </a>
              </li>
              <li className="nav-item">
                <a href="/#" target="_blank" className="nav-link-icon nav-link" style={{ color: primaryColor()}}>
                  <i className="fa fa-twitter-square"></i>
                </a>
              </li>
              <li className="nav-item">
                <a href="/#" target="_blank" className="nav-link-icon nav-link" style={{ color: primaryColor()}}>
                  <i className="fa fa-youtube"></i>
                </a>
              </li>
            </ul>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

export { Footer };

import React, { useEffect, useState } from "react";
import XLSX from "xlsx";
import { utils } from "../../../_helpers";

export function BulkPaymentPreview(props: any) {
  const b: any[] = [];
  const [bulks, setBulks] = useState(b);

  useEffect(() => {
    const file = props.model?.provider?.getValue("inputFile", null);
    const devise = props.model?.provider?.getValue("devise", null);
    const currencies = props.model?.provider?.getValue("currencies", null);
    const curr = currencies.find((c: any) => c.value === devise);
    const code = curr ? curr.code : "CDF";

    if (file) {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = function (e) {
        if (
          e.target?.result !== null &&
          typeof e.target?.result !== "string" &&
          typeof e.target?.result !== "undefined"
        ) {
          const data = new Uint8Array(e.target?.result);
          const workbook = XLSX.read(data, { type: "array", raw: true });
          const sheet_name_list = workbook.SheetNames;
          const xlData: any[] = XLSX.utils.sheet_to_json(
            workbook.Sheets[sheet_name_list[0]]
          );
         
          const bulks: any[] = [];
          const l = xlData.length;
          for (let i = 0; i < l; i++) {
            if (
              typeof xlData[i].numCompte != "undefined" &&
              typeof xlData[i].Montant != "undefined" &&
              typeof xlData[i].nom != "undefined"
            ) {
              const bulk = {
                id: xlData[i].id,
                numCompte: xlData[i].numCompte.toString(),
                nom: xlData[i].nom,
                Montant: `${utils().formatDouble(xlData[i].Montant)} ${code}`,
                import: false,
              };
              bulks.push(bulk);
            }
          }
          setBulks(bulks);
        }
      };
    }
  }, []);

  return (
    <div
      className=""
      id="recapitulatif-group-bulk"
      style={{ fontSize: "80%" }}
      data-shown="0"
    >
      <table id="admin_users_table2" className="table table-hover user-table">
        <thead>
          <tr className="row100 head text-center">
            <th className="cell100 column1">#</th>
            <th className="cell100 column1">Numero de compte</th>
            <th className="cell100 column1">Nom</th>
            <th className="cell100 column2">Montant à transférer</th>
          </tr>
        </thead>
        <tbody>
          {bulks.map((item, i) => {
            return (
              <tr className="row100 body text-center" key={i}>
                <td className="cell100 column1">{item.id}</td>
                <td className="cell100 column2">{item.numCompte}</td>
                <td className="cell100 column1">{item.nom}</td>
                <td className="cell100 column3">{item.Montant}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

import React, { Component, useEffect, useState } from "react";
import { appConstants } from "../../../_constants";
import { utils } from "../../../_helpers";
import { connector, PropsFromRedux } from "../../../_reducers";

const { FULL_LOADING_ACTION } = appConstants.keys.eventKeys;

class Loader extends Component<PropsFromRedux, any> {
  constructor(props: PropsFromRedux) {
    super(props);
    this.state = {
      loading: false,
      ref: React.createRef(),
    };
  }
  componentDidMount() {
    utils().emitter.on(FULL_LOADING_ACTION, (res) => {
      this.handleFullLoadingActionEvent(res);
    });
  }

  handleFullLoadingActionEvent(res: any) {
    if (this.checkIfMounted()) {
      this.setState({ loading: res });
    }
  }
  checkIfMounted() {
    return this.state.ref.current !== null;
  }
  componentWillUnmount() {
    utils().emitter.off(FULL_LOADING_ACTION, this.handleFullLoadingActionEvent);
  }

  render() {
    const { model } = this.props;
    const isLoading = this.state.loading || model.fullLoading;
    return (
      <div
        id="full-loader"
        ref={this.state.ref}
        className="loading"
        style={{
          color: "#fff",
          background: "#fff",
          display: isLoading ? "" : "none",
        }}
      >
        Loading&#8230;
      </div>
    );
  }
}

const connectedLoader = connector(Loader);
export { connectedLoader as Loader };

import React, {Component} from "react";
import {restClient} from "../../../../_helpers";

class ValidateStepForm extends Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            errmsg: "",
            motif: "",
            err:false,
            account:this.props.settings.account,
            freeze: false,
        };
        this.handleChange = this.handleChange.bind(this);
        console.log("Les props depuis validate step", props);

    }

    componentDidMount() {

    }

    handleChange(e) {
        e.preventDefault();
        const {name, value} = e.target;
        this.setState({[name]: value});
        this.props.stateSetter(
            {
                settings: {
                    content: "validateStep",
                    title: "Rejeter cette validation",
                    showSaveBtn: true,
                    action: "validateStep",
                    message: "",
                    motif:value
                }
            });
    }

    getAccount(numero){

    }

    render() {
        return (
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-12'>
                        <p className={this.props.settings.cls}>
                            {this.props.settings.message}
                        </p>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm-4'>
                        <label className="control-label">Message</label>
                    </div>
                    <div className="col-sm-8">
                        <input type="text" className="form-control mb-3"
                               placeholder="Raison du rejet"
                               onChange={this.handleChange}
                               name={"motif"}
                               // value={this.state.account}
                        />
                        {this.state.err && (<span style={{fontSize: "80%", font: "bold", color: "red"}}>
                                    {this.state.errmsg}
                            </span>)}
                    </div>
                </div>
            </div>
        );
    }
}

export {ValidateStepForm};

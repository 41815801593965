import React from "react";
import { Link } from "react-router-dom";
import { utils } from "../../../_helpers";
import { PropsFromRedux } from "../../../_reducers";

export function SidebarSubItem(props: PropsFromRedux & any) {
  const { subItem } = props;

  const onClick = (e: any) => {
    /* $(".sidebar-submenu").slideUp(200);
    $(e.currentTarget).parent().parent().parent().parent().addClass("active");*/
  };
  return (
    <li {...getItemAttributes(subItem)}>
      <Link to={subItem.path} onClick={onClick}>
        {subItem.title}
      </Link>
    </li>
  );
}

const getItemAttributes = (item: any) => {
  let liCls = "";
  let attrLi = {};
  if (utils().isActiveSidebarItem(item)) {
    liCls += " active";
  }
  if (liCls) {
    attrLi = { className: liCls };
  }
  return attrLi;
};

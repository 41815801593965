import { restClient, utils } from "../_helpers";
import { BaseEntity } from "./BaseEntity";
import { InputType } from "./enumerations";
import { Field } from "./Field";

class UserCni extends BaseEntity {
  public static readonly addTitle = "Modifier la CNI";
  public static readonly saveBtnTitle = "Modifier";
  public static readonly processNewSuccessMsg =
    "La CNI a été modifié avec succès !";
  public static instance: UserCni = new UserCni();
  public static instances = [];
  public static relations = [];

  public static get definitions(): Field[] {
    const definitions = [
      new Field({
        name: "cni",
        label: "Nouvelle CNI",
        required: true,
        provider: UserCni.instance,
        inputType: InputType.INPUT_FILE,
        customData: {
          isImg64: false,
          inputInlineSyle: {
            padding: 0,
          },
        },
      }),
    ];
    return definitions;
  }

  async initNew() {}

  processNew(payload: any) {
    return new Promise(async (resolve, reject) => {
      console.log(payload);
      console.log(this);
      const { cni } = payload;
      payload.cni = "";
      let cls = "alert-danger";
      let content = "";
      const res = await this.uploadFile(
        payload,
        cni,
        this.getValue("userId", 0)
      );
      if (res.success) {
        if (!utils().isProfil()) {
          window.location.reload();
          return;
        }
        cls = "alert-success";
        content =
          `La CNI a été modifié avec succès ! ${utils().isProfil()
            ? " Vous devez vous reconnecter pour voir les modifications."
            : ""}`;
        utils().emitter.emit("alert_message", { content, cls });
        resolve({ success: true, data: res });
      } else {
        content =
          res?.message ||
          "Une erreur s'est produite lors de l'enregistrement. Merci de reéssayer.";
        utils().emitter.emit("alert_message", { content, cls });
        resolve({ success: false, data: {} });
      }
    });
  }

  buildFilePayload(cni: any, userId: any) {
    let ws_path = `/user/media/update/${userId}/CNI`;
    console.log("==> buildPayload ws_path ", ws_path);
    const formData = new FormData();
    formData.append("file", cni);
    formData.append("ws_path", encodeURI(ws_path));
    return formData;
  }

  async uploadFile(payload: any, cni: any, userId: any): Promise<any> {
    console.log("==> payload processNew", payload);
    return await new Promise(async (resolve) => {
      const body = this.buildFilePayload(cni, userId);
      const multipart =
        "multipart/form-data; boundary=empataSuperCoolBoundary--";
      const res = await restClient.post({
        multipart,
        body,
        prefix: "/multipart",
      });
      console.log("==> uploadFile res", res);
      resolve(res);
    });
  }
}

export { UserCni };

import { restClient, utils } from "../_helpers";
import { BaseModel } from "../_models";
import { BaseEntity } from "./BaseEntity";
import { Field } from "./Field";

class UserCniReject extends BaseEntity {
  public static readonly addTitle = "Rejeter la CNI";
  public static readonly saveBtnTitle = "Valider";
  public static readonly processNewSuccessMsg =
    "La CNI a été rejeté avec succès !";
  public static instance: UserCniReject = new UserCniReject();
  public static instances = [];
  public static relations = [];

  public static get definitions(): Field[] {
    const definitions = [
      new Field({
        name: "motif",
        label: "Motif du rejet",
        required: true,
        provider: UserCniReject.instance,
        customData: {
          isImg64: false,
          inputInlineSyle: {
            padding: 0,
          },
        },
      }),
    ];
    return definitions;
  }

  processNew(payload: any) {
    console.log(payload);
    return new Promise(async (resolve, reject) => {
      let cls = "alert-danger";
      let content = "";
      const res = await restClient.post(`user/media/valid`, {
        userId: payload?.modalData.account?.user?.userId || 0,
	      state: false,
	      motif: payload.motif
      });
      if (res.success) {
        cls = "alert-success";
        content = `La CNI a été rejeté avec succès !`;
        utils().emitter.emit("alert_message", { content, cls });
        resolve({ success: true, data: res });
      } else {
        content =
          res?.message ||
          "Une erreur s'est produite lors de l'enregistrement. Merci de reéssayer.";
        utils().emitter.emit("alert_message", { content, cls });
        resolve({ success: false, data: {} });
      }
    });
  }
}

export { UserCniReject };

import * as allcomponents from ".";

export * from "./home";
export * from "./common";
export * from "./auth";
export * from "./admin";
export * from "./renderer";
export * from "./client";
export * from "./admin/Content/AccountDetails/AccountParametres/AccountProfil";
export * from "./admin/Content/Workflows/WorkflowForm"

export const components = {
  kpi: allcomponents.Kpi,
  table: allcomponents.TableWrapper,
  adminConfig: allcomponents.AdminConfig,
  affectation: allcomponents.AdminAffectations,
  accountDetails: allcomponents.AccountDetails,
  accountProfil: allcomponents.AccountProfil,
  workflowNew: allcomponents.WorkflowForm,
};

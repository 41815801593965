import React, { useState } from "react";
import { InputType } from "../../../_entities";
import { BaseModel } from "../../../_models";
import { CheckBox } from "./CheckBox";
import { Input } from "./Input";
import InputCard from "./InputCard";
import { InputDetails } from "./InputDetails";
import { InputDetails2 } from "./InputDetails2";
import { InputFile } from "./InputFile";
import { InputFiles } from "./InputFiles";
import { InputMontantFrais } from "./InputMontantFrais";
import { InputPass } from "./InputPass";
import { InputRecaptcha } from "./InputRecaptcha";
import { InputSelect } from "./InputSelect";
import { InputTel } from "./InputTel";
import { InputTextArea } from "./InputTextArea";

export function BaseField(props: any) {
  const { customData } = props;
  let field: React.ReactNode;
  switch (props.field?.inputType) {
    case InputType.INPUT_CHECKBOX:
      field = <CheckBox {...props} />;
      break;
    case InputType.INPUT_SELECT:
      field = <InputSelect {...props} />;
      break;
    case InputType.INPUT_TEL:
      field = <InputTel {...props} />;
      break;
    case InputType.INPUT_PASSWORD:
      field = <InputPass {...props} />;
      break;
    case InputType.INPUT_PASSWORD_2:
      field = <InputPass {...props} renderCheckBox={false} />;
      break;
    case InputType.INPUT_RECAPTCHA:
      field = <InputRecaptcha {...props} renderCheckBox={false} />;
      break;
    case InputType.INPUT_FILE:
      field = <InputFile {...props} />;
      break;
      case InputType.INPUT_FILES:
        field = <InputFiles {...props} />;
        break;
    case InputType.INPUT_TEXTAREA:
      field = <InputTextArea {...props} />;
      break;
    case InputType.INPUT_DETAILS:
      field = <InputDetails {...props} />;
      break;
    case InputType.INPUT_DETAILS_2:
      field = <InputDetails2 {...props} />;
      break;
    default:
      field = <Input {...props} />;
      break;
  }

  if (
    (customData?.hasCheckBox || customData?.checkBoxData) &&
    props.field?.inputType !== InputType.INPUT_PASSWORD_2
  ) {
    field = (
      <>
        {field}
        <CheckBox {...props} />
      </>
    );
  }

  if (customData?.inputFraisData) {
    field = (
      <>
        {field}
        <InputMontantFrais {...props} />
      </>
    );
  }
  if (customData?.hasCards) {
    field = (
      <>
        {field}
        <InputCard {...props} />
      </>
    );
  }
  if (customData?.hasFileTemplateDownloadLink) {
    field = (
      <>
        {field}
        <a href="/template-bulk.csv" className="d-block small" target="_blank">
          <i className="fa fa-download mr-1"></i>
          Télécharger le template de fichier.
        </a>
      </>
    );
  }

  if (customData?.hasResendCodeLink) {
    field = (
      <>
        {field}
        <ResendCode />
      </>
    );
  }

  return <>{field}</>;
}

function ResendCode() {
  const [loading, setLoading] = useState(false);
  if (loading) {
    return <small className="text-muted mt-1">Chargement ... </small>
  }
  return (
    <a
      href="/#"
      onClick={(e) => {
        e.preventDefault();
        setLoading(true);
        const provider = BaseModel.getInstance().provider;
        if (provider) {
          const payload = provider.serialize();
          provider.processNew({...payload, resend: "yes"}).then((res) => {
            setLoading(false);
          });
        } else {
          setLoading(false);
        }
      }}
      className="d-block small mt-1"
      target="_blank"
    >
      <i className="fa fa-refresh mr-1"></i>
      Renvoyer le code.
    </a>
  );
}

import React, { useEffect, useState } from "react";
import { utils } from "../../../_helpers";
import { LoadingGif } from "../Loader/LoadingGif";

export function ModalHeader(props: any) {
  const { title } = props;
  const [nom, setNom] = useState("");
  const [isMounted, setIsMounted] = useState(false);
  
  useEffect(() => {
    setIsMounted(true);
    const handle = (v: any) => {
      if (isMounted) {
        setNom(v);
      }
    };
    utils().emitter.on("nom_entreprise", (v: any) => handle(v));
    return () => {
      setIsMounted(false);
      utils().emitter.off("nom_entreprise", handle);
    };
  }, [isMounted]);

  return (
    <div className="modal-header d-flex flex-row justify-content-between">
      <h5 className="modal-title">
        {title}{" "}
        {nom.length > 0 && <small className="print" style={{ display: "none"}}>
          {" "}
          - fait par <b>{nom}</b>{" "}
        </small>}
      </h5>
      <LoadingGif {...props} />
    </div>
  );
}

import React from "react";

export function InputDetails(props: any) {
  const { nom, prenom, email } = props.customData;
  return (
    <table className=" w-100">
      <tbody>
        <tr>
          <td>
            <span className="d-flex p-1">
              <small>
                <span className="font-weight-bold mr-2">Nom : </span>
                <span id="ben-infos-nom">{nom}</span>
              </small>
            </span>
          </td>
          <td>
            <span className="d-flex">
              <small>
                <span className="font-weight-bold mr-2">Prénom : </span>{" "}
                <span id="ben-infos-prenom">{prenom}</span>
              </small>
            </span>
          </td>
        </tr>
        <tr>
          <td colSpan={2}>
            <span className="d-flex p-1">
              <small>
                <span className="font-weight-bold mr-2">Email : </span>{" "}
                <span id="ben-infos-email">{email}</span>
              </small>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

import { appConstants } from "../_constants";
import { BaseEntity } from "./BaseEntity";
import { FieldType } from "./enumerations";
import { Field } from "./Field";
import {BaseModel} from "../_models";

const {
  RENDER_ACTIONS,
  RENDER_ID,
  RENDER_STATUS,
} = appConstants.keys.renderKeys;

class MarqueBlanche extends BaseEntity {
  public static readonly label: any;
  public static readonly className: any;
  public static readonly art: any;
  public static readonly addTitle = "Configurer une marque";
  public static readonly showTitle = "Détails du MarqueBlanche";
  public static readonly editTitle = "Modifier un MarqueBlanche";
  
  public static readonly processNewSuccessMsg =
    "Le MarqueBlanche a été créée avec succès. Merci !";
  public static readonly processEditSuccessMsg =
    "Le MarqueBlanche a été modifié avec succès. Merci !";
  public static readonly processNewPath = "MarqueBlanche/add";
  public static readonly processEditPath = "MarqueBlanche/update/_ID_";
  public static instance: MarqueBlanche = new MarqueBlanche();
  public static instances = [];
  public static relations = [];

  public static get definitions(): Field[] {
    const model: any = BaseModel.getInstance();
    const aChildAll = [
      {
        label: "Modifier",
        title: "Modifer ce type de compte",
        action: "editActions",
        color: "text-success",
        icon: "fa fa-edit",
      },
      {
        actionCallback: (data: any) => {
          return {
            label: "Affectations",
            title: "Affecter des droits à ce MarqueBlanche",
            action: "navigateActions",
            link: `/admin/profiles/affectations/?id=${data.id}`,
            color: "text-warning",
            icon: "fa fa-user",
          };
        },
      },
      {
        label: "Supprimer",
        title: "Supprimmer ce type de compte",
        action: "deleteActions",
        color: "text-danger",
        icon: "fa fa-trash",
      },
    ];
    const aChildMod = [
      {
        label: "Modifier",
        title: "Modifer ce type de compte",
        action: "editActions",
        color: "text-success",
        icon: "fa fa-edit",
      },
      {
        actionCallback: (data: any) => {
          return {
            label: "Affectations",
            title: "Affecter des droits à ce MarqueBlanche",
            action: "navigateActions",
            link: `/admin/profiles/affectations/?id=${data.id}`,
            color: "text-warning",
            icon: "fa fa-user",
          };
        },
      }
    ];
    const aChildDel = [
      {
        label: "Supprimer",
        title: "Supprimmer ce type de compte",
        action: "deleteActions",
        color: "text-danger",
        icon: "fa fa-trash",
      },
    ];
    const definitions = [
      new Field({
        name: "id",
        type: FieldType.NUMBER,
        primary: true,
        creatable: false,
        editable: false,
        serializeNew: false,
        serializeEdit: false,
        label: "#",
        renderKey: RENDER_ID,
        provider: MarqueBlanche.instance,
      }),
      new Field({
        name: "code",
        primary: true,
        creatable: false,
        editable: false,
        serializeNew: false,
        serializeEdit: false,
        label: "Code",
        provider: MarqueBlanche.instance,
      }),
      new Field({
        name: "libelle",
        label: "Libellé",
        provider: MarqueBlanche.instance,
      }),
      new Field({
        name: "description",
        label: "Description",
        provider: MarqueBlanche.instance,
      }),
      new Field({
        name: "status",
        label: "Status",
        creatable: false,
        editable: false,
        renderKey: RENDER_STATUS,
        serializeCallback: (item: any, value: any) => {
          return {...item, status: true};
        },
        provider: MarqueBlanche.instance,
        onTable: false,
      }),
      new Field({
        name: "actions",
        label: "Actions",
        dataPath: "id",
        creatable: false,
        editable: false,
        serializeNew: false,
        serializeEdit: false,
        serializeView: false,
        renderKey: RENDER_ACTIONS,
        provider: MarqueBlanche.instance,
        customData: {
          renderOptions: {
            clsActions: "justify-content-center",
          },
          actions: [
            {
              label: "Détails",
              title: "Afficher les détails",
              action: "detailsActions",
              color: "btn-info",
              icon: "fa fa-eye",
            },
            {
              type: "btnGroup",
              color: "btn-primary",
              children:model.user.user.MarqueBlanche===null?aChildAll:
                  model.profCrud.modification && model.profCrud.suppression?aChildAll:
                      model.profCrud.modification?aChildMod:model.profCrud.suppression?aChildDel:[]
            },
          ],
        },
      }),
    ];
    return definitions;
  }

  toView() {
    return [
      {
        title: false,
        rows: [
          { label: "MarqueBlanche ID", value: this.id },
          { label: "Libellé", value: this.libelle },
          { label: "Status", value: this.status ? "Actif" : "Inactif" },
          { label: "Description", value: this.description },
        ],
      },
    ];
  }

  getDeleteActionConfig() {
    return {
      path: `/MarqueBlanche/delete/${this.id}`,
      resolve: (res: any) =>{
        if(!res.success){
          alert(res.message);
        }
        return  res?.message === "OK";
      }
    };
  }
}

export { MarqueBlanche };

import React from "react";
import { model, restClient, utils } from ".";
import { Field } from "../_entities";
import {
  RenderAccountType,
  RenderActions,
  RenderAffectation,
  RenderOuiNon,
  RenderStatus,
  RenderStatusOperation,
} from "../_components";
import ReactDOMServer from "react-dom/server";
import Transaction from "../_components/client/Transaction/Transaction";
import { Order } from "../_components/client/Order/Order";
import { appConstants } from "../_constants";
import { RenderProfile } from "../_components/renderer/RenderProfile";
import { BaseModel } from "../_models";

const defaultString = "<b>-</b>";

const { MODAL_ACTION } = appConstants.keys.eventKeys;
const {
  RENDER_ID,
  RENDER_STATUS,
  RENDER_DATETIME,
  RENDER_DATE,
  RENDER_ACCOUNT_TYPE,
  RENDER_PROFILE,
  RENDER_AMOUNT,
  RENDER_AMOUNT_USD,
  RENDER_LONG_TEXT,
  RENDER_ACTIONS,
  RENDER_BENEFICIARE,
  RENDER_EMETTEUR,
  RENDER_STATUS_OPERATION,
  RENDER_OPERATION_TYPE,
  RENDER_AMOUNT_TRANSACTION,
  RENDER_AMOUNT_PRIX,
  RENDER_AMOUNT_TOTAL,
  RENDER_AMOUNT_PRODUIT,
  RENDER_OUI_NON,
  RENDER_CLIENT_TRANSACTION,
  RENDER_CATEGORY_OPERATION_TYPE,
  RENDER_DOUBLE,
  RENDER_AFFECTATION,
  RENDER_OPERATION_TYPE_BULK,
  RENDER_DEVISE_BULK,
  RENDER_CLIENT_ORDER,
  RENDER_OPERATION_CURRENCY,
} = appConstants.keys.renderKeys;

class TableRenderer {
  private static _instance: TableRenderer | undefined;

  constructor() {
    if (TableRenderer._instance) {
      throw new Error(
        "Error : Instanciation failed : Use TableRenderer.getInstance() instead of new."
      );
    }
    TableRenderer._instance = this;
  }

  public static getInstance(): TableRenderer {
    if (!TableRenderer._instance) {
      return new TableRenderer();
    }
    return TableRenderer._instance;
  }

  public static reset() {
    TableRenderer._instance = undefined;
  }

  renderId() {
    return (data: any, type: any, row: any, meta: any) => {
      if (type === "display") {
        return (
          '<span data-id="' + data + '">' + (parseInt(meta.row) + 1) + "</span>"
        );
      } else {
        return data;
      }
    };
  }

  renderText() {
    return (data: any, type: any, row: any, meta: any) => {
      if (type === "display") {
        if (data) {
          return data;
        } else {
          return defaultString;
        }
      }
      return data;
    };
  }

  renderOperationDevise() {
    return (data: any, type: any, row: any, meta: any) => {
      if (row?.devise?.code) {
        return row?.devise?.code;
      } else {
        return defaultString;
      }
    };
  }

  renderStatus() {
    return (data: any, type: any, row: any, meta: any) => {
      if (type === "display") {
        return utils().render(<RenderStatus data={data} />);
      } else {
        return data;
      }
    };
  }

  renderOuiNon() {
    return (data: any, type: any, row: any, meta: any) => {
      if (type === "display") {
        return utils().render(<RenderOuiNon data={data} />);
      } else {
        return data;
      }
    };
  }

  renderAccountType() {
    return (data: any, type: any, row: any, meta: any) => {
      if (type === "display") {
        return utils().render(
          <RenderAccountType data={data} id={row.accountType.accountTypeId} />
        );
      } else {
        return data;
      }
    };
  }

  renderProfile() {
    return (data: any, type: any, row: any, meta: any) => {
      if (type === "display") {
        // console.log("LA ROWWWWW", row);
        return utils().render(
          <RenderProfile data={data} id={row.profile?.id} />
        );
      } else {
        return data;
      }
    };
  }

  renderDateTime() {
    return (data: any, type: any, row: any, meta: any) => {
      if (type === "display") {
        if (data) {
          return utils().formatDate("DD/MM/YYYY [à] H:mm:ss", data);
        } else {
          return defaultString;
        }
      } else {
        return data;
      }
    };
  }

  renderDate() {
    return (data: any, type: any, row: any, meta: any) => {
      if (type === "display") {
        if (data) {
          return utils().formatDate("DD/MM/YYYY", data);
        } else {
          return defaultString;
        }
      } else {
        return data;
      }
    };
  }

  renderLongText(field: Field) {
    return (data: any, type: any, row: any, meta: any) => {
      if (type === "display") {
        const cuttoff = field.customData?.renderOptions?.cuttoff || 30;
        return data
          ? `<span title="${data}">${data.substring(0, cuttoff)} ... </span>`
          : defaultString;
      } else {
        return data;
      }
    };
  }

  renderAmount() {
    return (data: any, type: any, row: any, meta: any) => {
      if (type === "display") {
        return utils().formatAmount(
          row.price?.devise ? row.price.devise.code : "CDF",
          data
        );
      } else {
        return data;
      }
    };
  }

  renderAmountUSD() {
    return (data: any, type: any, row: any, meta: any) => {
      if (type === "display") {
        return utils().formatAmount("USD", data);
      } else {
        return data;
      }
    };
  }

  renderAmountProduit() {
    return (data: any, type: any, row: any, meta: any) => {
      if (row.hasAbonnement) {
        return "Pas fixe";
      }
      return utils().formatAmount(row.devise?.code || "CDF", data);
    };
  }

  renderAmountOrder() {
    return (data: any, type: any, row: any, meta: any) => {
      if (type === "display") {
        const mt = row?.qte * row?.product?.amount;
        return utils().formatAmount("CDF", mt);
      } else {
        return data;
      }
    };
  }

  renderAmountPrice() {
    return (data: any, type: any, row: any, meta: any) => {
      if (type === "display") {
        const mt = row.amount > 0 ? row.amount : row.percent;
        const ispercent = row.amount > 0 ? false : true;
        return ispercent
          ? `${mt}%`
          : utils().formatAmount(
              row.price.devise ? row.price.devise.code : "CDF",
              data
            );
      } else {
        return data;
      }
    };
  }

  renderActions(field: Field) {
    return (data: any, type: any, row: any, meta: any) => {
      if (type === "display") {
        const actions = field?.customData?.actions || [];
        return utils().render(
          <RenderActions actions={actions} id={data} data={row} field={field} />
        );
      } else {
        return data;
      }
    };
  }

  renderBeneficiaire() {
    return (data: any, type: any, row: any, meta: any) => {
      if (type === "display") {
        const operationTypeId = row.operationType?.operationTypeId || 0;
        if (row.operationType === null) {
          return "e-mpata";
        } else if (operationTypeId === 8) {
          return "eRecette";
        } else if (operationTypeId === 14 || operationTypeId === 15) {
          return row.guestReceiver?.tel || defaultString;
        } else if (row.receiver) {
          return row.receiver;
        } else if (operationTypeId === 1 || operationTypeId === 12) {
          return row.account.msisdn;
        } else if (operationTypeId === 6 || operationTypeId === 5) {
          if (row.amount > 0) {
            if (row.account) {
              return row.account.msisdn;
            } else {
              return defaultString;
            }
          } else {
            if (row.other) {
              return row.other.msisdn;
            } else {
              return defaultString;
            }
          }
        } else if (operationTypeId === 3 || operationTypeId === 4) {
          if (row.amount >= 0) {
            return row.account.msisdn;
          } else {
            if (row.other) {
              return row.other.msisdn;
            } else {
              return defaultString;
            }
          }
        } else if (row.receiver) {
          return row.receiver;
        } else if (operationTypeId === 2 || operationTypeId > 4) {
          return defaultString;
        } else {
          return defaultString;
        }
      } else {
        return data;
      }
    };
  }

  renderEmetteur() {
    return (data: any, type: any, row: any, meta: any) => {
      if (type === "display") {
        const operationTypeId = row.operationType?.operationTypeId || 0;
        if (row.operationType === null) {
          if (row.account) {
            return row.account.msisdn;
          } else {
            return defaultString;
          }
        } else if (operationTypeId === 14 || operationTypeId === 15) {
          return row.guestSender?.tel || defaultString;
        } else if (operationTypeId === 1) {
          if (row.amount < 0) {
            if (row.account?.accountId) {
              return row.account?.msisdn;
            }
          }
          return defaultString;
        } else if (operationTypeId === 6 || operationTypeId === 5) {
          if (row.amount > 0) {
            if (row.other) {
              return row.other.msisdn;
            } else {
              return defaultString;
            }
          } else {
            if (row.account) {
              return row.account.msisdn;
            } else {
              return defaultString;
            }
          }
        } else if (operationTypeId === 2 || operationTypeId > 4) {
          if (row.account?.msisdn) {
            return row.account?.msisdn || defaultString;
          } else {
            return defaultString;
          }
        } else if (operationTypeId === 3 || operationTypeId === 4) {
          if (row.amount < 0) {
            return row.account.msisdn;
          } else {
            if (row.other) {
              return row.other.msisdn;
            } else {
              return defaultString;
            }
          }
        } else {
          console.log(row);
          return defaultString;
        }
      } else {
        return data;
      }
    };
  }

  renderDouble() {
    return (data: any, type: any, row: any, meta: any) => {
      if (type === "display") {
        return utils().formatDouble(data);
      } else {
        return data;
      }
    };
  }

  renderStatusOperation() {
    return (data: any, type: any, row: any, meta: any) => {
      if (type === "display") {
        return utils().render(<RenderStatusOperation data={data} />);
      } else {
        return data;
      }
    };
  }

  renderAmountTransaction() {
    let isAdminFinancier =
      window.location.pathname.indexOf("/admin/financier") !== -1;
    return (data: any, type: any, row: any, meta: any) => {
      const typeOp =
        typeof row?.operationType?.operationTypeId !== "undefined"
          ? row.operationType.operationTypeId
          : data;
      if (type === "display") {
        if (row.operationType === null) {
          let mt = data;
          let mtt = "";
          if (isAdminFinancier) {
            if (data < 0) {
              mt = data * -1;
              mtt = "+" + utils().formatAmount("", mt);
            }
          } else {
            mtt = utils().formatAmount("", mt);
          }
          return `<span style="margin:0;color:#ffc107;font-weight: bold;">${mtt}</span>`;
        } else if (typeOp === 8) {
          const mt = data < 0 ? data * -1 : data;
          return `<span style="margin:0;color:#dc3545;font-weight: bold;">${utils().formatAmount(
            "",
            mt
          )}</span>`;
        } else if (typeOp === 1 || typeOp === 14) {
          const signe = data > 0 ? "+" : "";
          return `<span style="margin:0;color:#1e7e34;font-weight: bold;">${signe}${utils().formatAmount(
            "",
            data
          )}</span>`;
        } else if (typeOp === 2) {
          return `<span style="margin:0;color:#dc3545;font-weight: bold;">${utils().formatAmount(
            "",
            data
          )}</span>`;
        } else if (typeOp === 3 || typeOp === 4) {
          if (row.amount < 0) {
            return `<span style="margin:0;color:#17a2b8;font-weight: bold;">${utils().formatAmount(
              "",
              data
            )}</span>`;
          } else {
            return `<span style="margin:0;color:#17a2b8;font-weight: bold;">+${utils().formatAmount(
              "",
              data
            )}</span>`;
          }
        } else {
          return `<span style="margin:0;color:#dc3545;font-weight: bold;">${utils().formatAmount(
            "",
            data
          )}</span>`;
        }
      } else {
        return data;
      }
    };
  }

  renderTypeOperation() {
    return (data: any, type: any, row: any, meta: any) => {
      const libelle =
        typeof row?.operationType?.operationTypeId !== "undefined"
          ? data?.libelle
          : typeof data === "number"
          ? row.libelle
          : this.getLibelle(data);

      const typeOp =
        typeof row?.operationType?.operationTypeId !== "undefined"
          ? row.operationType.operationTypeId
          : data;

      if (type === "display") {
        if (data === null) {
          return `<span class="badge badge-warning">${"Frais"}</span>`;
        } else if (typeOp === 1 || typeOp === 14) {
          return `<span class="badge badge-success">${libelle}</span>`;
        } else if (typeOp === 2 || typeOp === 15) {
          return `<span class="badge badge-danger">${libelle}</span>`;
        } else if (typeOp === 3 || typeOp === 4) {
          return `<span class="badge badge-info">${libelle}</span>`;
        } else {
          return `<span class="badge badge-secondary">${libelle}</span>`;
        }
      } else {
        return libelle;
      }
    };
  }

  renderTypeOperationBulk() {
    return (data: any, type: any, row: any, meta: any) => {
      return `<span class="badge badge-info">${this.getLibelle(data)}</span>`;
    };
  }

  renderDeviseBulk() {
    return (data: any, type: any, row: any, meta: any) => {
      return data === 1 ? "Franc congolais" : data === 2 ? "Dollar US" : data;
    };
  }

  renderCategoryTypeOperation() {
    return (data: any, type: any, row: any, meta: any) => {
      const typeOp = data;
      const libelle =
        data === 1 ? "Transferts" : data === 2 ? "Paiements" : "Autres";

      if (type === "display") {
        if (typeOp === 1) {
          return `<span class="badge badge-success">${libelle}</span>`;
        } else if (typeOp === 2) {
          return `<span class="badge badge-danger">${libelle}</span>`;
        } else {
          return `<span class="badge badge-secondary">${libelle}</span>`;
        }
      } else {
        return libelle;
      }
    };
  }

  renderAffectation(field: Field) {
    return (data: any, type: any, row: any, meta: any) => {
      if (type === "display") {
        return utils().render(
          <RenderAffectation id={data} data={row} field={field} />
        );
      } else {
        return data;
      }
    };
  }

  getLibelle(data: any) { 
    if (data === 3) {
      return "Transfert e-mpata vers e-mpata";
    } else if (data === 4) {
      return "Transfert e-mpata vers autres";
    } else {
      return data;
    }
  }

  renderClientTransaction() {
    return (data: any, type: any, row: any, meta: any) => {
      if (type === "display") {
        return ReactDOMServer.renderToString(<Transaction transaction={row} />);
      } else if (type === "filter") {
        return this.getLibelle2(row.operationType);
      } else {
        return data;
      }
    };
  }

  renderClientOrder() {
    return (data: any, type: any, row: any, meta: any) => {
      if (type === "display") {
        return ReactDOMServer.renderToString(<Order order={row} />);
      }
      return data;
    };
  }

  getLibelle2(operationType: any) {
    const libelles = {
      1: "Dépôt de fonds",
      2: "Retrait d'espèces",
      3: "Transfert "+(model().appTitle || "e-Mpata")+" vers "+(model().appTitle || "e-Mpata"),
      4: "Transfert "+(model().appTitle || "e-Mpata")+" vers autres",
      5: "Achat de produits et services",
      6: "Souscription à un abonnement",
      7: "Achat de credit",
      8: "Paiement de taxes et impôts",
    };
    if (!operationType) {
      return "Frais de transaction";
    }
    return (
      (libelles as any)[operationType.operationTypeId] ?? operationType.libelle
    );
  }

  public getRenderer(field: Field) {
    switch (field.renderKey) {
      case RENDER_ID:
        return this.renderId();
      case RENDER_STATUS:
        return this.renderStatus();
      case RENDER_DATETIME:
        return this.renderDateTime();
      case RENDER_DATE:
        return this.renderDate();
      case RENDER_ACCOUNT_TYPE:
        return this.renderAccountType();
      case RENDER_PROFILE:
        return this.renderProfile();
      case RENDER_AMOUNT:
        return this.renderAmount();
      case RENDER_AMOUNT_USD:
        return this.renderAmountUSD();
      case RENDER_LONG_TEXT:
        return this.renderLongText(field);
      case RENDER_ACTIONS:
        return this.renderActions(field);
      case RENDER_EMETTEUR:
        return this.renderEmetteur();
      case RENDER_BENEFICIARE:
        return this.renderBeneficiaire();
      case RENDER_STATUS_OPERATION:
        return this.renderStatusOperation();
      case RENDER_AMOUNT_TRANSACTION:
        return this.renderAmountTransaction();
      case RENDER_OPERATION_TYPE:
        return this.renderTypeOperation();
      case RENDER_OPERATION_TYPE_BULK:
        return this.renderTypeOperationBulk();
      case RENDER_DEVISE_BULK:
        return this.renderDeviseBulk();
      case RENDER_AMOUNT_PRIX:
        return this.renderAmountPrice();
      case RENDER_AMOUNT_TOTAL:
        return this.renderAmountOrder();
      case RENDER_AMOUNT_PRODUIT:
        return this.renderAmountProduit();
      case RENDER_OUI_NON:
        return this.renderOuiNon();
      case RENDER_CLIENT_TRANSACTION:
        return this.renderClientTransaction();
      case RENDER_CLIENT_ORDER:
        return this.renderClientOrder();
      case RENDER_CATEGORY_OPERATION_TYPE:
        return this.renderCategoryTypeOperation();
      case RENDER_DOUBLE:
        return this.renderDouble();
      case RENDER_AFFECTATION:
        return this.renderAffectation(field);
      case RENDER_OPERATION_CURRENCY:
        return this.renderOperationDevise();
      default:
        return this.renderText();
    }
  }

  getOptions(props: any) {
    const { retrieveTable, pageLength } = props;
    const opts = {
      pageLength: pageLength || 5,
      ordering: false,
      retrieve: typeof retrieveTable !== "undefined" ? retrieveTable : false,
      lengthMenu: [
        [5, 10, 25, 50, 100, -1],
        [5, 10, 25, 50, 100, "All"],
      ],
      language: {
        lengthMenu: "Afficher _MENU_",
        zeroRecords: "Tableau vide",
        info: "Page _PAGE_ sur _PAGES_",
        infoEmpty: "Aucune donnée disponible.",
        infoFiltered: "(filtrage de _MAX_ enregistrements.)",
        sSearch: "Rechercher",
        oPaginate: {
          sFirst: "Premier",
          sPrevious: "Précédent",
          sNext: "Suivant",
          sLast: "Dernier",
        },
      },
      createdRow: (row: any, data: any, index: any) => {
        ($(row) as any).on("click", ".actionBtn", (e: any) => {
          utils().emitter.emit(MODAL_ACTION, { btn: e.currentTarget, data });
        });
        ($(row) as any).on("click", (e: any) => {
          if (data?.userId) {
            const el = $(e.target) as any;
            if (
              !el.hasClass("btn") &&
              !el.hasClass("detailsActions") &&
              !el.hasClass("fa") &&
              !el.hasClass("btn-group")
            ) {
              utils().emitter.emit(MODAL_ACTION, {
                btn: "detailsActions",
                data,
              });
            }
          }
        });

        if (typeof props.createdRowCallBack === "function") {
          props.createdRowCallBack(row);
        }
      },
    };
    if (props?.data?.length) {
      if (props?.data[0].operationId && !BaseModel.getInstance().isClient) {
        Object.assign(opts, {
          dom: "Blfrtip",
          buttons: [
            {
              extend: "excel",
              footer: false,
              exportOptions: {
                columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
              },
            },
          ],
        });
      }
    }
    return opts;
  }
}

export { TableRenderer };

import React from "react";

export function RenderAffectation(props: any) {
  const { field, id } = props;

  return (
    <span
      className="d-flex justify-content-center"
      id={`fn-${field.name}-${id}`}
    ></span>
  );
}

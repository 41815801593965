import { clientPrefix } from "../_config";
import { Order, Product, Subscription, Transaction } from "../_entities";

export const dashboardRoutes = [
  {
    mainPath: clientPrefix,
    pageTitle: "Tableau de board ",
    listTitle: "Historique de vos transactions",
    addBtnTitle: "Faire une transaction",
    pageType: "table",
    filterType: "selectfilter",
    needFilterBlock: true,
    provider: Transaction.instance,
    dataPath: (meta: any) => {
      const { user } = meta.model;
      const accountId = user?.accountId || 0;
      return Transaction.instance.applyFilters(
        `/account/history/${accountId}`,
        meta
      );
    },
  },
  {
    mainPath: `${clientPrefix}/commandes`,
    pageTitle: "Mes commandes ",
    listTitle: "Liste de vos commandes",
    pageType: "table",
    filterType: "selectfilter",
    needFilterBlock: false,
    provider: Order.instance,
    dataPath: (meta: any) => {
      const { user } = meta.model;
      const accountId = user?.accountId || 0;
      return `/order/all/account/${accountId}`;
     /* return Transaction.instance.applyFilters(
        `/account/history/${accountId}`,
        meta
      );*/
    },
  },
  {
    mainPath: `${clientPrefix}/abonnements`,
    pageTitle: "Mes abonnements ",
    listTitle: "Liste de vos abonnements",
    pageType: "table",
    filterType: "selectfilter",
    needFilterBlock: false,
    provider: Subscription.instance,
    dataPath: (meta: any) => {
      const { user } = meta.model;
      const accountId = user?.accountId || 0;
      return `/subscription/account/${accountId}/all`;
      /*return Transaction.instance.applyFilters(
        `/account/history/${accountId}`,
        meta
      );*/
    },
  },
  {
    mainPath: `${clientPrefix}/produits`,
    pageTitle: "Produits",
    listTitle: "Liste des produits disponibles",
    provider: Product.instance,
    dataPath: (meta: any) => {
      return `/product/all`;
    },
  },
];

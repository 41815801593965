import React from "react";
import { BaseForm } from "../BaseForm/BaseForm";
import { TabBtns } from "./TabBtns";

export function PriceForm(props: any) {
  const { provider } = props.model;

  const onTabBtnClick = (e: any) => {
    e.preventDefault();
    ($(e.target) as any).tab("show");
  };
  return (
    <div className="row">
      <div className="col-3">
        <TabBtns onClick={onTabBtnClick} />
      </div>
      <div className="col-9">
        <div className="tab-content" id="v-pills-tabContent">
          <div
            className="tab-pane fade active show"
            id="v-pills-home"
            role="tabpanel"
            aria-labelledby="v-pills-home-tab"
          >
            <BaseForm
              {...props}
              customRows={provider.getRows("tabOne") || []}
              customLayoutInput={"col-md-12"}
              customLayoutLabel={"none"}
            />
          </div>
          <div
            className="tab-pane fade"
            id="v-pills-profile"
            role="tabpanel"
            aria-labelledby="v-pills-profile-tab"
          >
            <BaseForm
              {...props}
              customRows={provider.getRows("tabTwo") || []}
              customLayoutInput={"col-md-12"}
              customLayoutLabel={"none"}
            />
          </div>
          <div
            className="tab-pane fade"
            id="v-pills-messages"
            role="tabpanel"
            aria-labelledby="v-pills-messages-tab"
          >
            <BaseForm
              {...props}
              customRows={provider.getRows("tabThree") || []}
              customLayoutInput={"col-md-12"}
              customLayoutLabel={"none"}
            />
          </div>
          <div
            className="tab-pane fade"
            id="v-pills-settings"
            role="tabpanel"
            aria-labelledby="v-pills-settings-tab"
          >
            <BaseForm
              {...props}
              customRows={provider.getRows("tabFour") || []}
              customLayoutInput={"col-md-12"}
              customLayoutLabel={"none"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

import { utils } from "../_helpers";

export const entreprisePrefix = "/entreprise";

export const entrepriseSidebarItems = [
  {
    title: "Dashboard",
    path: entreprisePrefix,
    icon: "fa fa-dashboard",
  },
  {
    title: "Mes transactions",
    path: `${entreprisePrefix}/transactions`,
    icon: "fa fa-random",
    subItems: [
      {
        title: "Tous",
        path: `${entreprisePrefix}/transactions`,
      },
      {
        title: "Dépôts",
        path: `${entreprisePrefix}/transactions/depots`,
      },
      {
        title: "Retraits",
        path: `${entreprisePrefix}/transactions/retraits`,
      },
      {
        title: "Transferts",
        path: `${entreprisePrefix}/transactions/transferts`,
      },
      {
        title: "Frais",
        path: `${entreprisePrefix}/transactions/frais`,
      },
    ],
  },
  {
    title: "Transferts/retraits standards",
    path: `${entreprisePrefix}/standards`,
    icon: "fa fa-money",
    subItems: [
      {
        title: "Tous",
        path: `${entreprisePrefix}/standards`,
      },
      {
        title: "Transferts",
        path: `${entreprisePrefix}/standards/transferts`,
      },
      {
        title: "Retraits",
        path: `${entreprisePrefix}/standards/retraits`,
      },
    ],
  },
  {
    title: "Bulk payments",
    path: `${entreprisePrefix}/transactions/bulkpayments`,
    icon: "fa fa-clone",
    subItems: [
      {
        title: "Historique",
        path: `${entreprisePrefix}/bulkpayments/historique`,
      },
      {
        title: "Suivi des status",
        path: `${entreprisePrefix}/bulkpayments/status`,
      },
    ],
  },
  // {
  //   title: "Achat de devise",
  //   path: `${entreprisePrefix}/achatDevise`,
  //   icon: "fa fa-money",
  // },
  {
    title: "Bon virtuel",
    path: `${entreprisePrefix}/bonvirtuel`,
    icon: "fa fa-money",
  },
  {
    title: "Mon profile",
    path: `${entreprisePrefix}/profile`,
    icon: "fa fa-user",
  },

  {
    title: "Déconnexion",
    path: "/logout",
    icon: "fa fa-sign-out",
  },
];

export const entrepriseNavbarStyles = {
  background: "#ffc107",
  borderLeft: "1px solid #ffc107",
  borderRight: "1px solid #ffc107",
};

export const entrepriseKpiItems = [
  {
    path: `${entreprisePrefix}/transactions`,
    title: "Mes transactions",
    icon: "fa fa-random",
    key: "entreprise_transaction",
    deps: [
      {
        key: "transactions",
        path: (meta: any) => {
          const { user } = meta.model;
          const accountId = user?.accountId || 0;
          return `/account/history/${accountId}`;
        },
        method: "get",
        body: {},
      },
    ],
    resolve: (res: any) => {
      const result = res.data[0]?.result || {};
      if (Array.isArray(result.data)) {
        return result.data.length;
      }
      return 0;
    },
  },
  {
    path: `${entreprisePrefix}/transactions/depots`,
    title: "Total dépôts",
    icon: "fa fa-plus",
    key: "entreprise_transactions_depot",
    deps: [
      {
        key: "transactions",
        path: (meta: any) => {
          const { user } = meta.model;
          const accountId = user?.accountId || 0;
          return `/account/history/${accountId}`;
        },
        method: "get",
        body: {},
      },
    ],
    resolve: (res: any) => {
      const result = res.data[0]?.result || {};
      let rs = 0;
      if (Array.isArray(result.data)) {
        rs = result.data
          .filter((i: any) => i.operationType?.operationTypeId === 1)
          .map((o: any) => o.amount)
          .reduce((a: number, b: number) => a + b, 0);
      }
      return utils().formatAmount("CDF", rs);
    },
  },
  {
    path: `${entreprisePrefix}/transactions/retraits`,
    title: "Total retraits",
    icon: "fa fa-minus",
    key: "entreprise_transactions_retraits",
    deps: [
      {
        key: "transactions",
        path: (meta: any) => {
          const { user } = meta.model;
          const accountId = user?.accountId || 0;
          return `/account/history/${accountId}`;
        },
        method: "get",
        body: {},
      },
    ],
    resolve: (res: any) => {
      const result = res.data[0]?.result || {};
      let rs = 0;
      if (Array.isArray(result.data)) {
        rs = result.data
          .filter((i: any) => i.operationType?.operationTypeId === 2)
          .map((o: any) => o.amount)
          .reduce((a: number, b: number) => a + b, 0);
      }
      return utils().formatAmount("CDF", rs);
    },
  },
  {
    path: `${entreprisePrefix}/transactions/transferts`,
    title: "Total reçus",
    icon: "fa fa-download",
    key: "entreprise_transactions_recus",
    deps: [
      {
        key: "transactions",
        path: (meta: any) => {
          const { user } = meta.model;
          const accountId = user?.accountId || 0;
          return `/account/history/${accountId}`;
        },
        method: "get",
        body: {},
      },
    ],
    resolve: (res: any) => {
      const result = res.data[0]?.result || {};
      let rs = 0;
      if (Array.isArray(result.data)) {
        rs = result.data
          .filter((i: any) => i.operationType?.operationTypeId === 3)
          .map((o: any) => o.amount)
          .filter((m: any) => m > 0)
          .reduce((a: number, b: number) => a + b, 0);
      }
      return utils().formatAmount("CDF", rs);
    },
  },
  {
    path: `${entreprisePrefix}/transactions/transferts`,
    title: "Total envoyés",
    icon: "fa fa-upload",
    key: "entreprise_transactions_send",
    deps: [
      {
        key: "transactions",
        path: (meta: any) => {
          const { user } = meta.model;
          const accountId = user?.accountId || 0;
          return `/account/history/${accountId}`;
        },
        method: "get",
        body: {},
      },
    ],
    resolve: (res: any) => {
      const result = res.data[0]?.result || {};
      let rs = 0;
      if (Array.isArray(result.data)) {
        rs =
          result.data
            .filter((i: any) => i.operationType?.operationTypeId === 3)
            .map((o: any) => o.amount)
            .filter((m: any) => m < 0)
            .reduce((a: number, b: number) => a + b, 0) * -1;
      }
      return utils().formatAmount("CDF", rs);
    },
  },
  {
    path: `${entreprisePrefix}/transactions/transferts`,
    title: "Total autres",
    icon: "fa fa-random",
    key: "entreprise_transactions_other",
    deps: [
      {
        key: "transactions",
        path: (meta: any) => {
          const { user } = meta.model;
          const accountId = user?.accountId || 0;
          return `/account/history/${accountId}`;
        },
        method: "get",
        body: {},
      },
    ],
    resolve: (res: any) => {
      const result = res.data[0]?.result || {};
      let rs = 0;
      if (Array.isArray(result.data)) {
        const ids = [1, 2, 3];
        rs = result.data
          .filter(
            (i: any) =>
              ids.indexOf(i.operationType?.operationTypeId) === -1 ||
              i.operationType === null
          )
          .map((o: any) => o.amount)
          .reduce((a: number, b: number) => a + b, 0);
      }
      return utils().formatAmount("CDF", rs);
    },
  },
];

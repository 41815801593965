import { BankTransaction } from "../_entities";

export const clientConfig = {
  getItems,
};

export const clientPrefix = "/dashboard";

function getItems(pathname: string) {
  return [
    {
      key: "client_dashboard",
      title: "Tableau de bord",
      path: "/dashboard",
      icon: "fa-dashboard",
    },
    {
      key: "client_produits_services",
      title: "Opérations courantes",
      path: "/dashboard/produits",
      icon: "fa-user",
      active:
        pathname === "/dashboard/abonnements" ||
        pathname === "/dashboard/commandes" ||
        pathname === "/dashboard/produits" ||
        pathname === "/dashboard/profile",
      subItems: [
        {
          title: "Produits",
          subItems: [
            {
              title: "Tous les produits",
              active: pathname === "/dashboard/produits",
              path: "/dashboard/produits",
              attributes: {
                action: "navigate",
              },
            },
          ],
        },
        {
          title: "Boutique",
          subItems: [
            {
              title: "Mes commandes",
              active: pathname === "/dashboard/commandes",
              path: "/dashboard/commandes",
              attributes: {
                action: "navigate",
              },
            },
            {
              title: "Mes abonnements",
              active: pathname === "/dashboard/abonnements",
              path: "/dashboard/abonnements",
              attributes: {
                action: "navigate",
              },
            },
          ],
        },
        {
          title: "Taxes et impots",
          subItems: [
            {
              title: "Payer une taxe",
              active: pathname === "/dashboard",
              path: "/#",
              attributes: {
                action: "customModalActions",
                content: "taxForm",
                customModal: "#taxModal",
              },
            },
            // {
            //   title: "Liste des paiements",
            //   active: pathname === "/dashboard/howitworks#avantages",
            //   path: "/#",
            // },
          ],
        },
        {
          title: "Transfert international",
          subItems: [
            {
              title: "eMpata to Bank",
              path: "/#",
              attributes: {
                reference: "empata2wallet",
                provider: BankTransaction.instance
              },
              /*attributes: {
                action: "customModalActions",
                content: "empata2wallet",
                customModal: "#empata2walletModal",
              },*/
              
            },
            {
              title: "Transfert hors RDC",
              path: "/#",
              attributes: {
                action: "customModalActions",
                content: "apgtransfertform",
                customModal: "#apgModal",
              },
            },
            {
              title: "Achat Voucher",
              path: "/#",
              attributes: {
                action: "customModalActions",
                content: "bonvirtuelform",
                customModal: "#bonModal",
              },
            },
          ],
        },
      ],
    },
    {
      key: "client_deconnexion",
      title: "Déconnexion",
      path: "/logout",
      subItems: [],
      active: pathname === "/logout" ? true : false,
      icon: "fa-sign-out",
    },
  ];
}

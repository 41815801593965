import React from "react";
import { withRouter } from "react-router-dom";
import Auth from "../pages/Auth";
import { SigninFields, SigninFooter } from "../_components";
import { Details } from "../_components/common/Details/Details";
import { User } from "../_entities";
import { connector } from "../_reducers";
import firebase from "firebase";

export function withSignin(Component: React.LazyExoticComponent<typeof Auth>) {
  return withRouter(
    connector(
      class extends React.Component<any, any> {
        constructor(props: any) {
          super(props);
          this.state = {
            getTitle: (data: any) => this.getTitle(data.step),
            getBtnTitle: (data: any) => this.getBtnTitle(data.step),
            pageTitle: "Signin",
            getExtraAuthClass: (data: any) => (data.step === 1 ? "auth2" : ""),
            renderFields: (data: any) => this.getFields(data),
            renderFooter: (data: any) => <SigninFooter {...data} />,
            handleSubmit: (data: any, provider: any) =>
              this.handleSubmit(data, provider),
          };
          this.handleSubmit = this.handleSubmit.bind(this);
        }

        getFields(data: any) {
          if (data.step === 3) {
            return <Details {...this.props} data={data.data} />;
          }
          return <SigninFields {...data} />;
        }

        getBtnTitle(step: number) {
          if (step === 2) {
            return "Valider mon numéro";
          } else if (step === 3) {
            return "Se connecter";
          }
          return "S'inscrire";
        }

        getTitle(step: number) {
          if (step === 2) {
            return "Validation du téléphone";
          } else if (step === 3) {
            return "Récapitulatif";
          }
          return "Inscription";
        }

        async handleSubmit(data: any, provider: any) {
          let res = {};
          if (data.step === 1) {
            if (provider.validate()) {
              const payload = provider.serialize();
              res = await provider.processNew(payload);
              firebase.analytics().logEvent("sign_up", {});
            }
          } else if (data.step === 2) {
            const field = User.getPhoneValidationField();
            if (field[0].isValid) {
              console.log("==> ", data);
              console.log("==> ", field);
              res = await provider.processActivateCode();
            }
          } else if (data.step === 4) {
            window.location.href = "/login";
            //history.push("/login");
            return;
          }

          return res;
        }

        render() {
          return <Component {...this.props} {...this.state} />;
        }
      }
    )
  );
}

import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Headroom from "headroom.js";
import { clientConfig } from "../../../_config";
import { NavbarItem } from "./NavbarItem";
import mpata from "../../../_assets/images/mpata.png";
import { primaryColor } from "../../../_helpers";

class Navbar extends Component<any, any> {
  constructor(props: any) {
    super(props);
    const { pathname } = this.props.history.location;

    this.state = {
      pathname: pathname,
    };
  }

  componentDidUpdate(previousProps: any, previousState: any) {
    if (previousProps.location.pathname !== this.props.location.pathname) {
      this.setState({ pathname: this.props.location.pathname });
    }
  }

  componentDidMount() {
    const n = document.getElementById("navbar-main");
    if (n) {
      let headroom = new Headroom(n);
      headroom.init();
    }
  }

  render() {
    const { pathname } = this.state;
    let navItems = clientConfig.getItems(pathname);
    return (
      <header className="hearder-global">
        <nav
          id="navbar-main"
          className="navbar-main navbar-transparent navbar-light navbar navbar-expand-lg dasboard-header"
          style={{backgroundColor:primaryColor()}}
        >
          <div className="container">
            <Link
              className="mr-lg-5 navbar-brand d-flex flex-row align-items-center justify-content-center"
              to="/"
            >
              <img
                src={mpata}
                alt=""
                style={{ maxHeight: "30px", marginRight: "3px" }}
              />
              <h5 style={{ margin: 0 }}>e-Mpata</h5>
            </Link>

            <button className="navbar-toggler" id="navbar_global">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse">
              <div className="navbar-collapse-header">
                <div className="row">
                  <div className="collapse-brand col-6">
                    <a href="/#"> </a>
                  </div>
                  <div className="collapse-close col-6">
                    <button className="navbar-toggler" id="navbar_global">
                      <span></span>
                      <span></span>
                    </button>
                  </div>
                </div>
              </div>
              <ul className="align-items-lg-center ml-lg-auto navbar-nav">
                {navItems.map((item: any, i: number) => (
                  <NavbarItem {...this.props} navItem={item} key={i} />
                ))}
              </ul>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

const routedNavbar = withRouter(Navbar);
export { routedNavbar as Navbar };

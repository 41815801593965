import React, {Component} from "react";
import {restClient, utils} from "../../../../_helpers";

class WorkflowForm extends Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            freeze: true,
            success:false,
            stepNumber: null,
            label: "",
            steps: [],
            err: false,
            errmsg: "",
            msg:"",
            cls:"",
            finish: false,
            validator:"",
            validators: [],
            validatorsToSend:[]
        };
        console.log("Les props Worflow ajout", this.props.model);
        console.log("L état wflCrud", this.props.model?.wflCrud?.ecriture);
        this.handleClick = this.handleClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeLabel = this.handleChangeLabel.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this);
    }

    componentDidMount() {
        this.getVAlidators();
    }

    handleChange(e) {
        e.preventDefault();
        const {name, value} = e.target;
        this.clearArray(this.state.validatorsToSend);
        if(value>=6){
            this.setState({[name]: 5, success:false});
            this.initValidatorsToSend(5);
            this.validatorsFields(5);
        }else {
            this.setState({[name]: value, success:false});
            this.initValidatorsToSend(value);
            this.validatorsFields(value);
        }
    }

    clearArray(array) {
        while (array.length) {
            array.pop();
        }
    }

    initValidatorsToSend(taille:number){
        for(let i = 0; i<taille; i++){
            // this.setValidatorTosend(i, "");
            this.state.validatorsToSend[i]="";

        }
    }
    setValidatorTosend(name:any, value:any){
        let validatorsToSend = [...this.state.validatorsToSend];
        validatorsToSend[name] = value;
        this.setState({validatorsToSend});
    }

    handleChangeLabel(e) {
        e.preventDefault();
        const {name, value} = e.target;
        this.setState({[name]: value, success: false});
    }

    handleChangeSelect(e) {
        e.preventDefault();
        const {name, value} = e.target;
        console.log("Valeur Changée",name);
        this.state.validatorsToSend[name]=value;
        // this.setValidatorTosend(name, value);

        this.setState({
            success: false
        });
        console.log("VALIDATEURS A ENVOYER",this.state.validatorsToSend);
    }

    handleClick(e) {
        e.preventDefault();
        console.log("La taille du tableau ",this.state.validatorsToSend.length);
        let nulFind = false;
        if(typeof this.state.label === 'undefined' || this.state.label.trim().length<=0){
            alert("Veuillez renseigner le nom du workflow!");
            return;
        }
        if(this.state.validatorsToSend.length<=0){
            alert("Veuillez renseigner au moins un validateur!");
            return;
        }else{
            for (var i = 0; i < this.state.validatorsToSend.length; i++) {
                if(!this.state.validatorsToSend[i]){
                    nulFind = true;
                }
            }
            if(nulFind){alert("Veuillez renseigner tous les validateurs"); return;}
        }
        if(!this.checkIfArrayIsUnique(this.state.validatorsToSend)){
            alert("Veuillez choisir des validateurs différents!");
            return;
        }else {
            let payload = {
                label:this.state.label,
                nbrSteps:this.state.stepNumber,
                currentStep:0,
                finished:false,
                validators:this.state.validatorsToSend,
                type:"model"
            }
            console.log("Le payload à envoyer", payload);
            this.addWorkflow(payload);
        }
    }

    checkIfArrayIsUnique(arr) {
        var map = {}, i, size;
        for (i = 0, size = arr.length; i < size; i++){
            if (map[arr[i]]){
                return false;
            }
            map[arr[i]] = true;
        }
        return true;
    }
    getVAlidators(){
        restClient.get(`/user/allsupervisor`).then((res) => {
            if (res?.data) {
                res.data.map((user)=>{
                    let val = {
                        id:user.userId,
                        name:user.firstName+" "+user.lastName
                    };
                    this.state.validators.push(val);
                    // let validators = [...this.state.validators];
                    // validators.push(val);
                    // this.setState({validators});
                });
                this.setState({
                    freeze:false
                })
            }
        });
    }

    addWorkflow(payload:any){
        this.setState({
            freeze:true
        });
        restClient.post(`/workflows/add`,payload).then((res) => {
            console.log("La reponse workflow", res);
            if (res?.success) {
                this.setState({
                    freeze:false,
                    success: true,
                    msg:"Enregistrement effectué avec succès.",
                    cls:"alert-success"
                });
            }else{
                this.setState({
                    freeze:false,
                    success: true,
                    msg:res.message,
                    cls:"alert-danger"
                });
            }
        });
    }

    validatorsFields(nombre:number){
        var rows:any = [];
        for (var i = 0; i < nombre; i++) {
            rows.push(
                <div className="form-group row w-100 m-3" key={i}>
                    <div className="col-md-6" style={{ padding: "0 1rem 0 0" }}>
                        <label
                            htmlFor="colFormLabelSm"
                            className=" col-form-label col-form-label-sm"
                        >
                            Validateur {i+1}
                        </label>
                    </div>
                    <div className="col-md-6" style={{ padding: 0 }}>
                        <select
                            name={i.toString()}
                            className="form-control form-control-sm"
                            onChange={this.handleChangeSelect}
                            required={true}
                            style={{width: "100%"}}
                        >
                            <option value="">Sélectionner un validateur... </option>
                            {this.state.validators?.map((item, j) => {
                                return <option key={j} value={item.id}>{item.name}</option>
                            })}
                        </select>
                    </div>
                </div>
            );
        }
        this.setState({
            steps:rows,
            // validatorsToSend:[]
            // stepNumber:nombre
        });
    }

    render() {
        const {success,freeze,msg,cls,finish} = this.state;

        return (
            <div className='list-group'>
                <div
                    className="bg-white box-shadow operation-title d-flex justify-content-between"
                    style={{ borderBottom: "2px solid rgba(0, 0, 0, 0.125)" }}
                >
                    <h5>Ajouter un Workflow</h5>
                    {success&&(<span className={cls}>{msg}</span>)}
                </div>
                <div
                    className="list-group-item flex-column align-items-start"
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                >
                    <div className="d-flex w-100 px-5 py-0">
                        <form
                            id="account-details-form"
                            className="ui error form auth-form w-100"
                        >
                            <div className="form-group row w-100 m-0">
                                <div className="col-md-6" style={{ padding: "0 1rem 0 0" }}>
                                    <label
                                        htmlFor="colFormLabelSm"
                                        className=" col-form-label col-form-label-sm"
                                    >
                                        Label du workflow
                                    </label>
                                    <input
                                        className="form-control form-control-sm"
                                        name="label"
                                        type="text"
                                        required={true}
                                        value={this.state.label}
                                        onChange={this.handleChangeLabel}
                                        // disabled={true}
                                        style={{ backgroundColor: "#eee" }}
                                    />
                                </div>
                                <div className="col-md-6" style={{ padding: 0 }}>
                                    <label
                                        htmlFor="colFormLabelSm"
                                        className=" col-form-label col-form-label-sm"
                                    >
                                        Nombre d'étapes
                                    </label>
                                    <input
                                        className="form-control form-control-sm"
                                        name="stepNumber"
                                        type="number"
                                        value={this.state.stepNumber}
                                        required={true}
                                        onChange={this.handleChange}
                                        style={{ backgroundColor: "#eee" }}
                                    />
                                </div>
                            </div>
                            <div className='list-group'>
                                <div
                                    className="bg-white mt-3"
                                    style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.125)" }}
                                >
                                    <label>Validateurs</label>
                                </div>
                                <div className="container">
                                    {/*{this.validators(5)}*/}
                                    <>{this.state.steps}</>
                                </div>
                            </div>

                            <button
                                onClick={this.handleClick}
                                type="button"
                                className="btn btn-primary box-shadow mt-3"
                                style={{ float: "right" }}
                                disabled={this.props.model.user.user?.profile === null?false:this.props.model?.wflCrud?.ecriture?this.props.model?.wflCrud?.ecriture:true}
                                // disabled={!this.props.model.confCrud?.modification && this.props.model.user.user.profil!=="Admin"}
                            >
                                {freeze && (
                                    <div className='loader'>
                                        <div className='row align-items-center'>
                                            <img src='/public/build/images/loading.gif' alt=''/>
                                            <p className='loading-message' style={{margin: 0}}>
                                                Veuiller patienter
                                            </p>
                                        </div>
                                    </div>
                                )}
                                <i className="fa fa-save mr-1"></i>
                                Enregistrer
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}
export {WorkflowForm};

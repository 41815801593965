import firebase from "firebase";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyA0FQK79CBg2KYJvmMSLLcO8BnmUJ4SseU",
  authDomain: "e-mpata.firebaseapp.com",
  databaseURL: "https://e-mpata.firebaseio.com",
  projectId: "e-mpata",
  storageBucket: "e-mpata.appspot.com",
  messagingSenderId: "286925695033",
  appId: "1:286925695033:web:52590b1611d29696ac45e8",
  measurementId: "G-1JY0SW6F5D",
};

export const firebaseAnalytics = () => firebase.analytics();

import React from "react";
import { PropsFromRedux } from "../../../../_reducers";

export function SearchBtn(props: PropsFromRedux & any) {
  return (
    <div className="col-md-1">
      <a
        href="/#"
        id="search_btn"
        className="btn btn-default btn-sm"
        title="Rechercher"
        onClick={handleSearchClick}
        style={{
          float: "right",
          marginRight: "15px",
          padding: "0 0.5rem",
        }}
      >
        <i
          id="search_icon"
          className="fa fa-times fa-lg"
          style={{ transition: "0.3s all" }}
        ></i>
      </a>
    </div>
  );
}

const handleSearchClick = (e: any) => {
  e.preventDefault();
  let searchGroup = $("#search_group");
  let target = $(e.currentTarget);
  let icon = target.find("i");
  if (icon.hasClass("fa-times")) {
    searchGroup.slideUp();
    icon.removeClass("fa-times");
    icon.addClass("fa-search");
  } else {
    searchGroup.slideDown();
    icon.removeClass("fa-search");
    icon.addClass("fa-times");
  }
};

import React from "react";
import Admin from "../pages/Admin";
import { connector, PropsFromRedux } from "../_reducers";
import {
  entrepriseSidebarItems,
  entrepriseNavbarStyles,
  entrepriseKpiItems,
} from "../_config";
import { withRouter } from "react-router-dom";

export function withEntreprise(
  Component: React.LazyExoticComponent<typeof Admin>
) {
  return withRouter(
    connector(
      class extends React.Component<PropsFromRedux & any, any> {
        render() {
          const { model } = this.props;
          const { config } = model;
          Object.assign(config, {
            navbarStyles: entrepriseNavbarStyles,
            sidebarItems: entrepriseSidebarItems,
            kpiItems: entrepriseKpiItems,
          });
          if (!config?.fatalError && !config?.pageType) {
            Object.assign(config, { pageType: "table" });
          }
          return <Component {...this.props} {...config} />;
        }
      }
    )
  );
}

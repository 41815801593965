import React, { useState } from "react";
import { utils } from "../../../../../_helpers";
import { SwitchBtn } from "../SwitchBtn";
import { NavItems } from "./NavItems";
import { TabsContent } from "./TabsContent";

export function AccountParametres(props: any) {
  const { loading, account, refreshAccount, model } = props;
  const [freeze, setFreeze] = useState(false);

  const onClick = (e: any) => {
    e.preventDefault();
    setFreeze(true);
    account.proccessEditProfil().then((res: any) => {
      setFreeze(false);
      if (res?.accountId) {
        refreshAccount(res);
      }
    });
  };

  return (
    <div className="list-group">
      <div
        className="operation-title d-flex justify-content-between"
        style={{ borderBottom: "2px solid rgba(0, 0, 0, 0.125)" }}
      >
        {!model.isClient && <h5>Paramètres du compte</h5>}
        {model.isClient && (
          <h6
            style={{
              marginBottom: "0px",
              color: "rgb(15, 50, 86)",
              padding: "10px",
            }}
          >
            Mon profil
          </h6>
        )}

        {!loading && !utils().isProfil() && <SwitchBtn {...props} />}
      </div>

      <div
        className={`list-group-item flex-column align-items-start ${model.isClient ? "border-0" : ""}`}
        style={{ paddingLeft: 0, paddingRight: 0 }}
      >
        <div className={`d-flex w-100 py-0 ${model.isClient ? "" : " px-5"}`}>
          <form
            id="account-details-form"
            className="ui error form auth-form w-100"
          >
            <div className="d-flex flex-column justify-content-center align-items-center">
              <div className="row w-100">
                <div className="col-2">
                  <NavItems {...props} />
                </div>
                <div className="col-10 pr-0">
                  <TabsContent {...props} loading={freeze} />
                </div>
              </div>
            </div>
            <button
              onClick={onClick}
              type="button"
              className="btn btn-primary box-shadow mt-3"
              style={{ float: "right" }}
              disabled={
                !props.model.confCrud?.modification &&
                props.model.user.user?.profil !== "Admin" && !utils().isProfil()
              }
            >
              <i className="fa fa-save mr-1"></i>
              Enregistrer
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { FormField } from "../../../../common/Form/BaseForm/FormField";

export function Profil(props: any) {
  const { model } = props;
  const account = props.account || model.user;
  const fields = account.getProfilFields();
  console.log("FIELDS ICIC",fields);

  return (
    <>
      <div className="form-group row w-100 m-0">
        <div className="col-md-6" style={{ padding: "0 1rem 0 0" }}>
          <FormField
            {...props}
            field={fields[0]}
            customLayoutInput={"col-md-12 p-0 m-0 w-100"}
            customLayoutLabel={"none"}
            customStyles={{ margin: "0 0 1rem 0", width: "100%" }}
          />
        </div>
        <div className="col-md-6" style={{ padding: 0 }}>
          <FormField
            {...props}
            field={fields[1]}
            customLayoutInput={"col-md-12 p-0 m-0 w-100"}
            customLayoutLabel={"none"}
            customStyles={{ margin: "0 0 1rem 0", width: "100%" }}
          />
        </div>
      </div>
      <div className="form-group row w-100 m-0">
        <div className="col-md-6" style={{ padding: "0 1rem 0 0" }}>
          <FormField
            {...props}
            field={fields[2]}
            customLayoutInput={"col-md-12 p-0 m-0 w-100"}
            customLayoutLabel={"none"}
            customStyles={{ margin: "0 0 1rem 0", width: "100%" }}
          />
        </div>
        <div className="col-md-6" style={{ padding: 0 }}>
          <FormField
            {...props}
            field={fields[3]}
            customLayoutInput={"col-md-12 p-0 m-0 w-100"}
            customLayoutLabel={"none"}
            customStyles={{ margin: "0 0 1rem 0", width: "100%" }}
          />
        </div>
      </div>
      <div className="form-group row w-100 m-0">
        <div className="col-md-6" style={{ padding: "0 1rem 0 0" }}>
          <FormField
            {...props}
            field={fields[4]}
            customLayoutInput={"col-md-12 p-0 m-0 w-100"}
            customLayoutLabel={"none"}
            customStyles={{ margin: "0 0 1rem 0", width: "100%" }}
          />
        </div>
        <div className="col-md-6" style={{ padding: 0 }}>
          <FormField
            {...props}
            field={fields[5]}
            customLayoutInput={"col-md-12 p-0 m-0 w-100"}
            customLayoutLabel={"none"}
            customStyles={{ margin: "0 0 1rem 0", width: "100%" }}
          />
        </div>
        <div className="col-md-6" style={{ padding: 0 }}>
          <FormField
              {...props}
              field={fields[6]}
              customLayoutInput={"col-md-12 p-0 m-0 w-100"}
              customLayoutLabel={"none"}
              customStyles={{ margin: "0 0 1rem 0", width: "100%" }}
          />
        </div>
      </div>
    </>
  );
}

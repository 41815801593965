import React, { Component } from "react";
import { appConstants } from "../../../_constants";
import { restClient, utils } from "../../../_helpers";
import { connector, PropsFromRedux } from "../../../_reducers";
import { ModalBody } from "./ModalBody";
import { ModalFooter } from "./ModalFooter";
import { ModalHeader } from "./ModalHeader";
import { history } from "../../../_helpers";
import { ApgTransfertForm } from "../APG/ApgTransfertForm";
import { PayerTaxeForm } from "../Taxe/PayerTaxeForm";
import { BaseModel } from "../../../_models";
import { CentralPayForm } from "../CentralPay/CentralPay";
const { MODAL_ACTION, FULL_LOADING_ACTION } = appConstants.keys.eventKeys;

class CustomModal extends Component<PropsFromRedux, any> {
  private unsubscribe: any;
  private mounted = false;
  constructor(props: PropsFromRedux) {
    super(props);
    this.state = {
      loading: false,
      mode: "",
      data: "",
      title: "",
      submitted: false,
      settings: {},
      errorCallBack: (e: string) => this.errorCallBack(e),
      stateSetter: (data: any) => this.stateSetter(data),
      handleSubmit: (e: any) => this.handleSubmit(e),
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.unsubscribe = history.listen((location, action) => {
      if (this.mounted) {
        this.setState({
          loading: false,
          mode: "",
          data: "",
          title: "",
          submitted: false,
          settings: {},
        });
      }
    });
  }

  componentDidMount() {
    this.mounted = true;
    utils().emitter.on(MODAL_ACTION, (res) => {
      utils().emitter.emit(FULL_LOADING_ACTION, true);
      this.handleFormActionEvent(res);
    });

    ($("#modal") as any).on("hidden.bs.modal", (e: any) => {
      utils().emitter.emit("hide_next", false);
      utils().emitter.emit("alert_message", {
        content: "",
        cls: "alert-danger",
      });
      this.props.model?.provider?.reset();
      if (this.mounted) {
        this.setState({
          loading: false,
          mode: "",
          data: "",
          title: "",
          submitted: false,
          settings: {},
        });
      }
    });
  }

  stateSetter(data: any) {
    this.setState({ ...data });
  }

  public get handlers(): any[] {
    return [
      {
        action: "detailsActions",
        handle: (res: any) => this.initDetails(res),
      },
      {
        action: "switchActionsActivate",
        handle: (res: any) => this.initSwitchAction(res, true),
      },
      {
        action: "switchActionsDeactivate",
        handle: (res: any) => this.initSwitchAction(res, false),
      },
      {
        action: "createActions",
        handle: (res: any) => this.initCreateAction(res?.data),
      },
      {
        action: "showMessage",
        handle: (res: any) => this.showMessage(res),
      },
      {
        action: "editActions",
        handle: (res: any) => this.initEditAction(res?.data),
      },
      {
        action: "deleteActions",
        handle: (res: any) => this.initDeleteAction(res),
      },
      {
        action: "customActions",
        handle: (res: any) => this.initCustomAction(res),
      },
      {
        action: "customModalActions",
        handle: (res: any) => this.initCustomModalAction(res.data),
      },
      {
        action: "navigateActions",
        handle: (res: any) => this.initNavigateAction(res),
      },
      {
        action: "processActions",
        handle: (res: any) => this.initProcessAction(res),
      },
    ];
  }

  async handleFormActionEvent(res: any) {
    const action = res?.btn?.dataset?.action || res?.btn;
    console.log(action);
    const i = this.handlers.findIndex((h) => h.action === action);
    if (i > -1) {
      await this.handlers[i].handle(res);
    }
  }

  async initNavigateAction(res: any) {
    utils().emitter.emit(FULL_LOADING_ACTION, false);
    const link = res?.btn?.dataset?.link || false;
    sessionStorage.setItem("key", JSON.stringify(res.data));
    if (link) {
      history.push({ pathname: link, state: res.data });
    }
  }

  async initCustomAction(props: any) {
    console.log("props de Workflow", props);
    const res = await this.props.model?.provider?.initCustom(props);
    if (!(res?.process === false)) {
      const data = props;
      const mode = "customActions";
      const title = res?.settings?.title || "";
      const content = res?.settings?.content || "";
      this.setState({ ...res, data, mode, title, content });
      utils().emitter.emit(FULL_LOADING_ACTION, false);
      this.showModal();
    } else {
      if (res?.refresh) {
        this.props.model.refreshDataTableData(this.props).then((res) => {
          utils().emitter.emit(FULL_LOADING_ACTION, false);
        });
      } else {
        utils().emitter.emit(FULL_LOADING_ACTION, false);
      }
    }
  }

  async initProcessAction(props: any) {
    const processKey = props?.btn?.dataset?.key;
    const res = await this.props.model?.provider?.processAction(
      props,
      processKey
    );
    if (res) {
      this.props.model.refreshDataTableData(this.props).then((res) => {
        utils().emitter.emit(FULL_LOADING_ACTION, false);
      });
    } else {
      utils().emitter.emit(FULL_LOADING_ACTION, false);
    }
  }

  async initCustomModalAction(props: any) {
    const data = props;
    const mode = "customModalActions";
    const content = props.content || "";
    this.setState({ data, mode, content });
    utils().emitter.emit(FULL_LOADING_ACTION, false);
    this.showModal();
  }

  async initEditAction(props: any) {
    await this.props.model?.provider?.initEdit(props);
    const data = props;
    const mode = "editActions";
    const title =
      props?.model?.provider?.editTitle || props?.editTitle || "Modifier";
    this.setState({ data, mode, title, settings: {} });
    utils().emitter.emit(FULL_LOADING_ACTION, false);
    this.showModal();
  }

  async initCreateAction(props: any) {
    const provider =
      props?.provider ||
      props?.model?.provider ||
      BaseModel.getInstance().provider;

    await provider?.initNew();
    const data = props;
    const mode = "createActions";
    const title = provider?.addTitle || "Ajouter";
    this.setState({ data, mode, title, settings: {} });
    utils().emitter.emit(FULL_LOADING_ACTION, false);
    this.showModal();
  }

  async initSwitchAction(res: any, status: boolean) {
    if (typeof res?.data?.switchAction === "function") {
      console.log("RES ICI", res);
      res.data.switchAction(status).then((res) => {
        if (res) {
          this.props.model.refreshDataTableData(this.props).then((res) => {
            utils().emitter.emit(FULL_LOADING_ACTION, false);
          });
        } else {
          utils().emitter.emit(FULL_LOADING_ACTION, false);
          alert(
            res.message
              ? res.message
              : "Une erreur s'est produite lors du changement de status !"
          );
        }
      });
    } else {
      utils().emitter.emit(FULL_LOADING_ACTION, false);
      alert("Impossible de changer le status. Merci de reéssayer !");
    }
  }

  async initDeleteAction(res: any) {
    const v = window.confirm(
      "Etes-vous certain de vouloir supprimer cet élément ? Cette action est irreversible !"
    );
    if (v) {
      if (typeof res?.data?.deleteAction === "function") {
        res.data.deleteAction().then((res: any) => {
          if (res) {
            this.props.model.refreshDataTableData(this.props).then((res) => {
              utils().emitter.emit(FULL_LOADING_ACTION, false);
            });
          } else {
            utils().emitter.emit(FULL_LOADING_ACTION, false);
            alert(
              "Une erreur est survenu lors de la suppression. Merci de reéssayer !"
            );
          }
        });
      } else {
        utils().emitter.emit(FULL_LOADING_ACTION, false);
        alert("Impossible de supprimer l'élément. Merci de reéssayer !");
      }
    } else {
      utils().emitter.emit(FULL_LOADING_ACTION, false);
    }
  }

  async initDetails(res: any) {
    const data = res?.data;
    if (typeof data?.initDetails === "function") {
      await data?.initDetails();
    }
    const mode = "detailsActions";
    const title = data.showTitle || "Détails";
    this.setState({ data, mode, title, settings: {} });
    this.showModal();
  }

  showModal() {
    utils().emitter.emit(FULL_LOADING_ACTION, false);
    ($("#modal") as any).modal({
      show: true,
      keyboard: true,
      backdrop: "static",
    });
  }

  errorCallBack(msg: string) {
    utils().emitter.emit("alert_message", {
      content: msg,
      cls: "alert-danger",
    });
  }

  componentWillUnmount() {
    this.mounted = false;
    this.unsubscribe();
    utils().emitter.off(MODAL_ACTION, this.handleFormActionEvent);
    ($("#modal") as any).off("hidden.bs.modal");
  }

  async handleSubmit(e: any) {
    e?.preventDefault();
    if (this.state.settings?.handleSubmit) {
      this.state.settings?.handleSubmit();
      return;
    }
    window.scroll(0, 0);
    this.setState({ submitted: true, loading: true });
    utils().emitter.emit("alert_message", {});
    const provider = this.state.data?.provider || this.props.model.provider;

    let res: any = {};
    if (provider) {
      if (this.state.settings.action === "validation") {
        this.validation(provider);
      } else if (this.state.settings.action === "correction") {
        this.correct(provider);
      } else {
        if (this.state.settings.action === "hierarchiser") {
          this.hier(provider);
        }
        if (this.state.settings.action === "updateWorkflow") {
          this.updateWorkflow();
        } else {
          if (this.state.settings.action === "validateStep") {
            this.validateStep(provider);
          } else {
            if (provider.validate({ ...this.props, ...this.state })) {
              const payload = provider.serialize();
              res = this.isEdit
                ? await provider.processEdit(payload)
                : await provider.processNew({
                    ...payload,
                    modalData: this.state.data,
                  });
              if (res) {
                if (!Array.isArray(res)) {
                  provider.populate(res);
                  if (typeof provider?.initDetails === "function") {
                    await provider?.initDetails();
                  }
                }
                this.props.model.refreshDataTableData(this.props);
                const data = Array.isArray(res) ? res : provider;
                return this.setState({
                  data,
                  loading: false,
                  mode: "detailsActions",
                  title: provider.showTitle,
                  hideFooter: false,
                  settings: {},
                });
              }
            }
          }
        }
      }
    }
    this.setState({ loading: false });
  }

  checkIfArrayIsUnique(arr) {
    var map = {},
      i,
      size;
    for (i = 0, size = arr.length; i < size; i++) {
      if (map[arr[i]]) {
        return false;
      }
      map[arr[i]] = true;
    }
    return true;
  }

  async updateWorkflow() {
    let payload = {
      label: this.state.settings.newLabel,
      nbrSteps: this.state.settings.nbrSteps,
      currentStep: 0,
      finished: false,
      validators: this.state.settings.validatorsToSend,
      type: "model",
    };
    let workflowId = this.state.data.data.workflowId;

    console.log("le payload à envoyer", payload);

    if (
      !this.checkIfArrayIsUnique(payload.validators) ||
      payload.validators.length === 0
    ) {
      alert("Veuillez choisir des validateurs différents!");
      return;
    } else {
      for (var i = 0; i < payload.validators.length; i++) {
        if (payload.validators[i] === "") {
          alert("Veuillez renseigner tous validateurs!");
          return;
        }
      }
    }
    this.setState({
      loading: true,
    });
    await restClient
      .put(`/workflows/update/${workflowId}`, payload)
      .then((res) => {
        console.log("RES WFL", res);
        if (res?.success) {
          this.props.model.refreshDataTableData(this.props);
          return this.setState({
            settings: {
              content: "updateWorkflow",
              title: "Modifier ce workflow",
              showSaveBtn: true,
              action: "updateWorkflow",
              message: "Modification effectuée avec succès.",
              cls: "alert-success",
            },
            loading: false,
            mode: "customActions",
            hideFooter: false,
          });
        } else {
          return this.setState({
            settings: {
              content: "updateWorkflow",
              title: "Modifier ce workflow",
              showSaveBtn: true,
              action: "updateWorkflow",
              message: "Echec de modification!" + res?.message,
              cls: "alert-danger",
            },
            loading: false,
            mode: "customActions",
            hideFooter: false,
          });
        }
      });
  }

  async validation(provider) {
    console.log(this.state);
    this.setState({
      loading: true,
    });
    const { emetMsg, destMsg } = this.state.settings.payload;
    console.log("Les states ici", this.state.settings);
    if (!emetMsg || !destMsg) {
      alert("Veuillez saisir les messages de l'emetteur et du destinataire !");
      return;
    }

    let res = await restClient.post(
      encodeURI(
        `operation/empatatobank/changeStatus/${this.state.data?.data?.operationId}/${emetMsg}/${destMsg}`
      ),
      {}
    );
    this.props.model.refreshDataTableData(this.props);
    return this.setState({
      settings: {
        ...this.state.settings,
        content: "validationtx",
        title: "Validation d'une transaction",
        showSaveBtn: true,
        btnTitle: "Valider",
        action: "validation",
        message:
          res?.data?.message ||
          "Erreur lors de la validation! Veuillez reessayer plus tard!",
        fund: this.state.settings.fund,
        status: this.state.settings.status,
      },
      loading: false,
      mode: "customActions",
      hideFooter: false,
    });
  }

  async correct(provider) {
    this.setState({
      loading: true,
    });
    console.log("Les states ici", this.state.settings);
    if (this.state.settings.fund == "" && this.state.settings.status == "") {
      alert("Veuillez choisir un statut ou une action à appliquer!");
      return;
    }
    let payload = {
      status: this.state.settings.status,
      action: this.state.settings.fund,
      opReference: this.state.data.data.reference,
    };
    let res = await provider.correcTx(payload);
    this.props.model.refreshDataTableData(this.props);
    return this.setState({
      settings: {
        content: "corrtx",
        title: "Corriger une transaction",
        showSaveBtn: false,
        btnTitle: "Corriger",
        action: "correction",
        message: res
          ? res
          : "Erreur lors de la correction! Veuillez reessayer plus tard!",
        fund: this.state.settings.fund,
        status: this.state.settings.status,
      },
      loading: false,
      mode: "customActions",
      hideFooter: false,
    });
  }

  formatdate(dt: string) {
    return (
      dt.substring(8, 10) +
      "/" +
      dt.substring(5, 7) +
      "/" +
      dt.substring(0, 4) +
      " " +
      dt.substring(11, 13) +
      ":" +
      dt.substring(14, 16)
    );
  }

  async showMessage(res: any) {
    const data = res?.data;
    const mode = "customActions";
    const title = "Message du " + this.formatdate(data.notif.createDate);
    const content = "showMsg";
    this.setState({
      data,
      mode,
      title,
      content,
      settings: {
        showSaveBtn: false,
        hasNext: true,
      },
    });
    utils().emitter.emit(FULL_LOADING_ACTION, false);
    this.showModal();
  }

  async hier(provider) {
    this.setState({
      loading: true,
    });
    let accountParent = this.state.settings.account;
    let parentAccountId = this.state.settings.parentAccountId;
    let res = await provider.hierTx(
      this.state.settings.parentAccountId,
      this.state.data.data.accountId
    );
    this.props.model.refreshDataTableData(this.props);
    return this.setState({
      settings: {
        content: "hierCompte",
        title: "Définir le parent de ce compte",
        showSaveBtn: true,
        action: "hierarchiser",
        message: "Compte parent bien défini.",
        account: accountParent,
        parentAccountId: parentAccountId,
      },
      loading: false,
      mode: "customActions",
      hideFooter: false,
    });
  }

  async validateStep(provider) {
    this.setState({
      loading: true,
    });
    ///Ecrire la méthode qui rejette un step ici. S'inspirer de ci-dessus
    if (this.state.settings.motif == "") {
      alert("Veuillez préciser la raison du rejet!");
      return;
    }
    let payload = {
      message: this.state.settings.motif,
    };
    console.log("Le payload à envoyer", payload);
    console.log("L'id du step à envoyer", this.state.data.data.workflowStepId);

    let res = await provider.declineValidation(
      payload,
      this.state.data.data.workflowStepId
    );
    console.log("La res declineValidation ici", res);

    this.props.model.refreshDataTableData(this.props);
    return this.setState({
      settings: {
        content: "validateStep",
        title: "Rejeter cette validation",
        showSaveBtn: true,
        action: "validateStep",
        message: res?.success ? "Rejet effectué avec succès!" : res.message,
        cls: res?.success ? "text-success" : "text-danger",
      },
      loading: false,
      mode: "customActions",
      hideFooter: false,
    });
  }

  public get isDetails(): boolean {
    return this.state.mode === "detailsActions";
  }

  public get isNew(): boolean {
    return this.state.mode === "createActions";
  }

  public get isCustom(): boolean {
    return this.state.mode === "customActions";
  }

  public get isCustomModal(): boolean {
    return this.state.mode === "customModalActions";
  }

  public get isEdit(): boolean {
    return this.state.mode === "editActions";
  }

  public get isMsg(): boolean {
    return this.state.mode === "msgActions";
  }

  public get maxWidth(): string {
    if (this.state.settings?.content === "clientform") {
      return "1000px";
    }
    return this.state.settings?.content === "productdetails"
      ? "800px"
      : "600px";
  }

  render() {
    return (
      <>
        <CentralPayForm {...this.props} {...this.state} />
        {this.props.model.isClient && (
          <>
            <PayerTaxeForm {...this.props} {...this.state} />
            <ApgTransfertForm {...this.props} {...this.state} />
          </>
        )}

        <div
          className="modal fade"
          id={"modal"}
          tabIndex={-1}
          role="dialog"
          aria-labelledby="ModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{
              maxWidth: this.maxWidth,
            }}
          >
            <div className="modal-content" style={{ padding: "30px" }}>
              <ModalHeader {...this.state} {...this.props} />
              <ModalBody
                {...this.state}
                {...this.props}
                isDetails={this.isDetails}
                isNew={this.isNew}
                isEdit={this.isEdit}
                isMsg={this.isMsg}
                isCustom={this.isCustom}
              />
              <ModalFooter
                {...this.state}
                {...this.props}
                isDetails={this.isDetails}
                isCustom={this.isCustom}
                isNew={this.isNew}
                isEdit={this.isEdit}
                isMsg={this.isMsg}
                handleSubmit={this.handleSubmit}
                hasNext={this.hasNext}
              />
            </div>
          </div>
        </div>
      </>
    );
  }

  public get hasNext(): boolean {
    const { provider } = this.props.model;
    return provider?.hasNext || false;
  }
}

const connectedModal = connector(CustomModal);
export { connectedModal as CustomModal };

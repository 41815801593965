import React from "react";
import { components } from ".";

export default function FilterWrapper(props: any) {
  const { fatalError, filterType } = props;

  let Component = (components as any)[filterType || "datefilter"];
  const isError = !Component || fatalError;

  return isError ? <div /> : <Component {...props} />;
}

import React from "react";
import { BaseModel } from "../../_models";

export function RenderActions(props: any) {
  const { actions, data, id, field } = props;
  const cls = field?.customData.renderOptions?.clsActions || "";
  return (
    <span className={`d-flex ${cls}`} key={id}>
      {actions &&
        actions?.length &&
        actions.map((action: any, i: number) => {
          const config =
            typeof action.actionCallback === "function"
              ? action.actionCallback(data)
              : action;
          if (action?.type === "btnGroup") {
            return <ButtonGroup id={id} {...config} data={data} key={i} />;
          }
          return <Button id={id} {...config} key={i} />;
        })}
    </span>
  );
}

function Button(props: any) {
  const { label, title, action, color, id, icon, link, processkey } = props;
  return (
    <button
      data-placement="top"
      data-toggle="tooltip"
      title={title}
      className={`btn ${color} btn-sm d-flex align-items-center mr-1 actionBtn box-shadow ${action}`}
      style={{ fontSize: "11px" }}
      data-id={id}
      data-action={action}
      data-link={link}
      data-key={processkey}
    >
      <i className={`${icon} mr-1`}></i>
      {label}
    </button>
  );
}

function ButtonGroup(props: any) {
  const { color, children, id, data } = props;
  return (
    <div className="btn-group align-items-center  d-flex">
      <button
        type="button"
        className={`btn ${color} btn-sm d-flex dropdown-toggle align-items-center mr-1 box-shadow`}
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        style={{ fontSize: "80%" }}
      >
        Plus ...
      </button>
      <div className="dropdown-menu">
        {children.map((child: any, i: number) => {
          if (
            !child?.shouldShow ||
            (child?.shouldShow && child?.shouldShow(data))
          ) {
            return <Buttonlink id={id} {...child} data={data} key={i} />;
          }
        })}
      </div>
    </div>
  );
}

function Buttonlink(props: any) {
  const config =
    typeof props.actionCallback === "function"
      ? props.actionCallback(props.data)
      : props;
  const { label, title, action, color, link, id, icon, processkey } = config;
  if (action === "generateActions") {
    return (
      <a
        href={`${BaseModel.getInstance().wsUrl}/client/download/${id}`}
        target="_blank"
        title={title}
        className={`btn btn-sm ${color} d-flex align-items-center mr-1 ${action}`}
        style={{ fontSize: "11px" }}
      >
        <i className={`${icon} mr-1`}></i>
        {label}
      </a>
    );
  }
  return (
    <a
      data-placement="top"
      data-toggle="tooltip"
      title={title}
      className={`btn btn-sm ${color} d-flex align-items-center mr-1 actionBtn actionLink ${action}`}
      style={{ fontSize: "11px" }}
      data-id={id}
      data-action={action}
      data-link={link}
      data-key={processkey}
    >
      <i className={`${icon} mr-1`}></i>
      {label}
    </a>
  );
}

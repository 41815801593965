import { supermarchandPrefix } from "../_config";
import {
  Transaction,
  Product,
  SubscriptionPlan,
  SubscriptionPlanPeriod,
  Subscription,
  SubscriptionPlanPeriodFile,
  Account,
  Marchand
} from "../_entities";

export const supermarchandRoutes = [
  {
    mainPath: supermarchandPrefix,
    pageTitle: "Tableau de board supermarchand",
    pageType: "kpi",
    provider: undefined,
  },
  {
    mainPath: `${supermarchandPrefix}/mes_marchands`,
    pageTitle: "Mes marchands",
    addBtnTitle: "Ajouter un marchand",
    listTitle: "Liste de vos marchands",
    provider: Marchand.instance,
    dataPath: (meta: any) => {
      const { user } = meta.model;
      const accountId = user?.accountId || 0;
      return `/marchand/${accountId}/all`;
    },
  },
  {
    mainPath: `${supermarchandPrefix}/mes_marchands/details/`,
    pageTitle: "Détails d'un compte",
    pageType: "accountDetails",
    listTitle: "Liste des transactions réalisées sur ce compte",
    provider: Transaction.instance,
    dataPath: (meta: any) => {
      const { user } = meta.model;
      const accountId =
        typeof meta?.getAccountId === "function"
          ? meta?.getAccountId()
          : user?.accountId || 0;
      return Transaction.instance.applyFilters(
        `/account/history/${accountId}`,
        meta
      );
    },
  },
  {
    mainPath: `${supermarchandPrefix}/transactions`,
    pageTitle: "Paiements et transferts",
    addBtnTitle: "Faire une transaction",
    listTitle: "Liste de paiements et transferts",
    provider: Transaction.instance,
    dataPath: (meta: any) => {
      const { user } = meta.model;
      const accountId = user?.accountId || 0;
      return `/account/history/${accountId}`;
    },
  },
  {
    mainPath: `${supermarchandPrefix}/transactions/paiements`,
    pageTitle: "Paiements",
    listTitle: "Liste de vos paiements",
    provider: Transaction.instance,
    dataPath: (meta: any) => {
      const { user } = meta.model;
      const accountId = user?.accountId || 0;
      const { filter } = meta;
      if (filter) {
        const { period, datedebut, datefin } = filter;
        if (period !== "0") {
          return `/account/history/${accountId}?period=${period}`;
        }
        if (datedebut !== "" && datefin !== "") {
          return `/account/history/${accountId}?datedebut=${datedebut}_AMP_datefin=${datefin}`;
        }
      }
      return `/account/history/${accountId}`;
    },
    dataPathCallBack: (res: any, props: any) => {
      const data = Array.isArray(res.data) ? res.data : [];
      return data.filter((d: any) => d.operationType?.operationTypeId === 5);
    },
  },
  {
    mainPath: `${supermarchandPrefix}/transactions/transferts`,
    pageTitle: "Transferts/autres",
    listTitle: "Liste de vos transactions",
    provider: Transaction.instance,
    dataPath: (meta: any) => {
      const { user } = meta.model;
      const accountId = user?.accountId || 0;
      return `/account/history/${accountId}`;
    },
    dataPathCallBack: (res: any, props: any) => {
      const data = Array.isArray(res.data) ? res.data : [];
      return data.filter((d: any) => d.operationType?.operationTypeId !== 5);
    },
  },
  {
    mainPath: `${supermarchandPrefix}/produits`,
    pageTitle: "Vos produits",
    listTitle: "Liste de vos produits",
    provider: Product.instance,
    addBtnTitle: "Ajouter un produit",
    dataPath: (meta: any) => {
      const { user } = meta.model;
      const accountId = user?.accountId || 0;
      return `/product/marchand/${accountId}/all?virtuel=0`;
    },
  },
  {
    mainPath: `${supermarchandPrefix}/produits/abonnement`,
    pageTitle: "Vos produits",
    listTitle: "Liste de vos produits avec abonnement",
    addBtnTitle: "Ajouter un produit avec abonnement",
    provider: Product.instance,
    dataPath: (meta: any) => {
      const { user } = meta.model;
      const accountId = user?.accountId || 0;
      return `/product/marchand/${accountId}/all?virtuel=0_AMP_hasAbonnement=1`;
    },
  },
  {
    mainPath: `${supermarchandPrefix}/produits/sans_abonnement`,
    pageTitle: "Vos produits",
    addBtnTitle: "Ajouter un produit sans abonnement",
    listTitle: "Liste de vos produits sans abonnement",
    provider: Product.instance,
    dataPath: (meta: any) => {
      const { user } = meta.model;
      const accountId = user?.accountId || 0;
      return `/product/marchand/${accountId}/all?virtuel=0_AMP_hasAbonnement=0_AMP_test=1`;
    },
  },
  {
    mainPath: `${supermarchandPrefix}/services`,
    pageTitle: "Services",
    addBtnTitle: "Ajouter un service",
    listTitle: "Liste de vos services",
    provider: Product.instance,
    dataPath: (meta: any) => {
      const { user } = meta.model;
      const accountId = user?.accountId || 0;
      return `/product/marchand/${accountId}/all?virtuel=1`;
    },
  },
  {
    mainPath: `${supermarchandPrefix}/services/abonnement`,
    pageTitle: "Vos services",
    listTitle: "Liste de vos services avec abonnement",
    addBtnTitle: "Ajouter un service avec abonnement",
    provider: Product.instance,
    dataPath: (meta: any) => {
      const { user } = meta.model;
      const accountId = user?.accountId || 0;
      return `/product/marchand/${accountId}/all?virtuel=1_AMP_hasAbonnement=1`;
    },
  },
  {
    mainPath: `${supermarchandPrefix}/services/sans_abonnement`,
    pageTitle: "Vos services",
    addBtnTitle: "Ajouter un service sans abonnement",
    listTitle: "Liste de vos services sans abonnement",
    provider: Product.instance,
    dataPath: (meta: any) => {
      const { user } = meta.model;
      const accountId = user?.accountId || 0;
      return `/product/marchand/${accountId}/all?virtuel=1_AMP_hasAbonnement=0_AMP_test=1`;
    },
  },
  {
    mainPath: `${supermarchandPrefix}/services/sans_abonnement`,
    pageTitle: "Vos services",
    addBtnTitle: "Ajouter un service sans abonnement",
    listTitle: "Liste de vos services sans abonnement",
    provider: Product.instance,
    dataPath: (meta: any) => {
      const { user } = meta.model;
      const accountId = user?.accountId || 0;
      return `/product/marchand/${accountId}/all?virtuel=1_AMP_hasAbonnement=0_AMP_test=1`;
    },
  },
  {
    mainPath: `${supermarchandPrefix}/plan_subscriptions`,
    pageTitle: "Plans de souscription",
    addBtnTitle: "Ajouter un plan de souscription",
    listTitle: "Liste de vos plans de souscription",
    provider: SubscriptionPlan.instance,
    dataPath: (meta: any) => {
      const { user } = meta.model;
      const accountId = user?.accountId || 0;
      return `/subscription/marchand/${accountId}/all`;
    },
  },
  {
    mainPath: `${supermarchandPrefix}/plan_subscriptions/journalier`,
    pageTitle: "Plans de souscription",
    addBtnTitle: "Ajouter un plan de souscription journalier",
    listTitle: "Liste de vos plans de souscription journalier",
    provider: SubscriptionPlan.instance,
    dataPath: (meta: any) => {
      const { user } = meta.model;
      const accountId = user?.accountId || 0;
      return `/subscription/marchand/${accountId}/all`;
    },
    dataPathCallBack: (res: any, props: any) => {
      const data = Array.isArray(res.data) ? res.data : [];
      return data.filter((d: any) => d.quota?.quotaId === 1);
    },
  },
  {
    mainPath: `${supermarchandPrefix}/plan_subscriptions/hebdomadaire`,
    pageTitle: "Plans de souscription",
    addBtnTitle: "Ajouter un plan de souscription hebdomadaire",
    listTitle: "Liste de vos plans de souscription hebdomadaire",
    provider: SubscriptionPlan.instance,
    dataPath: (meta: any) => {
      const { user } = meta.model;
      const accountId = user?.accountId || 0;
      return `/subscription/marchand/${accountId}/all`;
    },
    dataPathCallBack: (res: any, props: any) => {
      const data = Array.isArray(res.data) ? res.data : [];
      return data.filter((d: any) => d.quota?.quotaId === 2);
    },
  },
  {
    mainPath: `${supermarchandPrefix}/plan_subscriptions/mensuel`,
    pageTitle: "Plans de souscription",
    addBtnTitle: "Ajouter un plan de souscription mensuel",
    listTitle: "Liste de vos plans de souscription mensuel",
    provider: SubscriptionPlan.instance,
    dataPath: (meta: any) => {
      const { user } = meta.model;
      const accountId = user?.accountId || 0;
      return `/subscription/marchand/${accountId}/all`;
    },
    dataPathCallBack: (res: any, props: any) => {
      const data = Array.isArray(res.data) ? res.data : [];
      return data.filter((d: any) => d.quota?.quotaId === 3);
    },
  },
  {
    mainPath: `${supermarchandPrefix}/reapprovisionnements`,
    pageTitle: "Reapprovisionnements",
    addBtnTitle: "Faire un reapprovisionnement",
    listTitle: "Liste de vos reapprovisionnements",
    provider: SubscriptionPlanPeriod.instance,
    dataPath: (meta: any) => {
      const { user } = meta.model;
      const accountId = user?.accountId || 0;
      return `/subscription/provision/marchand/${accountId}/all`;
    },
  },
  {
    mainPath: `${supermarchandPrefix}/reapprovisionnements/actif`,
    pageTitle: "Reapprovisionnements",
    addBtnTitle: "Faire un reapprovisionnement",
    listTitle: "Liste de vos reapprovisionnements actifs",
    provider: SubscriptionPlanPeriod.instance,
    dataPath: (meta: any) => {
      const { user } = meta.model;
      const accountId = user?.accountId || 0;
      return `/subscription/provision/marchand/${accountId}/all`;
    },
    dataPathCallBack: (res: any, props: any) => {
      const data = Array.isArray(res.data) ? res.data : [];
      return data.filter((d: any) => d.state);
    },
  },
  {
    mainPath: `${supermarchandPrefix}/reapprovisionnements/inactif`,
    pageTitle: "Reapprovisionnements",
    addBtnTitle: "Faire un reapprovisionnement",
    listTitle: "Liste de vos reapprovisionnements inactifs",
    provider: SubscriptionPlanPeriod.instance,
    dataPath: (meta: any) => {
      const { user } = meta.model;
      const accountId = user?.accountId || 0;
      return `/subscription/provision/marchand/${accountId}/all`;
    },
    dataPathCallBack: (res: any, props: any) => {
      const data = Array.isArray(res.data) ? res.data : [];
      return data.filter((d: any) => !d.state);
    },
  },
  {
    mainPath: `${supermarchandPrefix}/subscriptions`,
    pageTitle: "Abonnements",
    listTitle: "Liste des abonnements sur vos produits",
    provider: Subscription.instance,
    dataPath: (meta: any) => {
      const { user } = meta.model;
      const accountId = user?.accountId || 0;
      return `/subscription/subscribe/marchand/${accountId}/all`;
    },
  },
  {
    mainPath: `${supermarchandPrefix}/subscriptions/journalier`,
    pageTitle: "Abonnements",
    listTitle: "Liste des abonnements journalier sur vos produits",
    provider: Subscription.instance,
    dataPath: (meta: any) => {
      const { user } = meta.model;
      const accountId = user?.accountId || 0;
      return `/subscription/subscribe/marchand/${accountId}/all`;
    },
    dataPathCallBack: (res: any, props: any) => {
      const data = Array.isArray(res.data) ? res.data : [];
      return data.filter((d: any) => d.subscriptionPlan.quota?.quotaId === 1);
    },
  },
  {
    mainPath: `${supermarchandPrefix}/subscriptions/hebdomadaire`,
    pageTitle: "Abonnements",
    listTitle: "Liste des abonnements hebdomadaire sur vos produits",
    provider: Subscription.instance,
    dataPath: (meta: any) => {
      const { user } = meta.model;
      const accountId = user?.accountId || 0;
      return `/subscription/subscribe/marchand/${accountId}/all`;
    },
    dataPathCallBack: (res: any, props: any) => {
      const data = Array.isArray(res.data) ? res.data : [];
      return data.filter((d: any) => d.subscriptionPlan.quota?.quotaId === 2);
    },
  },
  {
    mainPath: `${supermarchandPrefix}/subscriptions/mensuel`,
    pageTitle: "Abonnements",
    listTitle: "Liste des abonnements mensuel sur vos produits",
    provider: Subscription.instance,
    dataPath: (meta: any) => {
      const { user } = meta.model;
      const accountId = user?.accountId || 0;
      return `/subscription/subscribe/marchand/${accountId}/all`;
    },
    dataPathCallBack: (res: any, props: any) => {
      const data = Array.isArray(res.data) ? res.data : [];
      return data.filter((d: any) => d.subscriptionPlan.quota?.quotaId === 3);
    },
  },
  {
    mainPath: `${supermarchandPrefix}/files`,
    pageTitle: "Fichiers et documents",
    listTitle: "Fichiers et documents",
    provider: SubscriptionPlanPeriodFile.instance,
    dataPath: (meta: any) => {
      const { user } = meta.model;
      const accountId = user?.accountId || 0;
      return `/subscription/provision/marchand/${accountId}/all`;
    },
  },
  {
    mainPath: `${supermarchandPrefix}/profile`,
    pageTitle: "Mon profile",
    provider: null,
    pageType: "accountProfil",
  },
];

import React from "react";
import { Input } from "./Input";

export function InputFile(props: any) {
  const { setValue, field, customData } = props;
  const onChange = (e: any) => {
    const file = e.target.files[0];
    if (customData.isImg64) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        if (reader.result && typeof reader.result === "string") {
          const res = reader.result.split(",")[1];
          console.log(res);
          setValue(field.updateValue(res));
        }
      };
      reader.onerror = function () {
        console.log("there are some problems");
      };
    } else {
      setValue(field.updateValue(file));
    }
  };
  return (
    <Input {...props} customOnChange={onChange} customValue={""} value={""} />
  );
}

import React from "react";
import { utils } from "../../../_helpers";
import { Input } from "./Input";
import { InputUniteFrais } from "./InputUniteFrais";
import { Label } from "./Label";

export function CheckBox(props: any) {
  const checkBoxData = props.customData?.checkBoxData || {};

  const onChange = (e: any) => {
    utils().emitter.emit(checkBoxData.eventName, {
      value: e.target.checked,
    });
  };

  const customInlineStyle = checkBoxData.customInlineStyle || {
    marginTop: "-2px",
  };

  const customLayoutInlineStyle = checkBoxData?.customLayoutInlineStyle || {
    marginTop: "6px",
  };
  let customAttr: any = {};
  if (checkBoxData.checked) {
    customAttr = {checked: true}
  }
  return (
    <div className="form-check" style={customLayoutInlineStyle}>
      <Input
        {...props}
        customType={"checkbox"}
        customName={checkBoxData.id}
        customCls={"form-check-input"}
        customOnChange={onChange}
        customValue={checkBoxData.checked}
        customInlineStyle={customInlineStyle}
        customAttr={customAttr}
      />
      <Label {...props} {...checkBoxData} />

      {props?.customData?.inputUniteData && <InputUniteFrais {...props} />}
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { appConstants } from "../../../_constants";
import { restClient, utils } from "../../../_helpers";

const { SUBMIT_EVENT, HIDE_FOOTER_BTNS, FULL_LOADING_ACTION } = appConstants.keys.eventKeys;

export function ProductDetails(props: any) {
  console.log(" ProductDetails ", props);
  const p = props?.data?.data?.produit || {};
  const periods = props?.settings?.periodicities || [];
  const [paying, setPaying] = useState(false);
  const [plan, setPlan] = useState<any>(0);
  const [pin, setPin] = useState<any>("");
  const [mode, setMode] = useState<any>("3");
  const [qte, setQte] = useState<any>("1");
  const onSubmit = () => {
    utils().emitter.emit(FULL_LOADING_ACTION, true);
    if (p.hasAbonnement) {
      restClient.post(`/subscription/subscribe`, {
        "accountId": props.model?.user?.accountId,
        "subscriptionPlanId": plan
      }).then((res) => {
        console.log('=====res');
        console.log(res);
        if (res?.data?.item?.id) {
          window.alert("Souscription réussie !")
          window.location.reload();
          return
        } else {
          utils().emitter.emit(FULL_LOADING_ACTION, false);
          if (res?.data?.errors?.length) {
            window.alert(res?.data?.errors[0])
          } else {
            window.alert("Erreur lors de la souscription !")
          }
        }
      });
    } else {
      restClient.post(`/order/add`, {
        "accountId": props.model?.user?.accountId,
        "mode": mode,
        "orderDate": new Date().toJSON(),
        "productId": p?.productId,
        "qte": qte
      }).then((res) => {
        console.log('=====res /////');
        console.log(res);
        if (res?.data?.orderId) {
          window.alert("Paiement réussie !")
          window.location.reload();
          return
        } else {
          utils().emitter.emit(FULL_LOADING_ACTION, false);
          if (res?.data?.errors?.length) {
            window.alert(res?.data?.errors[0])
          } else {
            window.alert("Erreur lors de l'achat !")
          }
        }
      });
    }
    
  }
  useEffect(() => {
    const handleSubmitEvent = (e: any) => {
      setPaying(true);
      utils().emitter.emit(HIDE_FOOTER_BTNS, true);
    };
    utils().emitter.on(SUBMIT_EVENT, handleSubmitEvent);

    return () => {
      utils().emitter.off(SUBMIT_EVENT, handleSubmitEvent);
    };
  }, []);

  const getPrix = () => {
    if (p.hasAbonnement) {
      return 'Pas fixe';
    } 
    return utils().formatAmount(p?.devise?.code ? p?.devise?.code : "CDF", p.amount);
  }
  
  return (
    <div>
      <div
        className=""
        id="recapitulatif-group-product"
        style={{ display: "none" }}
        data-shown="0"
      ></div>
      <div
        className=""
        id="error-group"
        style={{ display: "none" }}
        data-shown="0"
      ></div>
      <div className="" id="product-details" data-id="0" data-abonnement="0">
        <div className="d-flex justify-content-between flex-row w-100">
          <div className="prodtuct-left d-flex flex-row w-100">
            <div className="prodtuct-img" style={{ minWidth: "110px" }}>
              <img
                id="product-img"
                style={{ maxWidth: "100px" }}
                src={`data:image/png;base64,${p.image}`}
                alt="IMG"
              />
            </div>
            <div className="product-text flex-column w-100">
              <h5 style={{ margin: 0 }}>
                <span className="depot_text" id="product-title">
                  {p.libelle} : {p.caracteristic} - Prix : {getPrix()}
                </span>
              </h5>
              <p
                style={{ fontSize: "80%" }}
                className="mt-2"
                id="product-description"
              >
                {p.description}
              </p>
              {paying && (
                <>
                  <div id="quantity-group" style={{ display: "" }}>
                    <div className="row d-flex flex-row justify-content-between">
                      <div className="col-md-6 mb-2">
                        <label
                          htmlFor="colFormLabelSm"
                          className=" col-form-label col-form-label-sm"
                        >
                          Mode de paiement
                        </label>
                        <select
                          id="operation_type"
                          className="form-control form-control-sm w-100"
                          name="operationType"
                          style={{ width: "auto" }}
                          value={mode}
                          onChange={(e) => setMode(e.target.value)}
                          data-style="form-control form-control-sm"
                        >
                          <option value="3" selected>
                            Utiliser mon solde{" "}
                          </option>
                          <option value="4">Paiement mobile </option>
                          <option value="5">Paiement CB </option>
                        </select>
                      </div>

                      <div className="col-md-6 d-flex flex-column">
                        <div
                          className="d-flex flex-column align-items-end"
                          style={{ float: "right" }}
                        >
                          {!p.hasAbonnement && (
                            <div id="qte-group" style={{ display: "" }}>
                              <label
                                htmlFor="colFormLabelSm"
                                className=" col-form-label col-form-label-sm mr-1"
                                style={{
                                  textAlign: "left",
                                  width: "100px",
                                  paddingLeft: "0.3rem",
                                }}
                              >
                                Quantité
                              </label>
                              <input
                                className="form-control form-control-sm"
                                name="qte"
                                id="qte"
                                type="number"
                                value={qte}
                                onChange={(e) => setQte(e.target.value)}
                                style={{ width: "100px" }}
                              />
                            </div>
                          )}

                          {p.hasAbonnement && (
                            <div id="period-group">
                              <label
                                htmlFor="colFormLabelSm"
                                className=" col-form-label col-form-label-sm"
                                style={{
                                  textAlign: "left",
                                  width: "133px",
                                  paddingLeft: "0.3rem",
                                }}
                              >
                                Périodicité
                              </label>
                              <select
                                id="period"
                                className="form-control form-control-sm"
                                name="period"
                                style={{ width: "auto" }}
                                onChange={(e) => setPlan(e.target.value)}
                                data-style="form-control form-control-sm"
                              >
                                <option value="0" selected>
                                  Selectionner ...{" "}
                                </option>
                                {periods.length > 0 &&
                                  periods.map((p: any, i: any) => {
                                    return (
                                      <option key={i} value={p.id}>
                                        {p.quota.libelle}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>
                          )}
                          <div
                            className="invalid-feedback mt-2"
                            style={{ textAlign: "right" }}
                          >
                            ce champ est requis.
                          </div>

                          <div
                            id="gopay"
                            style={{
                              position: "relative",
                              left: "-50%",
                              marginTop: "-125px",
                            }}
                          ></div>
                        </div>
                        <div className="d-flex flex-column">
                          <p
                            style={{ margin: 0, fontSize: "0.8rem" }}
                            className="d-flex flex-row justify-content-end"
                          >
                            <span
                              className="mt-2 mb-1"
                              id="product-price"
                              data-price="0"
                            ></span>
                          </p>
                          <p
                            style={{
                              margin: 0,
                              fontSize: "0.8rem",
                              display: "",
                            }}
                            className=" flex-row justify-content-end"
                          >
                            <span
                              className="mt-1 mb-1"
                              id="product-price-total"
                              data-price="0"
                            ></span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="validate-pin-group" style={{ display: "" }}>
                    <div
                      className="form-group row"
                      style={{ margin: "0 0 1rem 0", width: "100%" }}
                    >
                      <div className="col-md-3" style={{ padding: 0 }}>
                        <input
                          className="form-control form-control-sm"
                          name="pin"
                          id="pin"
                          type="number"
                          value={pin}
                          onChange={(e) => setPin(e.target.value)}
                          placeholder="Code Pin"
                          style={{ width: "100%" }}
                        />
                      </div>
                      <div
                        className="d-flex flex-row col-md-4"
                        style={{ padding: 0 }}
                      >
                        <button
                          id="validate-pin-btn"
                          type="button"
                          className="btn btn-success box-shadow mr-1"
                          data-id=""
                          style={{ fontSize: "80%", marginLeft: "5px" }}
                          onClick={() => onSubmit()}
                        >
                          Valider
                        </button>
                        <button
                          id="cancel-validate-pin-product-btn"
                          type="button"
                          className="btn box-shadow btn-danger"
                          style={{ fontSize: "80%" }}
                          onClick={() => {
                            setPaying(false);
                            utils().emitter.emit(HIDE_FOOTER_BTNS, false);
                          }}
                        >
                          Annuler
                        </button>
                      </div>
                      <div
                        className="invalid-feedback"
                        style={{ fontSize: "70%" }}
                      >
                        Veuillez entrer votre code PIN pour valider l'achat.
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";

export function SaveBtn(props: any) {
  const { handleSubmit, loading, data, settings } = props;
  return (
    <button
      type="button"
      onClick={handleSubmit}
      disabled={loading}
      className="btn btn-primary btn-sm box-shadow"
    >
      <i className="fa fa-save mr-1"></i>
      {settings?.btnTitle || data?.provider?.saveBtnTitle || data?.model?.provider?.saveBtnTitle || "Enregistrer"}
    </button>
  );
}

import React, { Component } from "react";
import { model } from "../../../_helpers";

class CTAs extends Component {
  render() {
    return (
      <React.Fragment>
        <div
          className="bg-light"
          style={{
            margin: 0,
            borderBottom: "1px solid #ddd",
            padding: "10px 0px",
          }}
        >
          <div className="container pt-8 pb-8">
            <div className="row align-items-center">
              <div className="col-12 col-md text-center text-lg-left mt-2">
                <h3 className="font-weight-bold mb-1 download_text">
                  Téléchargez {model().appTitle || "e-Mpata"}!
                </h3>
                <p className="text-muted mb-6 mb-md-0 no-whitespace download_details_text">
                  Bon électronique rapide et sécurisé.
                </p>
              </div>
              <div className="col-12 col-lg-7">
                <div className="container download-button">
                  <div className="row">
                    <div className="col-lg-2 col-sm-12"></div>
                    <div className="col-lg-2 col-sm-12"></div>
                    <div className="col-lg-8 col-sm-12 d-flex justify-content-center">
                      <a href="/#" target="_blank" className="applestore ">
                        {" "}
                      </a>
                      <a
                        href="https://play.google.com/store/apps/details?id=com.snedac.empata"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="googleplay"
                      >
                        {" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export { CTAs };

import { appConstants } from "../_constants";
import { BaseEntity } from "./BaseEntity";
import { FieldType, InputType } from "./enumerations";
import { Field } from "./Field";
import { BaseModel } from "../_models";

const { RENDER_ACTIONS, RENDER_ID } = appConstants.keys.renderKeys;

class PriceIntervalle extends BaseEntity {
  public static readonly addTitle = "Ajouter un intervale de prix";
  public static readonly showTitle = "Détails d'un intervale de prix";
  public static readonly editTitle = "Modifier un intervale de prix";
  public static readonly saveBtnTitle = "Enregistrer";
  public static readonly idField = "priceId";
  public static readonly processNewSuccessMsg =
    "L'intervale de prix a été enregistré avec succès !";
  public static readonly processEditSuccessMsg =
    "L'intervale de prix' a été enregistré avec succès !";
  public static readonly processNewPath = "price/add";
  public static readonly processEditPath = "price/update/_ID_";
  public static instance: PriceIntervalle = new PriceIntervalle();
  public static instances = [];
  public static relations = [
    {
      dataPath: "currency/all",
      key: "currencies",
      resolve: (res: any) => {
        if (Array.isArray(res?.data)) {
          return res.data.map((v: any) => PriceIntervalle.getSelectCurrency(v));
        }
        return [];
      },
    },
  ];

  public static get definitions(): Field[] {
    const model: any = BaseModel.getInstance();
    const aChildAll = [
      {
        label: "Modifier",
        title: "Modifer cet intervale de prix",
        action: "editActions",
        color: "text-success",
        icon: "fa fa-edit",
      },
      {
        label: "Supprimer",
        title: "Supprimmer cet intervale de prix",
        action: "deleteActions",
        color: "text-danger",
        icon: "fa fa-trash",
      },
    ];
    const aChildMod = [
      {
        label: "Modifier",
        title: "Modifer cet intervale de prix",
        action: "editActions",
        color: "text-success",
        icon: "fa fa-edit",
      },
    ];
    const aChildDel = [
      {
        label: "Supprimer",
        title: "Supprimmer cet intervale de prix",
        action: "deleteActions",
        color: "text-danger",
        icon: "fa fa-trash",
      },
    ];
    const definitions = [
      new Field({
        name: "priceId",
        type: FieldType.NUMBER,
        primary: true,
        creatable: false,
        editable: false,
        serializeNew: false,
        serializeEdit: false,
        label: "#",
        renderKey: RENDER_ID,
        provider: PriceIntervalle.instance,
      }),
      new Field({
        name: "libelle",
        label: "Libellé",
        provider: PriceIntervalle.instance,
      }),
      new Field({
        name: "valMin",
        label: "Valeur minimale",
        inputType: InputType.INPUT_NUMBER,
        provider: PriceIntervalle.instance,
      }),
      new Field({
        name: "valMax",
        label: "Valeur maximale",
        inputType: InputType.INPUT_NUMBER,
        provider: PriceIntervalle.instance,
      }),
      new Field({
        name: "devise",
        label: "Devise",
        dataPath: "devise.code",
        inputType: InputType.INPUT_SELECT,
        relationKey: "currencies",
        formRowIndex: 0,
        provider: PriceIntervalle.instance,
      }),
      new Field({
        name: "actions",
        label: "Actions",
        dataPath: "priceId",
        creatable: false,
        editable: false,
        serializeNew: false,
        serializeEdit: false,
        serializeView: false,
        renderKey: RENDER_ACTIONS,
        provider: PriceIntervalle.instance,
        customData: {
          renderOptions: {
            clsActions: "justify-content-center",
          },
          actions: [
            {
              label: "Détails",
              title: "Afficher les détails",
              action: "detailsActions",
              color: "btn-info",
              icon: "fa fa-eye",
            },
            {
              type: "btnGroup",
              color: "btn-primary",
              children:
                model.user.user?.profile === null
                  ? aChildAll
                  : model.confCrud.modification && model.confCrud.suppression
                  ? aChildAll
                  : model.confCrud.modification
                  ? aChildMod
                  : model.confCrud.suppression
                  ? aChildDel
                  : [],
            },
          ],
        },
      }),
    ];
    return definitions;
  }

  /**
   * getSelectCurrency
   */
  public static getSelectCurrency(v: any) {
    return {
      label: v.nom,
      value: v.code,
      taux: v.taux,
    };
  }

  toView() {
    return [
      {
        title: false,
        rows: [
          { label: "Price ID", value: this.priceId },
          { label: "Libellé", value: this.libelle },
          { label: "Valeur minimale", value: this.valMin },
          { label: "Valeur maximale", value: this.valMax },
          { label: "Devise", value: this.devise?this.devise?.code:"CDF" },
        ],
      },
    ];
  }

  initEditCallBack(props: any) {
    this.updateValue("devise", this.devise?this.devise.code:"CDF");
  }

  getDeleteActionConfig() {
    return {
      path: `/price/delete/${this.priceId}`,
      resolve: (res: any) => res?.success,
    };
  }
}

export { PriceIntervalle };

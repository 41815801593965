import React from "react";

export function SwitchBtn(props: any) {
  const { onClick, account } = props;
  const { active } = account;
  return (
      (!props.model.confCrud?.modification && props.model.user.user.profil!=="Admin")?<div></div>: <small>
      <a
        href=""
        onClick={onClick}
        className={`btn ${
          active ? "btn-danger" : "btn-success"
        } box-shadow btn-sm`}
      >
        <i
          className={`fa ${active ? "fa-times" : "fa-check"} mr-1`}
          aria-hidden="true"
        ></i>
        {!active && "Activer ce compte"}
        {active && "Désactiver ce compte"}
      </a>
    </small>
  );
}

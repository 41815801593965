import React, { useEffect } from "react";
import ReactDOM, { render } from "react-dom";
import { tableRenderer } from "../../../../_helpers";
import { TableLoader } from "./TableLoader";

export function TableContent(props: any) {
  const { tabRef, searchRef, retrieveTable, cls } = props;
  const id = props?.id || "admin_table";
  const styles = { id, className: cls || "" };

  useEffect(() => {
    const datatable = createDataTable(props, tabRef);
    $(searchRef.current).on("keyup", (e) => {
      datatable.search(e.target.value).draw();
    });

    return () => {
      if (!retrieveTable) {
        destroyDataTable(datatable, tabRef);
      }
    };
  });
  return (
    <table
      {...styles}
      ref={tabRef}
      className="table table-hover text-center datatable"
    />
  );
}

const createDataTable = (props: any, ref: any) => {
  const { columns } = props;
  let retrieveTable = false;
  if (!($ as any).fn.DataTable.isDataTable($(ref.current) as any)) {
    retrieveTable = true;
  }
  let tab = ($(ref.current) as any).DataTable({
    columns,
    ...tableRenderer.getOptions(props),
  });
  refreshDataTableData(tab, props);
  if (retrieveTable) {
    appendLoader(props);
  }
  return tab;
};

const destroyDataTable = (datatable: any, ref: any) => {
  const lg = document.getElementById("loader-group");
  if (lg) {
    ReactDOM.unmountComponentAtNode(lg);
  }
  datatable.clear();
  datatable.destroy();
  $(ref.current).empty();
};

const refreshDataTableData = (datatable: any, props: any) => {
  const { data } = props;
  if (!datatable || !data) {
    return;
  }
  datatable.clear();
  datatable.rows.add(data);
  datatable.draw();
};

const appendLoader = (props: any) => {
  const id = props?.id || "admin_table";
  const filter = $(`#${id}_filter`);
  if (filter) {
    filter.parent().prepend($("<div id='loader-group'></div>"));
    render(<TableLoader {...props} />, document.getElementById("loader-group"));
  }
};

import React, { useState } from "react";
import { appConstants } from "../../../../../_constants";
import { utils } from "../../../../../_helpers";
import { PropsFromRedux } from "../../../../../_reducers";

const { TABLE_DATA_FILTER } = appConstants.keys.eventKeys;

export function SelectFilter(props: PropsFromRedux & any) {
  const { model, periodValue } = props;
  const [config, setConfig] = useState({
    period: typeof periodValue !== "undefined" ? periodValue : "today",
  });

  const onChange = (e: any) => {
    const { value } = e.target;
    const { period } = config;
    let cfg = { period };
    cfg.period = value;
    setConfig(cfg);
    utils().emitter.emit(TABLE_DATA_FILTER, cfg);
    model.refreshDataTableData(props, null, cfg);
  };
  const { period } = config;
  return (
    <div className="trier-block w-100 d-flex justify-content-end">
      <small style={{ marginLeft: "10px", marginRight: "10px" }}>Trier </small>
      <select
        onChange={onChange}
        className="form-control2"
        value={period}
        style={{ maxWidth: "150px" }}
      >
        <option value="today">Aujourd'hui</option>
        <option value="yesterday">Hier</option>
        <option value="week">Il y a 7 jours</option>
        <option value="month">Il y a 30 jours</option>
        <option value="deposit">Dépôts</option>
        <option value="withdraw">Retraits</option>
        <option value="transfer">Transferts</option>
        <option value="fees">Frais</option>
        <option value="">Toutes</option>
      </select>
    </div>
  );
}

import React, { Component } from "react";
import { Link } from "react-router-dom";
import Headroom from "headroom.js";
import { connector, PropsFromRedux } from "../../_reducers";

import mpataLogo from "../../_assets/images/mpata.png";

class HomeNavbar extends Component<PropsFromRedux> {
  componentDidMount() {
    const node = document.getElementById("navbar-main");
    if (node instanceof HTMLElement) {
      let headroom = new Headroom(node);
      headroom.init();
    }
  }

  render() {
    const { model, logout } = this.props;

    return (
      <React.Fragment>
        <header className="header-global">
          <nav
            id="navbar-main"
            className="navbar-main navbar-transparent navbar-light headroom navbar navbar-expand-lg headroom--not-bottom headroom--pinned headroom--top"
          >
            <div className="container p-0" style={{ maxWidth: "1200px" }}>
              <Link
                className="mr-lg-5 navbar-brand d-flex flex-row align-items-center justify-content-center"
                to="/"
              >
                <img
                  src={model.logo || mpataLogo}
                  alt=""
                  style={{ maxHeight: "50px", marginRight: "3px" }}
                />
                <h5 style={{ margin: 0 }}>{model.appTitle || "e-Mpata"}</h5>
              </Link>
              <button className="navbar-toggler d-none" id="navbar_global">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse">
                <div className="navbar-collapse-header">
                  <div className="row">
                    <div className="collapse-brand col-6"></div>
                    <div className="collapse-close col-6">
                      <button className="navbar-toggler" id="navbar_global">
                        <span></span>
                        <span></span>
                      </button>
                    </div>
                  </div>
                </div>
                <ul className="align-items-lg-center ml-lg-auto navbar-nav">
                  {!model.isLogin ? (
                    <li className="d-lg-block ml-lg-4 nav-item">
                      <Link
                        id="login-btn"
                        to="/login"
                        className="btnn login_btn modal-btn"
                        style={{ textDecoration: "none" }}
                      >
                        <span className="nav-link-inner--text ml-1">
                          Connexion
                        </span>
                      </Link>
                      <Link
                        id="login-btn"
                        to="/signin"
                        onClick={(e) => (window.location.href = "/signin")}
                        className="btnn sigin_btn modal-btn"
                        style={{ textDecoration: "none" }}
                      >
                        <span className="nav-link-inner--text ml-1">
                          Inscription
                        </span>
                      </Link>
                    </li>
                  ) : (
                    <React.Fragment>
                      <li className="nav-item">
                        <Link
                          to={model.userPath ? model.userPath : "/"}
                          className="nav-link"
                        >
                          <i className="fa fa-dashboard"></i>
                          <span
                            className="nav-link-inner--text"
                            style={{ marginLeft: "0.2rem" }}
                          >
                            Tableau de bord
                          </span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/logout"
                          className="nav-link"
                          onClick={(e) => {
                            e.preventDefault();
                            logout();
                          }}
                        >
                          <i className="fa fa-sign-out"></i>
                          <span
                            className="nav-link-inner--text"
                            style={{ marginLeft: "0.2rem" }}
                          >
                            Déconnexion
                          </span>
                        </Link>
                      </li>
                    </React.Fragment>
                  )}
                </ul>
              </div>
            </div>
          </nav>
        </header>
      </React.Fragment>
    );
  }
}

const connectedHomeNavbar = connector(HomeNavbar);
export { connectedHomeNavbar as HomeNavbar };

import React, { Component } from "react";
import { model, restClient, utils } from "../../../_helpers";
import { BaseModel } from "../../../_models";

const iconClasses = {
  1: "btn-success",
  2: "btn-danger",
  3: "btn-info",
  4: "btn-danger",
  5: "btn-secondary",
  6: "btn-secondary",
  7: "btn-secondary",
};

const libelles = {
  1: "Dépôt de fonds",
  2: "Retrait d'espèces",
  3: "Transfert e-Mpata vers e-Mpata",
  4: "Transfert e-Mpata vers autres",
  5: "Achat de produits et services",
  6: "Souscription à un abonnement",
  7: "Achat de credit",
  8: "Paiement de taxes et impôts",
};

const subtitles = {
  1: "Pour le compte de :",
  2: "Retrait :",
  3: "Pour le compte de :",
};

const colors = {
  1: "#1e7e34",
  2: "#dc3545",
  3: "#17a2b8",
};

const colorStatus = {
  SUCCESS: "text-success",
  FAIL: "text-danger",
};

class Transaction extends Component<any, any> {
  getBen() {
    if (this.props.user?.msisdn) {
      return this.props.user?.msisdn;
    }
    if (this.props.model?.user?.msisdn) {
      return this.props.model?.user?.msisdn;
    }
    let msisdn = $("#title").data("msisdn");
    if (msisdn) {
      return msisdn;
    }
    return false;
  }

  render() {
    const { transaction } = this.props;
    const currentUser = BaseModel.getInstance()?.user?.user;
    // console.log("USER ACTUELS",currentUser);
    // console.log("USER TX ACTUELS",transaction.account?.user);
    const currentUserTx = transaction.account?.user;
    return (
      <React.Fragment>
        <div
          className="transaction-left d-flex flex-row justify-content-between actionBtn"
          style={{ cursor: "pointer" }}
          data-action={"detailsActions"}
        >
          <div className="d-flex">
            <span
              className={
                "transaction-icon btn " +
                this.getIconClass(transaction.operationType)
              }
              style={{ height: "40px", width: "40px", borderRadius: "50%" }}
            >
              <i className="fa fa-arrow-left" aria-hidden="true"></i>
            </span>
            <div className="transaction-text flex-column ml-3">
              <h6 style={{ margin: 0 }} className="text-left">
                <span>{this.getLibelle(transaction.operationType)}</span>
              </h6>
              <small style={{ color: "#888", display: "flex" }}>
                <span>
                  {this.formatdate(transaction.operationDate)} -{" "}
                  {this.getSubtitle(transaction)}
                </span>
              </small>
              {transaction.operationType && (
                <div className="description-group text-left">
                  <small className="mb-0">
                    {typeof transaction !== "undefined" && (
                      <div className="name-group">
                        <small className="text-secondary">
                          {transaction.amount > 0 &&
                          currentUser?.userId !== currentUserTx?.userId
                            ? transaction.account?.user?.firstName
                            : transaction.other?.user?.firstName}{" "}
                          {transaction.amount > 0 &&
                          currentUser?.userId !== currentUserTx?.userId
                            ? transaction.account?.user?.lastName
                            : transaction.other?.user?.lastName}{" "}
                        </small>
                      </div>
                    )}
                    {/*{typeof transaction !== "undefined" && (<div className="name-group">*/}
                    {/*  <small*/}
                    {/*      className="text-secondary">{receiv.firstName}{" "}{receiv.lastName}</small>*/}
                    {/*</div>)}*/}
                    {typeof transaction.description !== "undefined" &&
                      transaction.description !== "" &&
                      transaction.description !== null && (
                        <div>
                          <div className="ben-group d-flex">
                            <small className="text-secondary">
                              {transaction.description}
                            </small>
                          </div>
                        </div>
                      )}
                    {typeof transaction.description === "undefined" ||
                      transaction.description !== "" ||
                      (transaction.description !== null && (
                        <small className="text-secondary">
                          aucune description
                        </small>
                      ))}
                  </small>
                </div>
              )}
            </div>
          </div>
          <div className="transaction-right">
            <div
              className="transaction-text flex-column"
              style={{ textAlign: "right" }}
            >
              <small className="amount_text">Montant</small>
              <h6
                style={{
                  margin: 0,
                  color: this.getColor(transaction.operationType),
                }}
              >
                <span className="currency">
                  {this.getSign(transaction)}
                  {utils().formatAmount(
                    transaction.devise ? transaction.devise?.code : "CDF",
                    transaction.amount
                  )}
                </span>
              </h6>
              {transaction.operationType && (
                <div className="status-group">
                  <small className="mb-0">
                    <small> statut : </small>
                    <small
                      className={
                        (colorStatus as any)[transaction.status] ??
                        "text-secondary"
                      }
                    >
                      {transaction.status}
                    </small>
                  </small>
                </div>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  getIconClass(operationType: any) {
    if (!operationType) {
      return "btn-warning";
    }
    return (iconClasses as any)[operationType.operationTypeId] ?? "btn-danger";
  }

  getColor(operationType: any) {
    if (!operationType) {
      return "#ffc107";
    }
    return (colors as any)[operationType.operationTypeId] ?? "#dc3545";
  }

  getSign(transaction: any) {
    if (!transaction.operationType && transaction.amount > 0) {
      return "-";
    }
    if (transaction.amount > 0) {
      return "+";
    }
    return "";
  }

  getLibelle(operationType: any) {
    if (!operationType) {
      return "Frais de transaction";
    }
    return (
      (libelles as any)[operationType.operationTypeId]?.replaceAll("e-Mpata",(model().appTitle || "e-Mpata")) ?? operationType.libelle
    );
  }

  getSubtitle(transaction: any) {
    if (
      transaction.operationType.operationTypeId === 24 ||
      transaction.operationType.operationTypeId === 26
    ) {
      return `Du compte: ${transaction.receiver}`;
    }
    if (!transaction.operationType) {
      return `Reférence : ${transaction.reference}`;
    }
    if (transaction.operationType.operationTypeId === 25) {
      return `Du compte: ${transaction.account.msisdn}`;
    }
    if (
      transaction.operationType.operationTypeId === 3 ||
      transaction.operationType.operationTypeId === 25
    ) {
      if (transaction.amount <= 0) {
        return `Du compte: ${
          transaction.amount > 0
            ? transaction.account.msisdn
            : transaction.other?.msisdn
        }`;
      }
    }
    return (subtitles as any)[transaction.operationType.operationTypeId]
      ? `${(subtitles as any)[transaction.operationType.operationTypeId]} ${
          transaction.amount > 0
            ? transaction.account.msisdn
            : transaction.other?.msisdn
        }`
      : `Du compte: ${
          transaction.amount > 0
            ? transaction.account.msisdn
            : transaction.other?.msisdn
        }`;
  }

  formatdate(dt: any) {
    return (
      dt.substring(8, 10) +
      "/" +
      dt.substring(5, 7) +
      "/" +
      dt.substring(0, 4) +
      " " +
      dt.substring(11, 13) +
      ":" +
      dt.substring(14, 16)
    );
  }
}

export default Transaction;

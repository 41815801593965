import React, { useEffect, useRef } from "react";
import { tableRenderer } from "../../../../_helpers";
import { BaseModel } from "../../../../_models";
import { BaseForm } from "../BaseForm/BaseForm";
import { TabBtns } from "./TabBtns";

const opValues = [];
const accountTypeValues = [];

export function ClientForm(props: any) {
  const { provider } = props.model;
  const persoTabRef = useRef(null);
  const typeAccountTabRef = useRef(null);
  const typeOpTabRef = useRef(null);

  const onTabBtnClick = (e: any) => {
    e.preventDefault();
    ($(e.target) as any).tab("show");
  };

  useEffect(() => {
    let persoTab = ($((persoTabRef as any).current) as any).DataTable({
      columns: provider.persoColumns,
      ...tableRenderer.getOptions(props),
    });
    let typeAccountTab = (
      $((typeAccountTabRef as any).current) as any
    ).DataTable({
      columns: provider.typeAccountColumns,
      ...tableRenderer.getOptions(props),
    });
    let typeOpTab = ($((typeOpTabRef as any).current) as any).DataTable({
      columns: provider.typeOpColumns,
      ...tableRenderer.getOptions(props),
    });

    refreshDataTableData(persoTab, provider.getValue("uiitems"));
    refreshDataTableData(typeAccountTab, provider.getValue("accountTypes"));
    refreshDataTableData(typeOpTab, provider.getValue("operationTypes"));

    ($(document) as any).on("change", ".operationTypeId", function (e) {
      let id = $(e.currentTarget).data("id");
      let val = $(e.currentTarget).val();
      (BaseModel.getInstance().provider as any)?.setOpValue(id, val);
    });
    ($(document) as any).on("change", ".accountTypeId", function (e) {
      let id = $(e.currentTarget).data("id");
      let val = $(e.currentTarget).val();
      (BaseModel.getInstance().provider as any)?.setAccountValue(id, val);
    });
    ($(document) as any).on("change", ".uiId", function (e) {
      let id = $(e.currentTarget).data("id");
      let val = $(e.currentTarget).val();
      (BaseModel.getInstance().provider as any)?.setUiValue(id, val);
    });

    return () => {
      destroyDataTable(persoTab, persoTabRef);
      destroyDataTable(typeAccountTab, typeAccountTabRef);
      destroyDataTable(typeOpTab, typeOpTabRef);
    };
  }, []);

  const destroyDataTable = (datatable: any, ref: any) => {
    datatable.clear();
    datatable.destroy();
    $(ref.current).empty();
  };

  const refreshDataTableData = (datatable: any, data: any) => {
    if (!datatable || !data) {
      return;
    }
    datatable.clear();
    datatable.rows.add(data);
    datatable.draw();
  };

  return (
    <div className="row">
      <div className="col-3">
        <TabBtns onClick={onTabBtnClick} />
      </div>
      <div className="col-9">
        <div className="tab-content" id="v-pills-tabContent">
          <div
            className="tab-pane fade active show"
            id="v-pills-home"
            role="tabpanel"
            aria-labelledby="v-pills-home-tab"
          >
            <BaseForm
              {...props}
              customRows={provider.getRows("tabOne") || []}
              customLayoutInput={"col-md-12"}
              customLayoutLabel={"none"}
            />
          </div>
          <div
            className="tab-pane fade"
            id="v-pills-profile"
            role="tabpanel"
            aria-labelledby="v-pills-profile-tab"
          >
            <table
              id="perso_table"
              className="table w-100 table-hover text-center datatable"
              ref={persoTabRef}
            ></table>
          </div>
          <div
            className="tab-pane fade"
            id="v-pills-messages"
            role="tabpanel"
            aria-labelledby="v-pills-messages-tab"
          >
            <table
              id="type_account_table"
              className="table w-100 table-hover text-center datatable"
              ref={typeAccountTabRef}
            ></table>
          </div>
          <div
            className="tab-pane fade"
            id="v-pills-settings"
            role="tabpanel"
            aria-labelledby="v-pills-settings-tab"
          >
            <table
              id="type_op_table"
              className="table w-100 table-hover text-center datatable"
              ref={typeOpTabRef}
            ></table>
          </div>
        </div>
      </div>
    </div>
  );
}

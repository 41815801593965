import { appConstants } from "../_constants";
import { BaseEntity } from "./BaseEntity";
import { FieldType } from "./enumerations";
import { Field } from "./Field";
import {BaseModel} from "../_models";
import {restClient, utils} from "../_helpers";

const {
  RENDER_ID,
  RENDER_ACTIONS,
  RENDER_STATUS_OPERATION,
} = appConstants.keys.renderKeys;

class Validation extends BaseEntity {
  public static readonly label: any;
  public static readonly className: any;
  public static readonly art: any;
  // public static readonly editTitle = "Modifier un Workflow";
  public static readonly showTitle = "Détails d'une validation";
  public static instance: Validation = new Validation();
  public static instances = [];
  public static relations = [];

  public static get definitions(): Field[] {
    const model: any = BaseModel.getInstance();

    const aChildDel = [
      {
        label: "Supprimer",
        title: "Supprimmer ce Workflow",
        action: "deleteActions",
        color: "text-danger",
        icon: "fa fa-trash",
      }
    ];

    const definitions = [
      new Field({
        name: "workflowId",
        type: FieldType.NUMBER,
        primary: true,
        creatable: false,
        editable: false,
        serializeNew: false,
        serializeEdit: false,
        label: "#",
        renderKey: RENDER_ID,
        provider: Validation.instance,
      }),
      new Field({
        name: "label",
        label: "Label",
        provider: Validation.instance,
        customData: {
          renderOptions: {
            className: "min-w-150",
          },
        },
      }),
      new Field({
        name: "currentStep",
        label: "Etape actuelle",
        type: FieldType.NUMBER,
        provider: Validation.instance,
      }),
      new Field({
        name: "nbrSteps",
        label: "Nombre d'étapes",
        type: FieldType.NUMBER,
        provider: Validation.instance,
      }),
      new Field({
        name: "status",
        label: "Statut",
        creatable: false,
        renderKey: RENDER_STATUS_OPERATION,
        provider: Validation.instance,
      }),
      new Field({
        name: "entityKey",
        label: "Objet concerné",
        type: FieldType.NUMBER,
        provider: Validation.instance,
      }),
      new Field({
        name: "declinedRaison",
        label: "Motif de rejet",
        type: FieldType.NUMBER,
        creatable: false,
        editable: false,
        serializeNew: false,
        onTable:false,
        serializeEdit: false,
        serializeView: false,
        provider: Validation.instance,
      }),
      new Field({
        name: "actions",
        label: "Actions",
        dataPath: "workflowId",
        creatable: false,
        editable: false,
        serializeNew: false,
        serializeEdit: false,
        serializeView: false,
        renderKey: RENDER_ACTIONS,
        provider: Validation.instance,
        customData: {
          renderOptions: {
            clsActions: "justify-content-center",
          },
          actions: [
            {
              label: "Détails",
              title: "Afficher les détails",
              action: "detailsActions",
              color: "btn-info",
              icon: "fa fa-eye",
            },
            {
              type: "btnGroup",
              color: "btn-primary",
              children:model.user.user?.profile === null
                  ? aChildDel
                  : model.wflCrud.suppression
                          ? aChildDel
                          : [],
            },
          ],
        },
      }),
    ];
    return definitions;
  }

  toView() {
    console.log("THIS STEP OHHHH", this.steps);
    let valRow:any = [];
    this.steps.map((step, index)=>{
      let val = {
        label:"Etape "+step.stepLevel+" "+"["+step.status+" "+utils().formatDate("DD/MM/YYYY [at] HH:mm:ss",step.validationDate)+"]",
        value:step.validator.firstName+" "+step.validator.lastName,
        stepLevel:step.stepLevel
      };
      valRow.push(val);
      valRow.sort((one, two) => (one.stepLevel < two.stepLevel ? -1 : 1));
    });
    return [
      {
        title: "Information générales",
        rows: [
          { label: "Validation ID", value: this.workflowId },
          { label: "Label", value: this.label },
          { label: "Nombre d'étapes", value: this.nbrSteps},
          { label: "Statut", value: this.status},
          { label: "Motif", value: this.declinedRaison},
        ],
      },
      {
        title: "Objet concerné",
        rows: [
          { label: "Label", value: this.entityKey.split("_")[0] },
          { label: "Référence/Code", value: this.entityKey.split("_")[1] },
        ]
      },
      {
        title: "Validateurs",
        rows: valRow,
      }
    ];
  }

  getDeleteActionConfig() {
    return {
      path: `/workflows/delete/${this.workflowId}`,
      resolve: (res: any) => res?.success,
    };
  }

  // getCustomProcessEditPath(payload: any) {
  //   return encodeURI(
  //     `/country/update/${this.id}?nom=${payload.nomFr}_AMP_blacklist=${this.blacklist}_AMP_countrycode=${payload.code}_AMP_countrysymbole=${payload.symbole}`
  //   );
  // }

  // getSwitchActionConfig(status: boolean) {
  //   return {
  //     path: `/country/change/${this.countrycode || this.code}/${status}`,
  //     resolve: (res: any) => res?.data?.status === "OK",
  //   };
  // }

}

export { Validation };

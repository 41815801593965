import React from "react";
import { Link } from "react-router-dom";
import { utils } from "../../../_helpers";
import { NavbarSubItem } from "./NavbarSubItem";

export function NavbarItem(props: any) {
  const { navItem } = props;
  return (
    <li
      className={
        "nav-item " + (navItem.subItems?.length ? "dropdown megamenu " : "")
      }
      key={navItem.key}
    >
      {getLink(navItem, props)}
      {getSubItems(navItem, props)}
    </li>
  );
}

const getSubItems = (navItem: any, props: any) => {
  if (navItem.subItems?.length) {
    return <NavbarSubItem {...props} navItems={navItem.subItems} />;
  }
  return "";
};

const getLink = (navItem: any, props: any) => {
  if (navItem.subItems?.length) {
    return (
      <a
        href={navItem.path}
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        className={
          "nav-link-icon nav-link dropdown-toggle " +
          (utils().isActiveSidebarItem(navItem) ? "active" : "")
        }
        style={{
          color: utils().isActiveSidebarItem(navItem)
            ? "#00d0ba"
            : "rgba(255, 255, 255, 0.95)",
          padding: "1rem",
        }}
      >
        <i className={"fa " + navItem.icon} aria-hidden="true"></i>
        <span className="ml-2 mr-1">{navItem.title}</span>
      </a>
    );
  }
  return (
    <Link
      to={navItem.path}
      className="nav-link-icon nav-link"
      onClick={(e) => {
        if (navItem.path === "/logout") {
          e.preventDefault();
          props.logout();
        }
      }}
      style={{
        color: utils().isActiveSidebarItem(navItem)
          ? "#00d0ba"
          : "rgba(255, 255, 255, 0.95)",
        padding: "1rem",
      }}
    >
      <i className={"fa " + navItem.icon} aria-hidden="true"></i>
      <span className="ml-2">{navItem.title}</span>
    </Link>
  );
};

import React from "react";
import { Input } from "./Input";

export function InputPass(props: any) {
  const { customData } = props;
  return (
    <>
      <Input
        {...props}
        renderCheckBox={false}
        customType={customData?.checkBoxData?.checked ? "text" : "password"}
      />
    </>
  );
}

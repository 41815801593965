export const marchandPrefix = "/marchand";

export const marchandSidebarItems = [
  {
    title: "Dashboard",
    path: marchandPrefix,
    icon: "fa fa-dashboard",
  },
  {
    title: "Transactions/Paiements",
    path: `${marchandPrefix}/transactions`,
    icon: "fa fa fa-random",
    subItems: [
      {
        title: "Tous",
        path: `${marchandPrefix}/transactions`,
      },
      {
        title: "Paiements",
        path: `${marchandPrefix}/transactions/paiements`,
      },
      {
        title: "Transferts",
        path: `${marchandPrefix}/transactions/transferts`,
      },
    ],
  },
  {
    title: "Produits",
    path: `${marchandPrefix}/produits`,
    icon: "fa fa-product-hunt",
    subItems: [
      {
        title: "Tous",
        path: `${marchandPrefix}/produits`,
      },
      {
        title: "Avec abonnement",
        path: `${marchandPrefix}/produits/abonnement`,
      },
      {
        title: "Sans abonnement",
        path: `${marchandPrefix}/produits/sans_abonnement`,
      },
    ],
  },
  {
    title: "Services",
    path: `${marchandPrefix}/services`,
    icon: "fa fa-exchange",
    subItems: [
      {
        title: "Tous",
        path: `${marchandPrefix}/services`,
      },
      {
        title: "Avec abonnement",
        path: `${marchandPrefix}/services/abonnement`,
      },
      {
        title: "Sans abonnement",
        path: `${marchandPrefix}/services/sans_abonnement`,
      },
    ],
  },
  {
    title: "Plan de souscription",
    path: `${marchandPrefix}/plan_subscriptions`,
    icon: "fa fa-calendar",
    subItems: [
      {
        title: "Tous",
        path: `${marchandPrefix}/plan_subscriptions`,
      },
      {
        title: "Journalier",
        path: `${marchandPrefix}/plan_subscriptions/journalier`,
      },
      {
        title: "Hebdomadaire",
        path: `${marchandPrefix}/plan_subscriptions/hebdomadaire`,
      },
      {
        title: "Mensuel",
        path: `${marchandPrefix}/plan_subscriptions/mensuel`,
      },
    ],
  },
  {
    title: "Reapprovisionnements",
    path: `${marchandPrefix}/reapprovisionnements`,
    icon: "fa fa-cubes",
    subItems: [
      {
        title: "Tous",
        path: `${marchandPrefix}/reapprovisionnements`,
      },
      {
        title: "Actifs",
        path: `${marchandPrefix}/reapprovisionnements/actif`,
      },
      {
        title: "Inactifs",
        path: `${marchandPrefix}/reapprovisionnements/inactif`,
      },
    ],
  },
  {
    title: "Abonnements",
    path: `${marchandPrefix}/subscriptions`,
    icon: "fa fa-cart-plus",
    subItems: [
      {
        title: "Tous",
        path: `${marchandPrefix}/subscriptions`,
      },
      {
        title: "Journalier",
        path: `${marchandPrefix}/subscriptions/journalier`,
      },
      {
        title: "Hebdomadaire",
        path: `${marchandPrefix}/subscriptions/hebdomadaire`,
      },
      {
        title: "Mensuel",
        path: `${marchandPrefix}/subscriptions/mensuel`,
      },
    ],
  },

  {
    title: "Fichiers et Documents",
    path: `${marchandPrefix}/files`,
    icon: "fa fa-folder-open",
  },
  {
    title: "Mon profile",
    path: `${marchandPrefix}/profile`,
    icon: "fa fa-user",
  },
  {
    title: "Déconnexion",
    path: "/logout",
    icon: "fa fa-sign-out",
  },
];

export const marchandNavbarStyles = {
  background: "#6772e5",
  borderLeft: "1px solid #6772e5",
  borderRight: "1px solid #6772e5",
};

export const marchandKpiItems = [
  {
    path: `${marchandPrefix}/produits`,
    title: "Mes Produits",
    icon: "fa fa-product-hunt",
    key: "marchand_produits",
    deps: [
      {
        key: "products",
        path: (meta: any) => {
          const { user } = meta.model;
          const accountId = user?.accountId || 0;
          return `/product/marchand/${accountId}/all`;
        },
        method: "get",
        body: {},
      },
    ],
    resolve: (res: any) => {
      const result = res.data[0]?.result || {};
      if (Array.isArray(result.data)) {
        return result.data.filter((a: any) => !a.virtuel).length;
      }
      return 0;
    },
  },
  {
    path: `${marchandPrefix}/services`,
    title: "Mes Services",
    icon: "fa fa-exchange",
    key: "marchand_services",
    deps: [
      {
        key: "products",
        path: (meta: any) => {
          const { user } = meta.model;
          const accountId = user?.accountId || 0;
          return `/product/marchand/${accountId}/all`;
        },
        method: "get",
        body: {},
      },
    ],
    resolve: (res: any) => {
      const result = res.data[0]?.result || {};
      if (Array.isArray(result.data)) {
        return result.data.filter((a: any) => a.virtuel).length;
      }
      return 0;
    },
  },
  {
    path: `${marchandPrefix}/plan_subscriptions`,
    title: "Plans de souscription",
    icon: "fa fa-calendar",
    key: "marchand_plan_subscriptions",
    deps: [
      {
        key: "subscriptionPlans",
        path: (meta: any) => {
          const { user } = meta.model;
          const accountId = user?.accountId || 0;
          return `/subscription/marchand/${accountId}/all`;
        },
        method: "get",
        body: {},
      },
    ],
    resolve: (res: any) => {
      const result = res.data[0]?.result || {};
      if (Array.isArray(result.data)) {
        return result.data.length;
      }
      return 0;
    },
  },
  {
    path: `${marchandPrefix}/subscriptions`,
    title: "Abonnements",
    icon: "fa fa-cart-plus",
    key: "marchand_subscriptions",
    deps: [
      {
        key: "subscriptions",
        path: (meta: any) => {
          const { user } = meta.model;
          const accountId = user?.accountId || 0;
          return `/subscription/subscribe/marchand/${accountId}/all`;
        },
        method: "get",
        body: {},
      },
    ],
    resolve: (res: any) => {
      const result = res.data[0]?.result || {};
      if (Array.isArray(result.data)) {
        return result.data.length;
      }
      return 0;
    },
  },
  {
    path: `${marchandPrefix}/subscriptions`,
    title: "Abonnements Actifs",
    icon: "fa fa-check",
    key: "marchand_subscriptions_actif",
    deps: [
      {
        key: "subscriptions",
        path: (meta: any) => {
          const { user } = meta.model;
          const accountId = user?.accountId || 0;
          return `/subscription/subscribe/marchand/${accountId}/all`;
        },
        method: "get",
        body: {},
      },
    ],
    resolve: (res: any) => {
      const result = res.data[0]?.result || {};
      if (Array.isArray(result.data)) {
        return result.data.filter((d: any) => d.state).length;
      }
      return 0;
    },
  },
  {
    path: `${marchandPrefix}/subscriptions`,
    title: "Abonnements Inactifs",
    icon: "fa fa-times",
    key: "marchand_subscriptions_inactif",
    deps: [
      {
        key: "subscriptions",
        path: (meta: any) => {
          const { user } = meta.model;
          const accountId = user?.accountId || 0;
          return `/subscription/subscribe/marchand/${accountId}/all`;
        },
        method: "get",
        body: {},
      },
    ],
    resolve: (res: any) => {
      const result = res.data[0]?.result || {};
      if (Array.isArray(result.data)) {
        return result.data.filter((d: any) => !d.state).length;
      }
      return 0;
    },
  },
  {
    path: `${marchandPrefix}/files`,
    title: "Fichiers et documents",
    icon: "fa fa-folder-open",
    key: "marchand_fichiers_documents",
    deps: [
      {
        key: "provisions",
        path: (meta: any) => {
          const { user } = meta.model;
          const accountId = user?.accountId || 0;
          return `/subscription/provision/marchand/${accountId}/all`;
        },
        method: "get",
        body: {},
      },
    ],
    resolve: (res: any) => {
      const result = res.data[0]?.result || {};
      if (Array.isArray(result.data)) {
        return result.data.length;
      }
      return 0;
    },
  },
];

import React from "react";
import { commoncomponents, ErrorPage } from "..";

export function CustomContent(props: any) {
  const { fatalError } = props;
  let Component = (commoncomponents as any)[props.content];
  const isError = !Component || fatalError;
  if (isError) {
    Component = ErrorPage;
  }
  return <Component {...props} />;
}

import firebase from "firebase";
import React from "react";
import { withRouter } from "react-router-dom";
import Auth from "../pages/Auth";
import { LoginFields, LoginFooter } from "../_components";
import { connector, PropsFromRedux } from "../_reducers";

export function withLogin(Component: React.LazyExoticComponent<typeof Auth>) {
  return withRouter(
    connector(
      class extends React.Component<PropsFromRedux & any, any> {
        constructor(props: any) {
          super(props);
          this.state = {
            getTitle: (data: any) => this.getTitle(data.step),
            getBtnTitle: (data: any) => this.getBtnTitle(data.step),
            pageTitle: "Connexion",
            getExtraAuthClass: (data: any) => "",
            renderFields: (data: any) => <LoginFields {...data} />,
            renderFooter: (data: any) => <LoginFooter {...data} />,
            handleSubmit: (data: any, provider: any) =>
              this.handleSubmit(data, provider),
          };
          this.handleSubmit = this.handleSubmit.bind(this);
        }

        getBtnTitle(step: number) {
          return "Se connecter";
        }

        getTitle(step: number) {
          return "Connexion";
        }

        async handleSubmit(data: any, provider: any) {
          let res: any = {};
          if (provider.validate()) {
            const payload = provider.serialize();
            res = await provider.processNew(payload);
            if (res?.success) {
              /* this.props.refreshUser(res);
              setTimeout(() => {
                history.push(res?.path);
              }, 400);
              return;*/
              firebase
                .analytics()
                .logEvent("login", { user: res?.data?.user?.email });

              window.location.href = res?.path || "/";
              return;
            } else {
              firebase.analytics().logEvent("login_error");
            }
          }
          return res;
        }

        render() {
          return <Component {...this.props} {...this.state} />;
        }
      }
    )
  );
}

import { utils } from "../_helpers";

export const adminErecettePrefix = "/admin/eRecette";

export const adminErecetteSidebarItems = [
  {
    title: "Dashboard",
    path: adminErecettePrefix,
    icon: "fa fa-dashboard",
  },
  {
    title: "Paiements de taxes",
    path: `${adminErecettePrefix}/taxes`,
    icon: "fa fa-random",
  },
  {
    title: "Transactions",
    path: `${adminErecettePrefix}/transactions`,
    icon: "fa fa fa-random",
    subItems: [
      {
        title: "Tous",
        path: `${adminErecettePrefix}/transactions`,
      },
      {
        title: "eMpata vers autres",
        path: `${adminErecettePrefix}/transactions/empatatoothers`,
      },
      {
        title: "eMpata to Bank",
        path: `${adminErecettePrefix}/transactions/empatatobank`,
      },
    ],
  },
  {
    title: "Mon profile",
    path: `${adminErecettePrefix}/profile`,
    icon: "fa fa-user",
  },
  {
    title: "Déconnexion",
    path: "/logout",
    icon: "fa fa-sign-out",
  },
];

export const adminErecetteNavbarStyles = {
  background: "#efd807",
  borderLeft: "1px solid #efd807",
  borderRight: "1px solid #efd807",
};

export const adminErecetteKpiItems = [
  {
    path: `${adminErecettePrefix}/transactions`,
    title: "Total paiements",
    icon: "fa fa-money",
    key: "adminErecette_paiements",
    deps: [
      {
        key: "payements",
        path: "/admin/eRecette/payements/all",
        method: "get",
        body: {},
      },
    ],
    resolve: (res: any) => {
      const result = res.data[0]?.result || {};
      if (Array.isArray(result.data)) {
        const res = result.data
          .filter((i: any) => {
            if (
              i.status === "SUCCESS" ||
              i.status === "WITHDRAWN" ||
              i.status === "TRAITÉ"
            ) {
              return true;
            }
            return false;
          })
          .map((o: any) => o.amount)
          .reduce((a: number, b: number) => {
            const ap = a < 0 ? a * -1 : a;
            const bp = b < 0 ? b * -1 : b;
            return ap + bp;
          }, 0);
        return utils().formatAmount("CDF", res);
      }
      return 0;
    },
  },
  {
    path: `${adminErecettePrefix}/transactions`,
    title: "Paiements terminés",
    icon: "fa fa-random",
    key: "adminErecette_total_transaction",
    deps: [
      {
        key: "payements",
        path: "/admin/eRecette/payements/all",
        method: "get",
        body: {},
      },
    ],
    resolve: (res: any) => {
      const result = res.data[0]?.result || {};
      if (Array.isArray(result.data)) {
        return result.data.filter((i: any) => {
          if (
            i.status === "SUCCESS" ||
            i.status === "WITHDRAWN" ||
            i.status === "TRAITÉ"
          ) {
            return true;
          }
          return false;
        }).length;
      }
      return 0;
    },
  },
  {
    path: `${adminErecettePrefix}/transactions`,
    title: "Paiements non terminés",
    icon: "fa fa-times",
    key: "adminErecette_total_transaction_not",
    deps: [
      {
        key: "payements",
        path: "/admin/eRecette/payements/all",
        method: "get",
        body: {},
      },
    ],
    resolve: (res: any) => {
      const result = res.data[0]?.result || {};
      if (Array.isArray(result.data)) {
        return result.data.filter((i: any) => {
          if (
            i.status !== "SUCCESS" &&
            i.status !== "WITHDRAWN" &&
            i.status !== "TRAITÉ"
          ) {
            return true;
          }
          return false;
        }).length;
      }
      return 0;
    },
  },

  {
    path: `${adminErecettePrefix}/transactions`,
    title: "Paiements aujourd'hui",
    icon: "fa fa-money",
    key: "adminErecette_paiements_today",
    deps: [
      {
        key: "payements_today",
        path: "/admin/eRecette/payements/all?period=today",
        method: "get",
        body: {},
      },
    ],
    resolve: (res: any) => {
      const result = res.data[0]?.result || {};
      if (Array.isArray(result.data)) {
        const res = result.data
          .filter((i: any) => {
            if (
              i.status === "SUCCESS" ||
              i.status === "WITHDRAWN" ||
              i.status === "TRAITÉ"
            ) {
              return true;
            }
            return false;
          })
          .map((o: any) => o.amount)
          .reduce((a: number, b: number) => {
            const ap = a < 0 ? a * -1 : a;
            const bp = b < 0 ? b * -1 : b;
            return ap + bp;
          }, 0);
        return utils().formatAmount("CDF", res);
      }
      return 0;
    },
  },

  {
    path: `${adminErecettePrefix}/transactions`,
    title: "Paiements cette semaine",
    icon: "fa fa-money",
    key: "adminErecette_paiements_week",
    deps: [
      {
        key: "payements_week",
        path: "/admin/eRecette/payements/all?period=week",
        method: "get",
        body: {},
      },
    ],
    resolve: (res: any) => {
      const result = res.data[0]?.result || {};
      if (Array.isArray(result.data)) {
        const res = result.data
          .filter((i: any) => {
            if (
              i.status === "SUCCESS" ||
              i.status === "WITHDRAWN" ||
              i.status === "TRAITÉ"
            ) {
              return true;
            }
            return false;
          })
          .map((o: any) => o.amount)
          .reduce((a: number, b: number) => {
            const ap = a < 0 ? a * -1 : a;
            const bp = b < 0 ? b * -1 : b;
            return ap + bp;
          }, 0);
        return utils().formatAmount("CDF", res);
      }
      return 0;
    },
  },

  {
    path: `${adminErecettePrefix}/transactions`,
    title: "Paiements ce mois",
    icon: "fa fa-money",
    key: "adminErecette_paiements_month",
    deps: [
      {
        key: "payements_month",
        path: "/admin/eRecette/payements/all?period=month",
        method: "get",
        body: {},
      },
    ],
    resolve: (res: any) => {
      const result = res.data[0]?.result || {};
      if (Array.isArray(result.data)) {
        const res = result.data
          .filter((i: any) => {
            if (
              i.status === "SUCCESS" ||
              i.status === "WITHDRAWN" ||
              i.status === "TRAITÉ"
            ) {
              return true;
            }
            return false;
          })
          .map((o: any) => o.amount)
          .reduce((a: number, b: number) => {
            const ap = a < 0 ? a * -1 : a;
            const bp = b < 0 ? b * -1 : b;
            return ap + bp;
          }, 0);
        return utils().formatAmount("CDF", res);
      }
      return 0;
    },
  },
];

import React from "react";
import { Field } from "../../../_entities";
import { SigninField } from "./SigninField";

export function SigninRow(props: any) {
  const { fields } = props;
  return (
    <div className="form-group row">
      {fields.map((field: Field, i: number) => (
        <SigninField field={field} {...props} key={i} />
      ))}
    </div>
  );
}

import React from "react";
import { Field, PassReset } from "../../../_entities";
import { SigninRow } from "../Signin/SigninRow";

export function PassResetFields(props: any) {
  const { rows, step } = props;
  return (
    <>
      {step === 1 &&
        rows.map((row: Field[], i: number) => (
          <SigninRow fields={row} {...props} key={i} />
        ))}
      {step === 2 && (
        <SigninRow fields={PassReset.getPhoneValidationField()} {...props} />
      )}
       {step === 3 && (
        <SigninRow fields={PassReset.getPassField()} {...props} />
      )}
    </>
  );
}

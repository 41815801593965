import React, { useEffect, useState } from "react";
import { utils } from "../../../../_helpers";
import { FormInput } from "./FormInput";
import { FormLabel } from "./FormLabel";

export function FormField(props: any) {
  const { customStyles } = props;
  const originalField = props.originalField || props.field;
  const [isMounted, setIsMounted] = useState(false);
  const [value, setValue] = useState(originalField.value || "");
  const [field, setField] = useState(originalField);
  const [formLayout, setFormLayout] = useState(originalField.formLayout);
  const [customData, setCustomData] = useState(originalField.customData);

  useEffect(() => {
    setIsMounted(true);
    let resolving = false;
    setValue(field.value);
    if (field.listeners.length) {
      const handle = (v: any, e: any) => {
        let res: any = {};
        if (
          e.behaviour === "toggleLayout" ||
          e.behaviour === "customAction" ||
          e.behaviour === "customActionAsyncCallback"
        ) {
          if (typeof e.resolve === "function") {
            res = e.resolve(v, customData);
          }
        }

        if (isMounted) {
          if (e.behaviour === "resetValue") {
            setValue(field.defaultValue);
          } else if (e.behaviour === "setValue") {
            setValue(field.value);
          } else if (e.behaviour === "toggleLayout") {
            setFormLayout(res);
          } else if (e.behaviour === "customAction") {
            setCustomData(res?.customData || res);
            if (res?.type) {
              utils().emitter.emit(res?.type, res?.data);
            }
          } else if (e.behaviour === "customActionAsync") {
            if (!resolving) {
              resolving = true;
              e.resolve(v, customData).then((result: any) => {
                resolving = false;
                setCustomData(result?.customData);
                utils().emitter.emit('inputFraisData', result?.customData);
                if (result?.type && result?.data) {
                  utils().emitter.emit(result?.type, result?.data);
                }
              });
            }
          } else if (e.behaviour === "customActionAsyncCallback") {
            setFormLayout(res.formLayout);
            setCustomData(res.customData);
          } else if (e.behaviour === "changeField") {
            e.resolve(v, field).then((res: any) => {
              setField(res);
              setCustomData(res.customData);
            });
          }
        }
      };
      field.listeners.forEach((e: any) => {
        utils().emitter.on(e.type, (v) => handle(v, e));
      });
      return () => {
        setIsMounted(false);
        field.listeners.forEach((e: any) => {
          utils().emitter.off(e.type, handle);
        });
      };
    }
  }, [isMounted]);

  const { inlineStyle } = field;
  const styles = customStyles
    ? { ...customStyles, ...inlineStyle }
    : { margin: "0 0 1rem 0", width: "90%", ...inlineStyle };

  return (
    <div className={`form-group row ${formLayout}`} style={styles}>
      <FormLabel field={field} {...props} />
      <FormInput
        field={field}
        {...props}
        customData={customData}
        value={value}
        setValue={setValue}
      />
    </div>
  );
}

import React from "react";
import { model } from "../../../_helpers";

export function CopyRight(props: any) {
  const cls = props.cls ? props.cls : "text-muted";
  return (
    <span className={cls}>
      &copy; Copyright {new Date().getFullYear()} |{" "}
      {model().appTitle || "e-Mpata"} |{" "}
      <span className="copyright_text">Tous droits reservés.</span>
    </span>
  );
}

import React, { useEffect } from "react";
import { history, restClient } from "../_helpers";

export function CentralPay(props: any) {
  useEffect(() => {
    const operationId = sessionStorage.getItem("operationId");
    console.log("====", operationId);
    let path = `/operation/update/status/${operationId}/centralpay?state=${props.state}`;
    restClient.put(path, { operationId: operationId }).then((res) => {
      console.log(res);
      sessionStorage.removeItem("operationId");
      history.push("/dashboard");
    });
  }, []);
  return <h1 className="text-muted">Chargement en cours ... </h1>;
}

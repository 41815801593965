import React from "react";
import { CustomLink } from "../../common/CustomLink/CustomLink";

export function LoginFooter(props: any) {
  return (
    <>
      <CustomLink
        cls={"login-lost-password-link modal-btn"}
        to={"/forget-password"}
        label={"Mot de passe oublié ?"}
      />
      <span
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: "10px",
          justifyContent: "center",
        }}
      >
        Pas de compte?
        <CustomLink
          cls={"login-lost-password-link"}
          inlineStyle={{
            marginTop: "0px",
            marginLeft: "3px",
          }}
          to={"/signin"}
          label={"Inscrivez-vous"}
        />
      </span>
    </>
  );
}

import { appConstants } from "../_constants";
import { utils } from "../_helpers";
import { BaseEntity } from "./BaseEntity";
import { FieldType, InputType } from "./enumerations";
import { Field } from "./Field";
import { Quota } from "./Quota";
import {BaseModel} from "../_models";

const {
  RENDER_ACTIONS,
  RENDER_ID,
  RENDER_STATUS,
  RENDER_ACCOUNT_TYPE,
  RENDER_AMOUNT,
} = appConstants.keys.renderKeys;

class AccountTypeQuota extends BaseEntity {
  public static readonly label: any;
  public static readonly className: any;
  public static readonly art: any;
  public static readonly addTitle = "Ajouter un quota sur un type de compte";
  public static readonly showTitle = "Détails du quota";
  public static readonly saveBtnTitle = "Enregistrer le quota";
  public static readonly processNewSuccessMsg =
    "Le quota a été créée avec succès. Merci !";
  public static readonly processNewPath = "account/type/addQuota";
  public static instance: AccountTypeQuota = new AccountTypeQuota();
  public static instances = [];
  public static relations = [
    {
      dataPath: "account/type/all",
      key: "accountTypes",
      resolve: (res: any) => {
        if (Array.isArray(res?.data)) {
          return res?.data.map((v: any) => ({
            label: v.libelle,
            value: v.accountTypeId,
          }));
        }
        return [];
      },
    },
    {
      dataPath: "quota/all",
      key: "quotas",
      resolve: (res: any) => {
        if (Array.isArray(res?.data)) {
          return res.data.map((v: any) => ({
            label: v.libelle,
            value: v.quotaId,
          }));
        }
        return [];
      },
    },
  ];
  public static get definitions(): Field[] {
    const model: any = BaseModel.getInstance();
    const aChildAll = [
      {
        actionCallback: (data: any) => {
          if (data.actif) {
            return {
              label: "Désactiver",
              title: "Désactiver ce quota",
              action: "switchActionsDeactivate",
              color: "btn-danger",
              icon: "fa fa-times",
            };
          }
          return {
            label: "Activer",
            title: "Activer ce quota",
            action: "switchActionsActivate",
            color: "btn-success",
            icon: "fa fa-check",
          };
        },
      },
      {
        label: "Supprimer",
        title: "Supprimmer ce type de compte",
        action: "deleteActions",
        color: "btn-danger",
        icon: "fa fa-trash",
      }
    ];
    const aChildMod = [
      {
        actionCallback: (data: any) => {
          if (data.actif) {
            return {
              label: "Désactiver",
              title: "Désactiver ce quota",
              action: "switchActionsDeactivate",
              color: "btn-danger",
              icon: "fa fa-times",
            };
          }
          return {
            label: "Activer",
            title: "Activer ce quota",
            action: "switchActionsActivate",
            color: "btn-success",
            icon: "fa fa-check",
          };
        },
      }
    ];
    const aChildDel = [
      {
        label: "Supprimer",
        title: "Supprimmer ce type de compte",
        action: "deleteActions",
        color: "btn-danger",
        icon: "fa fa-trash",
      }
    ];
    const definitions = [
      new Field({
        name: "pk",
        type: FieldType.NUMBER,
        primary: true,
        creatable: false,
        editable: false,
        serializeNew: false,
        serializeEdit: false,
        dataPath: "max",
        label: "#",
        renderKey: RENDER_ID,
        provider: AccountTypeQuota.instance,
      }),
      new Field({
        name: "libelle",
        label: "Libellé",
        dataPath: "quota.libelle",
        creatable: false,
        provider: AccountTypeQuota.instance,
      }),
      new Field({
        name: "max",
        label: "Max transferts",
        provider: AccountTypeQuota.instance,
      }),
      new Field({
        name: "maxAmount",
        label: "Max montant",
        renderKey: RENDER_AMOUNT,
        provider: AccountTypeQuota.instance,
      }),
      new Field({
        name: "maxUsd",
        label: "valeur max en USD",
        provider: AccountTypeQuota.instance,
      }),
      new Field({
        name: "accountType",
        label: "Type de compte",
        formRowIndex: 0,
        relationKey: "accountTypes",
        renderKey: RENDER_ACCOUNT_TYPE,
        inputType: InputType.INPUT_SELECT,
        dataPath: "accountType.libelle",
        provider: AccountTypeQuota.instance,
      }),
      new Field({
        name: "quota",
        label: "Quota",
        formRowIndex: 1,
        relationKey: "quotas",
        inputType: InputType.INPUT_SELECT,
        onTable: false,
        provider: AccountTypeQuota.instance,
      }),
      new Field({
        name: "actif",
        label: "Status",
        creatable: false,
        renderKey: RENDER_STATUS,
        provider: AccountTypeQuota.instance,
        serializeCallback: (item: any, v: any) => {
          return {...item, actif: true};
        },
      }),

      new Field({
        name: "actions",
        label: "Actions",
        dataPath: "max",
        creatable: false,
        editable: false,
        serializeNew: false,
        serializeEdit: false,
        serializeView: false,
        renderKey: RENDER_ACTIONS,
        provider: AccountTypeQuota.instance,
        customData: {
          actions: [
            {
              label: "Détails",
              title: "Afficher les détails",
              action: "detailsActions",
              color: "btn-info",
              icon: "fa fa-eye",
            },
            {
              type: "btnGroup",
              color: "btn-primary",
              children:model.user.user?.profile===null?aChildAll:
                  model.confCrud.modification && model.confCrud.suppression?aChildAll:
                      model.confCrud.modification?aChildMod:model.confCrud.suppression?aChildDel:[]
            }
          ],
        },
      }),
    ];
    return definitions;
  }

  toView() {
    return [
      {
        title: "Information générales",
        rows: [
          { label: "Maximum de transaction", value: this.max },
          {
            label: "Montant maximum ",
            value: utils().formatAmount("CDF", this.maxAmount),
          },
          {
            label: "Valeur maximum en USD ",
            value: utils().formatAmount("USD", this.maxUsd),
          },
          { label: "Status ", value: this.actif ? "Actif" : "Inactif" },
        ],
      },
      {
        title: "Périodicité",
        rows: [{ label: false, value: this.quota.libelle }],
      },
      {
        title: "Type de compte",
        rows: [{ label: false, value: this.accountType.libelle }],
      },
    ];
  }

  getSwitchActionConfig(status: boolean) {
    return {
      path: `/account/type/${this.pk.accountTypeId}/state/quota/${this.pk.quotaId}/${status}`,
      resolve: (res: any) => res?.data?.status === "OK",
    };
  }

  getDeleteActionConfig() {
    return {
      path: `/account/type/${this.pk.accountTypeId}/delete/quota/${this.pk.quotaId}`,
      method: "get",
      resolve: (res: any) => res?.data?.status === "OK",
    };
  }
}

export { AccountTypeQuota };

import React from "react";
import { TabBtn } from "./TabBtn";

const tabBtns = [
  {
    className: "nav-link active",
    id: "v-pills-home-tab",
    href: "#v-pills-home",
    role: "tab",
    "aria-controls": "v-pills-home",
    "aria-selected": "true",
    label: "Compte",
  },
  {
    className: "nav-link",
    id: "v-pills-profile-tab",
    href: "#v-pills-profile",
    role: "tab",
    "aria-controls": "v-pills-profile",
    "aria-selected": "false",
    label: "Transferts",
  },
  {
    className: "nav-link",
    id: "v-pills-messages-tab",
    href: "#v-pills-messages",
    role: "tab",
    "aria-controls": "v-pills-messages",
    "aria-selected": "false",
    label: "Paiements",
  },
  {
    className: "nav-link",
    id: "v-pills-settings-tab",
    href: "#v-pills-settings",
    role: "tab",
    "aria-controls": "v-pills-settings",
    "aria-selected": "false",
    label: "Autres",
  },
];

export function TabBtns(props: any) {
  return (
    <div
      className="nav flex-column nav-pills"
      id="v-pills-tab"
      role="tablist"
      aria-orientation="vertical"
    >
      {tabBtns.map((tabBtn, i) => {
        return <TabBtn onClick={props.onClick} {...tabBtn} key={i} />;
      })}
    </div>
  );
}

import { utils } from "../_helpers";

export const adminFinancierPrefix = "/admin/financier";

export const adminFinancierSidebarItems = [
  {
    title: "Dashboard",
    path: adminFinancierPrefix,
    icon: "fa fa-dashboard",
  },
  {
    title: "Transactions",
    path: `${adminFinancierPrefix}/transactions`,
    icon: "fa fa fa-random",
    subItems: [
      {
        title: "Tous",
        path: `${adminFinancierPrefix}/transactions`,
      },
      {
        title: "Dépôts",
        path: `${adminFinancierPrefix}/transactions/depots`,
      },
      {
        title: "Retraits",
        path: `${adminFinancierPrefix}/transactions/retraits`,
      },
      {
        title: "Transferts",
        path: `${adminFinancierPrefix}/transactions/transferts`,
      },
      {
        title: "eMpata to Bank",
        path: `${adminFinancierPrefix}/transactions/empatatobank`,
      },
    ],
  },
  {
    title: "Frais",
    path: `${adminFinancierPrefix}/frais`,
    icon: "fa fa-money",
    subItems: [
      {
        title: "Tous",
        path: `${adminFinancierPrefix}/frais`,
      },
      {
        title: "Frais Transferts/Achats crédits",
        path: `${adminFinancierPrefix}/frais/transferts_credits`,
      },
    ],
  },
  {
    title: "Mon profile",
    path: `${adminFinancierPrefix}/profile`,
    icon: "fa fa-user",
  },
  {
    title: "Déconnexion",
    path: "/logout",
    icon: "fa fa-sign-out",
  },
];

export const adminFinancierNavbarStyles = {
  background: "#dc3545",
  borderLeft: "1px solid #dc3545",
  borderRight: "1px solid #dc3545",
};

export const adminFinancierKpiItems = [
  {
    path: `${adminFinancierPrefix}/transactions`,
    title: "Transactions",
    icon: "fa fa-random",
    key: "admin_transactions",
    deps: [
      {
        key: "operations",
        path: "/operation/all",
        method: "get",
        body: {},
      },
    ],
    resolve: (res: any) => {
      const result = res.data[0]?.result || {};
      if (Array.isArray(result.data)) {
        return result.data.length;
      }
      return 0;
    },
  },
  {
    path: `${adminFinancierPrefix}/frais`,
    title: "Total frais",
    icon: "fa fa-money",
    key: "adminFinancier_total_frais",
    deps: [
      {
        key: "fees",
        path: "/admin/financier/fees/all",
        method: "get",
        body: {},
      },
    ],
    resolve: (res: any) => {
      const result = res.data[0]?.result || {};
      if (Array.isArray(result.data)) {
        const res = result.data
          .filter((i: any) => {
            if (
              i.status === "SUCCESS" ||
              i.status === "WITHDRAWN" ||
              i.status === "TRAITÉ"
            ) {
              return true;
            }
            return false;
          })
          .map((o: any) => o.amount)
          .reduce((a: number, b: number) => {
            const ap = a < 0 ? a * -1 : a;
            const bp = b < 0 ? b * -1 : b;
            return ap + bp;
          }, 0);
        return utils().formatAmount("CDF", res);
      }
      return 0;
    },
  },
 /* {
    path: `${adminFinancierPrefix}/transactions`,
    title: "Total transactions terminées",
    icon: "fa fa-bank",
    key: "adminFinancier_total_transaction",
    deps: [
      {
        key: "operations",
        path: "/operation/all",
        method: "get",
        body: {},
      },
    ],
    resolve: (res: any) => {
      const result = res.data[0]?.result || {};
      if (Array.isArray(result.data)) {
        const res = result.data
          .filter((i: any) => {
            if (
              i.status === "SUCCESS" ||
              i.status === "WITHDRAWN" ||
              i.status === "TRAITÉ"
            ) {
              return true;
            }
            return false;
          })
          .map((o: any) => o.amount)
          .reduce((a: number, b: number) => a + b, 0);
        return utils().formatAmount("CDF", res);
      }
      return 0;
    },
  },*/

  {
    path: `${adminFinancierPrefix}/frais`,
    title: "Frais prélevés aujourd'hui",
    icon: "fa fa-money",
    key: "adminFinancier_total_frais_today",
    deps: [
      {
        key: "fees_today",
        path: "/admin/financier/fees/all?period=today",
        method: "get",
        body: {},
      },
    ],
    resolve: (res: any) => {
      const result = res.data[0]?.result || {};
      if (Array.isArray(result.data)) {
        const res = result.data
          .filter((i: any) => {
            if (
              i.status === "SUCCESS" ||
              i.status === "WITHDRAWN" ||
              i.status === "TRAITÉ"
            ) {
              return true;
            }
            return false;
          })
          .map((o: any) => o.amount)
          .reduce((a: number, b: number) => {
            const ap = a < 0 ? a * -1 : a;
            const bp = b < 0 ? b * -1 : b;
            return ap + bp;
          }, 0);
        return utils().formatAmount("CDF", res);
      }
      return 0;
    },
  },

  {
    path: `${adminFinancierPrefix}/frais`,
    title: "Frais prélevés cette semaine",
    icon: "fa fa-money",
    key: "adminFinancier_total_frais_week",
    deps: [
      {
        key: "fees_week",
        path: "/admin/financier/fees/all?period=week",
        method: "get",
        body: {},
      },
    ],
    resolve: (res: any) => {
      const result = res.data[0]?.result || {};
      if (Array.isArray(result.data)) {
        const res = result.data
          .filter((i: any) => {
            if (
              i.status === "SUCCESS" ||
              i.status === "WITHDRAWN" ||
              i.status === "TRAITÉ"
            ) {
              return true;
            }
            return false;
          })
          .map((o: any) => o.amount)
          .reduce((a: number, b: number) => {
            const ap = a < 0 ? a * -1 : a;
            const bp = b < 0 ? b * -1 : b;
            return ap + bp;
          }, 0);
        return utils().formatAmount("CDF", res);
      }
      return 0;
    },
  },

  {
    path: `${adminFinancierPrefix}/frais`,
    title: "Frais prélevés ce mois",
    icon: "fa fa-money",
    key: "adminFinancier_total_frais_month",
    deps: [
      {
        key: "fees_month",
        path: "/admin/financier/fees/all?period=month",
        method: "get",
        body: {},
      },
    ],
    resolve: (res: any) => {
      const result = res.data[0]?.result || {};
      if (Array.isArray(result.data)) {
        const res = result.data
          .filter((i: any) => {
            if (
              i.status === "SUCCESS" ||
              i.status === "WITHDRAWN" ||
              i.status === "TRAITÉ"
            ) {
              if (!i.devise || i.devise?.code === "CDF") {
                return true;
              }
            }
            return false;
          })
          .map((o: any) => o.amount)
          .reduce((a: number, b: number) => {
            const ap = a < 0 ? a * -1 : a;
            const bp = b < 0 ? b * -1 : b;
            return ap + bp;
          }, 0);
        return utils().formatAmount("CDF", res);
      }
      return 0;
    },
  },
  {
    path: `${adminFinancierPrefix}/frais`,
    title: "Total frais en Ussd",
    icon: "fa fa-dollar-sign",
    key: "adminFinancier_total_frais_usd",
    deps: [
      {
        key: "fees",
        path: "/admin/financier/fees/all",
        method: "get",
        body: {},
      },
    ],
    resolve: (res: any) => {
      const result = res.data[0]?.result || {};
  
      if (Array.isArray(result.data)) {
        const res = result.data
          .filter((i: any) => {
            if (
              i.status === "SUCCESS" ||
              i.status === "WITHDRAWN" ||
              i.status === "TRAITÉ"
            ) {
              if (i.devise?.code === "USD") {
                return true;
              }
            }
            return false;
          })
          .map((o: any) => o.amount)
          .reduce((a: number, b: number) => {
            const ap = a < 0 ? a * -1 : a;
            const bp = b < 0 ? b * -1 : b;
            return ap + bp;
          }, 0);
        return utils().formatAmount("USD", res);
      }
      return 0;
    },
  },
  {
    path: `${adminFinancierPrefix}/transactions/empatatobank`,
    title: "Total empata to bank",
    icon: "fa fa-money",
    key: "adminFinancier_total_empata_to_bank_cdf",
    deps: [
      {
        key: "operations",
        path: "/operation/all",
        method: "get",
        body: {},
      },
    ],
    resolve: (res: any) => {
      const result = res.data[0]?.result || {};
  
      if (Array.isArray(result.data)) {
        const res = result.data
          .filter((i: any) => {
            if (i.operationBank && i.amount > 0) {
              if (
                i.status === "SUCCESS" ||
                i.status === "WITHDRAWN" ||
                i.status === "TRAITÉ"
              ) {
                if (!i.devise || i.devise?.code === "CDF") {
                  return true;
                }
              }
            }
            return false;
          })
          .map((o: any) => o.amount)
          .reduce((a: number, b: number) => {
            const ap = a < 0 ? a * -1 : a;
            const bp = b < 0 ? b * -1 : b;
            return ap + bp;
          }, 0);
        return utils().formatAmount("CDF", res);
      }
      return 0;
    },
  },
  {
    path: `${adminFinancierPrefix}/transactions/empatatobank`,
    title: "Total empata to bank",
    icon: "fa fa-money",
    key: "adminFinancier_total_empata_to_bank_usd",
    deps: [
      {
        key: "operations",
        path: "/operation/all",
        method: "get",
        body: {},
      },
    ],
    resolve: (res: any) => {
      const result = res.data[0]?.result || {};
      if (Array.isArray(result.data)) {
        const res = result.data
          .filter((i: any) => {
            if (i.operationBank && i.amount > 0) {
              if (
                i.status === "SUCCESS" ||
                i.status === "WITHDRAWN" ||
                i.status === "TRAITÉ"
              ) {
                if (i.devise?.code === "USD") {
                  return true;
                }
              }
            }
            return false;
          })
          .map((o: any) => o.amount)
          .reduce((a: number, b: number) => {
            const ap = a < 0 ? a * -1 : a;
            const bp = b < 0 ? b * -1 : b;
            return ap + bp;
          }, 0);
        return utils().formatAmount("USD", res);
      }
      return 0;
    },
  },
  /*{
    path: `${adminFinancierPrefix}/transactions`,
    title: "Total des dépôts",
    icon: "fa fa-download",
    key: "adminFinancier_total_depots",
    deps: [
      {
        key: "operations",
        path: "/operation/all",
        method: "get",
        body: {},
      },
    ],
    resolve: (res: any) => {
      const result = res.data[0]?.result || {};
      if (Array.isArray(result.data)) {
        const res = result.data
          .filter((i: any) => {
            if (
              i.status === "SUCCESS" ||
              i.status === "WITHDRAWN" ||
              i.status === "TRAITÉ"
            ) {
              if (i.operationType?.operationTypeId === 1) {
                return true;
              }
            }
            return false;
          })
          .map((o: any) => o.amount)
          .reduce((a: number, b: number) => a + b, 0);
        return utils().formatAmount("CDF", res);
      }
      return 0;
    },
  },

  {
    path: `${adminFinancierPrefix}/transactions`,
    title: "Total des retraits",
    icon: "fa fa-download",
    key: "adminFinancier_total_retraits",
    deps: [
      {
        key: "operations",
        path: "/operation/all",
        method: "get",
        body: {},
      },
    ],
    resolve: (res: any) => {
      const result = res.data[0]?.result || {};
      if (Array.isArray(result.data)) {
        const res = result.data
          .filter((i: any) => {
            if (
              i.status === "SUCCESS" ||
              i.status === "WITHDRAWN" ||
              i.status === "TRAITÉ"
            ) {
              if (i.operationType?.operationTypeId === 2) {
                return true;
              }
            }
            return false;
          })
          .map((o: any) => o.amount)
          .reduce((a: number, b: number) => a + b, 0);
        return utils().formatAmount("CDF", res);
      }
      return 0;
    },
  },*/
];

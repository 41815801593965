import { Auth, PassReset, User } from "../_entities";
import { adminErecetteRoutes } from "./adminErecetteRoutes";
import { adminFinancierRoutes } from "./adminFinancierRoutes";
import { adminRoutes } from "./adminRoutes";
import { dashboardRoutes } from "./dashboardRoutes";
import { entrepriseRoutes } from "./entrepriseRoutes";
import { marchandRoutes } from "./marchandRoutes";
import { supermarchandRoutes } from "./supermarchandRoutes";

export const routes = [
  ...adminRoutes,
  ...marchandRoutes,
  ...supermarchandRoutes,
  ...entrepriseRoutes,
  ...adminFinancierRoutes,
  ...adminErecetteRoutes,
  ...dashboardRoutes,
  {
    mainPath: "/signin",
    pageTitle: "Inscription",
    provider: User.instance,
  },
  {
    mainPath: "/login",
    pageTitle: "Connexion",
    provider: Auth.instance,
  },
  {
    mainPath: "/forget-password",
    pageTitle: "Mot de passe oublié",
    provider: PassReset.instance,
  },
];

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { PropsFromRedux } from "../../../_reducers";

import mpata from "../../../_assets/images/mpata.png";
import { model } from "../../../_helpers";

class TopNav extends Component<PropsFromRedux & any> {
  componentDidMount() {
    $("#toggle-sidebar").on("click", function (e) {
      e.preventDefault();
      if ($(this).attr("data-state") === "shown") {
        $(".page-wrapper").addClass("toggled");
        $("#lblLiens").html("Afficher le menu");
        $(".js-toggle-main-menu>i").addClass("fa-bars");
        $(".js-toggle-main-menu>i").removeClass("fa-times");
        $(this).attr("data-state", "hidden");
        $(".span3").parent().addClass("d-none");
        $(".span9").parent().addClass("col-lg-12");
        $(".span9").parent().addClass("col-md-12");
        $(".span9").parent().removeClass("col-lg-9");
        $(".span9").parent().removeClass("col-md-8");
      } else {
        $("#lblLiens").html("Masquer le menu");
        $(".js-toggle-main-menu>i").addClass("fa-times");
        $(".js-toggle-main-menu>i").removeClass("fa-bars");
        $(this).attr("data-state", "shown");
        $(".span9").parent().removeClass("col-lg-12");
        $(".span9").parent().removeClass("col-md-12");
        $(".span9").parent().addClass("col-lg-9");
        $(".span9").parent().addClass("col-md-8");
        $(".span3").parent().removeClass("d-none");
      }
    });
  }

  componentWillUnmount() {
    $("#toggle-sidebar").off("click");
  }

  render() {
    const { navbarStyles } = this.props;
    const styles =
      typeof navbarStyles === "function" ? navbarStyles() : navbarStyles;
    return (
      <div className="container-fluid">
        <nav
          className="navbar navbar-default navbar-default-dark no-border-radius no-margin navbar-hover"
          style={styles}
        >
          <div className="container-fluid" id="navbar-inner-container">
            <div className="navbar-header">
              <span
                id="toggle-sidebar"
                className="js-toggle-main-menu"
                data-state="shown"
              >
                <i className="mr-1 fa fa-times" aria-hidden="true"></i>
                <span id="lblLiens" style={{ marginLeft: "2px" }}>
                  Masquer le menu
                </span>
              </span>
            </div>
            <Link
              className="navbar-title navbar-brand navbar-brand-center d-flex flex-row align-items-center justify-content-center"
              to="/"
              style={{ color: "#fff", margin: "auto" }}
            >
              <img
                src={model().logo || mpata}
                alt=""
                style={{ maxHeight: "50px", marginRight: "3px" }}
              />
              <h5 style={{ margin: 0 }}>{model().appTitle}</h5>
            </Link>
            <div className="navbar-title"></div>
          </div>
        </nav>
      </div>
    );
  }
}

export { TopNav };

import React from "react";

export function NavItems(props: any) {
  const { model, profil } = props;
  return (
    <div
      className="nav flex-column nav-pills"
      id="v-pills-tab"
      role="tablist"
      aria-orientation="vertical"
    >
      <a
        className="nav-link p-2 active"
        id="home-tab"
        data-toggle="tab"
        href="#home"
        role="tab"
        aria-controls="home"
        aria-selected="true"
      >
        Compte
      </a>
      {model.isAdmin && !profil && (
        <a
          className="nav-link p-2"
          id="parent-tab"
          data-toggle="tab"
          href="#parent"
          role="tab"
          aria-controls="parent"
          aria-selected="false"
        >
          Compte Parent
        </a>
      )}
      <a
        className="nav-link p-2"
        id="profile-tab"
        data-toggle="tab"
        href="#profil"
        role="tab"
        aria-controls="profil"
        aria-selected="false"
      >
        Profile
      </a>
      <a
          className="nav-link p-2"
          id="nfcard-tab"
          data-toggle="tab"
          href="#nfcard"
          role="tab"
          aria-controls="nfcard"
          aria-selected="false"
      >
        Cartes
      </a>
      <a
        className="nav-link p-2"
        id="photo-tab"
        data-toggle="tab"
        href="#photo"
        role="tab"
        aria-controls="photo"
        aria-selected="false"
      >
        CNI
      </a>
    </div>
  );
}

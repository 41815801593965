import { appConstants } from "../_constants";
import { utils } from "../_helpers";
import { BaseEntity } from "./BaseEntity";
import { FieldType } from "./enumerations";
import { Field } from "./Field";

const {
  RENDER_ACTIONS,
  RENDER_ID,
  RENDER_OUI_NON,
  RENDER_DATETIME,
} = appConstants.keys.renderKeys;

class SubscriptionPlanPeriodFile extends BaseEntity {
  public static readonly label: any;
  public static readonly className: any;
  public static readonly art: any;
  public static readonly showTitle = "Détails d'un fichier";
  public static instance: SubscriptionPlanPeriodFile = new SubscriptionPlanPeriodFile();
  public static instances = [];
  public static relations = [];

  public static definitions = [
    new Field({
      name: "id",
      type: FieldType.NUMBER,
      primary: true,
      creatable: false,
      editable: false,
      serializeNew: false,
      serializeEdit: false,
      label: "#",
      renderKey: RENDER_ID,
      provider: SubscriptionPlanPeriodFile.instance,
    }),
    new Field({
      name: "libelle",
      label: "Nom du fichier",
      dataPath: "subscriptionPlanPeriodFile.name",
      provider: SubscriptionPlanPeriodFile.instance,
    }),
    new Field({
      name: "distributed",
      label: "Le fichier est il distribué ?",
      dataPath: "subscriptionPlanPeriodFile.distributed",
      renderKey: RENDER_OUI_NON,
      provider: SubscriptionPlanPeriodFile.instance,
    }),

    new Field({
      name: "createdAt",
      label: "Date de création",
      renderKey: RENDER_DATETIME,
      dataPath: "subscriptionPlanPeriodFile.createdAt",
      provider: SubscriptionPlanPeriodFile.instance,
    }),
    new Field({
      name: "actions",
      label: "Actions",
      dataPath: "id",
      creatable: false,
      editable: false,
      serializeNew: false,
      serializeEdit: false,
      serializeView: false,
      renderKey: RENDER_ACTIONS,
      provider: SubscriptionPlanPeriodFile.instance,
      customData: {
        renderOptions: {
          clsActions: "justify-content-center",
        },
        actions: [
          {
            label: "Détails",
            title: "Afficher les détails",
            action: "detailsActions",
            color: "btn-info",
            icon: "fa fa-eye",
          },

        /*  {
            label: "Supprimer",
            title: "Supprimmer ce type de compte",
            action: "deleteActions",
            color: "btn-danger",
            icon: "fa fa-trash",
          },*/
        ],
      },
    }),
  ];

  toView() {
    return [
      {
        title: false,
        rows: [
          { label: "File ID", value: this.subscriptionPlanPeriodFile?.id },
          { label: "Nom", value: this.subscriptionPlanPeriodFile?.name },
          { label: "Le fichier est il distribué ?", value: this.subscriptionPlanPeriodFile?.distributed ? "OUI" : "NON" },
          { label: "Date de création", value: utils().formatDate("DD/MM/YYYY [à] HH:mm:ss", this.subscriptionPlanPeriodFile?.createdAt) },
        ],
      },
    ];
  }
}

export { SubscriptionPlanPeriodFile };

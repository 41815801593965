enum FieldType {
  STRING,
  NUMBER,
  BOOLEAN,
  ENTITY,
  ARRAY_STRING,
  ARRAY_NUMBER,
  ARRAY_BOOLEAN,
  ARRAY_ENTITY,
}

enum InputType {
  INPUT_TEXT = "text",
  INPUT_NUMBER = "number",
  INPUT_CHECKBOX = "checkbox",
  INPUT_RADIO = "radio",
  INPUT_SELECT = "select",
  INPUT_FILE = "file",
  INPUT_FILES = "files",
  INPUT_DATE = "date",
  INPUT_EMAIL = "email",
  INPUT_DATETIME = "datetime-local",
  INPUT_TIME = "time",
  INPUT_SELECT_MULTIPLE = "select_multiple",
  INPUT_TEXTAREA = "textarea",
  INPUT_RANGE_SINGLE = "range_single",
  INPUT_RANGE_DOUBLE = "range_double",
  INPUT_SWITCH = "switch",
  INPUT_PASSWORD = "password",
  INPUT_PASSWORD_2 = "password_2",
  INPUT_TEL = "tel",
  INPUT_RECAPTCHA = "recaptcha",
  INPUT_DETAILS = "details",
  INPUT_DETAILS_2 = "details_2",
}

export { FieldType, InputType };

import React from "react";

export function InvalidFeedBack(props: any) {
  const msg =
    props?.msg ||
    props?.customData?.validateData?.msg ||
    " Ce champ est requis.";
  const forceValidate = props?.customData?.validateData?.force;
  const customCls = props?.customData?.validateData?.cls || "";

  let cls =
    (props.submitted && !props.field.isValid) || forceValidate ? "d-block" : "";
  cls += " " + customCls;

  return <div className={`invalid-feedback ${cls}`}>{msg}</div>;
}

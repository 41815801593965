import React from "react";
import { utils } from "../../../_helpers";

export function Order({ order }) {
  const isAbonnement = order?.subscriptionPlan?.product ? true : false
  const product = order?.subscriptionPlan?.product || order.product;
  const mt = isAbonnement ? order?.subscriptionPlan?.amount : order.amount * order.qte 
  const devise = isAbonnement ? order?.subscriptionPlan?.devise?.code || "CDF" : "CDF";
  return (
    <div className="details-prodtuct-link d-flex justify-content-between flex-row cursor" style={{cursor: "pointer"}}>
      <div className="prodtuct-left d-flex flex-row justify-content-start">
        <div className="prodtuct-img mr-3">
          <img
            style={{ maxWidth: "100px" }}
            src={`data:image/png;base64,${product?.image}`}
          />
        </div>
        <div className="prodtuct-text flex-column align-items-start">
          <h6 style={{ margin: 0 }}>
            <span className="depot_text">
            {isAbonnement && 'Abonnement'}{!isAbonnement && 'Commande'} N°{order.orderId} : Reférence = {order.reference}
            </span>
          </h6>
          <p style={{ fontSize: "80%", textAlign: "left" }}>
            Nom du produit {!isAbonnement && 'commandé'} : {product?.libelle}
            <br />
            Caractéristiques : {product?.caracteristic}
          </p>
        </div>
      </div>
      <div className="prodtuct-right">
        <div
          className="prodtuct-text flex-column"
          style={{ textAlign: "right", minWidth: "90px" }}
        >
          <span style={{ fontSize: "80%", textAlign: "right" }}>
            Montant total
          </span>
          <p className="text-success" style={{ margin: 0, fontSize: "90%" }}>
            {utils().formatAmount(devise, mt)}
          </p>
        </div>
      </div>
    </div>
  );
}

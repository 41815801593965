import React, { Component } from "react";
import { Link } from "react-router-dom";
import { restClient, utils } from "../../../_helpers";
import { PropsFromRedux } from "../../../_reducers";

class SecondNav extends Component<PropsFromRedux & any, any> {
  private mounted = false;
  constructor(props: any) {
    super(props);
    this.handleRefresh = this.handleRefresh.bind(this);
    this.state = {
      soldes: {},
      loading: true,
    };
  }

  componentDidMount() {
    this.mounted = true;
    window.onclick = function (event: any) {
      if (!event.target.matches(".dropdown-toggle")) {
        var dropdowns = document.getElementsByClassName("dropdown-menu");
        var i;
        for (i = 0; i < dropdowns.length; i++) {
          var openDropdown = dropdowns[i];
          if (openDropdown.classList.contains("show")) {
            openDropdown.classList.remove("show");
          }
        }
      }
    };
    const { user } = this.props.model;
    restClient.get(`account/soldes/${user.accountId}`).then((res) => {
      if (this.mounted) {
        this.setState({
          soldes: res.data.content || {},
          loading: false,
        });
      }
    });
  }

  toggleDropDownProfile(e: any) {
    e.preventDefault();
    const nd = document.getElementById("dropdownProfile");
    if (nd) {
      nd.classList.toggle("show");
    }
  }

  toggleDropDownQuickLink(e: any) {
    e.preventDefault();
    const nd = document.getElementById("dropdownQuickLink");
    if (nd) {
      nd.classList.toggle("show");
    }
  }

  handleRefresh(e: any) {
    e.preventDefault();
    window.location.reload();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    const { model, pageTitle, logout, mainPath } = this.props;
    const { user } = model;
    const { pathname } = window.location;
    const soldeEntrepriseCDF = user.soldeEntreprise || 0;
    const { loading, soldes } = this.state;
    return (
      <div className="navbar-inner">
        <div className="container p-0 d-flex align-items-center justify-content-between">
          <Link className="brand" to={mainPath || "/admin"}>
            {pageTitle}
          </Link>
          <ul className="nav pull-right d-flex align-items-center">
            {user.accountType?.accountTypeId === 4 && !model.isAdmin && (
              <>
                <li className="hover mr-3">
                  <p className="m-0 " style={{ fontSize: ".8rem" }}>
                    solde CDF:{" "}
                    {!loading && (
                      <b className="text-success">
                        {utils().formatAmount("CDF", soldes.cdf)}
                      </b>
                    )}{" "}
                    {loading && "Chargement ..."}
                  </p>
                  <hr style={{ marginTop: ".3rem", marginBottom: ".3rem" }} />
                  <p className="m-0 " style={{ fontSize: ".8rem" }}>
                    solde entreprise CDF:{" "}
                    {!loading && (
                      <b className="text-success">
                        {utils().formatAmount("CDF", soldeEntrepriseCDF)}
                      </b>
                    )}{" "}
                    {loading && "Chargement ..."}
                  </p>
                </li>

                <li className="hover mr-3">
                  <p className="m-0 " style={{ fontSize: ".8rem" }}>
                    solde USD:{" "}
                    {!loading && (
                      <b className="text-success">
                        {utils().formatAmount("USD", soldes.usd)}
                      </b>
                    )}{" "}
                    {loading && "Chargement ..."}
                  </p>
                  <hr style={{ marginTop: ".3rem", marginBottom: ".3rem" }} />
                  <p className="m-0 " style={{ fontSize: ".8rem" }}>
                    solde entreprise USD:{" "}
                    {!loading && (
                      <b className="text-success">
                        {utils().formatAmount(
                          "USD",
                          Number((soldeEntrepriseCDF * soldes.usd) / soldes.cdf)
                        )}
                      </b>
                    )}{" "}
                    {loading && "Chargement ..."}
                  </p>
                </li>
              </>
            )}
            {user.accountType?.accountTypeId !== 4 && !model.isAdmin && (
              <>
                <li className="hover mr-3">
                  <p className="m-0 ">
                    solde CDF:{" "}
                    {!loading && (
                      <b className="text-success">
                        {utils().formatAmount("CDF", soldes.cdf)}
                      </b>
                    )}{" "}
                    {loading && "Chargement ..."}
                  </p>
                </li>
                <li className="hover mr-3">
                  <p className="m-0 ">
                    solde USD:{" "}
                    {!loading && (
                      <b className="text-success">
                        {utils().formatAmount("USD", soldes.usd)}
                      </b>
                    )}{" "}
                    {loading && "Chargement ..."}
                  </p>
                </li>
              </>
            )}
            {!model.isAdmin && (
              <li className="hover">
                <p className="m-0">|</p>
              </li>
            )}
            <li className="hover dropdown d-none">
              <Link
                to="/#"
                className="dropdown-toggle"
                aria-expanded="false"
                onClick={(e) => this.toggleDropDownQuickLink(e)}
              >
                <i className="fa fa-link mr-1" aria-hidden="true"></i>
                Liens rapides
                <b className="caret"></b>
              </Link>
              <ul
                id="dropdownQuickLink"
                className="dropdown-menu dropdown-menu-right"
                x-placement="bottom-end"
                style={{
                  position: "absolute",
                  willChange: "transform",
                  right: 0,
                  left: "auto",
                }}
              >
                <li>
                  <a href="/#">Fair un transfert</a>
                </li>
                <li>
                  <a href="/#">Faire un dépot</a>
                </li>
              </ul>
            </li>
            <li className="hover">
              <Link to={pathname || "/#"} onClick={this.handleRefresh}>
                <i className="fa fa-refresh mr-1" aria-hidden="true"></i>
                Rafraichir
              </Link>
            </li>
            <li className="nav-user dropdown hover">
              <Link
                to="/#"
                className="dropdown-toggle"
                onClick={(e) => this.toggleDropDownProfile(e)}
              >
                <i className="fa fa-user mr-1" aria-hidden="true"></i>
                {user.firstName || user.user?.firstName || "Annonyme"}
                <b className="caret"></b>
              </Link>
              <ul
                id="dropdownProfile"
                className="dropdown-menu dropdown-menu-right"
                style={{ position: "absolute", right: "0px", left: "auto" }}
              >
                <li>
                  <Link to={model.userPath + "/profile"}>Mon Profile</Link>
                </li>

                <li className="dropdown-divider"></li>
                <li>
                  <Link
                    to="/logout"
                    onClick={(e) => {
                      e.preventDefault();
                      logout();
                    }}
                  >
                    Déconnexion
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export { SecondNav };

import { restClient, utils } from "../_helpers";
import { BaseEntity } from "./BaseEntity";
import { InputType } from "./enumerations";
import { Field } from "./Field";

class PassReset extends BaseEntity {
  public static readonly label: any;
  public static readonly className: any;
  public static readonly art: any;
  public static instance: PassReset = new PassReset();
  public static instances = [];
  public static relations = [];

  public static definitions = [
    new Field({
      name: "email",
      label: "",
      formRowIndex: 0,
      formLayout: "col-md-12",
      formColumnIndex: 0,
      provider: PassReset.instance,
      customData: {
        placeholder: "Saisissez votre adresse email",
      },
      
    }),
    new Field({
      name: "validcode",
      label: "",
      creatable: false,
      editable: false,
      onTable: false,
      serializeNew: false,
      serializeEdit: false,
      serializeView: false,
      defaultValue: "",
      formLayout: "col-md-12",
      inlineStyle: {},
      customData: {
        placeholder: "Saisissez le code de validation",
      },
      provider: PassReset.instance,
    }),

    new Field({
      name: "password",
      label: "Nouveau mot de passe",
      formRowIndex: 7,
      formColumnIndex: 0,
      creatable: false,
      editable: false,
      onTable: false,
      serializeNew: false,
      serializeEdit: false,
      serializeView: false,
      formLayout: "col-md-12",
      customClasses: "d-flex flex-column",
      inlineStyle: { padding: "0 1rem 0 0" },
      provider: PassReset.instance,
      inputType: InputType.INPUT_PASSWORD,
      /*customData: {
        checkBoxData: {
          id: "passview",
          cls: "form-check-label",
          customLabel: "Afficher le mot de passe.",
          styles: { fontSize: "80%", marginTop: "3px", display: "none" },
          checked: false,
          eventName: "passview_change",
        },
      },*/
      listeners: [
        {
          type: "passview_change",
          behaviour: "customAction",
          resolve: (v: any, customData?: any) => {
            return {
              checkBoxData: { ...customData?.checkBoxData, checked: v.value },
            };
          },
        },
      ],
    }),
    new Field({
      name: "password2",
      label: "Confirmer mot de passe",
      formRowIndex: 7,
      formColumnIndex: 1,
      creatable: false,
      editable: false,
      onTable: false,
      serializeNew: false,
      serializeEdit: false,
      serializeView: false,
      formLayout: "col-md-12",
      provider: PassReset.instance,
      inputType: InputType.INPUT_PASSWORD_2,
      customData: {
        checkBoxData: {
          id: "passview",
          cls: "form-check-label",
          customLabel: "Afficher le mot de passe.",
          styles: { fontSize: "80%", marginTop: "3px" },
          checked: false,
          eventName: "passview_change",
        },
      },
      validators: [
        /*{
          meta: { key: "password2" },
          validate: (meta: any) => PassReset.instance.assertNotNull(meta),
        },
        {
          meta: {
            key: "password2",
            otherKey: "password",
            msg: "Les mots de passe doivent etre identiques",
          },
          validate: (meta: any) => PassReset.instance.assertEquals(meta),
        },
        {
          meta: {
            key: "password2",
            otherKey: "password",
            msg:
              "Le mot de passe doit avoir au moins 1 lettre majuscule, 1 lettre minuscule, 1 chiffre, 1 caractère spécial et contenir au minimum 08 caractères.",
          },
          validate: (meta: any) => PassReset.instance.assertPasswordStrenght(meta),
        },*/
      ],
      listeners: [
        {
          type: "passview_change",
          behaviour: "customAction",
          resolve: (v: any, customData?: any) => {
            return {
              checkBoxData: { ...customData?.checkBoxData, checked: v.value },
            };
          },
        },
      ],
    }),
  ];

  public static getPhoneValidationField(): Field[] {
    const i = PassReset.definitions.findIndex((f) => f.name === "validcode");
    if (i > -1) {
      return [PassReset.definitions[i]];
    }
    return [];
  }

  public static getPassField(): Field[] {
    return [PassReset.definitions[2],PassReset.definitions[3]];
  }

  processNew(payload: any) {
    console.log(payload);
    return new Promise(async (resolve, reject) => {
      const step = payload.data.step;
      let res: any = {};
      if (step === 1) {
        const res = await restClient.get(encodeURI(`/user/otp/${payload.payload.email}`));
        if (Number(res?.data?.content) > 0) {
          resolve({ success: true, data: Number(res?.data?.content) });
          return;
        }
      }else if (step === 2) {
        const code = this.getValue('validcode', '');
        const res = await restClient.get(`/user/validCode/${code}`);
        if (res?.data?.content) {
          resolve({ success: true, data: res?.data });
          return;
        } else {
          alert("Code invalide !");
          resolve({ success: false, data: {} });
          return;
        }
      }else if (step === 3) {
        const p1 = this.getValue('password', '');
        const p2 = this.getValue('password2', '');
        if (!p1) {
          alert("Veuillez saisir le nouveau mot de passe");
          resolve({ success: false, data: {} });
          return;
        }
        if (!p2) {
          alert("Veuillez confirmer le nouveau mot de passe");
          resolve({ success: false, data: {} });
          return;
        }
        if (p2 !== p1) {
          alert("les mots de passe doivent être identique");
          resolve({ success: false, data: {} });
          return;
        }
        const res = await restClient.put(`/user/password`, {email:payload.payload.email, password: p1});
        if (res?.data?.userId) {
          alert("Mot de passe reinitialisé avec succès !");
          window.location.assign('/login');
          //resolve({ success: true, data: res?.data });
          return;
        } 
      }
      
      utils().emitter.emit("alert_message", {
        content: res?.message || "Erreur lors de la reinitialisation du mot de passe. Merci de reéssayer !",
        cls: "alert-danger",
      });
      resolve({ success: false, data: {} });
    });
  }
}

export { PassReset };

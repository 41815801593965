import React, {Component} from "react";
import {restClient} from "../../../_helpers";

class HierachCompte extends Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            errmsg: "",
            err:false,
            account:this.props.settings.account,
            freeze: false,
            parentFirstName:"",
            parentLastName:""
        };
        this.handleChange = this.handleChange.bind(this);
        console.log("Les props depuis Hierarchiser", props);
        console.log("Les settings de PROPS Hierarchiser", props.settings);
        console.log("Le ACCOUNT DE PROPS Hierarchiser", this.state.account);
    }

    componentDidMount() {
        this.setState({
            account: this.props.settings.account
        });
        console.log("lE COMPTE PERE",this.state.account);
        console.log("lE COMPTE PERE SETTING",this.props.settings.account);
        this.getAccount(this.state.account);
    }

    handleChange(e) {
        e.preventDefault();
        const {name, value} = e.target;
        console.log("Numéro de compte saisi",value);
        this.setState({[name]: value});
        this.getAccount(value);
    }

    getAccount(numero){
        restClient.get(`/account/find/byMsisdn/`+numero).then((res) => {
            if (res?.data?.user) {
                this.setState({
                    parentFirstName:res.data.user.firstName,
                    parentLastName : res.data.user.lastName,
                    freeze:true,
                    err:false
                });
                this.props.stateSetter(
                    { settings: {
                            content: "hierCompte",
                            title: "Définir le parent de ce compte",
                            showSaveBtn:true,
                            action:"hierarchiser",
                            message:"",
                            account:numero,
                            parentAccountId:res.data.accountId
                        } });
                console.log("Le compte ID trouvé",res.data.accountId);
                console.log("Les nouveaux settings",this.props.settings);
            } else {
                this.setState({
                    freeze:false,
                    err:true,
                    errmsg:"Compte parent non trouvé!"
                });
                this.props.stateSetter(
                    { settings: {
                            content: "hierCompte",
                            title: "Définir le parent de ce compte",
                            showSaveBtn:true,
                            action:"hierarchiser",
                            message:"",
                            account:"",
                            parentAccountId:0
                        } });
                console.log("Pas de compte");
            }
        });
    }

    render() {
        return (
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-12'>
                        <p className='text-danger'>
                            {this.props.settings.message}
                        </p>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm-4'>
                        <label className="control-label">Compte parent:</label>
                    </div>
                    <div className="col-sm-8">
                        <input type="text" className="form-control"
                               placeholder="Numéro de compte"
                               onChange={this.handleChange}
                               name={"account"}
                               value={this.state.account}
                        />
                        {this.state.err && (<span style={{fontSize: "80%", font: "bold", color: "red"}}>
                                    {this.state.errmsg}
                            </span>)}
                        {this.state.freeze && (<table className='table'>
                            <thead>
                            <tr>
                                <th>Nom</th>
                                <th>Prenom</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>{this.state.parentFirstName}</td>
                                <td>{this.state.parentLastName}</td>
                            </tr>
                            </tbody>
                        </table>)}
                    </div>
                </div>
            </div>
        );
    }
}

export {HierachCompte};

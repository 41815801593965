const renderKeys = {
  RENDER_ID: "RENDER_ID_KEY",
  RENDER_IMAGE: "RENDER_IMAGE_KEY",
  RENDER_ICON_BTNS: "RENDER_ICON_BTNS_KEY",
  RENDER_DELETE_BTN: "RENDER_DELETE_BTN",
  RENDER_DATETIME: "RENDER_DATETIME_KEY",
  RENDER_DATE: "RENDER_DATE_KEY",
  RENDER_OUI_NON: "RENDER_OUI_NON_KEY",
  RENDER_LONG_TEXT: "RENDER_LONG_TEXT_KEY",
  RENDER_AMOUNT_USD: "RENDER_AMOUNT_USD",
  RENDER_AMOUNT: "RENDER_AMOUNT_KEY",
  RENDER_ENUM: "RENDER_ROLE_KEY",
  RENDER_STATUS: "RENDER_STATUS_KEY",
  RENDER_ACTIONS: "RENDER_ACTIONS_KEY",
  RENDER_WORKFLOWSTEP_WORKFLOW: "RENDER_WORKFLOWSTEP_WORKFLOW",
  RENDER_WORKFLOWSTEP_VALIDATOR: "RENDER_WORKFLOWSTEP_VALIDATOR",
  RENDER_EMETTEUR: "RENDER_EMETTEUR_KEY",
  RENDER_BENEFICIARE: "RENDER_BENEFICIARE_KEY",
  RENDER_ACCOUNT_TYPE: "RENDER_ACCOUNT_TYPE_KEY",
  RENDER_PROFILE: "RENDER_PROFILE_KEY",
  RENDER_STATUS_OPERATION: "RENDER_STATUS_OPERATION_KEY",
  RENDER_OPERATION_TYPE: "RENDER_OPERATION_TYPE_KEY",
  RENDER_AMOUNT_TRANSACTION: "RENDER_AMOUNT_TRANSACTION_KEY",
  RENDER_AMOUNT_PRIX: "RENDER_AMOUNT_PRIX_KEY",
  RENDER_PRICE_ETS: "RENDER_PRICE_ETS",
  RENDER_AMOUNT_TOTAL: "RENDER_AMOUNT_TOTAL_KEY",
  RENDER_AMOUNT_PRODUIT: "RENDER_AMOUNT_PRODUIT_KEY",
  RENDER_CLIENT_TRANSACTION: "RENDER_CLIENT_TRANSACTION_KEY",
  RENDER_CATEGORY_OPERATION_TYPE: "RENDER_CATEGORY_OPERATION_TYPE",
  RENDER_DOUBLE: "RENDER_DOUBLE_TYPE",
  RENDER_AFFECTATION: "RENDER_AFFECTATION_KEY",
  RENDER_OPERATION_TYPE_BULK: "RENDER_OPERATION_TYPE_BULK_KEY",
  RENDER_DEVISE_BULK: "RENDER_DEVISE_BULK_KEY",
  RENDER_CLIENT_ORDER: 'RENDER_CLIENT_ORDER',
  RENDER_OPERATION_CURRENCY: "RENDER_OPERATION_CURRENCY_KEY"
};

const eventKeys = {
  TABLE_DATA_CHANGE: "TABLE_DATA_CHANGE",
  TABLE_DATA_FILTER: "TABLE_DATA_FILTER",
  USER_BALANCE_CHANGE: "USER_BALANCE_CHANGE",
  USER_BALANCES_CHANGE: "USER_BALANCES_CHANGE",
  MODAL_ACTION: "MODAL_ACTION",
  FULL_LOADING_ACTION: "FULL_LOADING_ACTION",
  TABLE_DATA_LOADING: "TABLE_DATA_LOADING",
  TABLE_DATA_ERROR: "TABLE_DATA_ERROR",
  TABLE_TITLE_CHANGE: "TABLE_TITLE_CHANGE",
  SUBMIT_EVENT: 'SUBMIT_EVENT',
  HIDE_FOOTER_BTNS: 'HIDE_FOOTER_BTNS'
  
};

export const keys = {
  renderKeys,
  eventKeys,
};

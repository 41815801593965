import { appConstants } from "../_constants";
import { BaseEntity } from "./BaseEntity";
import { FieldType, InputType } from "./enumerations";
import { Field } from "./Field";
import { BaseModel } from "../_models";
import { Account } from "./Account";
import { utils } from "../_helpers";

const { RENDER_ACTIONS, RENDER_ID, RENDER_STATUS, RENDER_DATE } =
  appConstants.keys.renderKeys;

class Card extends BaseEntity {
  public static readonly addTitle = "Ajouter une carte";
  public static readonly showTitle = "Détails d'une carte";
  public static readonly editTitle = "Modifier une carte";
  public static readonly saveBtnTitle = "Enregistrer";
  public static readonly idField = "id";
  public static readonly processNewSuccessMsg =
    "La carte a été enregistrée avec succès !";
  public static readonly processEditSuccessMsg =
    "La carte a été modifiée avec succès !";
  public static readonly processNewPath = "/account/nfc/add";
  public static readonly processEditPath = "/account/nfc/update/_ID_";
  public static readonly allAccountCardPath = "/account/nfc/getByAccount/";
  public static instance: Card = new Card();
  public static instances = [];
  public static relations = [
    {
      dataPath: "account/all",
      key: "accountId",
      resolve: (res: any) => {
        if (Array.isArray(res?.data)) {
          return res.data.map((v: any) => Account.getSelectAccount2(v));
        }
        return [];
      },
    },
  ];

  public static get definitions(): Field[] {
    const model: any = BaseModel.getInstance();
    const aChildAll = [
      {
        label: "Modifier",
        title: "Modifer cette carte",
        action: "editActions",
        color: "text-success",
        icon: "fa fa-edit",
      },
      {
        actionCallback: (data: any) => {
          if (data.status) {
            return {
              label: "Désactiver",
              title: "Désactiver cette carte",
              action: "switchActionsDeactivate",
              color: "text-danger",
              icon: "fa fa-times",
            };
          }
          return {
            label: "Activer",
            title: "Activer cette carte",
            action: "switchActionsActivate",
            color: "text-success",
            icon: "fa fa-check",
          };
        },
      },
      {
        label: "Supprimer",
        title: "Supprimmer cette carte",
        action: "deleteActions",
        color: "text-danger",
        icon: "fa fa-trash",
      },
    ];
    const aChildMod = [
      {
        label: "Modifier",
        title: "Modifer cette carte",
        action: "editActions",
        color: "text-success",
        icon: "fa fa-edit",
      },
      {
        actionCallback: (data: any) => {
          if (data.status) {
            return {
              label: "Désactiver",
              title: "Désactiver cette carte",
              action: "switchActionsDeactivate",
              color: "text-danger",
              icon: "fa fa-times",
            };
          }
          return {
            label: "Activer",
            title: "Activer cette carte",
            action: "switchActionsActivate",
            color: "text-success",
            icon: "fa fa-check",
          };
        },
      },
    ];
    const aChildDel = [
      {
        label: "Supprimer",
        title: "Supprimmer cette carte",
        action: "deleteActions",
        color: "text-danger",
        icon: "fa fa-trash",
      },
    ];

    const definitions = [
      new Field({
        name: "id",
        type: FieldType.NUMBER,
        primary: true,
        creatable: false,
        editable: false,
        serializeNew: false,
        serializeEdit: false,
        label: "#",
        renderKey: RENDER_ID,
        provider: Card.instance,
      }),
      new Field({
        name: "number",
        label: "Numéro",
        formRowIndex: 1,
        provider: Card.instance,
      }),
      new Field({
        name: "creation",
        label: "Date de création",
        formRowIndex: 2,
        renderKey: RENDER_DATE,
        creatable: false,
        editable: false,
        provider: Card.instance,
      }),
      new Field({
        name: "expiration",
        label: "Date d'expiration",
        formRowIndex: 3,
        renderKey: RENDER_DATE,
        inputType: InputType.INPUT_DATE,
        provider: Card.instance,
      }),
      new Field({
        name: "pin",
        label: "Code PIN",
        formRowIndex: 4,
        onTable: false,
        provider: Card.instance,
      }),
      new Field({
        name: "description",
        label: "Description",
        formRowIndex: 5,
        onTable: false,
        provider: Card.instance,
      }),
      new Field({
        name: "account",
        label: "Compte",
        dataPath: "account.msisdn",
        inputType: InputType.INPUT_SELECT,
        onTable: true,
        required: false,
        relationKey: "accountId",
        formRowIndex: 6,
        provider: Card.instance,
        customData: {
          renderOptions: {
            className: "min-w-150",
          },
          checkBoxData: {
            id: "active",
            cls: "form-check-label",
            customLabel: "Active ?",
            styles: { fontSize: "80%", marginLeft: "19px" },
            checked: true,
            eventName: "hasFees_change",
            customInlineStyle: { marginLeft: "1px", marginTop: "7px" },
            customLayoutInlineStyle: {
              marginTop: "6px",
              padding: 0,
            },
          },
        },
        listeners: [
          {
            type: "hasFees_change",
            behaviour: "customAction",
            resolve: (v: any, customData?: any) => {
              Card.instance.updateValue("active", v?.value);
              return {
                ...customData,
                checkBoxData: { ...customData.checkBoxData, checked: v?.value },
              };
            },
          },
        ],
        serializeCallback: (item: any, v: any) => {
          console.log(item, v);
          console.log("Le item", item);
          const active = Card.instance.getValue("active", true);
          const account = Card.instance.getValue("account", true);
          // item.status = active;
          return { ...item, active, account };
        },
      }),
      new Field({
        name: "status",
        label: "Statut",
        formRowIndex: 7,
        creatable: false,
        renderKey: RENDER_STATUS,
        provider: Card.instance,
      }),
      new Field({
        name: "actions",
        label: "Actions",
        dataPath: "id",
        creatable: false,
        editable: false,
        serializeNew: false,
        serializeEdit: false,
        serializeView: false,
        renderKey: RENDER_ACTIONS,
        provider: Card.instance,
        customData: {
          renderOptions: {
            clsActions: "justify-content-center",
          },
          actions: [
            {
              label: "Détails",
              title: "Afficher les détails",
              action: "detailsActions",
              color: "btn-info",
              icon: "fa fa-eye",
            },
            {
              type: "btnGroup",
              color: "btn-primary",
              children:
                model.user.user?.profile === null
                  ? aChildAll
                  : model.cardCrud.modification && model.cardCrud.suppression
                  ? aChildAll
                  : model.cardCrud.modification
                  ? aChildMod
                  : model.cardCrud.suppression
                  ? aChildDel
                  : [],
            },
          ],
        },
      }),
    ];
    return definitions;
  }

  toView() {
    return [
      {
        title: false,
        rows: [
          { label: "Card ID", value: this.id },
          { label: "Numéro de carte", value: this.number },
          {
            label: "Date de création",
            value: utils().formatDate("DD/MM/YYYY", this.creation),
          },
          {
            label: "Date d'expiration",
            value: utils().formatDate("DD/MM/YYYY", this.expiration),
          },
          {
            label: "Compte propriétaire",
            value:
              "( " +
              this.account.msisdn +
              " ) " +
              this.account.user?.firstName +
              " " +
              this.account.user?.lastName,
          },
          { label: "Code PIN", value: this.pin },
          { label: "Description", value: this.description },
          { label: "Statut", value: this.status ? "Active" : "Inactive" },
        ],
      },
    ];
  }

  getSwitchActionConfig(status: boolean) {
    return {
      path: `/account/nfc/state/${this.id}/${status}`,
      resolve: (res: any) => res?.success,
    };
  }

  getDeleteActionConfig() {
    return {
      path: `/account/nfc/delete/${this.id}`,
      resolve: (res: any) => res?.success,
    };
  }

  setStatus(status: boolean) {
    this.status = status;
  }
}

export { Card };

import React from "react";
import { utils } from "../../../../_helpers";

export function AccountStatus(props: any) {
  const { account } = props;

  return (
    <p
      style={{ fontSize: "90%", fontWeight: 400, margin: 0 }}
      className="profile-subtitle"
    >
      <span style={{ fontFamily: "Ubuntu-RegularItalic" }}>
        {account.user?.email} /{account.msisdn}. Date de création :{" "}
        {utils().formatDate("DD/MM/YYYY  [à] H:mm:ss", account.createDate)}. Le
        compte est
        {account.active && (
          <span style={{ margin: 0, color: "#28a745" }}> Activé</span>
        )}
        {!account.active && (
          <span style={{ margin: 0, color: "#dc3545" }}> Désactivé</span>
        )}
        .
      </span>
    </p>
  );
}

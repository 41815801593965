import { appConstants } from "../_constants";
import { BaseEntity } from "./BaseEntity";
import { FieldType } from "./enumerations";
import { Field } from "./Field";
import { BaseModel } from "../_models";
import { InputType } from ".";

const { RENDER_ACTIONS, RENDER_ID } = appConstants.keys.renderKeys;

class UIItem extends BaseEntity {
  public static readonly addTitle = "Ajouter une personnalisation";
  public static readonly showTitle = "Détails d'une personnalisation";
  public static readonly editTitle = "Modifier une personnalisation";
  public static readonly saveBtnTitle = "Enregistrer";
  public static readonly idField = "id";
  public static readonly processNewSuccessMsg =
    "La personnalisation a été enregistré avec succès !";
  public static readonly processEditSuccessMsg =
    "La personnalisation a été modifié avec succès !";
  public static readonly processNewPath = "/uiitem/add";
  public static readonly processEditPath = "uiitem/update/_ID_";
  public static instance: UIItem = new UIItem();
  public static instances = [];
  public static relations = [];

  public static get definitions(): Field[] {
    const model: any = BaseModel.getInstance();
    const aChildAll = [
      {
        label: "Modifier",
        title: "Modifer cette personnalisation",
        action: "editActions",
        color: "text-success",
        icon: "fa fa-edit",
      },
      {
        label: "Supprimer",
        title: "Supprimmer cette personnalisation",
        action: "deleteActions",
        color: "text-danger",
        icon: "fa fa-trash",
      },
    ];
    const aChildMod = [
      {
        label: "Modifier",
        title: "Modifer cette personnalisation",
        action: "editActions",
        color: "text-success",
        icon: "fa fa-edit",
      },
    ];
    const aChildDel = [
      {
        label: "Supprimer",
        title: "Supprimmer cette personnalisation",
        action: "deleteActions",
        color: "text-danger",
        icon: "fa fa-trash",
      },
    ];
    const definitions = [
      new Field({
        name: "id",
        type: FieldType.NUMBER,
        primary: true,
        creatable: false,
        editable: false,
        serializeNew: false,
        serializeEdit: false,
        label: "#",
        renderKey: RENDER_ID,
        provider: UIItem.instance,
      }),
      new Field({
        name: "name",
        label: "Nom",
        provider: UIItem.instance,
      }),
      new Field({
        name: "description",
        label: "Description",
        inputType: InputType.INPUT_TEXTAREA,
        provider: UIItem.instance,
      }),
      new Field({
        name: "type",
        label: "Type",
        inputType: InputType.INPUT_SELECT,
        provider: UIItem.instance,
        relationKey: "types",
      }),
      new Field({
        name: "actions",
        label: "Actions",
        dataPath: "id",
        creatable: false,
        editable: false,
        serializeNew: false,
        serializeEdit: false,
        serializeView: false,
        renderKey: RENDER_ACTIONS,
        provider: UIItem.instance,
        customData: {
          renderOptions: {
            clsActions: "justify-content-center",
          },
          actions: [
            {
              label: "Détails",
              title: "Afficher les détails",
              action: "detailsActions",
              color: "btn-info",
              icon: "fa fa-eye",
            },
            {
              type: "btnGroup",
              color: "btn-primary",
              children:
                model.user.user?.profile === null
                  ? aChildAll
                  : model.confCrud.modification && model.confCrud.suppression
                  ? aChildAll
                  : model.confCrud.modification
                  ? aChildMod
                  : model.confCrud.suppression
                  ? aChildDel
                  : [],
            },
          ],
        },
      }),
    ];
    return definitions;
  }

  initNewCallBack() {
    this.updateValue("types", [
      { value: "Texte", label: "Texte" },
      { value: "Nombre", label: "Nombre" },
    ]);
  }

  toView() {
    return [
      {
        title: false,
        rows: [
          { label: "ID", value: this.id },
          { label: "Nom", value: this.name },
          { label: "type", value: this.type },
          { label: "Description", value: this.description },
        ],
      },
    ];
  }

  getDeleteActionConfig() {
    return {
      path: `/uiitem/delete/${this.id}`,
      resolve: (res: any) => res?.success,
    };
  }
}

export { UIItem };

import React, { Component } from "react";
import { restClient, utils } from "../../../_helpers";
import { InputTextAPG } from "./InputTextAPG";
import { InputSelect } from "./InputSelect";
import { TextFieldAPG } from "./TextFieldAPG";
import { Transaction } from "../../../_entities/Transaction_old";
import { appConstants } from "../../../_constants";
import { Details } from "../Details/Details";

const { MODAL_ACTION } = appConstants.keys.eventKeys;

class EMpata2WalletForm extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      freeze:
        typeof this.props.freeze !== "undefined" ? this.props.freeze : true,

      cashtowallet: false,
      cashtobank: true,
      cashtocash: false,
      accountLabel: "Numéro du RIB*",
      operationType: 25,
      placeholder: "Numéro du compte bancaire",
      err: false,
      errmsg: "",
      finish: false,
      success: false,
      nom: "",
      prenom: "",
      id: 0,
      currency: "CDF",
      channel: "CshAPG02",
      country: "",
      swift: "",
      account: "",

      totalMontantDollar: "",
      tel: "",

      fraisinclus: false,
      feesCDF: 0.0,
      feesUSD: 0.0,
      bankName: "",
      soinc: "",
      motif: "",
      countriesLoading: true,
      currencies: [
        { id: "CDF", name: "Franc congolais", taux: 1500 },
        { id: "USD", name: "Dollar US", taux: 1 },
      ],
      channels: [],
      countries: [],
      /*countries: [
        { id: "BJ", name: "Bénin" },
        { id: "GW", name: "Guinée Bissau" },
        { id: "BF", name: "Burkina Faso" },
        { id: "CM", name: "Cameroun" },
        { id: "CI", name: "Côte d'Ivoire" },
        { id: "CD", name: "République Démocratique du Congo" },
        { id: "CG", name: "Congo" },
        { id: "GQ", name: "Guinée Équatoriale" },
        { id: "GA", name: "Gabon" },
        { id: "GM", name: "Gambie" },
        { id: "GN", name: "Guinée" },
        { id: "ML", name: "Mali" },
        { id: "NE", name: "Niger" },
        { id: "SN", name: "Sénégal" },
        { id: "SL", name: "Sierra Léone" },
        { id: "TD", name: "Tchad" },
        { id: "TG", name: "Togo" },
      ],*/
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeAmount = this.handleChangeAmount.bind(this);
    this.handleChangeType = this.handleChangeType.bind(this);
    this.handleChangeCurr = this.handleChangeCurr.bind(this);
  }

  componentDidMount() {
    let obj = this;

    $(document).on("shown.bs.modal", "#empata2walletModal", function (e) {
      restClient.get(`/currency/all`).then((res) => {
        if (res.data && Array.isArray(res.data)) {
          obj.setState({
            currencies: res.data.map((d) => ({ ...d, id: d.code })),
            freeze: false,
          });
        } else {
          obj.setState({ freeze: false });
        }
      });
    });

    $(document).on("hidden.bs.modal", "#empata2walletModal", function () {
      console.log(obj.props);

      if (obj.state.success) {
        window.location.reload();
        return;
      }
      obj.setState(
        {
          freeze: true,
          err: false,
          errmsg: "",
          finish: false,
          success: false,
          nom: "",
          id: 0,
          account: "",
          totalMontantDollar: "",
          tel: "",
        },
        () => {
          $("#recapitulatif-group").empty();
          $("#error-group").empty();
          $("#apg-form").slideDown();
          $("#recapitulatif-group").slideUp();
          $("#accountType").parent().find(".invalid-feedback").hide();
          $("#apgMin").parent().find(".invalid-feedback").hide();
          $("#apgMax").parent().find(".invalid-feedback").hide();
          $("#apg").parent().find(".invalid-feedback").hide();
          $("#empata2walletModal").attr("data-action", "");
          $("#empata2walletModal").attr("data-id", "");
        }
      );
    });

    restClient.get(`country/all`).then((res) => {
      if (Array.isArray(res?.data)) {
        this.setState({
          countries: res?.data.map((d: any) => ({ id: d.code, name: d.nomFr })),
        });
      }
    });
  }

  handleChange(e) {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleChangeCurr(e) {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({ [name]: value });
    // @ts-ignore
    this.getFees(this.state.amount, this.state.operationType, value);
  }

  handleChangeAmount(e) {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({ [name]: value });
    // @ts-ignore
    this.getFees(value, this.state.operationType, this.state.currency);
  }

  handleChangeType(e) {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({ [name]: value });
    // this.getApgCountriesByChannel(value);
    if (value == "CshAPG01") {
      this.setState({
        cashtowallet: true,
        cashtobank: false,
        cashtocash: false,
        accountLabel: "Numéro du Wallet*",
        operationType: 17,
        placeholder: "Numéro du Wallet ID",
      });
      // @ts-ignore
      this.getFees(this.state.amount, 17, this.state.currency);
    } else {
      if (value == "CshAPG02") {
        this.setState({
          cashtowallet: false,
          cashtobank: true,
          cashtocash: false,
          accountLabel: "Numéro du RIB*",
          operationType: 18,
          placeholder: "Numéro du compte bancaire",
        });
        // @ts-ignore
        this.getFees(this.state.amount, 18, this.state.currency);
      } else {
        this.setState({
          cashtowallet: false,
          cashtobank: false,
          cashtocash: true,
          operationType: 19,
        });
        // @ts-ignore
        this.getFees(this.state.amount, 19, this.state.currency);
      }
    }
  }

  handleClick(e) {
    e.preventDefault();
    this.setState({
      freeze: true,
      err: false,
      errmsg: "",
    });
    // @ts-ignore
    const {
      currency,
      amount,
      bankName,
      soinc,
      motif,
      account,
      swift,
      country,
      nom,
      prenom,
      tel,
    } = this.state;
    const { protocol, hostname, port } = window.location;
    const basePath = `${protocol}//${hostname}:${port}`;
    let payload = {
      amount: amount,
      currency: currency,
      toCountry: country,
      toWalletId: account,
      beneficiaryFirstName: nom,
      beneficiaryLastName: prenom,
      beneficiaryPhone: tel,
      toBankAccountNumber: account,
      toBankSwift: swift,
      senderAccount: this.getBen(),
      toBankName: bankName,
      sourceOfIncome: soinc,
      purposeDetails: motif,
    };
    if (typeof country === "undefined" || country.trim().length <= 0) {
      this.setState({
        freeze: false,
        err: true,
        errmsg: "Veuillez sélectionner un pays!",
        finish: false,
      });
      return;
    }
    if (typeof amount === "undefined" || parseFloat(amount) <= 0) {
      this.setState({
        freeze: false,
        err: true,
        errmsg: "Veuillez indiquer un montant valide!",
        finish: false,
      });
      return;
    }

    if (this.state.channel == "CshAPG01") {
      if (typeof account === "undefined" || account.trim().length <= 0) {
        this.setState({
          freeze: false,
          err: true,
          errmsg: "Veuillez renseigner le Wallet ID!",
          finish: false,
        });
        return;
      }
      restClient.post(`/operation/cashtowallet`, payload).then((res) => {
        console.log(res);
        if (res?.data?.code == "0000") {
          this.setState({
            data: res.data,
            freeze: false,
            finish: true,
          });
          // @ts-ignore
          window.location = `${basePath}/dashboard`;
        } else {
          this.setState({
            freeze: false,
            err: true,
            errmsg: decodeURIComponent(escape(res.data.message)),
            finish: false,
          });
        }
      });
    } else {
      // @ts-ignore
      if (this.state.channel == "CshAPG02") {
        if (typeof nom === "undefined" || nom.trim().length <= 0) {
          this.setState({
            freeze: false,
            err: true,
            errmsg: "Veuillez renseigner le nom du bénéficiaire!",
            finish: false,
          });
          return;
        }
        if (typeof prenom === "undefined" || prenom.trim().length <= 0) {
          this.setState({
            freeze: false,
            err: true,
            errmsg: "Veuillez renseigner le prénom du bénéficiaire!",
            finish: false,
          });
          return;
        }
        if (typeof swift === "undefined" || swift.trim().length <= 0) {
          this.setState({
            freeze: false,
            err: true,
            errmsg: "Veuillez renseigner le code swift!",
            finish: false,
          });
          return;
        }
        if (typeof account === "undefined" || account.trim().length <= 0) {
          this.setState({
            freeze: false,
            err: true,
            errmsg: "Veuillez renseigner le RIB!",
            finish: false,
          });
          return;
        }
        if (typeof account === "undefined" || account.trim().length <= 0) {
          this.setState({
            freeze: false,
            err: true,
            errmsg: "Veuillez renseigner le RIB!",
            finish: false,
          });
          return;
        }
        if (typeof bankName === "undefined" || bankName.trim().length <= 0) {
          this.setState({
            freeze: false,
            err: true,
            errmsg: "Veuillez renseigner le nom de la bank!",
            finish: false,
          });
          return;
        }
        if (typeof soinc === "undefined" || soinc.trim().length <= 0) {
          this.setState({
            freeze: false,
            err: true,
            errmsg: "Veuillez renseigner l'origine des fonds!",
            finish: false,
          });
          return;
        }
        if (typeof tel === "undefined" || tel.trim().length <= 0) {
          this.setState({
            freeze: false,
            err: true,
            errmsg:
              "Veuillez renseigner le numéro de téléphone du bénéficiaire!",
            finish: false,
          });
          return;
        }
        if (typeof motif === "undefined" || motif.trim().length <= 0) {
          this.setState({
            freeze: false,
            err: true,
            errmsg: "Veuillez renseigner la raison du tranfert!",
            finish: false,
          });
          return;
        }
        console.log(payload);
        const p = {
          amount: payload.amount,
          codeSwift: payload.toBankSwift,
          currency: payload.currency,
          date: new Date().toJSON(),
          feesIn: false,
          motif: payload.purposeDetails,
          nomBanque: payload.toBankName,
          nomBenef: payload.beneficiaryFirstName,
          operationType: 25,
          origineFonds: payload.sourceOfIncome,
          pays: payload.toCountry,
          prenomBenef: payload.beneficiaryLastName,
          rib: payload.toBankAccountNumber,
          sender: payload.senderAccount,
          telephone: payload.beneficiaryPhone,
        };
        restClient.post(`/operation/empatatobank`, p).then((res) => {
          if (Array.isArray(res?.data)) {
            const transaction = new Transaction();
            Object.assign(transaction, res.data[0]);
            this.setState({
              data: transaction,
              freeze: false,
              finish: true,
            });
            this.props.model?.refreshDataTableData(this.props);
            Transaction.instance.refreshBalances();
            // @ts-ignore
            /*window.location = `${basePath}/dashboard`;*/
            //($("#empata2walletModal") as any).modal("hide");
            //utils().emitter.emit(MODAL_ACTION, { btn: "detailsActions", data: transaction });
          } else {
            this.setState({
              freeze: false,
              err: true,
              errmsg:
                res?.data?.message ||
                "Une erreur est survenu lors de l'opération. Merci de reéssayer !",
              finish: false,
            });
          }
        });
      } else {
        if (typeof nom === "undefined" || nom.trim().length <= 0) {
          this.setState({
            freeze: false,
            err: true,
            errmsg: "Veuillez renseigner le nom du bénéficiaire!",
            finish: false,
          });
          return;
        }
        if (typeof prenom === "undefined" || prenom.trim().length <= 0) {
          this.setState({
            freeze: false,
            err: true,
            errmsg: "Veuillez renseigner le prénom du bénéficiaire!",
            finish: false,
          });
          return;
        }
        if (typeof tel === "undefined" || tel.trim().length <= 0) {
          this.setState({
            freeze: false,
            err: true,
            errmsg:
              "Veuillez renseigner le numéro de téléphone du bénéficiaire!",
            finish: false,
          });
          return;
        }
        restClient.post(`/operation/cashtocash`, payload).then((res) => {
          console.log(res);
          if (res?.data?.code == "0000") {
            this.setState({
              data: res.data,
              freeze: false,
              finish: true,
            });
            // @ts-ignore
            window.location = `${basePath}/dashboard`;
          } else {
            this.setState({
              freeze: false,
              err: true,
              errmsg: decodeURIComponent(escape(res.data.message)),
              finish: false,
            });
          }
        });
      }
    }
  }

  getBen() {
    if (this.props.user?.msisdn) {
      return this.props.user?.msisdn;
    }
    if (this.props.model?.user?.msisdn) {
      return this.props.model?.user?.msisdn;
    }
    let msisdn = $("#title").data("msisdn");
    if (msisdn) {
      return msisdn;
    }
    return false;
  }

  render() {
    const {
      success,
      freeze,
      finish,
      currency,
      currencies,
      amount,
      account,
      err,
      nom,
      swift,
      errmsg,
      tel,
      accountLabel,
      fraisinclus,
      feesUSD,
      bankName,
      soinc,
      motif,
      placeholder,
    } = this.state;

    return (
      <div
        className="modal fade"
        id="empata2walletModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="empata2walletModalLabel"
        aria-hidden="true"
      >
        <div
          id="empata2walletModal"
          className="modal-dialog"
          role="document"
          style={{ maxWidth: "600px" }}
        >
          <div className="modal-content" style={{ padding: "30px" }}>
            <div className="modal-header d-flex flex-row justify-content-between">
              <h5 className="modal-title" id="empata2walletModalLabel">
                Transfert eMpata to Bank
              </h5>
              {err && (
                <span style={{ fontSize: "80%", font: "bold", color: "red" }}>
                  {errmsg}
                </span>
              )}
              {freeze && (
                <div className="loader">
                  <div className="row align-items-center">
                    <img src="/public/build/images/loading.gif" alt="" />
                    <p className="loading-message" style={{ margin: 0 }}>
                      Veuiller patienter
                    </p>
                  </div>
                </div>
              )}
            </div>
            <div
              id="create-modal-body"
              className="modal-body"
              style={{ padding: "20px 0 0" }}
            >
              <div className="" id="recapitulatif-group"></div>
              <div className="" id="error-group" data-shown="0">
                {success && (
                  <div
                    className="alert alert-success alert-dismissible fade show"
                    role="alert"
                  >
                    <span id="ajaxSuccess" style={{ fontSize: "80%" }}>
                      Transfert reussi
                    </span>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                )}
              </div>
              {finish && <Details {...this.state} />}
              <form
                id="empata2Wallet-form"
                className="ui error form auth-form"
                style={{ fontSize: "80%", display: finish ? "none" : "" }}
              >
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <div style={{ display: "none" }}>
                    <InputSelect
                      onChange={this.handleChangeType}
                      value={this.state.channel}
                      label={"Type de transfert*"}
                      name={"channel"}
                      freeze={freeze}
                      items={this.state.channels}
                    />
                  </div>

                  <InputSelect
                    onChange={this.handleChange}
                    value={this.state.country}
                    label={"Pays*"}
                    name={"country"}
                    freeze={freeze}
                    items={this.state.countries}
                  />
                  <InputSelect
                    onChange={this.handleChangeCurr}
                    value={currency}
                    label={"Devise*"}
                    name={"currency"}
                    freeze={freeze}
                    items={currencies}
                  />
                  <InputTextAPG
                    onChange={this.handleChangeAmount}
                    value={amount}
                    fraisinclus={fraisinclus}
                    onFraisinclusChange={(v) =>
                      this.setState({ fraisinclus: v })
                    }
                    label={"Montant*"}
                    name={"amount"}
                    freeze={freeze}
                    type={"number"}
                    {...this.getUtils()}
                  />
                  {/*<div className='col-md-4 d-flex flex-column'>*/}
                  <span
                    style={{
                      fontSize: "13px",
                      font: "bold",
                      color: "red",
                      paddingBottom: "10px",
                    }}
                  >
                    Frais : {this.state.feesCDF?.toFixed(2)} CDF ||{" "}
                    {feesUSD?.toFixed(2)} $
                  </span>
                  {/*</div>*/}
                  {(this.state.cashtobank || this.state.cashtocash) && (
                    <InputTextAPG
                      onChange={this.handleChange}
                      value={nom}
                      label={"Nom*"}
                      name={"nom"}
                      freeze={freeze}
                      placeholder={"Nom du bénéficiaire"}
                      type={"text"}
                    />
                  )}
                  {(this.state.cashtobank || this.state.cashtocash) && (
                    <InputTextAPG
                      onChange={this.handleChange}
                      value={this.state.prenom}
                      label={"Prénom*"}
                      name={"prenom"}
                      placeholder={"Prénom du bénéficiaire"}
                      freeze={freeze}
                      type={"text"}
                    />
                  )}
                  {this.state.cashtobank && (
                    <InputTextAPG
                      onChange={this.handleChange}
                      value={swift}
                      label={"Code Swift*"}
                      name={"swift"}
                      placeholder={"Code Swift de la banque"}
                      freeze={freeze}
                      type={"text"}
                    />
                  )}
                  {(this.state.cashtobank || this.state.cashtowallet) && (
                    <InputTextAPG
                      onChange={this.handleChange}
                      value={account}
                      label={accountLabel}
                      name={"account"}
                      placeholder={placeholder}
                      freeze={freeze}
                      type={"text"}
                    />
                  )}
                  {this.state.cashtobank && (
                    <InputTextAPG
                      onChange={this.handleChange}
                      value={bankName}
                      label={"Nom de la banque*"}
                      name={"bankName"}
                      placeholder={"Nom de la banque réceptrice"}
                      freeze={freeze}
                      type={"text"}
                    />
                  )}
                  {this.state.cashtobank && (
                    <InputTextAPG
                      onChange={this.handleChange}
                      value={soinc}
                      label={"Origine des fonds*"}
                      name={"soinc"}
                      placeholder={"D'où proviennent les fonds?"}
                      freeze={freeze}
                      type={"text"}
                    />
                  )}
                  {(this.state.cashtobank || this.state.cashtocash) && (
                    <InputTextAPG
                      onChange={this.handleChange}
                      value={tel}
                      label={"Téléphone*"}
                      name={"tel"}
                      freeze={freeze}
                      placeholder={"Numéro de téléphone du bénéficiaire"}
                      type={"text"}
                    />
                  )}
                  {this.state.cashtobank && (
                    <TextFieldAPG
                      onChange={this.handleChange}
                      value={motif}
                      label={"Motif de transfert*"}
                      placeholder={"Raison du virement"}
                      name={"motif"}
                      freeze={freeze}
                      type={"text"}
                    />
                  )}
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                id="cancel-bon-btn"
                type="button"
                className="btn btn-danger"
                disabled={freeze}
                data-dismiss="modal"
                style={{ fontSize: "90%" }}
              >
                {finish && "Fermer"}
                {!finish && "Annuler"}
              </button>

              {!finish && (
                <button
                  id="create-btn"
                  type="button"
                  onClick={this.handleClick}
                  className="btn btn-primary"
                  disabled={freeze}
                  style={{ fontSize: "90%" }}
                >
                  Envoyer
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  getFraisCDF() {
    const { currency, amount, currencies } = this.state;
    const value = this.removeSeparator(amount);
    if ((currency !== "CDF" && currency !== "USD") || value === 0) {
      return 0;
    }
    if (currency === "CDF") {
      return (value * 3) / 100;
    }
    if (currency === "USD") {
      return ((value * 3) / 100) * this.getTaux("CDF");
    }
  }

  getTaux(val) {
    const { currencies } = this.state;

    const i = currencies.findIndex((c) => c.id === val);
    console.log(i);
    if (i > -1) {
      return currencies[i].taux;
    }
    return 1;
  }

  getApgCountriesByChannel(channel) {
    console.log("Obtention des pays APG du channel", channel);
    restClient.post(`/country/apg/` + channel, {}).then((res) => {
      console.log("Le data (res) des pays APG", res);
      if (res?.data?.code == "0") {
        let countries = [];
        res.data.country.map((count, i) => {
          let country = {
            id: count.countryIsoCode,
            name: decodeURIComponent(escape(count.name)),
          };
          // @ts-ignore
          countries.push(country);
        });
        this.setState({
          countries: countries,
        });
        console.log(
          "Les pays APG obtenus dans res.data.countries",
          res.data.country
        );
        console.log("Les pays APG mis dans countries", countries);
      } else {
        this.setState({
          err: true,
          errmsg: decodeURIComponent(escape(res.data.message)),
        });
        console.log(
          "Pas de pays obtenu car code sûrement different de 0. Code actuel:",
          res.data.code
        );
      }
    });
  }

  getTotalNetCDF() {
    const { currency, fraisinclus, amount } = this.state;
    const value = this.removeSeparator(amount);

    if ((currency !== "CDF" && currency !== "USD") || value === 0) {
      return 0;
    }
    let frais = this.getFraisCDF();

    if (fraisinclus) {
      if (currency == "CDF") {
        // @ts-ignore
        return value - frais;
      }
      if (currency == "USD") {
        // @ts-ignore
        return value * this.getTaux("CDF") - frais;
      }
    } else {
      if (currency == "CDF") {
        return value;
      }
      if (currency == "USD") {
        return value * this.getTaux("CDF");
      }
    }
  }

  getTotalTTCCDF() {
    const { currency, fraisinclus, amount } = this.state;
    const value = this.removeSeparator(amount);

    if ((currency !== "CDF" && currency !== "USD") || value === 0) {
      return 0;
    }
    let frais = this.getFraisCDF();
    if (fraisinclus) {
      if (currency === "CDF") {
        return value;
      }
      if (currency === "USD") {
        return value * this.getTaux("CDF");
      }
    } else {
      if (currency === "CDF") {
        // @ts-ignore
        return value + frais;
      }
      if (currency === "USD") {
        // @ts-ignore
        return value * this.getTaux("CDF") + frais;
      }
    }
  }

  getFraisDollar() {
    // @ts-ignore
    const { currency, amount } = this.state;
    const value = this.removeSeparator(amount);
    if ((currency !== "CDF" && currency !== "USD") || value === 0) {
      return 0;
    }
    if (currency === "CDF") {
      return (value * 3) / 100 / this.getTaux("CDF");
    }
    if (currency === "USD") {
      return (value * 3) / 100;
    }
  }

  getTotalNetDollar() {
    // @ts-ignore
    const { currency, fraisinclus, amount } = this.state;
    const value = this.removeSeparator(amount);
    if ((currency !== "CDF" && currency !== "USD") || value === 0) {
      return 0;
    }
    const taux = this.getTaux("CDF");
    const frais = this.getFraisDollar();
    if (fraisinclus) {
      if (currency == "CDF") {
        // @ts-ignore
        return value / taux - frais;
      }
      if (currency == "USD") {
        // @ts-ignore
        return value - frais;
      }
    } else {
      if (currency == "CDF") {
        return value / taux;
      }
      if (currency == "USD") {
        return value;
      }
    }
  }

  getTotalTTCDollar() {
    // @ts-ignore
    const { currency, fraisinclus, amount } = this.state;
    const value = this.removeSeparator(amount);
    if ((currency !== "CDF" && currency !== "USD") || value === 0) {
      return 0;
    }
    const taux = this.getTaux("CDF");
    const frais = this.getFraisDollar();
    if (fraisinclus) {
      if (currency == "CDF") {
        return value / taux;
      }
      if (currency == "USD") {
        return value;
      }
    } else {
      if (currency == "CDF") {
        // @ts-ignore
        return value / taux + frais;
      }
      if (currency == "USD") {
        // @ts-ignore
        return value + frais;
      }
    }
  }

  applySeparator(mt) {
    try {
      let mtt = parseFloat(mt);
      return mtt
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    } catch (error) {}
    return mt;
  }

  removeSeparator(value) {
    if (value) {
      try {
        return parseInt(value.replace(/\s/g, ""));
      } catch (error) {}
    }
    return 0;
  }

  getFees(amount, optype, currency) {
    let payload = {
      operationType: optype,
      currency: currency,
      amount: amount,
      sender: this.getBen(),
    };
    restClient.post(`/operation/fees`, payload).then((res) => {
      if (res?.data) {
        console.log("le data des frais", res);
        this.setState({
          feesCDF: res.data.feesInCDF,
          feesUSD: res.data.feesInUSD,
        });
      } else {
        this.setState({
          feesCDF: 0.0,
          feesUSD: 0.0,
        });
      }
    });
  }

  getUtils() {
    return {
      removeSeparator: (val) => this.removeSeparator(val),
      applySeparator: (val) => this.applySeparator(val),
      getTotalTTCDollar: () => this.getTotalTTCDollar(),
      getTotalNetDollar: () => this.getTotalNetDollar(),
      getFraisDollar: () => this.getFraisDollar(),
      getTotalTTCCDF: () => this.getTotalTTCCDF(),
      getTotalNetCDF: () => this.getTotalNetCDF(),
      getFraisCDF: () => this.getFraisCDF(),
    };
  }
}

export { EMpata2WalletForm };

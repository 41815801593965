import { appConstants } from "../_constants";
import { history } from "../_helpers";
import { BaseModel } from "../_models";

export const appActions = {
  replaceModel,
  logout,
  refreshUser,
};

function replace(model?: BaseModel) {
  if (model) {
    return {
      type: appConstants.REPLACE_MODEL_REQUEST,
      model: model.clone(),
    };
  } else {
    return {
      type: appConstants.REPLACE_MODEL_REQUEST,
      model: BaseModel.getInstance().clone(),
    };
  }
}

function replaceModel(model?: BaseModel) {
  return async function (dispatch: any) {
    dispatch(replace(model));
  };
}

function refreshUser(user?: any) {
  return async function (dispatch: any) {
    const model = BaseModel.getInstance();
    model.fullLoading = true;
    dispatch(replace(model));
    const res = await model.initUser(user);
    console.log("Le model pour setUserRessources", model);
    await setUserRessources(model);
    if (res) {
      dispatch(replace(model));
    }
  };
}

async function setUserRessources(model: any) {
  console.log("MODELLLLLLLL", model);
  if (model.user.user?.profile?.code) {
    //Ressources sur gestion des comptes
    let resAc: any = await model.getResCrud(model.user.user, 1);
    if (resAc?.data[0]) {
      model.accCrud = {
        ecriture: resAc.data[0].ecriture,
        modification: resAc.data[0].modification,
        suppression: resAc.data[0].suppression,
        lecture: resAc.data[0].lecture,
      };
    }
    //Ressources sur gestion des transactions
    let resTx: any = await model.getResCrud(model.user.user, 14);
    if (resTx?.data[0]) {
      model.txCrud = {
        ecriture: resTx.data[0].ecriture,
        modification: resTx.data[0].modification,
        suppression: resTx.data[0].suppression,
        lecture: resTx.data[0].lecture,
      };
    }
    console.log("Transaction Crud", model.txCrud);
    //Ressources sur gestion des configurations
    let resConf: any = await model.getResCrud(model.user.user, 25);
    if (resConf?.data[0]) {
      model.confCrud = {
        ecriture: resConf.data[0].ecriture,
        modification: resConf.data[0].modification,
        suppression: resConf.data[0].suppression,
        lecture: resConf.data[0].lecture,
      };
    }
    console.log("Config Crud", model.confCrud);
    //Ressources sur gestion des produits et services
    let resProd: any = await model.getResCrud(model.user.user, 60);
    if (resProd?.data[0]) {
      model.prodCrud = {
        lecture: resProd.data[0].lecture,
        ecriture: resProd.data[0].ecriture,
        modification: resProd.data[0].modification,
        suppression: resProd.data[0].suppression,
      };
    }

    console.log("Workflow Crud", model.wflCrud);
    //Ressources sur gestion des produits et services
    let resWfl: any = await model.getResCrud(model.user.user, 20);
    if (resWfl?.data[0]) {
      model.prodCrud = {
        lecture: resWfl.data[0].lecture,
        ecriture: resWfl.data[0].ecriture,
        modification: resWfl.data[0].modification,
        suppression: resWfl.data[0].suppression,
      };
    }

    console.log("Product Crud", model.prodCrud);
    //Ressources sur gestion des commandes
    let resCom: any = await model.getResCrud(model.user.user, 61);
    if (resCom?.data[0]) {
      model.comCrud = {
        ecriture: resCom.data[0].ecriture,
        modification: resCom.data[0].modification,
        suppression: resCom.data[0].suppression,
        lecture: resCom.data[0].lecture,
      };
    }
    //Ressources sur gestion des utilisateurs
    let resUser: any = await model.getResCrud(model.user.user, 62);
    if (resUser?.data[0]) {
      model.userCrud = {
        ecriture: resUser.data[0].ecriture,
        modification: resUser.data[0].modification,
        suppression: resUser.data[0].suppression,
        lecture: resUser.data[0].lecture,
      };
    }
    //Ressources sur gestion de l'internationalisation
    let resI18: any = await model.getResCrud(model.user.user, 41);
    if (resI18?.data[0]) {
      model.i18Crud = {
        ecriture: resI18.data[0].ecriture,
        modification: resI18.data[0].modification,
        suppression: resI18.data[0].suppression,
        lecture: resI18.data[0].lecture,
      };
    }
    console.log("i18 Crud", model.i18Crud);
    //Ressources sur gestion des rôles des utilisateurs
    let ResUserR: any = await model.getResCrud(model.user.user, 46);
    if (ResUserR?.data[0]) {
      model.profCrud = {
        ecriture: ResUserR.data[0].ecriture,
        modification: ResUserR.data[0].modification,
        suppression: ResUserR.data[0].suppression,
        lecture: ResUserR.data[0].lecture,
      };
    }
    //Ressources sur gestion des cartes nfc
    let ResCard: any = await model.getResCrud(model.user.user, 15);
    if (ResCard?.data[0]) {
      model.cardCrud = {
        ecriture: ResCard.data[0].ecriture,
        modification: ResCard.data[0].modification,
        suppression: ResCard.data[0].suppression,
        lecture: ResCard.data[0].lecture,
      };
    }
  }

}

function logout(path?: string) {
  return async function (dispatch: any) {
    const model = BaseModel.getInstance();
    dispatch(replace(model));
    const res = await model.logout();
    if (res) {
      const mdl = BaseModel.reset();
      mdl.fullLoading = false;
      dispatch(replace(mdl));
      history.push(path || "/");
    }
  };
}

import { restClient, utils } from "../_helpers";
import { BaseEntity } from "./BaseEntity";
import { InputType } from "./enumerations";
import { Field } from "./Field";

class Auth extends BaseEntity {
  public static readonly label: any;
  public static readonly className: any;
  public static readonly art: any;
  public static instance: Auth = new Auth();
  public static instances = [];
  public static relations = [];

  public static definitions = [
    new Field({
      name: "email",
      label: "Email",
      formRowIndex: 0,
      formLayout: "col-md-12",
      formColumnIndex: 0,
      provider: Auth.instance,
      inputType: InputType.INPUT_EMAIL,
    }),

    new Field({
      name: "password",
      label: "Mot de passe",
      formRowIndex: 1,
      formColumnIndex: 0,
      formLayout: "col-md-12",
      onTable: false,
      customClasses: "d-flex flex-column",
      inlineStyle: { padding: "0" },
      provider: Auth.instance,
      inputType: InputType.INPUT_PASSWORD,

      customData: {
        checkBoxData: {
          id: "passview",
          cls: "form-check-label",
          customLabel: "Afficher le mot de passe.",
          styles: { fontSize: "80%", marginTop: "8px" },
          checked: false,
          eventName: "passview_change",
        },
      },
      listeners: [
        {
          type: "passview_change",
          behaviour: "customAction",
          resolve: (v: any, customData?: any) => {
            return {
              checkBoxData: { ...customData?.checkBoxData, checked: v.value },
            };
          },
        },
      ],
    }),
  ];

  processNew(payload: any) {
    return new Promise(async (resolve, reject) => {
      const res = await restClient.post(
        { path: "/auth/login", prefix: false },
        payload
      );
      if (res.success) {
        if (res.data?.accountId || res.data?.userId) {
          resolve(res);
          return;
        }
      }

      utils().emitter.emit("alert_message", {
        content: res?.message || "Erreur lors du login. Merci de reéssayer !",
        cls: "alert-danger",
      });
      resolve({ success: false, data: {} });
    });
  }
}

export { Auth };

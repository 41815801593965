import React from "react";
import { Alert } from "../Alert/Alert";
import { Details } from "../Details/Details";
import { BaseForm } from "../Form";
import { CustomContent } from "./CustomContent";

export function ModalBody(props: any) {
  const { isDetails, isNew, isCustom, isEdit } = props;
  return (
    <div className="modal-body" style={{ padding: "20px 0 0" }}>
      <Alert />
      {isDetails && <Details {...props} />}
      {(isNew || isEdit) && <BaseForm {...props} />}
      {isCustom && <CustomContent {...props} />}
    </div>
  );
}

import { appConstants } from "../_constants";
import { restClient, utils } from "../_helpers";
import { BaseEntity } from "./BaseEntity";
import { Field } from "./Field";

const { RENDER_AFFECTATION } = appConstants.keys.renderKeys;
const { TABLE_TITLE_CHANGE } = appConstants.keys.eventKeys;

class Affectation extends BaseEntity {
  public static instance: Affectation = new Affectation();
  public static instances = [];
  public static relations = [];

  public static definitions = [
    new Field({
      name: "libelle",
      label: "Fonctionnalité",
      provider: Affectation.instance,
      customData: {
        renderOptions: {
          className: "min-w-300",
        },
      },
    }),
    new Field({
      name: "all",
      label: "Tous les droits",
      dataPath: "id",
      renderKey: RENDER_AFFECTATION,
      provider: Affectation.instance,
    }),
    new Field({
      name: "read",
      label: "Droit de lecture",
      dataPath: "id",
      renderKey: RENDER_AFFECTATION,
      provider: Affectation.instance,
    }),
    new Field({
      name: "write",
      label: "Droit d'écriture",
      dataPath: "id",
      renderKey: RENDER_AFFECTATION,
      provider: Affectation.instance,
    }),
    new Field({
      name: "edit",
      label: "Droit de modification",
      dataPath: "id",
      renderKey: RENDER_AFFECTATION,
      provider: Affectation.instance,
    }),
    new Field({
      name: "delete",
      label: "Droit de suppression",
      dataPath: "id",
      renderKey: RENDER_AFFECTATION,
      provider: Affectation.instance,
    }),
  ];

  async refreshTableDataCallBack(props: any, data: any[]) {
    const id = utils().getUrlParam("id");
    const profile = this.getValue("profile", {}, true);
    const linkedResources = this.getValue("linkedResources", false, true);
    let promises: any[] = [];
    if (typeof profile?.id === "undefined") {
      promises.push(restClient.get(`/profile/find/${id}`));
    } else if (profile?.id !== id) {
      promises.push(restClient.get(`/profile/find/${id}`));
      this.deleteValue("profile", true);
    }
    if (!Array.isArray(linkedResources)) {
      promises.push(restClient.get(`/profile/linked/resource/${id}`));
    }
    if (promises.length) {
      await Promise.all(promises).then((results) => {
        if (results[0]?.data?.id) {
          this.updateValue("profile", results[0].data, true);
          utils().emitter.emit(
            TABLE_TITLE_CHANGE,
            `Affecter des droits au profil : ${results[0].data.libelle}`
          );
        }
        if (Array.isArray(results[1]?.data)) {
          this.updateValue("linkedResources", results[1].data, true);
          const resources: any[][] = results[1].data.map((d: any) => {
            const res: string[] = [];
            if (d.ecriture) res.push(`fn-write-${d.id.resourceId}`);
            if (d.lecture) res.push(`fn-read-${d.id.resourceId}`);
            if (d.modification) res.push(`fn-edit-${d.id.resourceId}`);
            if (d.suppression) res.push(`fn-delete-${d.id.resourceId}`);
            if (d.suppression && d.modification && d.lecture && d.ecriture)
              res.push(`fn-all-${d.id.resourceId}`);
            return res;
          });
          const resources2: any[] = [];
          resources.forEach((arr: any[]) => {
            resources2.push(...arr);
          });
          this.updateValue("resources", resources2);
        }
      });
    }
    return data;
  }

  toogleResource(id: string) {
    const resources: string[] = this.getValue("resources", []);
    const i = resources.findIndex((r: string) => r === id);
    if (i > -1) {
      resources.splice(i, 1);
    } else {
      resources.push(id);
    }
    this.updateValue("resources", resources);
  }

  checkResource(id: string, res?: string[]) {
    const resources: string[] = res || this.getValue("resources", []);
    const i = resources.findIndex((r: string) => r === id);
    if (i > -1) {
      return true;
    }
    return false;
  }

  async saveResources(): Promise<any> {
    const resources: string[] = this.getValue("resources", []);
    const profile = this.getValue("profile", {}, true);
    let data: any[] = [];
    resources.forEach((v) => {
      const parts = v.split("-");
      const id = parts[2];
      const right = parts[1];
      let i = data.findIndex((d) => d.resourceId === parseInt(id));
      if (i > -1) {
        data = this.updatePayload(right, data, i);
      } else {
        data.push({
          ecriture: false,
          lecture: false,
          modification: false,
          resourceId: parseInt(id),
          suppression: false,
        });
        i = data.length - 1;
        data = this.updatePayload(right, data, i);
      }
    });
    return new Promise(async (resolve) => {
      const res = await restClient.post("/profile/link/resource", {
        profileId: profile.id,
        resources: data,
      });
      res.success ? resolve(true) : resolve(res);
    });
  }

  updatePayload(right: string, data: any[], i: number) {
    switch (right) {
      case "all":
        data[i].ecriture = true;
        data[i].lecture = true;
        data[i].modification = true;
        data[i].suppression = true;
        break;
      case "read":
        data[i].lecture = true;
        break;
      case "write":
        data[i].ecriture = true;
        break;
      case "edit":
        data[i].modification = true;
        break;
      case "delete":
        data[i].suppression = true;
        break;
      default:
        break;
    }
    return data;
  }
}

export { Affectation };

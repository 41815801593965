import React, {Component} from "react";
import { appConstants } from "../../../../../_constants";
import { UserCni } from "../../../../../_entities";
import {restClient, utils} from "../../../../../_helpers";
import { BaseModel } from "../../../../../_models";
import {Card} from "../../../../../_entities/Card";

const { MODAL_ACTION } = appConstants.keys.eventKeys;

class Nfcard extends  Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      allCard:[]
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e: any) {
    e.preventDefault();
    Card.instance.updateValue("id", this.getAccoutId());
    // console.log(this.props?.account);
    utils().emitter.emit(MODAL_ACTION, {
      btn: "editActions",
      data: {...this.props, provider: Card.instance},
    });
  }

  componentWillMount() {
    let accountId = this.getAccoutId();
    if(accountId === null){
      accountId = this.props.account?.accountId;
    }
    const path = Card.allAccountCardPath + accountId;
    restClient.get(path).then((res)=>{
      if(res.success && res.data.length>0){
        this.setState({
          allCard:res.data
        });
      }else {
        this.setState({
          allCard:[]
        });
      }
    });
  }

  getAccoutId(){
    let currentPath = window.location.href;
    let url = new URL(currentPath);
    var accountId = url.searchParams.get("id");
    return accountId;
  }

  render() {
    const onClick = (e: any) => {
      e.preventDefault();
      Card.instance.updateValue("id", this.getAccoutId());
      utils().emitter.emit(MODAL_ACTION, {
        btn: "editActions",
        data: {...this.props, provider: Card.instance},
      });
    }
    return (
        <div className="form-group row w-100 justify-content-center m-0">
          <table className="table table-hover">
            <thead>
            <tr>
              <th>#</th>
              <th>Numéro</th>
              <th>Date de création</th>
              <th>Date d'expiration</th>
              <th>Statut</th>
              {/*<th>#</th>*/}
            </tr>
            </thead>
            <tbody>
            {
              this.state.allCard.map((card:any, index) => {
                return(
                <tr>
                  <td>{index+1}</td>
                  <td>{card.number}</td>
                  <td>{utils().formatDate("DD/MM/YYYY",card.creation)}</td>
                  <td>{utils().formatDate("DD/MM/YYYY",card.expiration)}</td>
                  <td>{card.status?"Active":"Inactive"}</td>
                  {/*<td>*/}
                  {/*  <button onClick={onClick}>*/}
                  {/*    <small> <i className="fa fa-edit mr-1" aria-hidden="true"></i></small>*/}
                  {/*  </button>*/}
                  {/*</td>*/}
                </tr>)
              })
            }
            </tbody>
          </table>
        </div>
    )
  }
}
export {Nfcard};


import { appConstants } from "../_constants";
import { BaseEntity } from "./BaseEntity";
import { FieldType } from "./enumerations";
import { Field } from "./Field";
import {BaseModel} from "../_models";
import {Utils} from "../_helpers/utils";
import {restClient, utils} from "../_helpers";

const {
  RENDER_ID,
  RENDER_ACTIONS,
  RENDER_WORKFLOWSTEP_VALIDATOR,
  RENDER_WORKFLOWSTEP_WORKFLOW,
  RENDER_STATUS_OPERATION
} = appConstants.keys.renderKeys;

class Workflowstep extends BaseEntity {
  public static readonly label: any;
  public static readonly className: any;
  public static readonly art: any;
  public static readonly editTitle = "Modifier un Workflowstep";
  public static readonly showTitle = "Détails de la validation";
  public static instance: Workflowstep = new Workflowstep();
  public static instances = [];
  public static relations = [];

  public static get definitions(): Field[] {
    const model: any = BaseModel.getInstance();
    const aCHild = [
      {
        label: "Valider",
        title: "Valider",
        action: "customActions",
        color: "text-dark",
        icon: "fa fa-edit",
        processkey: "cancel"
      },
      {
        label: "Rejeter",
        title: "Rejeter",
        action: "customActions",
        color: "text-dark",
        icon: "fa fa-reply",
      }
    ];

    const definitions = [
      new Field({
        name: "workflowStepId",
        type: FieldType.NUMBER,
        primary: true,
        creatable: false,
        editable: false,
        serializeNew: false,
        serializeEdit: false,
        label: "#",
        renderKey: RENDER_ID,
        provider: Workflowstep.instance,
      }),
      new Field({
        name: "label",
        label: "label",
        creatable: false,
        provider: Workflowstep.instance,
        customData: {
          renderOptions: {
            className: "min-w-150",
          },
        },
      }),
      new Field({
        name: "stepLevel",
        label: "Ordre",
        creatable: false,
        provider: Workflowstep.instance,
      }),
      new Field({
        name: "validator",
        label: "Validateur",
        renderKey: RENDER_WORKFLOWSTEP_VALIDATOR,
        dataPath: "validator.firstName",
        creatable: false,
        provider: Workflowstep.instance,
      }),
      new Field({
        name: "validationMsg",
        label: "Message",
        renderKey: RENDER_WORKFLOWSTEP_WORKFLOW,
        creatable: true,
        onTable:false,
        provider: Workflowstep.instance,
        // renderKey: RENDER_ACTIONS,
      }),
      new Field({
        name: "status",
        label: "Statut",
        creatable: false,
        renderKey: RENDER_STATUS_OPERATION,
        provider: Workflowstep.instance,
      }),
      new Field({
        name: "actions",
        label: "Actions",
        dataPath: "workflowStepId",
        creatable: false,
        editable: false,
        serializeNew: false,
        serializeEdit: false,
        serializeView: false,
        renderKey: RENDER_ACTIONS,
        provider: Workflowstep.instance,
        customData: {
          renderOptions: {
            clsActions: "justify-content-center",
          },
          actions: [
            {
              label: "Détails",
              title: "Afficher les détails",
              action: "detailsActions",
              color: "btn-info",
              icon: "fa fa-eye",
            },
            {
              type: "btnGroup",
              color: "btn-primary",
              children:aCHild,
            },
          ],
        },
      }),
    ];
    return definitions;
  }

  toView() {
    return [
      {
        title: false,
        rows: [
          { label: "Validation ID", value: this.workflowStepId },
          { label: "Label", value: this.label },
          { label: "Ordre", value: this.stepLevel },
          { label: "Validateur", value: this.validator.firstName},
          { label: "Statut", value: this.status},
          { label: "Message", value: this.validationMsg},
          { label: "Date de validation", value: utils().formatDate("DD/MM/YYYY [à] HH:mm:ss",this.validationDate) },
        ],
      },
    ];
  }

  getSwitchActionConfig(status: boolean) {
    return {
      path: `/workflowstep/validate/${this.workflowStepId}`,
      resolve: (res: any) => res?.message === "OK",
    };
  }

  async initCustom(props: any) {
    const { btn, data } = props;
    console.log("Les data à valider", data);
    return new Promise(async (resolve) => {
      console.log("Les propos depuis initCustom", props);
      const action = btn?.dataset?.key;
      if (action === "cancel") {
        const v = window.confirm(
            "Etes-vous certain de vouloir confirmer cette validation?"
        );
        if (v) {
          const res = await restClient.get(
              `/workflowstep/validate/${data.workflowStepId}`
          );
          // const result = await restClient.post(`workflowstep/validate/${data.workflowStepId}`);
           console.log("==> result", res);
          if (res?.success) {
            return resolve({ process: false, refresh: true });
          } else {
            let msg =
                res?.message ||
                "Une erreur s'est produite lors de cette validation. Merci de réessayer !";
            alert(msg);
          }
        }
        return resolve({ process: false });
      }
      let res: any = {};
      res = {
        settings: {
          content: "validateStep",
          title: "Rejeter cette validation",
          showSaveBtn: true,
          action: "validateStep",
          message: "",
          motif: ""
        },
      };
      resolve(res);
    });
  }
}

export { Workflowstep };

import React, { useEffect, useRef } from "react";
import Select from "react-select";

export function InputSelect(props: any) {
  const { loading, field, value, setValue } = props;
  let defaultValue: any;
  if (value) {
    const i = field.options.findIndex((o: any) => o.value === value);
    if (i > -1) {
      defaultValue = field.options[i];
    } else {
      defaultValue = field.provider.getDefaultValue(field, value);
    }
  }

  const isDisabled =
    field.customData?.isDisabled ||
    loading ||
    (!props.model?.confCrud?.modification &&
      !props.model?.txCrud?.modification &&
      !props.model?.prodCrud?.modification &&
      !props.model?.comCrud?.modification &&
      !props.model?.userCrud?.modification &&
      !props.model?.i18Crud?.modification &&
      !props.model?.accCrud?.modification &&
      !props.model?.cardCrud?.modification &&
      !props.model?.wflCrud?.modification &&
      props.model?.user.user?.profil !== "Admin" &&
      props.model?.user.accountType?.accountTypeId === 18);
  return (
    <Select
      className="basic-single w-100"
      value={defaultValue}
      defaultValue={defaultValue}
      classNamePrefix="select"
      isDisabled={isDisabled}
      isLoading={loading}
      isClearable={true}
      isSearchable={true}
      name={field.name}
      style={{ width: "100%", background: isDisabled ? "#e9ecef" : "#fff" }}
      options={field.options}
      placeholder="Selectionner ... "
      onChange={(option, meta) =>
        setValue(field.updateValue((option as any)?.value))
      }
    />
  );
}

import React, { useEffect, useState } from "react";
import { appConstants } from "../../../../_constants";
import { utils } from "../../../../_helpers";
import { PropsFromRedux } from "../../../../_reducers";

const { TABLE_DATA_CHANGE } = appConstants.keys.eventKeys;

export function Total(props: PropsFromRedux & any) {
  const [total, setTotal] = useState(0);
  const [total2, setTotal2] = useState(0);

  useEffect(() => {
    const handleTableDataChangeEvent = (res: any) => {
      if (res?.data?.length) {
        setTotal(res?.data?.length);
        setTotal2(
          res?.data
            .filter(
              (i: any) =>
                i.operationType?.operationTypeId === 7 && i.status === "SUCCESS"
            )
            .map((o: any) => (o.amount >= 0 ? o.amount : o.amount * -1))
            .reduce((a: number, b: number) => a + b, 0)
        );
      }
    };
    utils().emitter.on(TABLE_DATA_CHANGE, (res) => {
      handleTableDataChangeEvent(res);
    });

    return () => {
      utils().emitter.off(TABLE_DATA_CHANGE, handleTableDataChangeEvent);
    };
  });
  const isMarqueBlanche =
    window.location.pathname.indexOf("marque_blanches") !== -1;
  return (
    <small>
      Total :{" "}
      <span id="total" className="badge badge-info">
        {total}
      </span>
      {!isMarqueBlanche && true && (
        <>
          <br />
          Crédit :{" "}
          <span className="text-danger">
            {utils().formatAmount("CDF", total2)}
          </span>
        </>
      )}
    </small>
  );
}

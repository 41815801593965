import React from "react";

export function PinBtn(props: any) {
  const { cls, label, onClick, icon} = props;

  return (
    <button
      type="button"
      className={`btn ${cls}`}
      onClick={onClick}
      style={{ fontSize: "80%", width: "96px", margin: "4px" }}
    >
      <i className={`fa ${icon} mr-1`} aria-hidden="true"></i>
      {label}
    </button>
  );
}

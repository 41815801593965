import React, { Component } from "react";
import { Avantage } from "../../../_config";
import { model } from "../../../_helpers";

class AvantageItem extends Component<Avantage> {
  desc2:any;
  render() {
    const { img, titre, desc } = this.props;
    this.desc2=desc;
    this.desc2=this.desc2.replace("e-Mpata",(model().appTitle || "e-Mpata"));
    return (
      <div className="col-12 col-md-6 col-lg-4 text-center mb-6">
        <div className="icon icon-xxl mb-3">
          <img src={img} style={{ height: "120px" }} alt="IMG" />
        </div>
        <h3 className="font-weight-bold">{titre}</h3>
        <p className="font-weight-light text-muted">{this.desc2}</p>
      </div>
    );
  }
}

export default AvantageItem;

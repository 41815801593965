import React from "react";
import { history } from "../../../../_helpers";

export function ReturnBtn(props: any) {
  return (
    <small>
      <button
        onClick={() => history.goBack()}
        className="btn btn-info box-shadow btn-sm"
      >
        <i className="fa fa-reply mr-1" aria-hidden="true"></i>
        Retour à la liste
      </button>
    </small>
  );
}

import axios, { AxiosInstance, AxiosResponse } from "axios";

export const restClient = {
  get,
  post,
  put,
  remove,
  patch,
  getInstance,
};

async function getInstance(multipart?: string): Promise<AxiosInstance> {
  return new Promise(async (resolve, reject) => {
    const headers = getHeaders(multipart);
    resolve(
      axios.create({
        timeout: 60000,
        headers: headers,
        withCredentials: true,
      })
    );
  });
}

function getHeaders(multipart?: string) {
  return {
    Accept: "application/json",
    "Content-Type": multipart || "application/json",
  };
}

function getWsPath(req: any): string {
  const prefix = `/api${req.prefix || ""}/?path=`;
  let path = "";
  if (typeof req === "string") {
    path = req;
  } else {
    if (req?.path) {
      path = req.path;
    }
  }
  if (req?.prefix !== false) {
    path = prefix + path;
  }
  return path;
}

function getPayload(req: any, data?: any) {
  return data || req.body || req;
}

async function get(req: any) {
  const axiosInstance = await getInstance();
  return axiosInstance
    .get(getWsPath(req))
    .then((res: any) => handleResponse(res))
    .catch((err: any) => handleError(err));
}

async function post(req: any, data?: any) {
  const axiosInstance = await getInstance(req.multipart);
  return axiosInstance
    .post(getWsPath(req), getPayload(req, data))
    .then((res: any) => handleResponse(res))
    .catch((err: any) => handleError(err));
}

async function put(req: any, data?: any) {
  const axiosInstance = await getInstance();
  console.log(getWsPath(req));
  console.log(getPayload(req, data));

  return axiosInstance
    .put(getWsPath(req), getPayload(req, data))
    .then((res: any) => handleResponse(res))
    .catch((err: any) => handleError(err));
}

async function patch(req: any, data?: any) {
  const axiosInstance = await getInstance();
  return axiosInstance
    .patch(getWsPath(req), getPayload(req, data))
    .then((res: any) => handleResponse(res))
    .catch((err: any) => handleError(err));
}

async function remove(req: any, data?: any) {
  const axiosInstance = await getInstance();
  return axiosInstance
    .delete(getWsPath(req), getPayload(req, data))
    .then((res: any) => handleResponse(res))
    .catch((err: any) => handleError(err));
}

function handleResponse(res: AxiosResponse) {
  console.log("Request response data ==> ", res.data);
  return res.data;
}

function handleError(err: any) {
  console.log("<== Request Error ==> ");
  let data = null;
  let status = 500;
  let message =
    "Une érreur est survenu lors de l'opération. Merci de reéssayer !";
  if (err.response) {
    console.log(err.response.data);
    console.log(err.response.status);
    console.log(err.response.config);
    data = err.response.data;
    status = err.response.status;
    if (err?.response?.data?.message) {
      message = err.response.data.message;
    }
  } else if (err.request) {
    console.log(err.request);
  } else {
    console.log(err.message);
  }
  console.log("<== End request Error ==> ");
  console.log("==> status ", status);
  if (status === 401) {
    get({ path: "/auth/token/refresh", prefix: false }).then(console.log);
  }
  return { success: false, status, data, message };
}

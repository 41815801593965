import React, { Component } from "react";
import { primaryColor, restClient } from "../../../_helpers";
import { InputSelect } from "./InputSelect";
import { InputText } from "./InputText";

class PayerTaxeForm extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      freeze:
        typeof this.props.freeze !== "undefined" ? this.props.freeze : false,
      err: false,
      errmsg: "",
      finish: false,
      success: false,
      numdoc: "",
      typedoc: "",
      typedocs: [
        { id: "1", name: "Note de perception" },
        { id: "2", name: "Déclaration" },
        { id: "3", name: "Avis de recouvrement type 1" },
        { id: "4", name: "Avis de recouvrement type 2" },
      ],
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    let obj = this;
    $(document).on("hidden.bs.modal", "#taxModal", function () {
      console.log(obj.props);
      if (obj.state.success) {
        window.location.reload();
        return;
      }
      obj.setState(
        {
          freeze: false,
          err: false,
          errmsg: "",
          finish: false,
          success: false,
        },
        () => {
          $("#recapitulatif-group").empty();
          $("#error-group").empty();
          $("#tax-form").slideDown();
          $("#recapitulatif-group").slideUp();
          $("#accountType").parent().find(".invalid-feedback").hide();
          $("#taxMin").parent().find(".invalid-feedback").hide();
          $("#taxMax").parent().find(".invalid-feedback").hide();
          $("#tax").parent().find(".invalid-feedback").hide();
          $("#taxModal").attr("data-action", "");
          $("#taxModal").attr("data-id", "");
        }
      );
    });
  }

  handleChange(e) {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleClick(e) {
    e.preventDefault();
    this.setState({
      freeze: true,
      err: false,
      errmsg: "",
    });
    const { typedocs, typedoc, numdoc } = this.state;
    const { protocol, hostname, port } = window.location;
    const basePath = `${protocol}//${hostname}:${port}`;
    let payload = {
      typedocs: typedocs,
      typedoc: typedoc,
      numdoc: numdoc,
    };
    if (typeof numdoc === "undefined" || numdoc.trim().length <= 0) {
      this.setState({
        freeze: false,
        err: true,
        errmsg: "Veuillez saisir un numéro de document!",
        finish: false,
      });
      return;
    }

    // if (this.state.channel == "CshAPG01") {
    //     restClient.post(`/operation/cashtowallet`, payload).then((res) => {
    //             console.log(res);
    //             if (res?.data?.code == "0000") {
    //                 this.setState({
    //                     data: res.data,
    //                     freeze: false,
    //                     finish: true
    //                 });
    //                 // @ts-ignore
    //                 window.location = `${basePath}/dashboard`;
    //             } else {
    //                 this.setState({
    //                     freeze: false,
    //                     err: true,
    //                     errmsg: decodeURIComponent(escape(res.data.message)),
    //                     finish: false
    //                 });
    //             }
    //         });
    // }
  }

  render() {
    const { success, freeze, finish, numdoc, errmsg, err } = this.state;

    return (
      <div
        className="modal fade"
        id="taxModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="taxModalLabel"
        aria-hidden="true"
      >
        <div
          id="taxModal"
          className="modal-dialog"
          role="document"
          style={{ maxWidth: "600px" }}
        >
          <div className="modal-content" style={{ padding: "30px" }}>
            <div className="modal-header d-flex flex-row justify-content-between">
              <h5 className="modal-title" id="taxModalLabel">
                Payer une taxe
              </h5>
              {err && (
                <span style={{ fontSize: "80%", font: "bold", color: "red" }}>
                  {errmsg}
                </span>
              )}
              {freeze && (
                <div className="loader">
                  <div className="row align-items-center">
                    <img src="/public/build/images/loading.gif" alt="" />
                    <p className="loading-message" style={{ margin: 0 }}>
                      Veuillez patienter
                    </p>
                  </div>
                </div>
              )}
            </div>
            <div
              id="create-modal-body"
              className="modal-body"
              style={{ padding: "20px 0 0" }}
            >
              <div className="" id="recapitulatif-group"></div>
              <div className="" id="error-group" data-shown="0">
                {success && (
                  <div
                    className="alert alert-success alert-dismissible fade show"
                    role="alert"
                  >
                    <span id="ajaxSuccess" style={{ fontSize: "80%" }}>
                      Payement reussi
                    </span>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                )}
              </div>

              <form
                id="tax-form"
                className="ui error form auth-form"
                style={{ fontSize: "80%", display: finish ? "none" : "" }}
              >
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <InputSelect
                    onChange={this.handleChange}
                    value={this.state.typedoc}
                    label={"Type de document"}
                    name={"typedoc"}
                    freeze={freeze}
                    items={this.state.typedocs}
                  />
                  <InputText
                    onChange={this.handleChange}
                    value={numdoc}
                    label={"Numéro du document"}
                    name={"numdoc"}
                    freeze={freeze}
                    placeholder={"Numéro du document"}
                    type={"text"}
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                id="cancel-bon-btn"
                type="button"
                className="btn btn-danger"
                disabled={freeze}
                data-dismiss="modal"
                style={{ fontSize: "90%" }}
              >
                {finish && "Fermer"}
                {!finish && "Annuler"}
              </button>

              {!finish && (
                <button
                  id="create-btn"
                  type="button"
                  onClick={this.handleClick}
                  className="btn "
                  disabled={freeze}
                  style={{ fontSize: "90%",backgroundColor:primaryColor(),color:"white" }}
                >
                  Payer
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  getDocumentType(id, number) {
    console.log("Obtention du type de document", number);
    let obj = this;
    let url = "/tax/document/verify/" + id + "/";
    restClient.get(`/tax/document/verify/` + id + `/` + number).then((res) => {
      if (res?.data) {
        // obj.setState({
        //     currencies: res.data.map((d) => ({...d, id: d.code})),
        //     freeze: false
        // });
      } else {
        // obj.setState({freeze: false});
      }
    });
  }
}

export { PayerTaxeForm };

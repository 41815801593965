import React from "react";

export function AdminConfig(props: any) {
  return (
    <div className="profile-wrapper box-shadow">
      <div
        className="profile-header d-flex justify-content-between"
        style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.125)" }}
      >
        <div className="d-flex flex-column justify-content-start ">
          <h5 className="profile-title">Parmètres généraux</h5>
        </div>
      </div>
      <div className="profile-content mb-2">
        <form className="ui error form auth-form">
          <div>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <div
                className="form-group row"
                style={{ margin: "0 0 1rem 0", width: "90%" }}
              >
                <div className="col-md-3" style={{ padding: "0 1rem 0 0" }}>
                  <label
                    htmlFor="colFormLabelSm"
                    className=" col-form-label col-form-label-sm"
                    style={{ float: "right" }}
                  >
                    Nom du site
                  </label>
                </div>
                <div className="col-md-7" style={{ padding: 0 }}>
                  <input
                    className="form-control form-control-sm"
                    name="email"
                    autoComplete="email"
                    type="email"
                    disabled={!props.model.confCrud?.modification && props.model.user.user?.profil!=="Admin"}
                    value="e-Mpata Web Wallet"
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
              <div
                className="form-group row"
                style={{ margin: "0 0 1rem 0", width: "90%" }}
              >
                <div className="col-md-3" style={{ padding: "0 1rem 0 0" }}>
                  <label
                    htmlFor="colFormLabelSm"
                    className=" col-form-label col-form-label-sm"
                    style={{ float: "right" }}
                  >
                    Email de contact
                  </label>
                </div>
                <div className="col-md-7" style={{ padding: 0 }}>
                  <input
                    className="form-control form-control-sm"
                    name="email"
                    autoComplete="email"
                    type="email"
                    disabled={!props.model.confCrud?.modification && props.model.user.user?.profil!=="Admin"}
                    value="info@e-mpata.com"
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            </div>
            <div className="form-group row" style={{ margin: "0 0 1rem 0" }}>
              <div className="col-md-10">
                <button
                  type="button"
                  className="btn btn-primary box-shadow"
                  style={{ float: "right", margin: "0 1rem" }}
                  disabled={!props.model.confCrud?.modification && props.model.user.user?.profil!=="Admin"}
                >
                  <i className="fa fa-save mr-1" aria-hidden="true"></i>
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

import { appConstants } from "../_constants";
import { BaseEntity } from "./BaseEntity";
import {FieldType, InputType} from "./enumerations";
import { Field } from "./Field";
import {BaseModel} from "../_models";
import {restClient, utils} from "../_helpers";
import {OperationType} from "./OperationType";
import {Workflows} from "./Workflows";
import {Utils} from "../_helpers/utils";

const {
  RENDER_ID,
  RENDER_ACTIONS,
  RENDER_DATETIME,
} = appConstants.keys.renderKeys;

class Permission extends BaseEntity {
  public static readonly label: any;
  public static readonly className: any;
  public static readonly art: any;
  public static readonly editTitle = "Modifier une permission";
  public static readonly showTitle = "Détails d'une permission";
  public static instance: Permission = new Permission();
  public static instances = [];
  public static relations = [
    {
      dataPath: "workflows/model/all",
      key: "workflows",
      resolve: (res: any) => {
        if (Array.isArray(res?.data)) {
          return res.data.map((v: any) => Workflows.getSelectWorkflow(v));
        }
        return [];
      },
    }
  ];

  public static get definitions(): Field[] {
    const model: any = BaseModel.getInstance();
    const aChildAll = [
      {
        label: "Modifier",
        title: "Modifer cette permission",
        action: "editActions",
        color: "text-dark",
        icon: "fa fa-edit",
      },
      {
        label: "Supprimer",
        title: "Supprimmer cette permission",
        action: "deleteActions",
        color: "text-danger",
        icon: "fa fa-trash",
      }
    ];
    const aChildDel = [
      {
        label: "Supprimer",
        title: "Supprimmer cette permission",
        action: "deleteActions",
        color: "text-danger",
        icon: "fa fa-trash",
      }
    ];
    const aChildMod = [
      {
        label: "Modifier",
        title: "Modifer cette permission",
        action: "customActions",
        color: "text-dark",
        icon: "fa fa-edit",
      }
    ];
    const definitions = [
      new Field({
        name: "permissionId",
        type: FieldType.NUMBER,
        primary: true,
        creatable: false,
        editable: false,
        serializeNew: false,
        serializeEdit: false,
        label: "#",
        renderKey: RENDER_ID,
        provider: Permission.instance,
      }),
      new Field({
        name: "label",
        label: "Label",
        editable:false,
        provider: Permission.instance,
        customData: {
          renderOptions: {
            className: "min-w-150",
          },
        },
      }),
      new Field({
        name: "workflowId",
        label: "Workflow",
        dataPath: "workflow.label",
        inputType: InputType.INPUT_SELECT,
        onTable: true,
        required: false,
        relationKey: "workflows",
        // formRowIndex: 6,
        provider: Permission.instance,
      }),
      new Field({
        name: "linkedDate",
        label: "Date d'affectation",
        renderKey: RENDER_DATETIME,
        creatable: false,
        provider: Permission.instance,
        customData: {
          renderOptions: {
            className: "min-w-150",
          },
        },
      }),
      new Field({
        name: "actions",
        label: "Actions",
        dataPath: "permissionId",
        creatable: false,
        editable: false,
        serializeNew: false,
        serializeEdit: false,
        serializeView: false,
        renderKey: RENDER_ACTIONS,
        provider: Permission.instance,
        customData: {
          renderOptions: {
            clsActions: "justify-content-center",
          },
          actions: [
            {
              label: "Détails",
              title: "Afficher les détails",
              action: "detailsActions",
              color: "btn-info",
              icon: "fa fa-eye",
            },
            {
              type: "btnGroup",
              color: "btn-primary",
              children:model.user.user?.profile === null
                  ? aChildAll
                  : model.confCrud.modification && model.confCrud.suppression
                      ? aChildAll
                      : model.confCrud.modification
                          ? aChildMod
                          : model.confCrud.suppression
                              ? aChildDel
                              : [],
            },
          ],
        },
      }),
    ];
    return definitions;
  }

  toView() {
    console.log("THIS OHHHH", this);
    let valRow:any = [];
    this.workflow?.steps.map((step, index)=>{
      let val = {
        label:"Validateur "+Number(index+1), value:step.validator.firstName+" "+step.validator.lastName
      };
      valRow.push(val);
    });
    return [
      {
        title: "Informations générales",
        rows: [
          { label: "Permission ID", value: this.permissionId },
          { label: "Label", value: this.label },
          { label: "Workflow", value: this.workflow?.label},
          { label: "Date d'affectation", value: utils().formatDate(
                "DD/MM/YYYY [à] H:mm:ss",
                this.linkedDate)},
        ],
      },
      {
        title: "Validateurs sur "+this.label,
        rows: valRow
      }
    ];
  }

  // getDeleteActionConfig() {
  //   return {
  //     path: `/permissions/delete/${this.permissionId}`,
  //     resolve: (res: any) => res?.success,
  //   };
  // }

  getCustomProcessEditPath(payload: any) {
    console.log("THIS IXX",this);
    return encodeURI(
        `/permissions/update/${this.permissionId}?label=${payload.label}
      workflowId=${payload.workflowId}`
    );
  }

  // getSwitchActionConfig(status: boolean) {
  //   return {
  //     path: `/country/change/${this.countrycode || this.code}/${status}`,
  //     resolve: (res: any) => res?.data?.status === "OK",
  //   };
  // }

  async initCustom(props: any) {
    return {
      settings: {
        content: "updateWorkflow",
        title: "Modifier ce workflow",
        showSaveBtn: true,
        action: "updateWorkflow",
        message: "",
        validatorsToSend : [],
        newLabel:"",
        nbrSteps: 0
      },
    };
  }
}

export { Permission };

import React, { useState } from "react";
import { utils } from "../../../_helpers";
import { PinBtn } from "./PinBtn";

export function CodePinValidator(props: any) {
  const numbersss: number[] = [];
  const [codePin, setCodePin] = useState("");
  const [numbers, setNumbers] = useState(numbersss);
  const [pinType, setPinType] = useState("password");
  const [cls, setCls] = useState("");

  const onValidate = (e: any) => {
    e.preventDefault();
    if (codePin.length !== 4) {
      setCls("d-block");
      return;
    }
    const { provider } = props.model;
    provider.processValidatePin(props, codePin).then((res: boolean) => {
      if (res) {
        e.target.setAttribute("disabled", "disabled");
        props.handleSubmit(e);
      } else {
        e.target.removeAttribute("disabled");
        props.errorCallBack("Code PIN invalide !");
      }
    });
  };

  const onClick = (e: any, num: number) => {
    e.preventDefault();
    if (codePin.length > 3) {
      return;
    }
    setCls("");
    setCodePin(codePin + num);
  };

  const onCancel = (e: any) => {
    e.preventDefault();
    console.log(e);
    const { provider } = props.model;
    const prevData = provider.processPrev(props);
    if (prevData) {
      props.stateSetter(prevData);
    }
  };

  if (!numbers.length) {
    const numberss: number[] = [];
    const items: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
    while (items.length != 0) {
      const item = utils().rand(items);
      const index = items.indexOf(item);
      if (index > -1) {
        const n: number = items.splice(index, 1)[0];
        if (typeof n === "number") {
          numberss.push(n);
        }
      }
    }
    setNumbers(numberss);
  }

  const buttons: JSX.Element[] = numbers.map(function (item, i) {
    return (
      <div className="PaQdxb A2W7l" key={i}>
        <div
          className="XRsWPe AOvabd number_btn"
          role="button"
          onClick={(e) => onClick(e, item)}
          tabIndex={0}
        >
          {item}
        </div>
      </div>
    );
  });

  return (
    <div>
      <div
        className="form-group row"
        style={{ margin: "0 0 1rem 0", width: "90%" }}
      >
        <div className="col-md-4" style={{ padding: "0 1rem 0 0" }}>
          <label
            htmlFor="colFormLabelSm"
            className=" col-form-label col-form-label-sm"
            style={{ float: "right" }}
          >
            Code PIN
          </label>
        </div>
        <div className="col-md-4" style={{ padding: 0 }}>
          <input
            className="form-control form-control-sm"
            name="pin"
            id="pin"
            type={pinType}
            value={codePin}
            style={{ width: "100%", backgroundColor: "#fff" }}
            readOnly={true}
          />
          <div className={`invalid-feedback ${cls}`}>
            code pin invalide. Il doit avoir 04 chiffres.
          </div>
        </div>
        <div
          className="col-md-4 d-flex justify-content-between"
          style={{ padding: 0 }}
        >
          <div
            className="PaQdxb A2W7l w-50"
            style={{ marginBottom: 0, marginTop: 0 }}
          >
            <div
              className="XRsWPe AOvabd"
              onClick={(e) =>
                setPinType(pinType === "password" ? "text" : "password")
              }
              role="button"
              tabIndex={0}
              style={{ height: "33px" }}
            >
              <i
                id="pinicon"
                className={`fa ${
                  pinType === "password" ? "fa-eye-slash" : "fa-eye"
                }`}
              ></i>
            </div>
          </div>
          <div
            className="PaQdxb A2W7l w-50"
            style={{ marginBottom: 0, marginTop: 0 }}
          >
            <div
              className="XRsWPe AOvabd erase_btn"
              role="button"
              tabIndex={0}
              onClick={(e) => setCodePin("")}
              style={{ height: "33px" }}
            >
              C
            </div>
          </div>
        </div>
      </div>
      <div
        className="form-group row"
        style={{ margin: "0 0 1rem 0", width: "90%" }}
      >
        <div className="col-md-4" style={{ padding: "0 1rem 0 0" }}></div>
        <div className="col-md-8" style={{ padding: 0 }}>
          <table
            className="ElumCf"
            style={{
              width: "100%",
              borderCollapse: "collapse",
              tableLayout: "fixed",
            }}
          >
            <tbody>
              <tr>
                <td>{buttons[0]}</td>
                <td>{buttons[1]}</td>
                <td>{buttons[2]}</td>
              </tr>
              <tr>
                <td>{buttons[3]}</td>
                <td>{buttons[4]}</td>
                <td>{buttons[5]}</td>
              </tr>
              <tr>
                <td>{buttons[6]}</td>
                <td>{buttons[7]}</td>
                <td>{buttons[8]}</td>
              </tr>
              <tr>
                <td>{buttons[9]}</td>
                <td>
                  <PinBtn
                    label={"Annuler"}
                    icon={"fa-times"}
                    cls={"btn-danger box-shadow"}
                    onClick={onCancel}
                  />
                </td>
                <td>
                  <PinBtn
                    label={"Valider"}
                    icon={"fa-check"}
                    cls={"btn-success box-shadow"}
                    onClick={onValidate}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

import React from "react";

export function InputTextArea(props: any) {
  const { loading, field, value, setValue,customData } = props;
  let attr = {};
  
 
  if (customData?.placeholder) {
    (attr as any).placeholder = customData?.placeholder;
  }
  return (
    <textarea
    {...attr}
      className="form-control form-control-sm"
      cols={3}
      name={field.name}
      value={value}
      onChange={(e) => setValue(field.updateValue(e.target.value))}
      readOnly={loading ? true : false}
    ></textarea>
  );
}

import React from "react";
import { Field } from "../../../../_entities";
import { FormField } from "./FormField";

export function FormFields(props: any) {
  return (
    <>
      {getFields(props).map((field: Field, i: number) => {
        return <FormField originalField={field} {...props} key={i} />;
      })}
    </>
  );
}

const getFields = (props: any): Field[] => {
  const { customRows, data } = props;
  const rows = customRows || data?.provider?.rows || data?.model?.provider?.rows || data?.rows || [];
  const fields: Field[] = [];
  rows.forEach((element: Field[]) => {
    element.forEach((el: Field) => {
      fields.push(el);
    });
  });
  return fields;
};

import React, { Component } from "react";
import { appConstants } from "../../../_constants";
import { utils } from "../../../_helpers";

const { MODAL_ACTION } = appConstants.keys.eventKeys;

class Product extends Component<any, any> {
  handleClick() {
    utils().emitter.emit(MODAL_ACTION, {
      btn: "customActions",
      data: { ...this.props, content: "productdetails" },
    });
  }
  render() {
    const { produit } = this.props;
    return (
      <div
        className="card"
        style={{
          maxWidth: "265px",
          maxHeight: "265px",
          overflow: "hidden",
        }}
      >
        <div className="d-flex flex-column align-items-center mt-2">
          <img
            src={"data:image/png;base64," + produit.image}
            className="card-img-top"
            alt="IMG"
            style={{ maxWidth: "100px" }}
          />
        </div>
        <div className="card-body d-flex flex-column justify-content-between">
          <div style={{ maxHeight: "70px" }}>
            <h6 className="card-title">
              <span
                className="libproduct"
                data-catgid={produit.productCategory.productCategoryId}
              >
                {produit.libelle +
                  " : " +
                  produit.caracteristic +
                  " - " +
                  produit.productCategory.libelle}
              </span>
            </h6>
            <p className="card-text" style={{ fontSize: "80%" }}>
              {produit.description}
            </p>
          </div>
          <div className="d-flex flex-column justify-content-end align-items-center mt-2">
            <button
              type="button"
              className="details-produit-link btn btn-primary btn-sm mt-3"
              data-id={produit.productId}
              onClick={() => this.handleClick()}
            >
              <i className="mr-1 fa fa-eye"></i>
              <small className="add_transaction_text">Détails</small>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Product;

import React, { useEffect, useState } from "react";
import { appConstants } from "../../../../_constants";
import { utils, history } from "../../../../_helpers";
import { PropsFromRedux } from "../../../../_reducers";

const { TABLE_TITLE_CHANGE } = appConstants.keys.eventKeys;

export function Title(props: PropsFromRedux & any) {
  const [title, setTitle] = useState(props.listTitle);

  useEffect(() => {
    const handleTableTitleChangeEvent = (res: any) => {
      setTitle(res);
    };
    utils().emitter.on(TABLE_TITLE_CHANGE, (res) => {
      handleTableTitleChangeEvent(res);
    });
    const unsubscribe = history.listen((location, action) => {
      utils().emitter.emit(TABLE_TITLE_CHANGE, props.model.config.listTitle);
    });
    return () => {
      utils().emitter.off(TABLE_TITLE_CHANGE, handleTableTitleChangeEvent);
      unsubscribe();
    };
  });
  return <h5 className="profile-title">{title}</h5>;
}

import { appConstants } from "../_constants";
import { restClient } from "../_helpers";
import { BaseEntity } from "./BaseEntity";
import { FieldType, InputType } from "./enumerations";
import { Field } from "./Field";
import { User } from "./User";
import { BaseModel } from "../_models";

const {
  RENDER_ID,
  RENDER_STATUS,
  RENDER_ACCOUNT_TYPE,
  RENDER_AMOUNT_USD,
  RENDER_DATE,
  RENDER_AMOUNT,
  RENDER_LONG_TEXT,
  RENDER_ACTIONS,
} = appConstants.keys.renderKeys;

class Marchand extends BaseEntity {
  public static readonly label: any;
  public static readonly className: any;
  public static readonly art: any;
  public static readonly addTitle = "Ajouter un marchand";
  public static readonly processNewPath = "user/adduser";
  public static instance: Marchand = new Marchand();
  public static instances = [];
  public static relations = [
    {
      dataPath: "country/all",
      key: "countries",
      resolve: (res: any) => {
        if (Array.isArray(res?.data)) {
          return res.data.map((v: any) => ({
            label: v.nomFr,
            value: `${v.code}-${v.devise}-${v.countryCode}`,
          }));
        }
        return [];
      },
    },
  ];

  public static get definitions(): Field[] {
    const model: any = BaseModel.getInstance();
    const aChild = [
      {
        label: "Modifier",
        title: "Modifer ce type de compte",
        action: "navigateActions",
        link: "/admin/profiles/affectations",
        color: "text-dark",
        icon: "fa fa-edit",
      },
      {
        label: "Hiérarchiser",
        title: "Définir le parent de ce compte",
        action: "customActions",
        color: "text-dark",
        icon: "fa fa-edit",
      },
      {
        actionCallback: (data: any) => {
          if (data.active) {
            return {
              label: "Désactiver",
              title: "Désactiver ce compte",
              action: "switchActionsDeactivate",
              color: "text-danger ",
              icon: "fa fa-times",
            };
          }
          return {
            label: "Activer",
            title: "Activer ce compte",
            action: "switchActionsActivate",
            color: "text-success",
            icon: "fa fa-check",
          };
        },
      },
    ];

    const definitions = [
      new Field({
        name: "accountId",
        type: FieldType.NUMBER,
        primary: true,
        creatable: false,
        editable: false,
        serializeNew: false,
        serializeEdit: false,
        label: "#",
        renderKey: RENDER_ID,
        provider: Marchand.instance,
      }),
      new Field({
        name: "numero",
        label: "N° compte",
        creatable: false,
        editable: false,
        provider: Marchand.instance,
      }),
      new Field({
        name: "msisdn",
        label: "MSISDN",
        provider: Marchand.instance,
        formRowIndex: 2,
      }),
      new Field({
        name: "email",
        label: "Email",
        formRowIndex: 3,
        dataPath: "user.email",
        renderKey: RENDER_LONG_TEXT,
        customData: {
          renderOptions: {
            cuttoff: 14,
          },
        },
        provider: Marchand.instance,
      }),
      new Field({
        name: "solde",
        label: "Solde CDF",
        creatable: false,
        editable: false,
        renderKey: RENDER_AMOUNT,
        provider: Marchand.instance,
        customData: {
          renderOptions: {
            className: "min-w-150",
          },
        },
      }),
      new Field({
        name: "soldeUsd",
        label: "Solde USD",
        creatable: false,
        editable: false,
        renderKey: RENDER_AMOUNT_USD,
        provider: Marchand.instance,
        customData: {
          renderOptions: {
            className: "min-w-150",
          },
        },
      }),
      new Field({
        name: "accountType",
        label: "Type de compte",
        creatable: false,
        editable: false,
        renderKey: RENDER_ACCOUNT_TYPE,
        dataPath: "accountType.libelle",
        provider: Marchand.instance,
      }),
      new Field({
        name: "createDate",
        renderKey: RENDER_DATE,
        creatable: false,
        editable: false,
        label: "Date création",
        provider: Marchand.instance,
      }),
      new Field({
        name: "cni",
        label: "CNI",
        onTable: false,
        formRowIndex: 4,
        inputType: InputType.INPUT_FILE,
        dataPath: "user.cni",
        provider: Marchand.instance,
      }),
      new Field({
        name: "active",
        label: "Status",
        creatable: false,
        editable: false,
        renderKey: RENDER_STATUS,
        provider: Marchand.instance,
      }),
      new Field({
        name: "firstName",
        label: "Nom",
        formRowIndex: 0,
        onTable: false,
        inlineStyle: { padding: 0 },
        provider: Marchand.instance,
      }),
      new Field({
        name: "lastName",
        label: "Prénom",
        formRowIndex: 1,
        onTable: false,
        inlineStyle: { padding: 0 },
        provider: Marchand.instance,
      }),
      new Field({
        name: "address",
        label: "Adresse",
        formRowIndex: 5,
        onTable: false,
        inlineStyle: { padding: 0 },
        provider: Marchand.instance,
      }),
      new Field({
        name: "country",
        label: "Pays",
        formRowIndex: 6,
        onTable: false,
        provider: Marchand.instance,
        inputType: InputType.INPUT_SELECT,
        relationKey: "countries",
        emitters: [{ type: "value_change_country" }],
        serializeCallback: (item: any, v: any) => {
          const country = v.split("-")[0];
          const currency = v.split("-")[1];
          return {
            ...item,
            country,
            currency,
          };
        },
      }),
      new Field({
        name: "password",
        label: "Mot de passe",
        formRowIndex: 7,
        onTable: false,
        customClasses: "d-flex flex-column",
        inlineStyle: { padding: 0 },
        provider: User.instance,
        inputType: InputType.INPUT_PASSWORD,
        customData: {
          checkBoxData: {
            id: "passview",
            cls: "form-check-label",
            customLabel: "Afficher le mot de passe.",
            styles: { fontSize: "80%", marginTop: "3px" },
            checked: false,
            eventName: "passview_change",
          },
        },
        listeners: [
          {
            type: "passview_change",
            behaviour: "customAction",
            resolve: (v: any, customData?: any) => {
              return {
                checkBoxData: { ...customData?.checkBoxData, checked: v.value },
              };
            },
          },
        ],
      }),
      new Field({
        name: "password2",
        label: "Confirmer le MDP",
        formRowIndex: 8,
        serializeNew: false,
        onTable: false,
        serializeEdit: false,
        provider: User.instance,
        inputType: InputType.INPUT_PASSWORD_2,
        customData: {
          checkBoxData: {
            id: "passview",
            cls: "form-check-label",
            customLabel: "Afficher le mot de passe.",
            styles: {fontSize: "80%", marginTop: "0px"},
            checked: false,
            eventName: "passview_change",
            customInlineStyle: {
              marginTop: "5px",
              background: "rgb(255, 255, 255)"
            }
          },
        },
        validators: [
          {
            meta: { key: "password2" },
            validate: (meta: any) => User.instance.assertNotNull(meta),
          },
          {
            meta: {
              key: "password2",
              otherKey: "password",
              msg: "Les mots de passe doivent etre identiques",
            },
            validate: (meta: any) => User.instance.assertEquals(meta),
          },
          {
            meta: {
              key: "password2",
              otherKey: "password",
              msg:
                "Le mot de passe doit avoir au moins 1 lettre majuscule, 1 lettre minuscule, 1 chiffre, 1 caractère spécial et contenir au minimum 08 caractères.",
            },
            validate: (meta: any) => User.instance.assertPasswordStrenght(meta),
          },
        ],
        listeners: [
          {
            type: "passview_change",
            behaviour: "customAction",
            resolve: (v: any, customData?: any) => {
              return {
                checkBoxData: { ...customData?.checkBoxData, checked: v.value },
              };
            },
          },
        ],
      }),
      new Field({
        name: "actions",
        label: "Actions",
        dataPath: "accountId",
        creatable: false,
        editable: false,
        serializeNew: false,
        serializeEdit: false,
        serializeView: false,
        renderKey: RENDER_ACTIONS,
        provider: Marchand.instance,
        customData: {
          actions: [
            {
              actionCallback: (data: any) => {
                return {
                  label: "Détails",
                  title: "Afficher les détails",
                  action: "navigateActions",
                  link: `${window.location.pathname}/details/?id=${data.accountId}`,
                  color: "btn-info",
                  icon: "fa fa-eye",
                };
              },
            },
            {
              type: "btnGroup",
              color: "btn-primary",
              children:
                model.user.user?.profile === null
                  ? aChild
                  : model.accCrud.modification
                  ? aChild
                  : [],
            },
          ],
        },
      }),
    ];
    return definitions;
  }

  toView() {
    return [
      {
        title: false,
        rows: [
          { label: "Marchand ID", value: this.accountId },
          { label: "Nom", value: this.user.firstName },
          { label: "Prénom", value: this.user.lastName },
          { label: "Email", value: this.user.email },
          { label: "Téléphone", value: this.user.msisdn },
          { label: "Type de compte", value: this.accountType.libelle },
          { label: "Profile", value: this.profile?.libelle },
          { label: "Code PIN", value: this.pin },
          { label: "Status", value: this.active ? "Actif" : "Inactif" },
        ],
      },
    ];
  }

  switchAction(status: boolean) {
    return new Promise(async (resolve, reject) => {
      const res = await restClient.get(
        `/account/change/${this.accountId}/${status}`
      );
      if (res?.success && res?.data?.accountId > 0) {
        resolve(true);
        return;
      }
      resolve(false);
    });
  }


 
  initEditCallBack(props: any) {
    const user = User.instance;
    user.data.push({
      name: "account_type_id",
      value: props?.accountType?.accountTypeId,
    });
    user.data.push({
      name: "profile_id",
      value: props?.user?.profile?.id,
    });
    user.data.push({
      name: "devise_id",
      value: props?.devise?.id,
    });
    user.data.push({
      name: "country",
      value: props?.country,
    });
    user.data.push({
      name: "nomEntreprise",
      value: props?.nomEntreprise,
    });
    user.data.push({
      name: "idNational",
      value: props?.idNational,
    });
    user.data.push({
      name: "rccm",
      value: props?.rccm,
    });
  }

  public static getSelectMarchand(v: any) {
    return {
      label: v.msisdn,
      value: v.accountId,
      taux: "",
    };
  }

  serializeCallback(payload: any, props: any) {
    return {
      ...payload,
      pin: Math.floor(Math.random() * (9999 - 1000 + 1) ) + 1000,
      link: `${window.location.protocol}://${window.location.host}:${window.location.port}/signin/validate`,
      profil: "User",
      account_type_id: 5,
      supermarchand: BaseModel.getInstance().user.accountId
    }
  }
}

export { Marchand };

import React from "react";
import ReactDOM from "react-dom";
import firebase from "firebase";
import { Provider } from "react-redux";
import { store } from "./_helpers";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { App } from "./app/App";
import { firebaseConfig } from "./_config";

import "./_assets/themes/theme-dark.scss";


firebase.initializeApp(firebaseConfig);
firebase.analytics();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
); 

serviceWorkerRegistration.unregister();
reportWebVitals(console.log);

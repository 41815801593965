import { appConstants } from "../_constants";
import { BaseEntity } from "./BaseEntity";
import { FieldType } from "./enumerations";
import { Field } from "./Field";
import { BaseModel } from "../_models";
import { InputType } from ".";

const { RENDER_ACTIONS, RENDER_ID } = appConstants.keys.renderKeys;

class Client extends BaseEntity {
  public static readonly addTitle = "Ajouter un client";
  public static readonly showTitle = "Détails d'un client";
  public static readonly editTitle = "Modifier un client";
  public static readonly saveBtnTitle = "Enregistrer";
  public static readonly idField = "id";
  public static readonly processNewSuccessMsg =
    "Le client a été enregistré avec succès !";
  public static readonly processEditSuccessMsg =
    "Le client a été modifié avec succès !";
  public static readonly processNewPath = "/client/add";
  public static readonly processEditPath = "client/update/_ID_";
  public static instance: Client = new Client();
  public static instances = [];
  public static relations = [
    {
      dataPath: "operation/type/all",
      key: "operationTypes",
      resolve: (res: any) => {
        if (Array.isArray(res?.data)) {
          return res.data;
        }
        return [];
      },
    },
    {
      dataPath: "account/type/all",
      key: "accountTypes",
      resolve: (res: any) => {
        if (Array.isArray(res?.data)) {
          return res.data;
        }
        return [];
      },
    },
    {
      dataPath: "uiitem/all",
      key: "uiitems",
      resolve: (res: any) => {
        if (Array.isArray(res?.data)) {
          return res.data;
        }
        return [];
      },
    },
  ];

  public static get definitions(): Field[] {
    const model: any = BaseModel.getInstance();
    const aChildAll = [
      {
        label: "Modifier",
        title: "Modifer ce client",
        action: "editActions",
        color: "text-success",
        icon: "fa fa-edit",
      },
      {
        label: "Générer le fichier",
        title: "Générer le fichier",
        action: "generateActions",
        color: "text-info",
        icon: "fa fa-download",
      },
      {
        label: "Supprimer",
        title: "Supprimmer ce client",
        action: "deleteActions",
        color: "text-danger",
        icon: "fa fa-trash",
      },
    ];
    const aChildMod = [
      {
        label: "Modifier",
        title: "Modifer ce client",
        action: "editActions",
        color: "text-success",
        icon: "fa fa-edit",
      },
    ];
    const aChildDel = [
      {
        label: "Supprimer",
        title: "Supprimmer ce client",
        action: "deleteActions",
        color: "text-danger",
        icon: "fa fa-trash",
      },
    ];
    const definitions = [
      new Field({
        name: "id",
        type: FieldType.NUMBER,
        primary: true,
        creatable: false,
        editable: false,
        serializeNew: false,
        serializeEdit: false,
        label: "#",
        renderKey: RENDER_ID,
        provider: Client.instance,
      }),
      new Field({
        name: "name",
        label: "Nom",
        provider: Client.instance,
      }),

      new Field({
        name: "email",
        label: "Email",
        inputType: InputType.INPUT_EMAIL,
        provider: Client.instance,
      }),
      new Field({
        name: "password",
        label: "Mot de passe",
        onTable: false,
        editable: false,
        serializeView: false,
        serializeEdit: false,
        inputType: InputType.INPUT_PASSWORD,
        provider: Client.instance,
      }),
      new Field({
        name: "password2",
        label: "Conf. mot de passe",
        onTable: false,
        editable: false,
        serializeView: false,
        serializeEdit: false,
        inputType: InputType.INPUT_PASSWORD_2,
        provider: Client.instance,
      }),
      new Field({
        name: "description",
        label: "Description",
        inputType: InputType.INPUT_TEXTAREA,
        provider: Client.instance,
      }),
      new Field({
        name: "actions",
        label: "Actions",
        dataPath: "id",
        creatable: false,
        editable: false,
        serializeNew: false,
        serializeEdit: false,
        serializeView: false,
        renderKey: RENDER_ACTIONS,
        provider: Client.instance,
        customData: {
          renderOptions: {
            clsActions: "justify-content-center",
          },
          actions: [
            {
              label: "Détails",
              title: "Afficher les détails",
              action: "detailsActions",
              color: "btn-info",
              icon: "fa fa-eye",
            },
            {
              type: "btnGroup",
              color: "btn-primary",
              children:
                model.user.user?.profile === null
                  ? aChildAll
                  : model.confCrud.modification && model.confCrud.suppression
                  ? aChildAll
                  : model.confCrud.modification
                  ? aChildMod
                  : model.confCrud.suppression
                  ? aChildDel
                  : [],
            },
          ],
        },
      }),
    ];
    return definitions;
  }

  public get persoColumns(): any[] {
    return [
      {
        title: "#",
        data: "id",
      },
      {
        title: "Personnalisation",
        data: "id",
        render: (data: any, type: any, row: any) => {
          if (type === "display") {
            return `<p class="text-left"><b style="font-size:16px;">${row.name}</b><br><span>${row.description}</span></p>`;
          }
          return `${row.name} ${row.description}`;
        },
      },
      {
        title: "Valeur",
        data: "id",
        className: "w-50",
        render: (data: any, type: any, row: any) => {
          if (type === "display") {
            return `<input type="text" class="form-control form-control-sm w-100 uiId" data-id="${data}" />`;
          }
          return `${row.name} ${row.description}`;
        },
      },
    ];
  }

  public get typeAccountColumns(): any[] {
    return [
      {
        title: "#",
        data: "accountTypeId",
      },
      {
        title: "Libellé du type decompte",
        data: "accountTypeId",
        render: (data: any, type: any, row: any) => {
          if (type === "display") {
            return `<p class="text-left"><b style="font-size:16px;">${row.libelle}</b></p>`;
          }
          return `${row.libelle} `;
        },
      },
      {
        title: "Libellé personnalisé",
        data: "accountTypeId",
        className: "w-50",
        render: (data: any, type: any, row: any) => {
          if (type === "display") {
            return `<input type="text" class="form-control form-control-sm w-100 accountTypeId" data-id="${data}" />`;
          }
          return `${row.libelle}`;
        },
      },
    ];
  }

  public get typeOpColumns(): any[] {
    return [
      {
        title: "#",
        data: "operationTypeId",
      },
      {
        title: "Libellé du type d'opération",
        data: "operationTypeId",
        render: (data: any, type: any, row: any) => {
          if (type === "display") {
            return `<p class="text-left"><b style="font-size:16px;">${row.libelle}</b></p>`;
          }
          return `${row.libelle} `;
        },
      },
      {
        title: "Libellé personnalisé",
        data: "operationTypeId",
        className: "w-50",
        render: (data: any, type: any, row: any) => {
          if (type === "display") {
            return `<input type="text" class="form-control form-control-sm w-100 operationTypeId" data-id="${data}"  />`;
          }
          return `${row.libelle}`;
        },
      },
    ];
  }

  setOpValue(id: any, val: any) {
    const values = this.getValue("opvalues", []);
    const i = values.findIndex((x) => x.operationId === id);
    if (i > -1) {
      values[i].customLibelle = val;
    } else {
      values.push({
        customLibelle: val,
        operationId: id,
      });
    }
    this.updateValue("opvalues", values);
  }

  setUiValue(id: any, val: any) {
    const values = this.getValue("uivalues", []);
    const i = values.findIndex((x) => x.uiItemId === id);
    if (i > -1) {
      values[i].value = val;
    } else {
      values.push({
        value: val,
        uiItemId: id,
      });
    }
    this.updateValue("uivalues", values);
  }

  setAccountValue(id: any, val: any) {
    const values = this.getValue("accvalues", []);
    const i = values.findIndex((x) => x.accountId === id);
    if (i > -1) {
      values[i].customLibelle = val;
    } else {
      values.push({
        customLibelle: val,
        accountId: id,
      });
    }
    this.updateValue("accvalues", values);
  }

  serializeCallback(payload: any, props: any) {
    if (payload.modalData) {
      delete payload.modalData;
    }

    return {
      ...payload,
      accountTypes: this.getValue("accvalues", []),
      operationTypes: this.getValue("opvalues", []),
      values: this.getValue("uivalues", []),
    };
  }
  async initCustom(props: any) {
    await this.initNew();
    return {
      settings: {
        content: "clientform",
        title: "Ajouter un client",
        showSaveBtn: true,
      },
    };
  }

  getRows(tab: string) {
    switch (tab) {
      case "tabOne":
        this.updateValue("tab", "tabOne");
        return this.rows;
      case "tabTwo":
        this.updateValue("tab", "tabTwo");
        return this.rows;
      case "tabThree":
        this.updateValue("tab", "tabThree");
        return this.rows;
      case "tabFour":
        this.updateValue("tab", "tabFour");
        return this.rows;

      default:
        break;
    }
    return [];
  }

  toView() {
    return [
      {
        title: false,
        rows: [
          { label: "ID", value: this.id },
          { label: "Nom", value: this.name },
          { label: "Email", value: this.email },
          { label: "Description", value: this.description },
        ],
      },
    ];
  }

  getDeleteActionConfig() {
    return {
      path: `/uiitem/delete/${this.id}`,
      resolve: (res: any) => res?.success,
    };
  }
}

export { Client };

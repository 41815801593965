import React, { useEffect } from "react";
import { render } from "react-dom";
import { TableWrapper } from "../Table/TableWrapper";
import { AffectationCheckBox } from "./AffectationCheckBox";
import { SaveBtn } from "./SaveBtn";

export function AdminAffectations(props: any) {
  useEffect(() => {
    $(".active2.active3.sidebar-dropdown").find(".sidebar-submenu").slideUp();
    $(".active2.active3.sidebar-dropdown").removeClass("active");
  });

  return (
    <TableWrapper
      {...props}
      shadow={true}
      pageLength={5}
      retrieveTable={true}
      saveBtn={<SaveBtn />}
      createdRowCallBack={(row: any) => createdRowCallBack(row)}
    />
  );
}

const createdRowCallBack = (row: any) => {
  $(row)
    .find("span")
    .each(function (i: number, el: any) {
      if (el.id) {
        const domContainer = $(row).find(`#${el.id}`)[0];
        if (domContainer) {
          render(<AffectationCheckBox id={el.id} />, domContainer);
        }
      }
    });
};

import React, { useEffect, useState } from "react";
import { Account, User } from "../../../../../_entities";
import { AccountParametres } from "./AccountParametres";

export function AccountProfil(props: any) {
  const { model } = props;
  const acc = Account.instance;
  acc.populate(model.user);
  const [data, setData] = useState({
    ...props,
    account: acc,
    loading: true,
    profil: true,
    items: [],
  });
  const { account } = data;
  useEffect(() => {
    Promise.all([
      User.instance.initEdit({ ...account?.user }),
      account.initEdit({ ...account }),
    ]).then((res2) => {
      setData({
        ...data,
        account,
        loading: false,
      });
    });
  }, []);
  return (
    <div className={`bg-white box-shadow ${model.isClient ? " col-md-9 rounded" : ""}`}>
      <AccountParametres
        {...props}
        {...data}
        refreshAccount={(acc: Account) =>
          refreshUser(acc, data, setData, props)
        }
      />
    </div>
  );
}

const refreshUser = (acc: Account, data: any, setData: any, props: any) => {
  setData({ ...data, account: acc });
  const v = window.confirm(
    "Voulez vous vous reconnecter pour rafraichir vos données !"
  );
  if (v) {
    props.logout("/login");
  }
};

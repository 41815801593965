import { appConstants } from "../_constants";
import { BaseEntity } from "./BaseEntity";
import { FieldType } from "./enumerations";
import { Field } from "./Field";
import {BaseModel} from "../_models";

const { RENDER_ACTIONS, RENDER_ID } = appConstants.keys.renderKeys;

class ProductCategory extends BaseEntity {
  public static readonly addTitle = "Ajouter une catégorie de produit";
  public static readonly showTitle = "Détails d'une catégorie de produit";
  public static readonly editTitle = "Modifier une catégorie de produit";
  public static readonly saveBtnTitle = "Enregistrer";
  public static readonly idField = "productCategoryId";
  public static readonly processNewSuccessMsg =
    "La catégorie a été enregistré avec succès !";
  public static readonly processEditSuccessMsg =
    "La catégorie a été modifié avec succès !";
  public static readonly processNewPath = "/product/category/add";
  public static readonly processEditPath = "/product/category/update/_ID_";
  public static instance: ProductCategory = new ProductCategory();
  public static instances = [];
  public static relations = [];

  public static get definitions(): Field[] {
    const model: any = BaseModel.getInstance();
    const aChildAll = [
      {
        label: "Modifier",
        title: "Modifer cette catégorie",
        action: "editActions",
        color: "text-success",
        icon: "fa fa-edit",
      },
      {
        label: "Supprimer",
        title: "Supprimmer cette catégorie",
        action: "deleteActions",
        color: "text-danger",
        icon: "fa fa-trash",
      },
    ];
    const aChildMod = [
      {
        label: "Modifier",
        title: "Modifer cette catégorie",
        action: "editActions",
        color: "text-success",
        icon: "fa fa-edit",
      }
    ];
    const aChildDel = [
      {
        label: "Supprimer",
        title: "Supprimmer cette catégorie",
        action: "deleteActions",
        color: "text-danger",
        icon: "fa fa-trash",
      }
    ];

    const definitions = [
      new Field({
        name: "productCategoryId",
        type: FieldType.NUMBER,
        primary: true,
        creatable: false,
        editable: false,
        serializeNew: false,
        serializeEdit: false,
        label: "#",
        renderKey: RENDER_ID,
        provider: ProductCategory.instance,
      }),
      new Field({
        name: "libelle",
        label: "Libellé",
        provider: ProductCategory.instance,
      }),
      new Field({
        name: "description",
        label: "Description",
        provider: ProductCategory.instance,
      }),

      new Field({
        name: "actions",
        label: "Actions",
        dataPath: "productCategoryId",
        creatable: false,
        editable: false,
        serializeNew: false,
        serializeEdit: false,
        serializeView: false,
        renderKey: RENDER_ACTIONS,
        provider: ProductCategory.instance,
        customData: {
          renderOptions: {
            clsActions: "justify-content-center",
          },
          actions: [
            {
              label: "Détails",
              title: "Afficher les détails",
              action: "detailsActions",
              color: "btn-info",
              icon: "fa fa-eye",
            },
            {
              type: "btnGroup",
              color: "btn-primary",
              children:model.user.user?.profile===null?aChildAll:
                  model.prodCrud.modification && model.prodCrud.suppression?aChildAll:
                      model.prodCrud.modification?aChildMod:model.prodCrud.suppression?aChildDel:[]
            },
          ],
        },
      }),
    ];
    return definitions;
  }

  toView() {
    return [
      {
        title: false,
        rows: [
          { label: "Product category ID", value: this.productCategoryId },
          { label: "Libellé", value: this.libelle },
          { label: "Description", value: this.description },
        ],
      },
    ];
  }

  getDeleteActionConfig() {
    return {
      path: `/product/category/delete/${this.productCategoryId}`,
      resolve: (res: any) => res?.success,
    };
  }
}

export { ProductCategory };

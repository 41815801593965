import React from "react";
import { Link } from "react-router-dom";
import { appConstants } from "../../../_constants";
import { utils } from "../../../_helpers";
const { MODAL_ACTION } = appConstants.keys.eventKeys;

export function NavbarSubItem(props: any) {
  return (
    <div
      aria-labelledby="megamneu"
      className="dropdown-menu border-0 p-0 m-0 w-100"
    >
      <div className="container">
        <div className="row bg-white rounded-0 m-0 shadow-sm w-100">
          <div className="col-lg-6 col-xl-6">
            <div className="p-4">
              <div className="row">
                <div className="col-lg-6 mb-4">{getSubItem(0, props)}</div>
                <div className="col-lg-6 mb-4">{getSubItem(1, props)}</div>
                <div className="col-lg-6 mb-4">{getSubItem(4, props)}</div>
                <div className="col-lg-6 mb-4">{getSubItem(5, props)}</div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-xl-6">
            <div className="p-4">
              <div className="row">
                <div className="col-lg-6 mb-4">{getSubItem(2, props)}</div>
                <div className="col-lg-6 mb-4">{getSubItem(3, props)}</div>
                <div className="col-lg-6 mb-4">{getSubItem(6, props)}</div>
                <div className="col-lg-6 mb-4">{getSubItem(7, props)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const onClick = (e: any, props: any, item: any) => {
  const attributes = item.attributes || {};
  if (attributes?.action === "navigate") {
    return;
  }
  e.preventDefault();
  if (attributes?.customModal) {
    ($(attributes?.customModal) as any).modal({
      show: true,
      keyboard: true,
      backdrop: "static",
    });
    return;
  }
  utils().emitter.emit(MODAL_ACTION, {
    btn: attributes.action || "createActions",
    data: { ...props, ...attributes, content: attributes.content || "" },
  });
};

const getSubItem = (i: number, props: any) => {
  const { navItems } = props;
  if (typeof navItems[i] == "undefined") {
    return "";
  }
  return (
    <React.Fragment>
      <h6 className="font-weight-bold text-uppercase">{navItems[i].title}</h6>
      <ul className="list-unstyled">
        {navItems[i].subItems.map((item: any, i: number) => {
          return (
            <li className="nav-item" key={i}>
              <Link
                to={item.path}
                className="nav-link text-small pb-0 link-text"
                onClick={(e) => onClick(e, props, item)}
              >
                {item.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </React.Fragment>
  );
};

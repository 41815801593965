import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

export function InputRecaptcha(props: any) {
  const onChange = (e: any) => {
    console.log("Captcha value:", e);
    props.setValue(props.field.updateValue(e));
  };
  return (
    <div className="form-check" style={{ marginTop: "6px" }}>
      <ReCAPTCHA
        sitekey="6LdDh80ZAAAAAJKGgWIKw_veDK0c5MOckGkP6fFe"
        onChange={onChange}
      />
    </div>
  );
}

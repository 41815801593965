import React, { Component } from "react";

class InputSelect extends Component<any, any> {
  render() {
    const { freeze, label, name, onChange, value, items } = this.props;
    return (
      <div
        className="form-group row"
        style={{ margin: "0 0 1rem 0", width: "90%" }}
      >
        <div className="col-md-4" style={{ padding: "0 1rem 0 0" }}>
          <label
            htmlFor="colFormLabelSm"
            className=" col-form-label col-form-label-sm"
            style={{ float: "right" }}
          >
            {label}
          </label>
        </div>
        <div className="col-md-8" style={{ padding: 0 }}>
          <select
            name={name}
            className="form-control form-control-sm"
            onChange={(e) => onChange(e)}
            disabled={freeze}
            value={value}
            style={{ width: "100%" }}
          >
            <option value="">Selectionner ... </option>
            {items.map((item, i) => {
              return (
                <option key={i} value={item.id}>
                  {item.name}
                </option>
              );
            })}
          </select>
          <div className="invalid-feedback">Ce champ est requis.</div>
          <input name="valid" type="hidden" value="true" />
        </div>
      </div>
    );
  }
}

export { InputSelect };

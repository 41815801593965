import React, { useEffect, useState } from "react";
import { Affectation } from "../../../../_entities";
import { utils } from "../../../../_helpers";
import { AffectationAction } from "../../../../_interfaces";

export function AffectationCheckBox(props: any) {
  const { id } = props;

  const [checked, setChecked] = useState(false);

  const onChange = (e: any) => {
    setChecked(!checked);
    const resources = Affectation.instance.getValue("resources", []);
    const index = resources.indexOf(id);
    const isAll = id.indexOf("all") !== -1;
    let type = index > -1 ? "remove" : "push";
    if (isAll) {
      type = type === "remove" ? "removeAll" : "pushAll";
    }
    const res = reducer(resources, { type, id, index });
    Affectation.instance.updateValue("resources", res);
  };

  useEffect(() => {
    const resources = Affectation.instance.getValue("resources", []);
    const index = resources.indexOf(id);
    setChecked(index > -1);
    const handleChangeEvent = (res: any) => {
      setChecked(res);
    };
    utils().emitter.on(id, (res) => {
      handleChangeEvent(res);
    });

    return () => {
      utils().emitter.off(id, handleChangeEvent);
    };
  }, []);

  return (
    <input
      className="form-check-input affectionCheck pointer"
      type="radio"
      checked={checked}
      onClick={onChange}
      onChange={() => {}}
    />
  );
}

const reducer = (state: any, action: AffectationAction) => {
  const { type, id, index, items } = action;
  const copy = [...state];
  const resourceId = id.split("-")[2];
  const ids = ["write", "read", "edit", "delete"].map((i) =>
    id.split("all").join(i)
  );

  switch (type) {
    case "initItems":
      return items;

    case "push":
      copy.push(id);
      if (checkIfAll(copy, resourceId)) {
        copy.push(`fn-all-${resourceId}`);
        utils().emitter.emit(`fn-all-${resourceId}`, true);
      }
      break;

    case "remove":
      copy.splice(index, 1);
      const i = copy.indexOf(`fn-all-${resourceId}`);
      if (i > -1) {
        copy.splice(i, 1);
        utils().emitter.emit(`fn-all-${resourceId}`, false);
      }
      break;

    case "pushAll":
      copy.push(id);
      ids.forEach((i) => {
        if (copy.indexOf(i) < 0) copy.push(i);
        utils().emitter.emit(i, true);
      });
      break;

    case "removeAll":
      copy.splice(index, 1);
      ids.forEach((i) => {
        copy.splice(copy.indexOf(i), 1);
        utils().emitter.emit(i, false);
      });
      break;

    default:
      break;
  }

  return copy;
};

const checkIfAll = (copy: any[], id: string): boolean => {
  const ids = [
    `fn-write-${id}`,
    `fn-read-${id}`,
    `fn-edit-${id}`,
    `fn-delete-${id}`,
  ];
  for (let index = 0; index < ids.length; index++) {
    const element = ids[index];
    if (copy.indexOf(element) < 0) {
      return false;
    }
  }
  return true;
};

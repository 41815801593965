import React from "react";
import { AccountParent } from "./AccountParent";
import { Compte } from "./Compte";
import { Photo } from "./Photo";
import { Nfcard } from "./Nfcard";
import { Profil } from "./Profil";

export function TabsContent(props: any) {
  const { model, profil} = props;
  return (
    <div className="tab-content" id="v-pills-tabContent">
      <div
        className="tab-pane fade show active"
        id="home"
        role="tabpanel"
        aria-labelledby="v-pills-home-tab"
      >
        <Compte {...props} />
      </div>
      <div
        className="tab-pane fade"
        id="profil"
        role="tabpanel"
        aria-labelledby="v-pills-profile-tab"
      >
        <Profil {...props} />
      </div>
    <div
        className="tab-pane fade"
        id="nfcard"
        role="tabpanel"
        aria-labelledby="nfcard-tab"
    >
        <Nfcard {...props} />
    </div>
      <div
        className="tab-pane fade"
        id="photo"
        role="tabpanel"
        aria-labelledby="photo-tab"
      >
        <Photo {...props} />
      </div>
      {model.isAdmin && !profil && <AccountParent {...props} />}
    </div>
  );
}

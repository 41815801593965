import { appConstants } from "../_constants";
import { BaseEntity } from "./BaseEntity";
import { FieldType } from "./enumerations";
import { Field } from "./Field";
import {BaseModel} from "../_models";
import {restClient, utils} from "../_helpers";

const {
  RENDER_ID,
  RENDER_ACTIONS,
  RENDER_STATUS,
} = appConstants.keys.renderKeys;

class Workflows extends BaseEntity {
  public static readonly label: any;
  public static readonly className: any;
  public static readonly art: any;
  public static readonly editTitle = "Modifier un Workflow";
  public static readonly showTitle = "Détails d'un Workflow";
  public static instance: Workflows = new Workflows();
  public static instances = [];
  public static relations = [];

  public static get definitions(): Field[] {
    const model: any = BaseModel.getInstance();
    const aChildAll = [
      {
        label: "Modifier",
        title: "Modifer ce Workflow",
        action: "customActions",
        color: "text-dark",
        icon: "fa fa-edit",
      },
      {
        label: "Supprimer",
        title: "Supprimmer ce Workflow",
        action: "deleteActions",
        color: "text-danger",
        icon: "fa fa-trash",
      }
    ];
    const aChildDel = [
      {
        label: "Supprimer",
        title: "Supprimmer ce Workflow",
        action: "deleteActions",
        color: "text-danger",
        icon: "fa fa-trash",
      }
    ];
    const aChildMod = [
      {
        label: "Modifier",
        title: "Modifer ce Workflow",
        action: "customActions",
        color: "text-dark",
        icon: "fa fa-edit",
      }
    ];
    const definitions = [
      new Field({
        name: "workflowId",
        type: FieldType.NUMBER,
        primary: true,
        creatable: false,
        editable: false,
        serializeNew: false,
        serializeEdit: false,
        label: "#",
        renderKey: RENDER_ID,
        provider: Workflows.instance,
      }),
      new Field({
        name: "label",
        label: "Label",
        provider: Workflows.instance,
        customData: {
          renderOptions: {
            className: "min-w-150",
          },
        },
      }),
      new Field({
        name: "nbrSteps",
        label: "Nombre d'étapes",
        type: FieldType.NUMBER,
        provider: Workflows.instance,
      }),
      new Field({
        name: "actions",
        label: "Actions",
        dataPath: "workflowId",
        creatable: false,
        editable: false,
        serializeNew: false,
        serializeEdit: false,
        serializeView: false,
        renderKey: RENDER_ACTIONS,
        provider: Workflows.instance,
        customData: {
          renderOptions: {
            clsActions: "justify-content-center",
          },
          actions: [
            {
              label: "Détails",
              title: "Afficher les détails",
              action: "detailsActions",
              color: "btn-info",
              icon: "fa fa-eye",
            },
            {
              type: "btnGroup",
              color: "btn-primary",
              children:model.user.user?.profile === null
                  ? aChildAll
                  : model.wflCrud.modification && model.wflCrud.suppression
                      ? aChildAll
                      : model.wflCrud.modification
                          ? aChildMod
                          : model.wflCrud.suppression
                              ? aChildDel
                              : [],
            },
          ],
        },
      }),
    ];
    return definitions;
  }

  toView() {
    console.log("THIS OHHHH", this);
    let valRow:any = [];
    this.steps.map((step, index)=>{
      let val = {
        label:"Validateur Etape "+step.stepLevel,
        value:step.validator.firstName+" "+step.validator.lastName,
        stepLevel:step.stepLevel
      };
      valRow.push(val);
      valRow.sort((one, two) => (one.stepLevel < two.stepLevel ? -1 : 1));
    });
    return [
      {
        title: "Information générales",
        rows: [
          { label: "Workflow ID", value: this.workflowId },
          { label: "Label", value: this.label },
          { label: "Nombre d'étapes", value: this.nbrSteps},
        ],
      },
      {
        title: "Validateurs",
        rows: valRow,
      }
    ];
  }

  getDeleteActionConfig() {
    return {
      path: `/workflows/delete/${this.workflowId}`,
      resolve: (res: any) => res?.success,
    };
  }

  // getCustomProcessEditPath(payload: any) {
  //   return encodeURI(
  //     `/country/update/${this.id}?nom=${payload.nomFr}_AMP_blacklist=${this.blacklist}_AMP_countrycode=${payload.code}_AMP_countrysymbole=${payload.symbole}`
  //   );
  // }

  // getSwitchActionConfig(status: boolean) {
  //   return {
  //     path: `/country/change/${this.countrycode || this.code}/${status}`,
  //     resolve: (res: any) => res?.data?.status === "OK",
  //   };
  // }
  public static getSelectWorkflow(v: any) {
    console.log("V ICIC",v);
    return {
      label: v.label,
      value: v.workflowId
    };
  }

  async initCustom(props: any) {
    return {
      settings: {
        content: "updateWorkflow",
        title: "Modifier ce workflow",
        showSaveBtn: true,
        action: "updateWorkflow",
        message: "",
        validatorsToSend : [],
        newLabel:"",
        nbrSteps: 0
      },
    };
  }
}

export { Workflows };

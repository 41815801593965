import React from "react";
import { Link } from "react-router-dom";

export function CustomLink(props: any) {
  const { to, label, cls, inlineStyle } = props;

  return (
    <Link className={cls} to={to} style={inlineStyle}>
      {label}
    </Link>
  );
}

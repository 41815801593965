import * as React from "react";
import {
  Route,
  Redirect,
  RouteProps,
  RouteComponentProps,
} from "react-router-dom";
import { utils } from "../_helpers";
import { BaseModel } from "../_models";

interface PrivateRouteProps extends RouteProps {
  model: BaseModel;
}

export class PrivateRoute extends Route<PrivateRouteProps & any> {
  render() {
    const { model, render } = this.props;
    const component: any = this.props.component;

    const { pathname } = window.location;
    return (
      <Route
        render={(props: RouteComponentProps) => {
          if (!model.isLogin) {
            return <Redirect to="/login" />;
          }

          if (!utils().checkAccess(model.user, pathname)) {
            return <Redirect to={model.userPath} />;
          }

          if (component) {
            return React.createElement(component);
          }

          if (render) {
            return render(props);
          }
        }}
      />
    );
  }
}

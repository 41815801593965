import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { utils } from "../../../../_helpers";
import { PropsFromRedux } from "../../../../_reducers";

export function Kpiitem(props: PropsFromRedux & any) {
  const { kpi } = props;

  const initialConfig = {
    error: false,
    loading: true,
    value: "Chargement ...",
  };
  const [config, setConfig] = useState(initialConfig);

  useEffect(() => {
    const handleKpiChange = (data: any) => {
      setConfig({
        loading: false,
        error: data?.error,
        value: data?.value,
      });
    };
    utils().emitter.on(kpi.key, handleKpiChange);
    return () => {
      utils().emitter.off(kpi.key, handleKpiChange);
    };
  }, []);

  const { loading, error, value } = config;
  return (
    <div className="col-md-4">
      <Link
        to={kpi.path}
        className="btn-box big span4 d-flex align-items-center w-100 justify-content-around box-shadow"
      >
        <div className="icon-box ml-3">
          <i className={kpi.icon} aria-hidden="true"></i>
        </div>
        <div className="icon-text-box mr-3 d-flex flex-column">
          <div>
            <b className={error ? "text-danger" : loading ? "text-muted" : ""}>
              {value}
              {error && "Erreur !"}
            </b>
          </div>
          <div>
            <p className="text-muted">{kpi.title}</p>
          </div>
        </div>
      </Link>
    </div>
  );
}

import React from "react";

export function AccountOwner(props: any) {
  const { account } = props;

  return (
    <h5 className="profile-title">
      Compte N°
      <span style={{ fontFamily: "Ubuntu-RegularItalic" }}>
        {account.numero}
      </span>{" "}
      de type{" "}
      <span style={{ fontFamily: "Ubuntu-RegularItalic" }}>
        {account.accountType?.libelle}
      </span>
    </h5>
  );
}

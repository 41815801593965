import React, { useEffect, useState } from "react";

export function Details(props: any) {
  const { data, errorCallBack } = props;
  const [details, setDetails] = useState([]);
  useEffect(() => {
    if (typeof data?.toView === "function") {
      setDetails(data.toView());
    } else if (Array.isArray(data)) {
      setDetails(data as any);
    } else {
      setDetails([]);
      if (typeof data === "object") {
        if (typeof errorCallBack === "function") {
          errorCallBack("Impossible d'afficher les détails");
        }
      }
    }
  }, [data]);
  return (
    <table className="table table-striped table-fixed">
      {details.map((item: any, i: number) => {
        const datas: any[] = [];
        if (item.title) {
          datas.push(<Header title={item.title} key={"h" + i} />);
        }
        datas.push(<Body rows={item.rows} key={"b" + i} />);
        return datas;
      })}
    </table>
  );
}

export function Header(props: any) {
  return (
    <thead className="thead-dark">
      <tr>
        <th scope="col" colSpan={2} style={{ padding: "0.4rem" }}>
          <h6 className="text-center" style={{ margin: 0 }}>
            {props.title}
          </h6>
        </th>
      </tr>
    </thead>
  );
}

export function Body(props: any) {
  return (
    <tbody>
      {props.rows.map((row, j) => {
        return <Row {...row} key={j} />;
      })}
    </tbody>
  );
}

export function Row(props: any) {
  if (props.label === "img") {
    if (props.images?.length) {
      return (
        <tr className="detail-row">
          <td colSpan={2} className="value-detail-row">
            <div className="row">
              {props.images.map((f, i) => {
                return (
                  <div className="col-md-3 py-2" key={i}>
                    <img
                    src={"data:image/png;base64," + f.img}
                      style={{ maxWidth: "100px", maxHeight: "100px" }}
                    />
                  </div>
                );
              })}
            </div>
          </td>
        </tr>
      );
    }
    return (
      <tr className="detail-row">
        <td colSpan={2} className="value-detail-row">
          <img
            src={"data:image/png;base64," + props.value}
            alt="IMG"
            style={{ maxWidth: "538px", margin: "auto" }}
          />
        </td>
      </tr>
    );
  }
  return props.label !== false ? (
    <tr className="detail-row">
      <td className="label-detail-row">{props.label}</td>
      <td className="value-detail-row">{props.value}</td>
    </tr>
  ) : (
    <tr className="detail-row">
      <td colSpan={2} className="value-detail-row">
        {props.value}
      </td>
    </tr>
  );
}

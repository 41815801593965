import React, { useEffect, useRef, useState } from "react";
import { appConstants } from "../../../../_constants";
import { utils, history } from "../../../../_helpers";
import { PropsFromRedux } from "../../../../_reducers";
import { TableContent } from "./TableContent";

const {
  TABLE_DATA_CHANGE,
  TABLE_DATA_FILTER,
  TABLE_DATA_LOADING,
  TABLE_DATA_ERROR,
  TABLE_TITLE_CHANGE,
} = appConstants.keys.eventKeys;

export function Table(props: PropsFromRedux & any) {
  const { model, searchRef, customTableSettings, listTitle } = props;
  const ref = useRef(null);
  const loaderRef = useRef(null);
  const errorRef = useRef(null);

  const [config, setConfig] = useState(
    customTableSettings || model.tableConfig
  );
  const onRetry = () => {
    if (errorRef.current) {
      $(errorRef.current as any).css("display", "none");
    }
    if (loaderRef.current) {
      $(loaderRef.current as any).css("display", "flex");
    }
    model.refreshDataTableData(props);
  };

  useEffect(() => {
    const handleTableDataChangeEvent = (res: any) => {
      setConfig({ ...model.tableConfig, ...res, loading: false });
      if (!res.success) {
        utils().emitter.emit(TABLE_DATA_ERROR, true);
      }
    };

    const handleTableDataFilterEvent = (res: any) => {
      setConfig({ ...model.tableConfig, ...res, loading: true });
    };

    const handleTableDataLoadingEvent = (res: any) => {
      setTimeout(() => {
        if (loaderRef.current) {
          $(loaderRef.current as any).css("display", res ? "flex" : "none");
        }
      }, 400);
    };

    const handleTableDataErrorEvent = (res: any) => {
      setTimeout(() => {
        if (errorRef.current) {
          $(errorRef.current as any).css("display", res ? "flex" : "none");
        }
      }, 400);
    };
    utils().emitter.on(TABLE_DATA_LOADING, (res) => {
      handleTableDataLoadingEvent(res);
    });
    utils().emitter.on(TABLE_DATA_ERROR, (res) => {
      handleTableDataErrorEvent(res);
    });
    utils().emitter.on(TABLE_DATA_FILTER, (res) => {
      handleTableDataFilterEvent(res);
    });
    utils().emitter.on(TABLE_DATA_CHANGE, (res) => {
      handleTableDataChangeEvent(res);
    });
    if (!customTableSettings) {
      model.refreshDataTableData(props);
    }

    const unsubscribe = history.listen((location, action) => {
      setConfig(model.tableConfig);
      model.refreshDataTableData(props);
    });

    return () => {
      utils().emitter.off(TABLE_DATA_CHANGE, handleTableDataChangeEvent);
      utils().emitter.off(TABLE_DATA_FILTER, handleTableDataChangeEvent);
      utils().emitter.off(TABLE_DATA_LOADING, handleTableDataLoadingEvent);
      utils().emitter.off(TABLE_DATA_ERROR, handleTableDataErrorEvent);
      unsubscribe();
    };
  }, []);

  return (
    <>
      {!config && <div />}
      {config && (
        <TableContent
          {...props}
          tabRef={ref}
          searchRef={searchRef}
          loaderRef={loaderRef}
          errorRef={errorRef}
          {...config}
          id={props.id}
          cls={props.cls}
          onRetry={onRetry}
        />
      )}
    </>
  );
}

import React, {Component} from "react";
import {restClient, utils} from "../../../../_helpers";
import {InputTextAPG} from "../../../common/APG/InputTextAPG";
import {InputSelect} from "../../../common/APG/InputSelect";
import {TextFieldAPG} from "../../../common/APG/TextFieldAPG";
import {stringify} from "querystring";
import {formatGroupLabel} from "react-select/src/builtins";

class WorkflowFormUpdate extends Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            freeze: true,
            stepNumber: null,
            label: "",
            steps: [],
            err: false,
            errmsg: "",
            finish: false,
            success: false,
            validator:"",
            validators: [],
            valList:[],
            validatorsToSend:[]
        };
        // this.handleClick = this.handleClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeLabel = this.handleChangeLabel.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this);
    }

    componentDidMount() {
        console.log("WORKFLOW COURANT",this.props);
        console.log("WORKFLOW COURANT",this.props.settings);
        this.setState({
            stepNumber: this.props.data.data.nbrSteps,
            label: this.props.data.data.label,
            // validators: this.props.data.data.steps,
        });
        this.getVAlidators();
        // this.validatorsFieldsU(this.props.data.data.nbrSteps, this.props.data.data.steps);
    }

    handleChange(e) {
        e.preventDefault();
        this.clearArray(this.state.validatorsToSend);
        this.clearArray(this.state.valList);
        const {name, value} = e.target;
        if(value>=6){
            this.setState({[name]: 5});
            this.initValidatorsToSend(5);
            this.validatorsFields(5);
        }else {
            this.setState({[name]: value});
            this.initValidatorsToSend(value);
            this.validatorsFields(value);
        }
    }
    initValidatorsToSend(taille:number){
        for(let i = 0; i<taille; i++){
            this.state.validatorsToSend[i]="";
            // this.setValidatorTosend(i, "");
        }
    }
    setValidatorTosend(name:any, value:any){
        let validatorsToSend = [...this.state.validatorsToSend];
        // let val: any;
        // val = value;
        validatorsToSend[name] = value;
        this.setState({validatorsToSend});
    }

    handleChangeLabel(e) {
        e.preventDefault();
        const {name, value} = e.target;
        this.setState({[name]: value});
        this.props.stateSetter(
                {
                settings: {
                    content: "updateWorkflow",
                    title: "Modifier ce workflow",
                    showSaveBtn: true,
                    action: "updateWorkflow",
                    message: "",
                    validatorsToSend : this.state.validatorsToSend,
                    newLabel:value,
                    nbrSteps: this.state.stepNumber
                }});
        console.log("Le nouveau setting",this.props.settings);
    }

    handleChangeSelect(e) {
        e.preventDefault();
        const {name, value} = e.target;
        console.log("Les validateurs à envoyer après change",this.state.validatorsToSend);
        this.state.validatorsToSend[name]=value;
        // this.setValidatorTosend(name, value);
        this.props.stateSetter(
          {
            settings: {
                content: "updateWorkflow",
                title: "Modifier ce workflow",
                showSaveBtn: true,
                action: "updateWorkflow",
                message: "",
                validatorsToSend : this.state.validatorsToSend,
                newLabel:this.state.label,
                nbrSteps: this.state.stepNumber
            }});
    }

    clearArray(array) {
        while (array.length) {
            array.pop();
        }
    }

    getVAlidators(){
        restClient.get(`/user/allsupervisor`).then((res) => {
            if (res?.data) {
                res.data.map((user)=>{
                    let val = {
                        id:user.userId,
                        name:user.firstName+" "+user.lastName
                    };
                    this.state.validators.push(val);
                    // let validators = [...this.state.validators];
                    // validators.push(val);
                    // this.setState({validators});
                });
                this.validatorsFields(this.props.data.data.nbrSteps);
                this.setState({
                    freeze:false,
                    // validators:arrVal
                });
            }
        });
        let valid:any=[];
        for(let i=0; i<this.props.data.data.steps.length; i++){
            let val = {
                id:this.props.data.data.steps[i].validator.userId,
                name:this.props.data.data.steps[i].validator.firstName+" "+this.props.data.data.steps[i].validator.lastName,
                step: i
            };
            this.state.valList.push(val);
            valid.push(val.id);
            // let valList = [...this.state.valList];
            // valList.push(val);
            // this.setState({valList});
            // valid.push(val.id);
        }
        this.props.stateSetter(
            {
                settings: {
                content: "updateWorkflow",
                title: "Modifier ce workflow",
                showSaveBtn: true,
                action: "updateWorkflow",
                message: "",
                validatorsToSend : valid,
                newLabel:this.props.data.data.label,
                nbrSteps: this.props.data.data.nbrSteps
            }});
        this.setState({
            validatorsToSend:valid
        });
    }

    validatorsFields(nombre:number){
        var rows:any = [];
        for (var i = 0; i < nombre; i++) {
            rows.push(
                <div className="form-group row w-100 m-3" key={i}>
                    <div className="col-md-6" style={{ padding: "0 1rem 0 0" }}>
                        <label
                            htmlFor="colFormLabelSm"
                            className=" col-form-label col-form-label-sm"
                        >
                            Validateur {i+1}
                        </label>
                    </div>
                    <div className="col-md-6" style={{ padding: 0 }}>
                        <select
                            name={i.toString()}
                            className="form-control form-control-sm"
                            onChange={this.handleChangeSelect}
                            required={true}
                            style={{width: "100%"}}
                        >
                            <option value="">Sélectionner un validateur... </option>
                            {this.state.validators?.map((item, j) => {
                                return <option key={j} value={item.id} selected={this.state.valList.some(e => e.id === item.id && e.step===i)}>{item.name}</option>
                            })}
                        </select>
                    </div>
                </div>
            );
        }
        this.props.stateSetter(
            {
                settings: {
                    content: "updateWorkflow",
                    title: "Modifier ce workflow",
                    showSaveBtn: true,
                    action: "updateWorkflow",
                    message: "",
                    validatorsToSend : this.state.validatorsToSend,
                    newLabel:this.state.label,
                    nbrSteps: nombre
                }});
        this.setState({
            steps:rows,
            // validatorsToSend:[]
            // stepNumber:nombre
        });
    }

    render() {
        return (
            <div className='list-group'>
                {this.props.settings.message &&(<span className={this.props.settings.cls}>{this.props.settings.message}</span>)}
                <div
                    className="list-group-item flex-column align-items-start"
                    style={{ paddingLeft: 5, paddingRight: 5 , marginBottom: 5}}
                >
                        <form
                            id="account-details-form"
                            className="ui error form auth-form w-100"
                        >
                            <div className="form-group row w-100 m-0">
                                <div className="col-md-6" style={{ padding: "0 1rem 0 0" }}>
                                    <label
                                        htmlFor="colFormLabelSm"
                                        className=" col-form-label col-form-label-sm"
                                    >
                                        Label du workflow
                                    </label>
                                    <input
                                        className="form-control form-control-sm"
                                        name="label"
                                        type="text"
                                        required={true}
                                        value={this.state.label}
                                        onChange={this.handleChangeLabel}
                                        // disabled={true}
                                        style={{ backgroundColor: "#eee" }}
                                    />
                                </div>
                                <div className="col-md-6" style={{ padding: 0 }}>
                                    <label
                                        htmlFor="colFormLabelSm"
                                        className=" col-form-label col-form-label-sm"
                                    >
                                        Nombre d'étapes
                                    </label>
                                    <input
                                        className="form-control form-control-sm"
                                        name="stepNumber"
                                        type="number"
                                        value={this.state.stepNumber}
                                        required={true}
                                        onChange={this.handleChange}
                                        style={{ backgroundColor: "#eee" }}
                                    />
                                </div>
                            </div>
                            <div className='list-group'>
                                <div
                                    className="bg-white mt-3"
                                    style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.125)" }}
                                >
                                    <label>Validateurs</label>
                                </div>
                                <div className="container">
                                    <>{this.state.steps}</>
                                </div>
                            </div>

                        </form>
                </div>
            </div>
        );
    }
}
export {WorkflowFormUpdate};

import React, { useEffect, useState } from "react";
import { Account } from "../../../../../_entities";
import { restClient } from "../../../../../_helpers";
import { Details } from "../../../../common/Details/Details";

export function AccountParent(props: any) {
  const [data, setData] = useState({
    loading: true,
    parent: new Account(),
    edit: false,
    num: "",
  });

  useEffect(() => {
    if (props?.account?.parentId) {
      restClient.get(`account/find/${props?.account?.parentId}`).then((res) => {
        if (res?.success) {
          const parent = new Account();
          parent.populate(res?.data);
          setData({
            ...data,
            parent,
            num: parent.numero,
          });
        }
      });
    }
  }, [props?.account?.parentId]);

  const onClickEdit = (e: any) => {
    e.preventDefault();
    setData({ ...data, edit: !data.edit });
  };

  const onChange = (e: any) => {
    e.preventDefault();
    setData({ ...data, num: e.target.value });
    props?.account.updateValue("parent", e.target.value);
  };

  const { edit, parent, num } = data;

  return (
    <div
      className="tab-pane fade"
      id="parent"
      role="tabpanel"
      aria-labelledby="v-pills-parent-tab"
    >
      <div className="w-100 d-flex justify-content-center flex-column align-items-center p-3">
        {!edit && parent && (parent as any)?.user && (
          <Details {...props} data={parent} />
        )}

        {edit && (
          <div className="form-group row m-0 w-100 d-flex justify-content-center flex-column align-items-center p-3">
            <div className="col-md-6" style={{ padding: "0 1rem 0 0" }}>
              <label
                htmlFor="colFormLabelSm"
                className=" col-form-label col-form-label-sm"
              >
                Numéro du compte parent
              </label>
              <input
                className="form-control form-control-sm"
                name="num"
                type="text"
                value={num}
                onChange={onChange}
              />
            </div>
          </div>
        )}

        {!edit && !(parent as any)?.user && (
          <h4 className="text-dark">Ce compte ne possède pas de parent !</h4>
        )}

        <button
          className={`btn ${edit ? "btn-danger" : "btn-primary"} btn-sm mt-2`}
          onClick={onClickEdit}
          disabled={!props.model.confCrud?.modification && props.model.user.user.profil!=="Admin"}
        >
          <i
            className={`fa ${edit ? "fa-times" : "fa-edit"} mr-1`}
            aria-hidden="true"
          ></i>
          {!edit && "Modifier le parent"}
          {edit && "Annuler"}
        </button>
      </div>
    </div>
  );
}

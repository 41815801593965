import React, { useEffect, useState } from "react";
import { primaryColor, utils } from "../../../../_helpers";

export function NextBtn(props: any) {
  const { errorCallBack, stateSetter } = props;
  const provider  = props.data?.provider || props.model?.provider;
  
  const [freeze, setFreeze] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const onClick = (e: any) => {
    e.preventDefault();
    stateSetter({ submitted: true, loading: true });
    if (provider.validate()) {
      if (typeof provider?.processNext === "function") {
        const nextData = provider.processNext(props);
        if (nextData) {
          stateSetter(nextData);
        }
      } else {
        errorCallBack("Une erreur s'est produite. Merci de reéssayer !");
      }
    }
    stateSetter({ loading: false });
  };

  useEffect(() => {
    setIsMounted(true);
    const handle = (v: any) => {
      if (isMounted) {
        setFreeze(v);
      }
    };
    utils().emitter.on("hide_next", (v: any) => handle(v));
    return () => {
      setIsMounted(false);
      utils().emitter.off("hide_next", handle);
    };
  }, [isMounted]);

  return (
    <button
      type="button"
      className="btn  btn-sm box-shadow"
      onClick={onClick}
      disabled={freeze}
      style={{backgroundColor:primaryColor(),color:"white"}}
    >
      Suivant
      <i className="fa fa-arrow-right ml-1"></i>
    </button>
  );
}

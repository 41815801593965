import { appConstants } from "../_constants";
import { getEmpata2BankFields } from "../_fields";
import { restClient, utils } from "../_helpers";
import { BaseModel } from "../_models";
import { BaseEntity } from "./BaseEntity";
import { FieldType, InputType } from "./enumerations";
import { Field } from "./Field";

const statuses = {
  WITHDRAWN: "Retiré",
  WAITING_WITHDRAW: "En attente de retrait",
  PENDING: "En cours",
  SUCCESS: "Succès",
  TRAITÉ: "Traité",
  FAILED: "Échoué",
};

const {
  RENDER_BENEFICIARE,
  RENDER_EMETTEUR,
  RENDER_ACTIONS,
  RENDER_STATUS_OPERATION,
  RENDER_OPERATION_TYPE,
  RENDER_AMOUNT_TRANSACTION,
  RENDER_ID,
  RENDER_DATETIME,
  RENDER_CLIENT_TRANSACTION,
  RENDER_OPERATION_CURRENCY,
  RENDER_DOUBLE,
} = appConstants.keys.renderKeys;

const { USER_BALANCE_CHANGE, USER_BALANCES_CHANGE } =
  appConstants.keys.eventKeys;

let processing = false;

class Transaction extends BaseEntity {
  public static readonly label: any;
  public static readonly addTitle = "Faire une transaction";
  public static readonly showTitle = "Détails de la transaction";
  public static readonly saveBtnTitle = "Initier la transaction";
  public static readonly processNewSuccessMsg =
    "Votre transaction a été créée avec succès. Merci !";
  public static readonly className: any;
  public static readonly art: any;
  public static instance: Transaction = new Transaction();
  public static instances = [];
  public static countries: [
    { id: "BJ"; name: "Bénin" },
    { id: "GW"; name: "Guinée Bissau" },
    { id: "BF"; name: "Burkina Faso" },
    { id: "CM"; name: "Cameroun" },
    { id: "CI"; name: "Côte d'Ivoire" },
    { id: "CD"; name: "République Démocratique du Congo" },
    { id: "CG"; name: "Congo" },
    { id: "GQ"; name: "Guinée Équatoriale" },
    { id: "GA"; name: "Gabon" },
    { id: "GM"; name: "Gambie" },
    { id: "GN"; name: "Guinée" },
    { id: "ML"; name: "Mali" },
    { id: "NE"; name: "Niger" },
    { id: "SN"; name: "Sénégal" },
    { id: "SL"; name: "Sierra Léone" },
    { id: "TD"; name: "Tchad" },
    { id: "TG"; name: "Togo" }
  ];
  public static relations = [
    {
      dataPath: "operation/type/all",
      key: "operationTypes",
      resolve: (res: any) => {
        if (Array.isArray(res?.data)) {
          const data = Transaction.filterOperationTypes(res?.data);
          return data.filter((o) => o.active).map((v: any) => ({
            label: v.libelle,
            value: v.operationTypeId,
          }));
        }
        return [];
      },
    },
    {
      dataPath: "currency/all",
      key: "currencies",
      resolve: (res: any) => {
        if (Array.isArray(res?.data)) {
          return res.data.map((v: any) => Transaction.getSelectCurrency(v));
        }
        return [];
      },
    },
    {
      dataPath: "country/all",
      key: "countries",
      resolve: (res: any) => {
        if (Array.isArray(res?.data)) {
          return res.data.map((v: any) => ({
            label: v.nomFr,
            value: v.id,
          }));
        }
        return [];
      },
    },
    {
      dataPath: "account/all",
      key: "accounts",
      resolve: (res: any) => {
        if (Array.isArray(res?.data)) {
          return res.data.map((v: any) => ({
            label: v.msisdn,
            value: v.msisdn,
            accountTypeId: v.accountType?.accountTypeId || 0,
          }));
        }
        return [];
      },
    },
    {
      key: "prices",
      dataPath: (meta?: any) => ({
        path: "account/type/all",
        children: {
          path: "account/type/find/_PLACE_HOLDER_",
          id_field: "accountTypeId",
        },
      }),
      resolve: (res: any) => {
        const data = Array.isArray(res.data) ? res.data : [];
        const prices: any[] = [];
        data.forEach((accountTypesDetail: any) => {
          accountTypesDetail?.data?.content?.accountTypePrices?.forEach(
            (accountTypePrice: any) => {
              prices.push(accountTypePrice);
            }
          );
        });
        return prices.sort((a, b) => a.price.valMin - b.price.valMin);
      },
    },
  ];

  public static get definitions(): Field[] {
    const model = BaseModel.getInstance();
    const isClient = model.isClient;
    const isAdmin = model.isAdmin;

    const defs = [
      new Field({
        name: "operationId",
        type: FieldType.NUMBER,
        primary: true,
        creatable: false,
        editable: false,
        serializeNew: false,
        serializeEdit: false,
        label: "#",
        renderKey: isClient ? RENDER_CLIENT_TRANSACTION : RENDER_ID,
        provider: Transaction.instance,
      }),
      new Field({
        name: "reference",
        label: "Référence",
        creatable: false,
        editable: false,
        serializeNew: false,
        onTable: !isClient,
        provider: Transaction.instance,
      }),
      new Field({
        name: "sender",
        label: "Emetteur",
        renderKey: RENDER_EMETTEUR,
        dataPath: "operationId",
        provider: Transaction.instance,
        relationKey: "accounts",
        onTable: !isClient,
        creatable: isAdmin && !model.isAdminErecette,
        inputType: isAdmin ? InputType.INPUT_SELECT : InputType.INPUT_TEXT,
        listeners: [
          {
            type: "value_change_operation_type_id",
            behaviour: "toggleLayout",
            resolve: (v: any, customData?: any) => {
              if (parseInt(v.value) === 1) {
                return "col-md-12 d-none";
              }
              return "col-md-12";
            },
          },
        ],
        validators: [
          {
            meta: {
              key: "sender",
              otherKey: "operationType",
              expetedVal: 1,
              checkCondition: (v: any) => parseInt(v) === 1,
            },
            validate: (meta: any) =>
              Transaction.instance.assertNotNullConditionnal(meta),
          },
        ],
        serializeCallback: (item: any, v: any) => {
          const operationType = Transaction.instance.getValue(
            "operationType",
            0
          );
          const model = BaseModel.getInstance();
          const sender = model.user?.msisdn || 0;
          if (operationType === 1) {
            return {
              ...item,
              sender,
            };
          }
          let p = { ...item };
          if (v !== "") {
            Object.assign(p, { sender: v });
          }
          return p;
        },
      }),
      new Field({
        name: "cardNumber",
        label: "Numero de carte",
        provider: Transaction.instance,
        formLayout: "col-md-12 d-none",
        onTable: false,
        customData: {
          hasCards: true,
        },
        listeners: [
          {
            type: "value_change_operation_type_id",
            behaviour: "toggleLayout",
            resolve: (v: any, customData?: any) => {
              if (parseInt(v.value) !== 12) {
                return "col-md-12 d-none";
              }
              return "col-md-12";
            },
          },
        ],
        validators: [
          {
            meta: {
              key: "cardNumber",
              otherKey: "operationType",
              expetedVal: 1,
              checkCondition: (v: any) => parseInt(v) === 12,
            },
            validate: (meta: any) =>
              Transaction.instance.assertNotNullConditionnal(meta),
          },
        ],
        serializeCallback: (item: any, v: any) => {
          const operationType = Transaction.instance.getValue(
            "operationType",
            0
          );
          if (operationType !== 12) {
            return item;
          }
          let p = { ...item };
          if (v !== "") {
            Object.assign(p, { cardNumber: v });
          }
          return p;
        },
      }),
      new Field({
        name: "cardExpirationYear",
        label: "Année d'expiration",
        provider: Transaction.instance,
        formLayout: "col-md-12 d-none",
        onTable: false,

        listeners: [
          {
            type: "value_change_operation_type_id",
            behaviour: "toggleLayout",
            resolve: (v: any, customData?: any) => {
              if (parseInt(v.value) !== 12) {
                return "col-md-12 d-none";
              }
              return "col-md-12";
            },
          },
        ],
        validators: [
          {
            meta: {
              key: "cardExpirationYear",
              otherKey: "operationType",
              expetedVal: 1,
              checkCondition: (v: any) => parseInt(v) === 12,
            },
            validate: (meta: any) =>
              Transaction.instance.assertNotNullConditionnal(meta),
          },
        ],
        serializeCallback: (item: any, v: any) => {
          const operationType = Transaction.instance.getValue(
            "operationType",
            0
          );
          if (operationType !== 12) {
            return item;
          }
          let p = { ...item };
          if (v !== "") {
            Object.assign(p, { cardExpirationYear: v });
          }
          return p;
        },
      }),
      new Field({
        name: "cardExpirationMonth",
        label: "Mois d'expiration",
        provider: Transaction.instance,
        formLayout: "col-md-12 d-none",
        onTable: false,

        listeners: [
          {
            type: "value_change_operation_type_id",
            behaviour: "toggleLayout",
            resolve: (v: any, customData?: any) => {
              if (parseInt(v.value) !== 12) {
                return "col-md-12 d-none";
              }
              return "col-md-12";
            },
          },
        ],
        validators: [
          {
            meta: {
              key: "cardExpirationMonth",
              otherKey: "operationType",
              expetedVal: 1,
              checkCondition: (v: any) => parseInt(v) === 12,
            },
            validate: (meta: any) =>
              Transaction.instance.assertNotNullConditionnal(meta),
          },
        ],
        serializeCallback: (item: any, v: any) => {
          const operationType = Transaction.instance.getValue(
            "operationType",
            0
          );
          if (operationType !== 12) {
            return item;
          }
          let p = { ...item };
          if (v !== "") {
            Object.assign(p, { cardExpirationMonth: v });
          }
          return p;
        },
      }),
      new Field({
        name: "cardSecurityCode",
        label: "Code de securité",
        provider: Transaction.instance,
        formLayout: "col-md-12 d-none",
        onTable: false,
        listeners: [
          {
            type: "value_change_operation_type_id",
            behaviour: "toggleLayout",
            resolve: (v: any, customData?: any) => {
              if (parseInt(v.value) !== 12) {
                return "col-md-12 d-none";
              }
              return "col-md-12";
            },
          },
        ],
        validators: [
          {
            meta: {
              key: "cardSecurityCode",
              otherKey: "operationType",
              expetedVal: 1,
              checkCondition: (v: any) => parseInt(v) === 12,
            },
            validate: (meta: any) =>
              Transaction.instance.assertNotNullConditionnal(meta),
          },
        ],
        serializeCallback: (item: any, v: any) => {
          const operationType = Transaction.instance.getValue(
            "operationType",
            0
          );
          if (operationType !== 12) {
            return item;
          }
          let p = { ...item };
          if (v !== "") {
            Object.assign(p, { cardSecurityCode: v });
          }
          return p;
        },
      }),
      new Field({
        name: "walletId",
        label: "Numéro du Wallet",
        provider: Transaction.instance,
        formLayout: "col-md-12 d-none",
        onTable: false,
        required: false,
        listeners: [
          {
            type: "value_change_operation_type_id",
            behaviour: "toggleLayout",
            resolve: (v: any, customData?: any) => {
              if (parseInt(v.value) !== 17) {
                return "col-md-12 d-none";
              }
              return "col-md-12";
            },
          },
        ],
        validators: [
          {
            meta: {
              key: "walletId",
              otherKey: "operationType",
              expetedVal: 1,
              checkCondition: (v: any) => parseInt(v) === 17,
            },
            validate: (meta: any) =>
              Transaction.instance.assertNotNullConditionnal(meta),
          },
        ],
        serializeCallback: (item: any, v: any) => {
          const operationType = Transaction.instance.getValue(
            "operationType",
            0
          );
          if (operationType !== 17) {
            return item;
          }
          let p = { ...item };
          if (v !== "") {
            Object.assign(p, { walletId: v });
          }
          return p;
        },
      }),
      
      new Field({
        name: "receiver",
        label: "Bénéficiaire",
        renderKey: RENDER_BENEFICIARE,
        dataPath: "operationId",
        onTable: !isClient,
        // required: model.isEntrepriseStandart ? false : true,
        required: false,
        emitters: [{ type: "value_change_receiver" }],
        provider: Transaction.instance,
        customData: {
          validateData: {
            force: false,
            cls: "text-danger",
            msg: "Ce champ est requis",
          },
        },
        listeners: [
          {
            type: "value_change_receiver",
            behaviour: "customActionAsync",
            resolve: async (v: any, customData?: any) => {
              return await Transaction.handleChangeReceiver(v, customData);
            },
          },
          {
            type: "value_change_operation_type_id",
            behaviour: "toggleLayout",
            resolve: (v: any, customData?: any) => {
              const vv = parseInt(v.value);
              if ([12, 14, 15, 17, 18, 19, 20,25,26].indexOf(vv) !== -1) {
                return "col-md-12 d-none";
              }
              return "col-md-12";
            },
          },
        ],
        serializeCallback: (item: any, v: any) => {
          const operationType = Transaction.instance.getValue(
            "operationType",
            0
          );
          if (operationType === 1) {
            return {
              ...item,
              account: v,
            };
          }
          if (operationType === 14) {
            return {
              ...item,
              telBeneficiare: v,
            };
          }

          return {
            ...item,
            receiver: v,
          };
        },
      }),

      new Field({
        name: "receiver_details",
        label: "Infos compte",
        provider: Transaction.instance,
        inputType: InputType.INPUT_DETAILS,
        onTable: false,
        serializeNew: false,
        serializeEdit: false,
        required: false,
        serializeView: false,
        formLayout: "col-md-12 d-none",
        customData: {},
        listeners: [
          {
            type: "value_change_receiver_callback",
            behaviour: "customActionAsyncCallback",
            resolve: (v: any, customData?: any) => {
              if (!v) {
                return {
                  formLayout: "col-md-12 d-none",
                  customData: {},
                };
              }
              return {
                formLayout: "col-md-12",
                customData: {
                  nom: v?.user?.firstName || "non renseigné",
                  prenom: v?.user?.lastName || "non renseigné",
                  email: v?.user?.email || "non renseigné",
                },
              };
            },
          },
        ],
      }),
      new Field({
        name: "amount",
        label: "Montant",
        renderKey: RENDER_AMOUNT_TRANSACTION,
        provider: Transaction.instance,
        onTable: !isClient,
        required: false,
        emitters: [{ type: "value_change_amount" }],
        customData: {
          renderOptions: {
            className: "min-w-150",
          },
          inputFraisData: {
            frais: 0,
            total: 0,
            tva: 0,
            cls: "d-none",
          },
          inputUniteData: {
            frais: 0,
            total: 0,
            cls: "d-none",
          },
          checkBoxData: {
            id: "fees_in",
            cls: "form-check-label",
            customLabel: "Frais inclus ?",
            styles: { fontSize: "80%", marginLeft: "19px" },
            checked: false,
            eventName: "fees_in_change",
            customInlineStyle: { marginLeft: "1px", marginTop: "7px" },
            customLayoutInlineStyle: {
              marginTop: "6px",
              padding: 0,
            },
          },
        },
        listeners: [
          {
            type: "value_change_amount",
            behaviour: "customActionAsync",
            resolve: async (v: any, customData?: any) =>
              Transaction.handleAmountChange({ v, customData }),
            },
          {
            type: "value_change_operation_type_id",
            behaviour: "changeField",
            resolve: async (v: any, field: Field) => {
              const display =
                v?.value !== 7 &&
                v?.value !== 17 &&
                v?.value !== 18 &&
                v?.value !== 19 &&
                
                v?.value !== 20
                  ? ""
                  : "none";

              const label = v?.value !== 7 ? "Montant" : "Unité";
              const customData = {
                ...field.customData,

                checkBoxData: {
                  ...field.customData.checkBoxData,
                  customLayoutInlineStyle: {
                    ...field.customData.checkBoxData.customLayoutInlineStyle,
                    display,
                  },
                },
              };

              field.customData = customData;
              field.label = label;
              return field;
            },
          },
          {
            type: "fees_in_change",
            behaviour: "customAction",
            resolve: (v: any, customData?: any) => {
              Transaction.instance.updateValue("feesIn", v?.value);
              const amount = Transaction.instance.getValue("amount", 0);
              console.log('customData == ' ,customData);
              return {
                type: "value_change_amount",
                customData,
                data: { value: amount },
              };
            },
          },
          {
            type: "value_change_operation_type_id",
            behaviour: "toggleLayout",
            resolve: (v: any, customData?: any) => {
              const vv = parseInt(v.value);
              if ([15,26].indexOf(vv) !== -1) {
                return "col-md-12 d-none";
              }
              return "col-md-12";
            },
          },
        ],
        serializeCallback: (item: any, v: any) => {
          const feesIn = Transaction.instance.getValue("feesIn", false);

          return {
            ...item,
            amount: parseFloat(v),
            feesIn,
          };
        },
      }),
      new Field({
        name: "description",
        label: "Description",
        onTable: false,
        required: false,
        provider: Transaction.instance,
        inputType: InputType.INPUT_TEXTAREA,
        listeners: [
          {
            type: "value_change_operation_type_id",
            behaviour: "toggleLayout",
            resolve: (v: any, customData?: any) => {
              const vv = parseInt(v.value);
              if ([15, 17, 18, 19,25,26].indexOf(vv) !== -1) {
                return "col-md-12 d-none";
              }
              return "col-md-12";
            },
          },
        ],
      }),
      new Field({
        name: "fees",
        creatable: false,
        editable: false,
        serializeNew: false,
        serializeEdit: false,
        label: "Frais",
        onTable: !isClient,
        renderKey: RENDER_DOUBLE,
        provider: Transaction.instance,
      }),
      new Field({
        name: "deductedFees",
        creatable: false,
        editable: false,
        serializeNew: false,
        serializeEdit: false,
        label: "Frais déduit",
        onTable:
          model.isAdminFinancier &&
          window.location.href.indexOf("/financier/frais") !== -1,
        renderKey: RENDER_DOUBLE,
        provider: Transaction.instance,
      }),
      new Field({
        name: "currency",
        label: "Dévise",
        dataPath: "operationId",
        required: false,
        formRowIndex: 1,
        onTable: !isClient,
        relationKey: "currencies",
        renderKey: RENDER_OPERATION_CURRENCY,
        inputType: InputType.INPUT_SELECT,
        emitters: [{ type: "value_change_devise" }],
        provider: Transaction.instance,
        listeners: [
          {
            type: "value_change_devise",
            behaviour: "customActionAsync",
            resolve: async (v: any, customData?: any) =>
              Transaction.handleDeviseChange({ v, customData }),
          },
          {
            type: "value_change_operation_type_id",
            behaviour: "toggleLayout",
            resolve: (v: any, customData?: any) => {
              const vv = parseInt(v.value);
              if ([15,26].indexOf(vv) !== -1) {
                return "col-md-12 d-none";
              }
              return "col-md-12";
            },
          },
        ],
      }),
      new Field({
        name: "operationType",
        label: "Type d'opération",
        renderKey: RENDER_OPERATION_TYPE,
        formRowIndex: 0,
        relationKey: "operationTypes",
        inputType: InputType.INPUT_SELECT,
        provider: Transaction.instance,
        onTable: !isClient,
        emitters: [{ type: "value_change_operation_type_id" }],
        customData: {
          checkBoxData: {
            id: "depot_entreprise",
            cls: "form-check-label",
            customLabel: "Dépot entreprise ?",
            styles: { fontSize: "80%", marginLeft: "19px" },
            checked: false,
            eventName: "depot_entreprise_change",
            customInlineStyle: { marginLeft: "1px", marginTop: "7px" },
            customLayoutInlineStyle: {
              marginTop: "6px",
              padding: 0,
              display: !isClient ? "" : "none",
            },
          },
        },
        listeners: [
          {
            type: "value_change_operation_type_id",
            behaviour: "customAction",
            resolve: (v: any, customData?: any) => {
              const display = v?.value === 1 ? "" : "none";
              return {
                checkBoxData: {
                  ...customData.checkBoxData,
                  customLayoutInlineStyle: {
                    ...customData.checkBoxData.customLayoutInlineStyle,
                    display,
                  },
                },
              };
            },
          },
          {
            type: "depot_entreprise_change",
            behaviour: "customAction",
            resolve: (v: any, customData?: any) => {
              Transaction.instance.updateValue("entreprise", v?.value);
              return { customData };
            },
          },
          {
            type: "value_change_operation_type_id",
            behaviour: "customAction",
            resolve: (v: any, customData?: any) => {
              if (v.value === 20) {
                Transaction.instance.updateValue(
                  "receiver",
                  BaseModel.getInstance().user.msisdn
                );
              }
              return { customData };
            },
          },
        ],

        serializeCallback: (item: any, v: any) => {
          const entreprise = Transaction.instance.getValue("entreprise", false);

          return { ...item, operationType: v, entreprise };
        },
      }),

      new Field({
        name: "country",
        label: "Pays",
        formRowIndex: 2,
        onTable: false,
        required: false,
        formLayout: model.isEntrepriseStandart
          ? "col-md-12"
          : "col-md-12 d-none",
        relationKey: "countries",
        inputType: InputType.INPUT_SELECT,
        provider: Transaction.instance,
        listeners: [
          {
            type: "value_change_operation_type_id",
            behaviour: "toggleLayout",
            resolve: (v: any, customData?: any) => {
              const vv = parseInt(v.value);
              if ([14, 17, 18, 19].indexOf(vv) === -1) {
                return "col-md-12 d-none";
              }
              return "col-md-12";
            },
          },
        ],
        
      }),
      new Field({
        name: "city",
        label: "Ville",
        formRowIndex: 2,
        onTable: false,
        required: false,
        formLayout: model.isEntrepriseStandart
          ? "col-md-12"
          : "col-md-12 d-none",
        provider: Transaction.instance,
        listeners: [
          {
            type: "value_change_operation_type_id",
            behaviour: "toggleLayout",
            resolve: (v: any, customData?: any) => {
              const vv = parseInt(v.value);
              if ([14].indexOf(vv) === -1) {
                return "col-md-12 d-none";
              }
              return "col-md-12";
            },
          },
        ],
      }),
      new Field({
        name: "token",
        label: "Jeton",
        formRowIndex: 2,
        onTable: false,
        required: false,
        formLayout: "col-md-12 d-none",
        provider: Transaction.instance,
        listeners: [
          {
            type: "value_change_operation_type_id",
            behaviour: "toggleLayout",
            resolve: (v: any, customData?: any) => {
              const vv = parseInt(v.value);
              if ([26].indexOf(vv) === -1) {
                return "col-md-12 d-none";
              }
              return "col-md-12";
            },
          },
        ],
      }),


      new Field({
        name: "operationDate",
        renderKey: RENDER_DATETIME,
        label: "Date",
        creatable: false,
        editable: false,
        onTable: !isClient,
        provider: Transaction.instance,
        customData: {
          renderOptions: {
            className: "min-w-150",
          },
        },
        serializeCallback: (item: any, v: any) => {
          const date = new Date().toJSON();
          return {
            ...item,
            date,
          };
        },
      }),
      new Field({
        name: "status",
        label: "Status",
        creatable: false,
        editable: false,
        serializeNew: false,
        onTable: !isClient,
        renderKey: RENDER_STATUS_OPERATION,
        provider: Transaction.instance,
        customData: {
          renderOptions: {
            className: "min-w-100",
          },
        },
      }),
      new Field({
        name: "actions",
        label: "Actions",
        dataPath: "operationId",
        creatable: false,
        editable: false,
        serializeNew: false,
        onTable: !isClient,
        serializeEdit: false,
        serializeView: false,
        renderKey: RENDER_ACTIONS,
        provider: Transaction.instance,
        customData: {
          actions: isAdmin
            ? [
                {
                  type: "btnGroup",
                  color: "btn-primary",
                  children:
                    model.user.user?.profile === null
                      ? [
                          {
                            label: "Détails",
                            title: "Afficher les détails",
                            action: "detailsActions",
                            color: "text-info",
                            icon: "fa fa-eye",
                          },
                          //
                          // {
                          //   label: "Rembourser",
                          //   title: "Rembourser la transaction",
                          //   action: "customActions",
                          //   color: "text-dark",
                          //   icon: "fa fa-reply",
                          //   processkey: "refund",
                          // },
                          {
                            label: "Valider",
                            title: "Valider la transaction",
                            action: "customActions",
                            color: "text-success",
                            icon: "fa fa-check",
                            processkey: "validation",
                            shouldShow: function (data: any) {
                              if (
                                data?.operationType?.operationTypeId === 25 &&
                                data?.status === "PENDING"
                              ) {
                                return true;
                              }
                              return false;
                            },
                          },
                          {
                            label: "Corriger",
                            title: "Corriger la transaction",
                            action: "customActions",
                            color: "text-danger",
                            icon: "fa fa-cogs",
                          },
                        ]
                      : model.txCrud.modification
                      ? [
                          {
                            label: "Détails",
                            title: "Afficher les détails",
                            action: "detailsActions",
                            color: "text-info",
                            icon: "fa fa-eye",
                          },
                          {
                            label: "Valider",
                            title: "Valider la transaction",
                            action: "customActions",
                            color: "text-success",
                            icon: "fa fa-check",
                            processkey: "validation",
                            shouldShow: function (data: any) {
                              if (
                                data?.operationType?.operationTypeId === 25 &&
                                data?.status === "PENDING"
                              ) {
                                return true;
                              }
                              return false;
                            },
                          },
                          // {
                          //   label: "Rembourser",
                          //   title: "Rembourser la transaction",
                          //   action: "customActions",
                          //   color: "text-dark",
                          //   icon: "fa fa-reply",
                          //   processkey: "refund",
                          // },
                          {
                            label: "Corriger",
                            title: "Corriger la transaction",
                            action: "customActions",
                            color: "text-danger",
                            icon: "fa fa-cogs",
                          },
                        ]
                      : [],
                },
              ]
            : [
                {
                  label: "Détails",
                  title: "Afficher les détails",
                  action: "detailsActions",
                  color: "btn-info",
                  icon: "fa fa-eye",
                },
              ],
        },
      }),
    ];
    if (model.isEntrepriseStandart) {
      return Transaction.instance.appendTransfertStandartFields(defs);
    }
    if (isClient || model.isAdminErecette) {
      return [...defs, ...getEmpata2BankFields(Transaction.instance)];
    }
    return defs;
  }

  /**
   * getSelectCurrency
   */
  public static getSelectCurrency(v: any) {
    return {
      label: v.nom,
      value: v.code,
      taux: v.taux,
    };
  }

  public static getSelectCountry(v: any) {
    return {
      label: v.nomFr,
      value: v.code,
      id: v.id,
    };
  }

  /**
   * handleChangeReceiver
   */
  public static async handleChangeReceiver(v: any, customData?: any) {
    if (v?.value?.length > 7) {
      const res = await restClient.get(`account/find/byMsisdn/${v.value}`);
      const id = res?.data?.accountId || 0;
      if (!(id > 0)) {
        return {
          type: "value_change_receiver_callback",
          customData: {
            validateData: {
              force: true,
              cls: "text-warning text-70",
              msg: "Ce compte bénéficiaire n'est pas connu dans la base de donnée e-Mpata.",
            },
          },
        };
      }
      return {
        type: "value_change_receiver_callback",
        data: res.data,
        customData: {
          validateData: {
            force: false,
            cls: "text-danger",
            msg: "Ce champ est requis.",
          },
        },
      };
    }
    return {
      type: "value_change_receiver_callback",
      customData: {
        validateData: {
          force: false,
          cls: "text-danger",
          msg: "Ce champ est requis",
        },
      },
    };
  }

  /**
   * handleChangeTnx
   */
  public static async handleChangeTnx(v: any, customData?: any) {
    if (v?.value?.length > 7) {
      const res = await restClient.get(`operation/findbyreference/${v.value}`);
      const data = Array.isArray(res?.data) ? res.data[0] : {};
      const id = data?.operationId || 0;
      if (!(id > 0)) {
        return {
          type: "value_change_tnx_callback",
          customData: {
            validateData: {
              force: true,
              cls: "text-warning text-70",
              msg: "Cette reférence de transaction n'est pas connu dans la base de donnée e-Mpata.",
            },
          },
        };
      }
      if (data?.status === "WITHDRAWN") {
        utils().emitter.emit("hide_next", true);
      } else {
        utils().emitter.emit("hide_next", false);
      }
      return {
        type: "value_change_tnx_callback",
        data: data,
        customData: {
          validateData: {
            force: false,
            cls: "text-danger",
            msg: "Ce champ est requis.",
          },
        },
      };
    }

    return {
      type: "value_change_tnx_callback",
      customData: {
        validateData: {
          force: false,
          cls: "text-danger",
          msg: "Ce champ est requis",
        },
      },
    };
  }

  /**
   * handleDeviseChange
   */
  public static async handleDeviseChange(data: any) {
    const { v, customData } = data;
    let operationTypeId = Transaction.instance.getValue("operationType", 0);
    if (operationTypeId === 20) {
      customData.checkBoxData = {
        ...customData.checkBoxData,
        current: v?.value === "USD" ? "CDF" : "USD",
      };
      return customData;
    }
  }

  /**
   * handleAmountChange
   */
  public static async handleAmountChange(data: any) {
    const feesIn = Transaction.instance.getValue("feesIn", false);
    const { v, customData } = data;
    let to = "";
    let operationTypeId = Transaction.instance.getValue("operationType", 0);
    let currentCode = Transaction.instance.getValue("currency", "");
    if (currentCode === "CDF") {
      to = "USD";
    } else {
      to = "CDF";
    }
    if (!processing) {
      processing = true;
      let data: any = {};
      let data2: any = {};
      let frais: any = {};

      if (operationTypeId === 7) {
        data2 = await Transaction.instance.convertUnitToCDF(v);
        data = await Transaction.instance.getFraisData({ value: data2.total });
      } else {
        frais = await Transaction.instance.getFees(
          v,
          currentCode,
          operationTypeId
        );
        let fees = currentCode === "USD" ? frais.feesInUSD : frais.feesInCDF;
        let total = feesIn ? Number(v.value) : Number(fees) + Number(v.value);
        Transaction.instance.updateValue("tva", frais.tva);
        data = { frais: fees, total: total, tva: frais.tva };
      }

      if (operationTypeId === 20) {
        data2 = await Transaction.instance.convertAmount(v, currentCode);
        data = await Transaction.instance.getFraisData({ value: data2.total });
        customData.checkBoxData = {
          ...customData.checkBoxData,
          current: to,
          operationType: operationTypeId,
          customLayoutInlineStyle: {
            ...customData.checkBoxData.customLayoutInlineStyle,
            display: "none",
          },
        };
      }
      processing = false;
      return {
        customData: {
          ...customData,
          inputFraisData: {
            ...data,
            cls: v?.value ? "" : "",
          },
          current: currentCode,
          inputUniteData: {
            ...data2,
            cls: v?.value && operationTypeId === 7 ? "" : "d-none",
          },
        },
      };
    }

    return { customData };
  }

  public static filterOperationTypes(data: any[]) {
    const model = BaseModel.getInstance();
    if (model.isAdminFinancier) {
      return data.filter((d: any) => {
        return [2, 3, 4].indexOf(d.operationTypeId) !== -1;
      });
    } else if (model.isAdminErecette) {
      return data.filter((d: any) => {
        return [4, 25].indexOf(d.operationTypeId) !== -1;
      });
    } else if (model.isAdmin) {
      return data.filter((d: any) => {
        return [1, 2, 3, 4, 7, 9, 10].indexOf(d.operationTypeId) !== -1;
      });
    } else if (model.isClient) {
      if (
        model.user?.accountType?.accountTypeId === 2 ||
        model.user?.accountType?.accountTypeId === 3
      ) {
        return data.filter((d: any) => {
          return [1, 2, 3, 4, 7, 20,25,24,26].indexOf(d.operationTypeId) !== -1;
        });
      }
      return data.filter((d: any) => {
        return [2, 3, 4, 7, 20,25,24].indexOf(d.operationTypeId) !== -1;
      });
    } else if (model.isMarchandOrSurperMarchand) {
      return data.filter((d: any) => {
        return [3, 4, 7, 12, 20,24,26].indexOf(d.operationTypeId) !== -1;
      });
    } else if (model.isEntreprise) {
      if (model.path.indexOf("/standards") !== -1) {
        return data.filter((d: any) => {
          return [14, 15, 20].indexOf(d.operationTypeId) !== -1;
        });
      }
      return data.filter((d: any) => {
        return [3, 4, 7, 12, 17, 18, 19, 20].indexOf(d.operationTypeId) !== -1;
      });
    }
    return [];
  }

  public get hasNext(): boolean {
    const model = BaseModel.getInstance();
    if (model.isAdmin) {
      return false;
    }
    const step = this.getValue("step", 1);
    const hideNext = this.getValue("hideNext", false);
    return step < 2 && !hideNext;
  }

  /**
   * processNext
   */
  public processNext(props: any) {
    const step = this.getValue("step", 1);
    this.updateValue("step", step + 1);

    const { mode, content, title, hideFooter, submitted } = props;

    this.updateValue("prevState", {
      mode,
      content,
      title,
      hideFooter,
      submitted,
    });

    return {
      mode: "customActions",
      content: "pinvalidator",
      title: "Validation",
      hideFooter: true,
      submitted: false,
    };
  }

  /**
   * processPrev
   */
  public processPrev(props: any) {
    const step = this.getValue("step", 1);
    this.updateValue("step", step - 1);
    return this.getValue("prevState", {});
  }

  /**
   * processValidatePin
   */
  public async processValidatePin(props: any, codePin: string) {
    const res = await restClient.get(
      `/operation/pin/${codePin}/${utils().getUserAccountId()}`
    );
    return res?.data?.content || false;
  }

  /**
   * initNewCallBack
   */
  public initNewCallBack() {
    const model = BaseModel.getInstance();
    if (!model.isAdmin || model.isAdminErecette) {
      this.updateValue("sender", model.user?.msisdn || "");
    }
    if (model.isEntrepriseStandart) {
      this.updateValue("operationType", 14);
      this.updateValue("currency", "CDF");
      this.updateValue("country", 52);
    }
    this.updateValue("sendSMS", false);
    this.updateValue("smss", [
      {
        label: "Non",
        value: false
      },
      {
        label: "Oui",
        value: true
      }
    ]);
  }

  /**
   * processNewCallBack
   */
  public processNewCallBack(res: any) {
    this.refreshBalances();
    if (Array.isArray(res.data)) {
      return res.data[0] || res.data;
    }
    return res.data;
  }

  /**
   * handleFilter
   */
  public async handleFilter(data: any[], props: any) {
    const { filter } = props;
    if (filter) {
      const { period } = filter;
      if (period) {
        if (
          [
            "amount_asc",
            "amount_desc",
            "deposit",
            "withdraw",
            "transfer",
            "fees",
          ].indexOf(period) !== -1
        ) {
          switch (period) {
            case "deposit":
              return data.filter((o) => o.operationType?.operationTypeId === 1);
            case "withdraw":
              return data.filter((o) => o.operationType?.operationTypeId === 2);
            case "transfer":
              return data.filter(
                (o) =>
                  o.operationType?.operationTypeId === 3 ||
                  o.operationType?.operationTypeId === 4
              );
            case "fees":
              return data.filter((o) => !o.operationType);
            default:
              break;
          }
        }
      }
    }
    return data;
  }

  /**
   * refreshBalance
   */
  public refreshBalance() {
    utils().emitter.emit(USER_BALANCE_CHANGE, -1);
    restClient
      .get(`/account/solde/${utils().getUserAccountId() || 0}`)
      .then((res) => {
        utils().emitter.emit(USER_BALANCE_CHANGE, res?.data || 0);
      });
  }

  /**
   * refreshBalances
   */
  public refreshBalances() {
    utils().emitter.emit(USER_BALANCES_CHANGE, -1);
    restClient
      .get(`/account/soldes/${utils().getUserAccountId() || {}}`)
      .then((res) => {
        utils().emitter.emit(USER_BALANCES_CHANGE, res?.data.content || {});
      });
  }

  toView() {
    const status = (statuses as any)[this.status] || this.status;
    
    const section1 = [
      { label: "Transaction ID", value: this.operationId },
      { label: "Reférence", value: this.reference },
      {
        label: "Montant",
        value: utils().formatAmount(
          this.devise ? this.devise.code : "CDF",
          this.amount
        ),
      },
      { label: "Status", value: status },
      {
        label: "Type de transaction",
        value: this.operationType ? this.operationType.libelle : "Frais",
      },
      {
        label: "Date de l'opération",
        value: utils().formatDate("DD/MM/YYYY [à] H:mm:ss", this.operationDate),
      },
      {
        label: "Frais",
        value: utils().formatAmount(
          this.devise ? this.devise.code : "CDF",
          this.fees
        ),
      },
    ];
    if (this.withdrawToken) {
      section1.splice(2, 0, { 
        label: "Token de retrait", 
        value: this.withdrawToken 

      });
    }
    if (
      this.operationType?.operationTypeId === 14 ||
      this.operationType?.operationTypeId === 15
    ) {
      section1.push({
        label: "TVA",
        value: utils().formatAmount(
          this.devise ? this.devise.code : "CDF",
          this.tva
        ),
      });
    }
    section1.push({ label: "Description", value: this.description });
    let data = [
      {
        title: "Informations générales",
        rows: section1,
      },
    ];

    const model = BaseModel.getInstance();
    if (model.isEntrepriseStandart) {
      data.push({
        title: "Localisation",
        rows: [
          { label: "Pays", value: this.country?.nomFr || "Inconnu" },
          { label: "Ville", value: this.city || "Inconnu" },
        ],
      });
    }
    const typeOp = this.operationType?.operationTypeId || 0;
    data = this.getUsersInfos(data, typeOp);
    data = this.getAdminInfos(data);
    return data;
  }

  getAdminInfos(data: any[]) {
    const model = BaseModel.getInstance();
    const isAdmin = model.isAdmin;
    if (isAdmin) {
      if (this.administrator) {
        data.push(
          this.getSimpleSection(
            "Informations sur l'administrateur",
            this.administrator
          )
        );
      }
    }
    return data;
  }

  getSimpleSection(title: string, value: any) {
    return {
      title,
      rows: [{ label: false, value }],
    };
  }

  getUsersInfos(data: any[], typeOp: number) {
    const amount = this.amount;
    const receiver = amount > 0 ? this.account : this.other;
    const sender = amount <= 0 ? this.account : this.other;
    if (typeOp === 0) {
      data.push(
        this.getSimpleSection("Informations sur le bénéficiaire", "eMpata")
      );
    } else if (typeOp === 1) {
      if (amount > 0) {
        data.push(
          this.getUserSection(receiver, "Informations sur le bénéficiaire")
        );
      } else {
        data.push(this.getUserSection(sender, "Informations sur l'émetteur"));
        // data.push(
        //     this.getSimpleSection(
        //         "Informations sur le bénéficiaire",
        //         this.receiver
        //     )
        // );
        data.push(
          this.getUserSection(this.other, "Informations sur le bénéficiaire")
        );
      }
    } else if (typeOp === 2) {
      if (amount <= 0) {
        if (receiver) {
          data.push(
            this.getUserSection(receiver, "Informations sur le bénéficiaire")
          );
        }
        data.push(this.getUserSection(sender, "Informations sur l'émetteur"));
      } else {
        data.push(
          this.getUserSection(receiver, "Informations sur le bénéficiaire")
        );
        if (sender) {
          data.push(this.getUserSection(sender, "Informations sur l'émetteur"));
        }
      }
    } else if (typeOp === 4 || typeOp === 26) {
      data.push(
        this.getUserSection(this.account, "Informations sur l'émetteur")
      );
      data.push(
        this.getSimpleSection("Informations sur le bénéficiaire", this.receiver)
      );
    } else if (typeOp === 14 || typeOp === 15) {
      data.push(
        this.getUserSection(this.guestSender, "Informations sur l'émetteur")
      );
      data.push(
        this.getUserSection(
          this.guestReceiver,
          "Informations sur le bénéficiaire"
        )
      );
    } else {
      if (receiver) {
        data.push(
          this.getUserSection(receiver, "Informations sur le bénéficiaire")
        );
      } else {
        if (this.other) {
          console.log("Je suis dans OTHER", this.other);
          data.push(
            this.getUserSection(this.other, "Informations sur le bénéficiaire")
          );
        } else {
          if (this.receiver) {
            console.log("Je suis dans RECEIVER", this.receiver);
            data.push(
              // this.getSimpleSection("Informations sur le bénéficiaire", receiver)
              this.getSimpleSection(
                "Informations sur le bénéficiaire",
                this.receiver
              )
            );
          }
        }
      }
      if (sender) {
        data.push(this.getUserSection(sender, "Informations sur l'émetteur"));
      }
    }

    if (this.operationBank) {
      const ob = this.operationBank;
      data.push({
        title: "Informations bancaire",
        rows: [
          { label: "Nom", value: ob.nomBenef },
          {
            label: "Prénom",
            value: ob.prenomBenef,
          },
          { label: "Code Swift", value: ob.codeSwift },
          { label: "Numéro du RIB", value: ob.rib },
          { label: "Nom de la banque", value: ob.nomBanque },
          { label: "Origine des fonds", value: ob.origineFonds },
          { label: "Téléphone", value: ob.telephone },
          { label: "Motif de transfert", value: ob.motif },
          { label: "Pays", value: ob.pays },
        ],
      });
    }
    return data;
  }

  getUserSection(account: any, title: string) {
    if (account?.user) {
      const user = account.user;
      return {
        title,
        rows: [
          { label: "User ID", value: user.userId },
          {
            label: "Nom et prénom",
            value: `${user.firstName} ${user.lastName}`,
          },
          { label: "Email", value: user.email },
          { label: "Téléphone", value: user.msisdn },
        ],
      };
    } else {
      if (account) {
        return {
          title,
          rows: [
            { label: "User ID", value: account.id },
            { label: "Nom et prénom", value: account.name },
            { label: "Email", value: account.email },
            { label: "Téléphone", value: account.tel },
          ],
        };
      } else {
        return {
          title,
          rows: [{ label: false, value: this.receiver }],
        };
      }
    }
  }

  public async getFraisData(v: any) {
    const feesIn = Transaction.instance.getValue("feesIn", false);
    let operationTypeId = Transaction.instance.getValue("operationType", 0);
    let currentCode = Transaction.instance.getValue("currency", "");
    let frais = await Transaction.instance.getFees(
      v,
      currentCode,
      operationTypeId
    );
    let fees = currentCode === "USD" ? frais.feesInUSD : frais.feesInCDF;
    let total = feesIn ? Number(v.value) : Number(fees) + Number(v.value);
    Transaction.instance.updateValue("tva", frais.tva);
    return { frais: fees, total: total, tva: frais.tva };
  }

  public async getFees(v: any, code: any, opTyp: any) {
    let amount = isNaN(parseFloat(v?.value)) ? 0 : parseFloat(v?.value);
    const model = BaseModel.getInstance();
    let total = { feesInCDF: 0, feesInUSD: 0, tauxUsdToCDF: 0 };
    const path = `operation/fees`;

    var data = {
      amount: amount,
      currency: code,
      operationType: opTyp,
      sender: model.user.msisdn,
    };

    const res = await restClient.post(path, data);
    if (res) {
      return res.data;
    }
    return { total };
  }

  public async convertAmount(v: any, code: any) {
    let amount = isNaN(parseFloat(v?.value)) ? 0 : parseFloat(v?.value);
    let description = Transaction.instance.getValue("description", "");
    const model = BaseModel.getInstance();
    let total = amount;
    const path = `operation/simulate`;
    var data = {
      account: model.user.msisdn,
      adminId: 0,
      amount: amount,
      currency: code,
      description: description,
      entreprise: false,
      feesIn: false,
      operationType: 20,
      //operationType: 20,
      sender: model.user.msisdn,
    };
    // const path = `/currency/convert/${amount}/${from}/${to}`;
    const res = await restClient.post(path, data);
    console.log("==> result", res.data);
    if (res) {
      total = res.data;
    }
    return { total };
  }

  public async convertUnitToCDF(v: any) {
    let amount = isNaN(parseFloat(v?.value)) ? 0 : parseFloat(v?.value);
    let total = amount;
    const path = `/currency/unit/convert/${amount}`;
    const res = await restClient.get(path);
    if (res?.data?.content) {
      total = res.data.content;
    }
    return { total };
  }

  public serializeCallback(payload: any, props: any) {
    const model = BaseModel.getInstance();
    if (model.isEntrepriseStandart) {
      const enterpriseId = model.user?.accountId || 0;
      return { ...payload, enterpriseId };
    }
    const adminId = model.isAdmin ? model.user?.user?.userId || 0 : 0;
    return { ...payload, adminId };
  }

  getCustomProcessNewPath(payload: any) {
    switch (payload?.operationType) {
      case 1:
        return "/operation/deposit";
      case 2:
        return "/operation/transfer";
      case 7:
        return "/operation/airtime";
      case 12:
        return "/operation/visa";
      case 14:
        return "/entreprise/transfert";
      case 15:
        const model = BaseModel.getInstance();
        return `/entreprise/withdraw/${model.user.accountId}/${payload?.tnx}`;
      case 17:
        return `/operation/cashtowallet`;
      case 18:
        return `/operation/cashtobank`;
      case 19:
        return `/operation/cashtocash`;
      case 20:
        return `/operation/buyCurrency`;
      case 25:
        return `/operation/empatatobank`;
        case 24:
        return `/operation/empata-cash`;
        case 26:
        return `/operation/withdraw-cash/{account}/{token}`;
      default:
        return "/operation/transfer";
    }
  }

  async initCustom(props: any) {
    const { btn, data } = props;
    return new Promise(async (resolve) => {
      console.log("Les propos depuis initCustom", props);
      const action = btn?.dataset?.key;
      if (action === "refund") {
        const v = window.confirm(
          "Etes-vous certain de vouloir éffectuer cette opération ? Cette action est irreversible !"
        );
        if (v) {
          const formData = {
            receiver: data?.other?.msisdn || data?.account?.msisdn,
            sender: data?.account?.msisdn,
            amount: data?.amount > 0 ? data?.amount : data?.amount * -1,
            operationType: 11,
            description: "Remboursement de fonds",
            feesIn: false,
            date: new Date().toISOString(),
          };
          const result = await restClient.post("operation/refund", formData);
          console.log("==> result", result);
          if (result?.success) {
            return resolve({ process: false, refresh: true });
          } else {
            let msg =
              result?.message ||
              "Une érreur s'est produite lors du remboursement. Merci de réessayer !";

            alert(msg);
          }
        }
        return resolve({ process: false });
      } else if (action === "validation") {
        let res: any = {};
        res = {
          settings: {
            content: "validationtx",
            title: "Validation d'une transaction",
            showSaveBtn: true,
            btnTitle: "Valider",
            action: "validation",
            message: "Attention opération délicate!!!",
            fund: "",
            status: "",
          },
        };
        resolve(res);
      }
      let res: any = {};
      res = {
        settings: {
          content: "corrtx",
          title: "Corriger une transaction",
          showSaveBtn: true,
          btnTitle: "Corriger",
          action: "correction",
          message: "Attention opération délicate!!!",
          fund: "",
          status: "",
        },
      };
      resolve(res);
    });
  }

  appendTransfertStandartFields(fields: Field[]): Field[] {
    const i = fields.findIndex((f) => f.name === "receiver");
    const j = fields.findIndex((f) => f.name === "receiver_details");
    if (i > -1) {
      fields[i].label = "Tél bénéficiaire";
      fields[i].name = "telBeneficiare";
    }
    const defs = [
      {
        name: "telEmetteur",
        label: "Tél émetteur",
      },
      {
        name: "nomEmetteur",
        label: "Nom(s) et prenom(s) de l'émetteur",
      },
      {
        name: "emailEmetteur",
        label: "Email émetteur",
      },
      /*{
        name: "telBeneficiare",
        label: "Tél bénéficiaire",
      },*/
      {
        name: "nomBeneficiare",
        label: "Nom(s) et prenom(s) du bénéficiaire",
      },
      {
        name: "emailBeneficiare",
        label: "Email bénéficiaire",
      },
    ];
    defs.forEach((d, i) => {
      const f = new Field({
        name: d.name,
        label: d.label,
        onTable: false,
        provider: Transaction.instance,
        formRowIndex: i + 5,
        listeners: [
          {
            type: "value_change_operation_type_id",
            behaviour: "toggleLayout",
            resolve: (v: any, customData?: any) => {
              const vv = parseInt(v.value);
              if ([15].indexOf(vv) !== -1) {
                return "col-md-12 d-none";
              }
              return "col-md-12";
            },
          },
        ],
      });
      fields.push(f);
    });

    fields.push(
      new Field({
        name: "tnx",
        label: "Trnx. Reférence",
        onTable: false,
        provider: Transaction.instance,
        formLayout: "col-md-12 d-none",
        emitters: [{ type: "value_change_tnx" }],
        formRowIndex: 11,
        listeners: [
          {
            type: "value_change_operation_type_id",
            behaviour: "toggleLayout",
            resolve: (v: any, customData?: any) => {
              const vv = parseInt(v.value);
              if ([15].indexOf(vv) !== -1) {
                return "col-md-12";
              }
              return "col-md-12 d-none";
            },
          },
          {
            type: "value_change_tnx",
            behaviour: "customActionAsync",
            resolve: async (v: any, customData?: any) => {
              return await Transaction.handleChangeTnx(v, customData);
            },
          },
        ],
      })
    );

    const defs2 = [
      {
        name: "emetteurDetails",
        label: "Détails de l'émetteur",
      },
      {
        name: "benDetails",
        label: "Détails du bénéficiaire",
      },
      {
        name: "montantTnx",
        label: "Montant du transfert",
      },
      {
        name: "statusTnx",
        label: "Status du transfert",
      },
    ];
    defs2.forEach((d, i) => {
      const f = new Field({
        name: d.name,
        label: d.label,
        onTable: false,
        provider: Transaction.instance,
        formLayout: "col-md-12 d-none",
        formRowIndex: i + 12,
        inputType: InputType.INPUT_DETAILS_2,
        listeners: [
          {
            type: "value_change_tnx_callback",
            behaviour: "customActionAsyncCallback",
            resolve: (v: any, customData?: any) => {
              console.log("==> v ==", v);
              if (!v) {
                return {
                  formLayout: "col-md-12 d-none",
                  customData: {},
                };
              }
              if (d.name === "emetteurDetails") {
                return {
                  formLayout: "col-md-12",
                  customData: {
                    typeText: "info",
                    name: v?.guestSender?.name,
                    tel: v?.guestSender?.tel,
                    email: v?.guestSender?.email,
                  },
                };
              }
              if (d.name === "benDetails") {
                return {
                  formLayout: "col-md-12",
                  customData: {
                    typeText: "info",
                    name: v?.guestReceiver?.name,
                    tel: v?.guestReceiver?.tel,
                    email: v?.guestReceiver?.email,
                  },
                };
              }
              if (d.name === "montantTnx") {
                return {
                  formLayout: "col-md-12",
                  customData: {
                    valText: utils().formatAmount(
                      v.devise ? v.devise.code : "CDF",
                      v?.amount
                    ),
                  },
                };
              }
              if (d.name === "statusTnx") {
                return {
                  formLayout: "col-md-12",
                  customData: {
                    valText:
                      v?.status === "WAITING_WITHDRAW"
                        ? "En attente de retrait"
                        : "Retiré",
                  },
                };
              }
            },
          },
        ],
      });
      fields.push(f);
    });

    fields.forEach((f) => {
      f.required = false;
    });
    return fields;
  }

  async initDetails() {
    const model = BaseModel.getInstance();
    if (model.isEntrepriseStandart) {
      if (this.account?.nomEntreprise?.length) {
        utils().emitter.emit("nom_entreprise", this.account?.nomEntreprise);
      }
    }
  }
}

export { Transaction };

import React from "react";

const statuses = {
  WITHDRAWN: "Retiré",
  WAITING_WITHDRAW: "En attente de retrait",
  PENDING: "En cours",
  SUCCESS: "Succès",
  TRAITÉ: "Traité",
  FAILED: "Échoué",
  CLOSED: "Terminé",
  DECLINED: "Rejeté",
  CANCELED: "Annulé",
  VALIDATED: "Validé"
};
const colors = {
  WITHDRAWN: "dark",
  WAITING_WITHDRAW: "secondary",
  PENDING: "warning",
  SUCCESS: "success",
  TRAITÉ: "info",
  FAILED: "danger",
  CLOSED: "success",
  DECLINED: "danger",
  CANCELED: "warning",
  VALIDATED: "success",
};

export function RenderStatusOperation(props: any) {
  const { data } = props;
  const status = (statuses as any)[data] || data;
  const color = (colors as any)[data] || data;
  return <span className={`text-${color}`}>{status}</span>;
}

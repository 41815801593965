import React from "react";
import { Input } from "./Input";

export function InputTel(props: any) {
  const { customData } = props;

  return (
    <>
      <span
        className="text-center border-right px-3"
        style={{
          background: customData.bgColor,
        }}
      >
        {customData.code}
      </span>
      <Input {...props} />
    </>
  );
}

import React from "react";
import { SidebarItem } from "./SidebarItem";
import { PropsFromRedux } from "../../../_reducers";

export function Sidebar(props: PropsFromRedux & any) {
  const { sidebarItems } = props;

  return (
    <div className="col-lg-3 col-md-4 m-0">
      <div className="span3 w-100 rounded" style={{ maxWidth: "300px" }}>
        <div className="sidebar-menu sidebar">
          <ul className="widget widget-menu unstyled">
            {sidebarItems.map((item: any, i: number) => (
              <SidebarItem navItem={item} {...props} key={i} />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

import { adminErecettePrefix } from "../_config";
import { Transaction } from "../_entities";

export const adminErecetteRoutes = [
  {
    mainPath: adminErecettePrefix,
    pageTitle: "Tableau de board admin eRecette",
    pageType: "kpi",
    provider: undefined,
  },
  {
    mainPath: `${adminErecettePrefix}/taxes`,
    pageTitle: "Paiements de taxes",
    listTitle: "Liste de paiements de taxes éffectués",
    provider: Transaction.instance,
    dataPath: (meta: any) => {
      return Transaction.instance.applyFilters(
        `/admin/eRecette/payements/all`,
        meta
      );
    },
  },
  {
    mainPath: `${adminErecettePrefix}/transactions`,
    pageTitle: "Transactions",
    addBtnTitle: "Faire une transaction",
    listTitle: "Liste des transactions réalisées",
    provider: Transaction.instance,
    dataPath: (meta: any) => {
      return Transaction.instance.applyFilters("operation/all", meta);
    },
    dataPathCallBack: (res: any, props: any) => {
      const data = Array.isArray(res.data) ? res.data : [];
      return data.filter((d: any) => d.operationType?.operationTypeId === 25 || d.operationType?.operationTypeId === 4);
    },
  },
  {
    mainPath: `${adminErecettePrefix}/transactions/empatatobank`,
    pageTitle: "Transferts eMpata to Bank",
    addBtnTitle: "Faire une transaction",
    listTitle: "Liste des transfert eMpata to Bank",
    provider: Transaction.instance,
    dataPath: (meta: any) => {
      return Transaction.instance.applyFilters("operation/all", meta);
    },
    dataPathCallBack: (res: any, props: any) => {
      const data = Array.isArray(res.data) ? res.data : [];
      return data.filter((d: any) => d.operationType?.operationTypeId === 25);
    },
  },
  {
    mainPath: `${adminErecettePrefix}/transactions/empatatoothers`,
    pageTitle: "Transferts eMpata vers Autres",
    addBtnTitle: "Faire une transaction",
    listTitle: "Liste des transfert eMpata vers Autres",
    provider: Transaction.instance,
    dataPath: (meta: any) => {
      return Transaction.instance.applyFilters("operation/all", meta);
    },
    dataPathCallBack: (res: any, props: any) => {
      const data = Array.isArray(res.data) ? res.data : [];
      return data.filter((d: any) => d.operationType?.operationTypeId === 4);
    },
  },
  {
    mainPath: `${adminErecettePrefix}/profile`,
    pageTitle: "Mon profile",
    provider: null,
    pageType: "accountProfil",
  },
];

import React from "react";
import { Link } from "react-router-dom";
import { PropsFromRedux } from "../../../_reducers";

export function SidebarItemLink(props: PropsFromRedux & any) {
  const { navItem } = props;
  return (
    <Link to={navItem.path} onClick={(e) => handleClick(e, navItem, props)}>
      <i className={navItem.icon} aria-hidden="true"></i>
      {navItem.title}
    </Link>
  );
}

const handleClick = (e: any, item: any, props: any) => {
  if (item.path === "/logout") {
    e.preventDefault();
    props.logout();
    return;
  }
  $(".sidebar-submenu").slideUp(200);
  if (item?.subItems?.length) {
    e.preventDefault();
    if ($(e.currentTarget).parent().hasClass("active")) {
      $(".sidebar-dropdown").removeClass("active");
      $(e.currentTarget).parent().removeClass("active");
    } else {
      $(".sidebar-dropdown").removeClass("active");
      $(e.currentTarget).next(".sidebar-submenu").slideDown(200);
      $(e.currentTarget).parent().addClass("active");
    }
  }
};

import React from "react";
import { appConstants } from "../../../../../_constants";
import { UserCni, UserCniReject } from "../../../../../_entities";
import { restClient, utils } from "../../../../../_helpers";
import { BaseModel } from "../../../../../_models";

const { MODAL_ACTION } = appConstants.keys.eventKeys;

export function Photo(props: any) {
  const cni = props?.account?.user?.cni;
  const url = BaseModel.getInstance().wsUrl;

  const onClick = (e: any) => {
    e.preventDefault();
    UserCni.instance.updateValue("userId", props?.account?.user?.userId);
    console.log(props?.account);
    utils().emitter.emit(MODAL_ACTION, {
      btn: "createActions",
      data: { ...props, provider: UserCni.instance },
    });
  };
 
  const onClick2 = (e: any) => {
    e.preventDefault();
    UserCni.instance.updateValue("userId", props?.account?.user?.userId);
    console.log(props?.account);
    utils().emitter.emit(MODAL_ACTION, {
      btn: "createActions",
      data: { ...props, provider: UserCniReject.instance },
    });
  };

  const onClick3 = async (e: any) => {
    e.preventDefault();
    const res = await restClient.post(`user/media/valid`, {
      userId: props.account?.user?.userId,
      state: true,
      motif: ""
    });
    if (!res?.success) {
      alert("Une erreur s'est produite lors de l'enregistrement. Merci de reéssayer.");
    } else {
      window.location.reload();
    }
  };
  const isAdmin = props?.model?.isAdmin;
  return (
    <>
      <div className="form-group row w-100 justify-content-center m-0">
        <div
          className="col-md-6 flex-column d-flex justify-content-center align-items-center"
          style={{ padding: 0 }}
        >
          {cni && (
            <img
              style={{ maxHeight: "256px", minHeight: "100px" }}
              src={`${url}/user/media/download/${cni}`}
            />
          )}
          {!cni && <small>Aucune image</small>}
          <button
            className="btn btn-secondary btn-sm d-block mt-2"
            onClick={onClick}
          >
            <small>
              <i className="fa fa-edit mr-1" aria-hidden="true"></i> Modifier la
              CNI
            </small>
          </button>
          {isAdmin && 
          <>
          {cni && props.account?.user?.cniState !== -1 && (
            <button
              className="btn btn-danger btn-sm d-block mt-2"
              onClick={onClick2}
            >
              <small>
                <i className="fa fa-times mr-1" aria-hidden="true"></i> Rejeter
                la CNI
              </small>
            </button>
          )}
          {cni && props.account?.user?.cniState !== 1 && (
            <button
              className="btn btn-success btn-sm d-block mt-2"
              onClick={onClick3}
            >
              <small>
                <i className="fa fa-check mr-1" aria-hidden="true"></i> Approuver
                la CNI
              </small>
            </button>
          )}
          </>
          }
        </div>
      </div>
    </>
  );
}

import React, { Component } from "react";

export default class InputCard extends Component {
  render() {
    return (
      <div
        className="b3id-card-icon-images-row b3-card-icon-images-row d-flex justify-content-end"
        data-was-visible="true"
      >
        <div className="b3-card-icon-image-container" data-was-visible="true">
          <img
            src="https://lh6.ggpht.com/YuGKCOHWY8rqy1Vbn8I0Jcd3VruVtBfJqSjeliVj2iXqhkmtB9cLkGhc-zoCxQeDoc6i0pw"
            alt="American&nbsp;Express"
            className="b3id-card-icon-image"
            data-was-visible="true"
          />
        </div>
        <div className="b3-card-icon-image-container" data-was-visible="true">
          <img
            src="https://lh6.ggpht.com/Hn_TXQrDTSf_kQer1hu4YglUu3mrcG4SUwPmqx8td6ornRqvMlmJvLFZyn3YY1-JIztUcYdS"
            alt="JCB"
            className="b3id-card-icon-image"
            data-was-visible="true"
          />
        </div>
        <div
          className="b3-card-icon-image-container mr-1"
          data-was-visible="true"
        >
          <img
            src="https://lh6.ggpht.com/NvYf_33MleY1waJfW6O98wb3KU6XeinwiahmvUIyu46LcWeQdTMGm7WYe81uZYWLUbkjvz0E"
            alt="Visa"
            className="b3id-card-icon-image"
            data-was-visible="true"
          />
        </div>
        <div className="b3-card-icon-image-container" data-was-visible="true">
          <img
            src="https://lh6.ggpht.com/h6TBIVV7tlYGr1zkIA8CmCzINizzASbPIetpxh_5otBu3VkPEC5_Kk_wH5szy7gDhMkRhVVp"
            alt="Mastercard"
            className="b3id-card-icon-image"
            data-was-visible="true"
          />
        </div>
      </div>
    );
  }
}

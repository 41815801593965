import React, { useRef, useState } from "react";
import { PropsFromRedux } from "../../../../_reducers";
import { AddButton } from "./AddButton";
import FilterWrapper from "./FilterBlock/FilterWrapper";
import { SearchBtn } from "./SearchBtn";
import { SearchInput } from "./SearchInput";
import { Table } from "./Table";
import { Title } from "./Title";
import { Total } from "./Total";
import {
  Abonnement,
  Account,
  AccountType,
  AccountTypePrice,
  AccountTypeQuota,
  Blacklist,
  BulkPayment,
  Commission,
  Currency,
  Fonctionnalite,
  Marchand,
  OperationType,
  PriceIntervalle,
  Product,
  ProductCategory,
  Profile,
  MarqueBlanche,
  SubscriptionPlan,
  SubscriptionPlanPeriod,
  Transaction,
  User,
  UIItem,
  Client,
} from "../../../../_entities";
import { Price } from "../../../../_entities/Price";
import { Card } from "../../../../_entities/Card";
import { ProductWrapper } from "../../../client/ProductList/ProductWrapper";

export function TableWrapper(props: PropsFromRedux & any) {
  const {
    needFilterBlock,
    addBtnTitle,
    shadow,
    isDashboard,
    isDataTable,
    model,
    saveBtn,
  } = props;
  const searchRef = useRef(null);
  const [hasData, setHasData] = useState(false);
  console.log("Le provider", props.provider);
  return (
    <div className={`${shadow && hasData ? "box-shadow rounded" : ""}`}>
      <div className={`list-group ${isDashboard ? " box-shadow" : ""}`}>
        <div
          className="list-group-item"
          style={{
            padding: "10px",
            borderBottom: "2px solid rgba(0, 0, 0, 0.125)",
          }}
        >
          <div className="w-100">
            <div className="d-flex flex-row justify-content-between">
              <Title {...props} />
              {/*{props.provider === Account.instance &&*/}
              {/*  addBtnTitle &&*/}
              {/*  props.model.user.user?.profile === null && (*/}
              {/*    <AddButton {...props} />*/}
              {/*  )}*/}
              {props.provider === Account.instance &&
                (props.model.user.user?.profile === null ||
                  props.model.accCrud.ecriture) &&
                addBtnTitle && <AddButton {...props} />}

              {/*{props.provider === Transaction.instance &&*/}
              {/*  addBtnTitle &&*/}
              {/*  props.model.user.user?.profile === null && (*/}
              {/*    <AddButton {...props} />*/}
              {/*  )}*/}
              {props.provider === Transaction.instance &&
                (props.model.user.user?.profile === null ||
                  props.model.txCrud.ecriture) &&
                addBtnTitle && <AddButton {...props} />}

              {/*{props.provider === Product.instance &&*/}
              {/*  addBtnTitle &&*/}
              {/*  props.model.user.user?.profile === null && (*/}
              {/*    <AddButton {...props} />*/}
              {/*  )}*/}
              {props.provider === Product.instance &&
                (props.model.user.user?.profile === null ||
                  props.model.prodCrud.ecriture) &&
                addBtnTitle && <AddButton {...props} />}

              {/*{props.provider === ProductCategory.instance &&*/}
              {/*  addBtnTitle &&*/}
              {/*  props.model.user.user?.profile === null && (*/}
              {/*    <AddButton {...props} />*/}
              {/*  )}*/}
              {props.provider === ProductCategory.instance &&
                (props.model.user.user?.profile === null ||
                  props.model.prodCrud.ecriture) &&
                addBtnTitle && <AddButton {...props} />}

              {/*{props.provider === Currency.instance &&*/}
              {/*  addBtnTitle &&*/}
              {/*  props.model.user.user?.profile === null && (*/}
              {/*    <AddButton {...props} />*/}
              {/*  )}*/}
              {props.provider === Currency.instance &&
                (props.model.user.user?.profile === null ||
                  props.model.i18Crud.ecriture) &&
                addBtnTitle && <AddButton {...props} />}

              {/*{props.provider === Fonctionnalite.instance &&*/}
              {/*  addBtnTitle &&*/}
              {/*  props.model.user.user?.profile === null && (*/}
              {/*    <AddButton {...props} />*/}
              {/*  )}*/}
              {props.provider === Fonctionnalite.instance &&
                (props.model.user.user?.profile === null ||
                  props.model.profCrud.ecriture) &&
                addBtnTitle && <AddButton {...props} />}

              {/*{props.provider === Profile.instance &&*/}
              {/*  addBtnTitle &&*/}
              {/*  props.model.user.user?.profile === null && (*/}
              {/*    <AddButton {...props} />*/}
              {/*  )}*/}
              {props.provider === Profile.instance &&
                (props.model.user.user?.profile === null ||
                  props.model.profCrud.ecriture) &&
                addBtnTitle && <AddButton {...props} />}

              {/*{props.provider === AccountType.instance &&*/}
              {/*  addBtnTitle &&*/}
              {/*  props.model.user.user?.profile === null && (*/}
              {/*    <AddButton {...props} />*/}
              {/*  )}*/}
              {props.provider === AccountType.instance &&
                (props.model.user.user?.profile === null ||
                  props.model.confCrud.ecriture) &&
                addBtnTitle && <AddButton {...props} />}

              {/*{props.provider === OperationType.instance &&*/}
              {/*  addBtnTitle &&*/}
              {/*  props.model.user.user?.profile === null && (*/}
              {/*    <AddButton {...props} />*/}
              {/*  )}*/}
              {props.provider === OperationType.instance &&
                (props.model.user.user?.profile === null ||
                  props.model.confCrud.ecriture) &&
                addBtnTitle && <AddButton {...props} />}

              {/*{props.provider === AccountTypeQuota.instance &&*/}
              {/*  addBtnTitle &&*/}
              {/*  props.model.user.user?.profile === null && (*/}
              {/*    <AddButton {...props} />*/}
              {/*  )}*/}
              {props.provider === AccountTypeQuota.instance &&
                (props.model.user.user?.profile === null ||
                  props.model.confCrud.ecriture) &&
                addBtnTitle && <AddButton {...props} />}

              {/*{props.provider === AccountTypePrice.instance &&*/}
              {/*  addBtnTitle &&*/}
              {/*  props.model.user.user?.profile === null && (*/}
              {/*    <AddButton {...props} />*/}
              {/*  )}*/}
              {props.provider === AccountTypePrice.instance &&
                (props.model.user.user?.profile === null ||
                  props.model.confCrud.ecriture) &&
                addBtnTitle && <AddButton {...props} />}

              {/*{props.provider === Abonnement.instance &&*/}
              {/*  addBtnTitle &&*/}
              {/*  props.model.user.user?.profile === null && (*/}
              {/*    <AddButton {...props} />*/}
              {/*  )}*/}
              {props.provider === Abonnement.instance &&
                (props.model.user.user?.profile === null ||
                  props.model.confCrud.ecriture) &&
                addBtnTitle && <AddButton {...props} />}

              {/*{props.provider === PriceIntervalle.instance &&*/}
              {/*  addBtnTitle &&*/}
              {/*  props.model.user.user?.profile === null && (*/}
              {/*    <AddButton {...props} />*/}
              {/*  )}*/}
              {props.provider === Blacklist.instance &&
                addBtnTitle &&
                props.model.user.user?.profile === null && (
                  <AddButton {...props} />
                )}

              {props.provider === PriceIntervalle.instance &&
                (props.model.user.user?.profile === null ||
                  props.model.confCrud.ecriture) &&
                addBtnTitle && <AddButton {...props} />}

              {props.provider === Commission.instance &&
                (props.model.user.user?.profile === null ||
                  props.model.confCrud.ecriture) &&
                addBtnTitle && <AddButton {...props} />}

              {props.provider === Card.instance &&
                (props.model.user.user?.profile === null ||
                  props.model.cardCrud.ecriture) &&
                addBtnTitle && <AddButton {...props} />}

              {props.provider === SubscriptionPlan.instance && addBtnTitle && (
                <AddButton {...props} />
              )}

              {props.provider === SubscriptionPlanPeriod.instance &&
                addBtnTitle && <AddButton {...props} />}

              {props.provider === Marchand.instance && addBtnTitle && (
                <AddButton {...props} />
              )}

              {props.provider === BulkPayment.instance && addBtnTitle && (
                <AddButton {...props} />
              )}

              {props.provider === Price.instance &&
                (props.model.user.user?.profile === null ||
                  props.model.confCrud.ecriture) &&
                addBtnTitle && <AddButton {...props} />}

              {props.provider === MarqueBlanche.instance &&
                (props.model.user.user?.profile === null ||
                  props.model.confCrud.ecriture) &&
                addBtnTitle && <AddButton {...props} />}

              {props.provider === UIItem.instance &&
                (props.model.user.user?.profile === null ||
                  props.model.confCrud.ecriture) &&
                addBtnTitle && <AddButton {...props} />}
              {props.provider === Client.instance &&
                (props.model.user.user?.profile === null ||
                  props.model.confCrud.ecriture) &&
                addBtnTitle && <AddButton {...props} />}
            </div>
          </div>
        </div>
        <div
          className="list-group-item flex-column align-items-start"
          style={{ paddingLeft: 0, paddingRight: 0, overflowX: "hidden" }}
        >
          <div className="d-flex w-100">
            <div className="row filter-block m-0">
              <div className="col-md-2" style={{ paddingLeft: "15px" }}>
                <Total {...props} />
              </div>
              <div className="col-md-9">
                <div className="display-style">
                  {needFilterBlock !== false && <FilterWrapper {...props} />}
                </div>
              </div>
              <SearchBtn />
            </div>
          </div>
          <SearchInput searchRef={searchRef} />
        </div>
        {((isDashboard && isDataTable) || !isDashboard) && (
          <div
            className="table100 ver4 m-b-110 list-group-item flex-column align-items-start "
            style={{ overflow: "auto", border: "none" }}
          >
            <Table
              {...props}
              searchRef={searchRef}
              setHasData={(v: boolean) => setHasData(v)}
            />
            {saveBtn && saveBtn}
          </div>
        )}
      </div>
      {isDashboard && !isDataTable && (
        <ProductWrapper {...props} products={model.tableData} />
      )}
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { utils } from "../../../_helpers";

export function Alert(props: any) {
  const [msg, setMsg] = useState({});
  const cls = (msg as any)?.cls
    ? (msg as any)?.cls
    : (msg as any)?.content
    ? "alert-danger"
    : "d-none";

  const content = (msg as any)?.content ? (msg as any)?.content : "";
  const cls2 = (msg as any)?.content ? "" : "d-none";
  useEffect(() => {
    const handle = (e: any) => {
      setMsg(e);
    };
    utils().emitter.on("alert_message", handle);
    return () => {
      utils().emitter.off("alert_message", handle);
    };
  });

  return (
    <div className={cls2}>
      <div className={`alert ${cls} alert-dismissible fade show`}>
        <span style={{ fontSize: "80%" }}>{content}</span>
        <button type="button" onClick={(e) => setMsg({})} className="close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
    </div>
  );
}

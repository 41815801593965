import React from "react";
import { InvalidFeedBack } from "../../Error";
import { BaseField } from "../../Fields";

export function FormInput(props: any) {
  const { customData, value, setValue, field, customLayoutInput } = props;
  const layout = customLayoutInput || "col-md-8";
 
  return (
    <div className={layout} style={{ padding: 0 }}>
      <BaseField
        {...props}
        customData={customData}
        value={value}
        setValue={setValue}
      />
      <InvalidFeedBack {...props} msg={field.validatorMessage} />
    </div>
  );
}

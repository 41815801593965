import React from "react";

export function TabBtn(props: any) {
  const { label, onClick } = props;
  return (
    <a onClick={onClick} {...props}>
      {label}
    </a>
  );
}

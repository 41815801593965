import React from "react";

export function CloseBtn(props: any) {
  const { isDetails, isMsg } = props;
  return (
    <button
      type="button"
      className="btn btn-danger btn-sm box-shadow"
      data-dismiss="modal"
    >
      <i className="fa fa-times mr-1"></i>
      {!isDetails && "Annuler"}
      {isDetails && "Fermer"}
    </button>
  );
}

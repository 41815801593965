import { model, primaryColor, utils } from "../_helpers";
import { adminFinancierPrefix } from "./adminFinancierConfig";

export const adminPrefix = "/admin";

export const adminSidebarItems = [
  {
    title: "Dashboard",
    path: adminPrefix,
    icon: "fa fa-dashboard",
  },
  {
    title: "Mes validations",
    path: `${adminPrefix}/workflowstep/validations`,
    icon: "fa fa-check-circle-o",
    // accessKeys: ["F001"],
    // relatedPaths: [`${adminPrefix}/workflows/details/`],
    // subItems: [
    //   {
    //     title: "Mes validations",
    //     path: `${adminPrefix}/workflowstep/validations`,
    //   }
    // ],
  },
  {
    title: "Cartes NFC",
    path: `${adminPrefix}/account/nfc/getAll`,
    icon: "fa fa-credit-card",
    accessKeys: ["F015"],
    // relatedPaths: [`${adminPrefix}/workflows/details/`],
    // subItems: [
    //   {
    //     title: "Mes validations",
    //     path: `${adminPrefix}/workflowstep/validations`,
    //   }
    // ],
  },
  {
    title: "Workflows",
    path: `${adminPrefix}/workflows`,
    icon: "fa fa-step-forward",
    accessKeys: ["F020"],
    relatedPaths: [`${adminPrefix}/workflows/details/`],
    subItems: [
      {
        title: "Nouveau",
        path: `${adminPrefix}/workflows/new`,
      },
      {
        title: "Models de validation",
        path: `${adminPrefix}/workflows/model/all`,
      },
      {
        title: "Toutes les validations",
        path: `${adminPrefix}/workflows/validations/all`,
      },
      {
        title: "Affectations",
        path: `${adminPrefix}/permissions/all`,
      },
    ],
  },
  {
    title: "Comptes",
    path: `${adminPrefix}/comptes`,
    icon: "fa fa-folder-open",
    accessKeys: ["F001"],
    relatedPaths: [`${adminPrefix}/comptes/details/`],
    subItems: [
      {
        title: "Tous",
        path: `${adminPrefix}/comptes`,
      },
      {
        title: "Particuliers",
        path: `${adminPrefix}/comptes/particuliers`,
      },
      {
        title: "Dealers",
        path: `${adminPrefix}/comptes/dealers`,
      },
      {
        title: "SuperDealers",
        path: `${adminPrefix}/comptes/superdealers`,
      },
      {
        title: "Entreprises",
        path: `${adminPrefix}/comptes/entreprises`,
      },
    ],
  },
  {
    title: "Transactions",
    path: `${adminPrefix}/transactions`,
    icon: "fa fa fa-random",
    accessKeys: ["F014"],
    subItems: [
      {
        title: "Tous",
        path: `${adminPrefix}/transactions`,
      },
      {
        title: "Dépôts",
        path: `${adminPrefix}/transactions/depots`,
      },
      {
        title: "Retraits",
        path: `${adminPrefix}/transactions/retraits`,
      },
      {
        title: "Transferts",
        path: `${adminPrefix}/transactions/transferts`,
      },
      {
        title: "Frais",
        path: `${adminPrefix}/transactions/frais`,
      },
    ],
  },
  {
    title: "Configurations",
    path: `${adminPrefix}/configurations`,
    icon: "fa fa-cog",
    accessKeys: ["F025"],
    subItems: [
      {
        title: "Général",
        path: `${adminPrefix}/configurations`,
      },
      {
        title: "Types de compte",
        path: `${adminPrefix}/configurations/types_compte`,
      },
      {
        title: "Types d'opération",
        path: `${adminPrefix}/configurations/types_operations`,
      },
      {
        title: "Quotas",
        path: `${adminPrefix}/configurations/quotas`,
      },
      {
        title: "Gestion des Frais",
        path: `${adminPrefix}/configurations/prix`,
      },
      {
        title: "Intervalle de prix",
        path: `${adminPrefix}/configurations/intervales/prix`,
      },
      {
        title: "Commissions",
        path: `${adminPrefix}/configurations/commissions`,
      },
      {
        title: "Abonnements",
        path: `${adminPrefix}/configurations/abonnements`,
      },
      {
        title: "Frais entreprises",
        path: `${adminPrefix}/configurations/ets/frais`,
      },
    ],
  },
  {
    title: "Marque Blanche",
    path: `${adminPrefix}/marque_blanches`,
    icon: "fa fa-address-card-o",
    subItems: [
      {
        title: "Clients",
        path: `${adminPrefix}/marque_blanches/clients`,
      },
      {
        title: "Personnalisations",
        path: `${adminPrefix}/marque_blanches/personnalisations`,
      },
    ],
  },
  {
    title: "Produits/Services",
    path: `${adminPrefix}/produits`,
    icon: "fa fa-bar-chart",
    accessKeys: ["F060"],
    subItems: [
      {
        title: "Catégories",
        path: `${adminPrefix}/produits/categories`,
      },
      {
        title: "Produits",
        path: `${adminPrefix}/produits`,
      },
    ],
  },
  {
    title: "Commandes",
    path: `${adminPrefix}/commandes`,
    icon: "fa fa-shopping-cart",
    accessKeys: ["F061"],
  },
  {
    title: "Utilisateurs",
    path: `${adminPrefix}/utilisateurs`,
    icon: "fa fa-users",
    accessKeys: ["F062"],
    subItems: [
      {
        title: "Tous",
        path: `${adminPrefix}/utilisateurs`,
      },
      {
        title: "Liste noire",
        path: `${adminPrefix}/utilisateurs/blacklist`,
      },
    ],
  },
  {
    title: "Internationalisation",
    path: `${adminPrefix}/internationalisation`,
    icon: "fa fa-globe",
    accessKeys: ["F041"],
    subItems: [
      {
        title: "Pays",
        path: `${adminPrefix}/internationalisation/pays`,
      },
      {
        title: "Devise",
        path: `${adminPrefix}/internationalisation/devises`,
      },
    ],
  },
  {
    title: "Rôle des utilisateurs",
    path: `${adminPrefix}/role`,
    relatedPaths: [`${adminPrefix}/profiles/affectations/`],
    icon: "fa fa-users",
    accessKeys: ["F046"],
    subItems: [
      {
        title: "Fonctionnalités",
        path: `${adminPrefix}/fonctionnalites`,
      },
      {
        title: "Profiles",
        path: `${adminPrefix}/profiles`,
      },
    ],
  },
  {
    title: "Mon profile",
    path: `${adminPrefix}/profile`,
    icon: "fa fa-user",
  },

  {
    title: "Déconnexion",
    path: `/logout`,
    icon: "fa fa-sign-out",
  },
];

export const adminNavbarStyles = () => ({
  background: primaryColor(),
  borderLeft: "1px solid " + primaryColor(),
  borderRight: "1px solid " + primaryColor(),
});

export const adminKpiItems = [
  {
    path: `${adminPrefix}/transactions`,
    title: "Solde USD",
    icon: "fa fa-dollar-sign",
    key: "admin_solde_usd",
    deps: [
      {
        key: "soldes",
        path: (meta: any) => {
          const { user } = meta.model;
          const accountId = user?.accountId || 0;
          return `account/soldes/${accountId}`;
        },
        method: "get",
        body: {},
      },
    ],
    resolve: (res: any) => {
      const result = res.data[0]?.result || {};
      return utils().formatAmount("USD", result.data?.content?.usd);
    },
  },
  {
    path: `${adminPrefix}/transactions`,
    title: "Solde CDF",
    icon: "fa fa-money",
    key: "admin_solde_cdf",
    deps: [
      {
        key: "soldes",
        path: (meta: any) => {
          const { user } = meta.model;
          const accountId = user?.accountId || 0;
          return `account/soldes/${accountId}`;
        },
        method: "get",
        body: {},
      },
    ],
    resolve: (res: any) => {
      const result = res.data[0]?.result || {};
      return utils().formatAmount("CDF", result.data?.content?.cdf);
    },
  },
  {
    path: `${adminPrefix}/transactions`,
    title: "Transactions",
    icon: "fa fa-random",
    accessKeys: ["F014"],
    key: "admin_transactions",
    deps: [
      {
        key: "operations",
        path: "/operation/all",
        method: "get",
        body: {},
      },
    ],
    resolve: (res: any) => {
      const result = res.data[0]?.result || {};
      if (Array.isArray(result.data)) {
        return result.data.length;
      }
      return 0;
    },
  },
  {
    path: `${adminPrefix}/transactions/frais`,
    title: "Total frais CDF",
    icon: "fa fa-money",
    accessKeys: ["F025"],
    key: "adminFinancier_total_frais",
    deps: [
      {
        key: "fees",
        path: "/admin/financier/fees/all",
        method: "get",
        body: {},
      },
    ],
    resolve: (res: any) => {
      const result = res.data[0]?.result || {};
      if (Array.isArray(result.data)) {
        const res = result.data
          .filter((i: any) => {
            if (
              i.status === "SUCCESS" ||
              i.status === "WITHDRAWN" ||
              i.status === "TRAITÉ"
            ) {
              return true;
            }
            return false;
          })
          .map((o: any) => o.amount)
          .reduce((a: number, b: number) => {
            const ap = a < 0 ? a * -1 : a;
            const bp = b < 0 ? b * -1 : b;
            return ap + bp;
          }, 0);
        return utils().formatAmount("CDF", res);
      }
      return 0;
    },
  },
  {
    path: `${adminPrefix}/transactions/frais`,
    title: "Total frais USD",
    icon: "fa fa-money",
    accessKeys: ["F025"],
    key: "adminFinancier_total_frais_usd",
    deps: [
      {
        key: "fees",
        path: "/admin/financier/fees/all",
        method: "get",
        body: {},
      },
    ],
    resolve: (res: any) => {
      const result = res.data[0]?.result || {};
      if (Array.isArray(result.data)) {
        const res = result.data
          .filter((i: any) => {
            if (
              (i.status === "SUCCESS" ||
                i.status === "WITHDRAWN" ||
                i.status === "TRAITÉ") &&
              i.devise?.code === "USD"
            ) {
              return true;
            }
            return false;
          })
          .map((o: any) => o.amount)
          .reduce((a: number, b: number) => {
            const ap = a < 0 ? a * -1 : a;
            const bp = b < 0 ? b * -1 : b;
            return ap + bp;
          }, 0);
        return utils().formatAmount("USD", res);
      }
      return 0;
    },
  },
  {
    path: `${adminPrefix}/transactions/frais`,
    title: "Frais prélevés aujourd'hui CDF",
    icon: "fa fa-money",
    accessKeys: ["F025"],
    key: "adminFinancier_total_frais_today",
    deps: [
      {
        key: "fees_today",
        path: "/admin/financier/fees/all?period=today",
        method: "get",
        body: {},
      },
    ],
    resolve: (res: any) => {
      const result = res.data[0]?.result || {};
      if (Array.isArray(result.data)) {
        const res = result.data
          .filter((i: any) => {
            if (
              i.status === "SUCCESS" ||
              i.status === "WITHDRAWN" ||
              i.status === "TRAITÉ"
            ) {
              return true;
            }
            return false;
          })
          .map((o: any) => o.amount)
          .reduce((a: number, b: number) => {
            const ap = a < 0 ? a * -1 : a;
            const bp = b < 0 ? b * -1 : b;
            return ap + bp;
          }, 0);
        return utils().formatAmount("CDF", res);
      }
      return 0;
    },
  },
  {
    path: `${adminPrefix}/transactions/frais`,
    title: "Frais prélevés aujourd'hui USD",
    icon: "fa fa-money",
    accessKeys: ["F025"],
    key: "adminFinancier_total_frais_today_usd",
    deps: [
      {
        key: "fees_today",
        path: "/admin/financier/fees/all?period=today",
        method: "get",
        body: {},
      },
    ],
    resolve: (res: any) => {
      const result = res.data[0]?.result || {};
      if (Array.isArray(result.data)) {
        const res = result.data
          .filter((i: any) => {
            if (
              (i.status === "SUCCESS" ||
                i.status === "WITHDRAWN" ||
                i.status === "TRAITÉ") &&
              i.devise.code === "USD"
            ) {
              return true;
            }
            return false;
          })
          .map((o: any) => o.amount)
          .reduce((a: number, b: number) => {
            const ap = a < 0 ? a * -1 : a;
            const bp = b < 0 ? b * -1 : b;
            return ap + bp;
          }, 0);
        return utils().formatAmount("USD", res);
      }
      return 0;
    },
  },
  {
    path: `${adminPrefix}/transactions/frais`,
    title: "Frais prélevés ce mois CDF",
    icon: "fa fa-money",
    accessKeys: ["F025"],
    key: "adminFinancier_total_frais_month",
    deps: [
      {
        key: "fees_month",
        path: "/admin/financier/fees/all?period=month",
        method: "get",
        body: {},
      },
    ],
    resolve: (res: any) => {
      const result = res.data[0]?.result || {};
      if (Array.isArray(result.data)) {
        const res = result.data
          .filter((i: any) => {
            if (
              i.status === "SUCCESS" ||
              i.status === "WITHDRAWN" ||
              i.status === "TRAITÉ"
            ) {
              return true;
            }
            return false;
          })
          .map((o: any) => o.amount)
          .reduce((a: number, b: number) => {
            const ap = a < 0 ? a * -1 : a;
            const bp = b < 0 ? b * -1 : b;
            return ap + bp;
          }, 0);
        return utils().formatAmount("CDF", res);
      }
      return 0;
    },
  },
  {
    path: `${adminPrefix}/transactions/frais`,
    title: "Frais prélevés ce mois USD",
    icon: "fa fa-money",
    accessKeys: ["F025"],
    key: "adminFinancier_total_frais_month_usd",
    deps: [
      {
        key: "fees_month",
        path: "/admin/financier/fees/all?period=month",
        method: "get",
        body: {},
      },
    ],
    resolve: (res: any) => {
      const result = res.data[0]?.result || {};
      if (Array.isArray(result.data)) {
        //console.log("TAUX DE CHANGE ", result);
        const taux = result.data[0]?.devise?.taux || 1;
        const res =
          result.data
            .filter((i: any) => {
              if (
                i.status === "SUCCESS" ||
                i.status === "WITHDRAWN" ||
                i.status === "TRAITÉ"
              ) {
                return true;
              }
              return false;
            })
            .map((o: any) => o.amount)
            .reduce((a: number, b: number) => {
              const ap = a < 0 ? a * -1 : a;
              const bp = b < 0 ? b * -1 : b;
              return ap + bp;
            }, 0) / taux;
        return utils().formatAmount("USD", res);
      }
      return 0;
    },
  },
  {
    path: `${adminPrefix}/comptes/entreprises`,
    title: "Comptes Entreprise",
    icon: "fa fa-university",
    accessKeys: ["F001"],
    key: "admin_comptes_entreprises",
    deps: [
      {
        key: "accounts",
        path: "/account/all",
        method: "get",
        body: {},
      },
    ],
    resolve: (res: any) => {
      const result = res.data[0]?.result || {};
      if (Array.isArray(result.data)) {
        return result.data.filter((a: any) => a.accountType.accountTypeId === 4)
          .length;
      }
      return 0;
    },
  },
  {
    path: `${adminPrefix}/comptes/dealers`,
    title: "Comptes Dealers",
    icon: "fa fa-briefcase",
    accessKeys: ["F001"],
    key: "admin_comptes_dealers",
    deps: [
      {
        key: "accounts",
        path: "/account/all",
        method: "get",
        body: {},
      },
    ],
    resolve: (res: any) => {
      const result = res.data[0]?.result || {};
      if (Array.isArray(result.data)) {
        return result.data.filter((a: any) => a.accountType.accountTypeId === 2)
          .length;
      }
      return 0;
    },
  },
  {
    path: `${adminPrefix}/comptes/particuliers`,
    title: "Comptes Particuliers",
    icon: "fa fa-user",
    accessKeys: ["F001"],
    key: "admin_comptes_particuliers",
    deps: [
      {
        key: "accounts",
        path: "/account/all",
        method: "get",
        body: {},
      },
    ],
    resolve: (res: any) => {
      const result = res.data[0]?.result || {};
      if (Array.isArray(result.data)) {
        return result.data.filter((a: any) => a.accountType.accountTypeId === 1)
          .length;
      }
      return 0;
    },
  },
  {
    path: `${adminPrefix}/comptes/dealers`,
    title: "Comptes SuperDealers",
    icon: "fa fa-briefcase",
    accessKeys: ["F001"],
    key: "admin_comptes_superdealers",
    deps: [
      {
        key: "accounts",
        path: "/account/all",
        method: "get",
        body: {},
      },
    ],
    resolve: (res: any) => {
      const result = res.data[0]?.result || {};
      if (Array.isArray(result.data)) {
        return result.data.filter((a: any) => a.accountType.accountTypeId === 3)
          .length;
      }
      return 0;
    },
  },
  {
    path: `${adminPrefix}/comptes`,
    title: "Comptes Actifs",
    icon: "fa fa-check",
    accessKeys: ["F001"],
    key: "admin_comptes_active",
    deps: [
      {
        key: "accounts",
        path: "/account/all",
        method: "get",
        body: {},
      },
    ],
    resolve: (res: any) => {
      const result = res.data[0]?.result || {};
      if (Array.isArray(result.data)) {
        return result.data.filter((a: any) => a.active).length;
      }
      return 0;
    },
  },
  {
    path: `${adminPrefix}/comptes`,
    title: "Comptes Inactifs",
    icon: "fa fa-times",
    accessKeys: ["F001"],
    key: "admin_comptes_inactive",
    deps: [
      {
        key: "accounts",
        path: "/account/all",
        method: "get",
        body: {},
      },
    ],
    resolve: (res: any) => {
      const result = res.data[0]?.result || {};
      if (Array.isArray(result.data)) {
        return result.data.filter((a: any) => !a.active).length;
      }
      return 0;
    },
  },
  {
    path: `${adminPrefix}/utilisateurs`,
    title: "Utilisateurs",
    icon: "fa fa-users",
    accessKeys: ["F062"],
    key: "admin_users",
    deps: [
      {
        key: "users",
        path: "/user/all",
        method: "get",
        body: {},
      },
    ],
    resolve: (res: any) => {
      const result = res.data[0]?.result || {};
      if (Array.isArray(result.data)) {
        return result.data.length;
      }
      return 0;
    },
  },
  {
    path: `${adminPrefix}/comptes`,
    title: "Comptes au total",
    icon: "fa fa-folder-open",
    accessKeys: ["F001"],
    key: "admin_accounts",
    deps: [
      {
        key: "accounts",
        path: "/account/all",
        method: "get",
        body: {},
      },
    ],
    resolve: (res: any) => {
      const result = res.data[0]?.result || {};
      if (Array.isArray(result.data)) {
        return result.data.length;
      }
      return 0;
    },
  },
];

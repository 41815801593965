import React from "react";
import { FormFields } from "./FormFields";

export function BaseForm(props: any) {
  return (
    <form className="ui error form auth-form">
      <div className="d-flex flex-column justify-content-center align-items-center">
        <FormFields {...props} />
      </div>
    </form>
  );
}

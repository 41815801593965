import React from "react";
import { Label } from "../../Fields";

export function FormLabel(props: any) {
  const { field, customLayoutLabel } = props;
  const layout = customLayoutLabel || "col-md-4";
  return (
    <div className={layout} style={{ padding: "0 1rem 0 0" }}>
      <Label
        field={field}
        cls={"col-form-label col-form-label-sm"}
        styles={{ float: "right" }}
      />
    </div>
  );
}

import { appConstants } from "../_constants";
import { BaseEntity } from "./BaseEntity";
import { FieldType, InputType } from "./enumerations";
import { Field } from "./Field";
import {BaseModel} from "../_models";

const {
  RENDER_ACTIONS,
  RENDER_ID,
  RENDER_STATUS,
  RENDER_OPERATION_TYPE,
  RENDER_OUI_NON,
  RENDER_CATEGORY_OPERATION_TYPE,
} = appConstants.keys.renderKeys;

class OperationType extends BaseEntity {
  public static readonly addTitle = "Ajouter un type d'opération";
  public static readonly showTitle = "Détails d'un type d'opération";
  public static readonly editTitle = "Modifier un type d'opération";
  public static readonly saveBtnTitle = "Enregistrer";
  public static readonly idField = "operationTypeId";
  public static readonly processNewSuccessMsg =
    "Le type d'opération a été enregistré avec succès !";
  public static readonly processEditSuccessMsg =
    "Le type d'opération a été enregistré avec succès !";
  public static readonly processNewPath = "operation/type/add";
  public static readonly processEditPath = "operation/type/update/_ID_";

  public static instance: OperationType = new OperationType();
  public static instances = [];
  public static relations = [];

  public static get definitions(): Field[] {
    const model: any = BaseModel.getInstance();
    const aChildAll = [
      {
        label: "Modifier",
        title: "Modifer ce type d'opération",
        action: "editActions",
        color: "text-success",
        icon: "fa fa-edit",
      },
      {
        actionCallback: (data: any) => {
          if (data.active) {
            return {
              label: "Désactiver",
              title: "Désactiver ce type d'opération",
              action: "switchActionsDeactivate",
              color: "text-danger ",
              icon: "fa fa-times",
            };
          }
          return {
            label: "Activer",
            title: "Activer ce type d'opération",
            action: "switchActionsActivate",
            color: "text-success",
            icon: "fa fa-check",
          };
        },
      },
      {
        label: "Supprimer",
        title: "Supprimmer ce type d'opération",
        action: "deleteActions",
        color: "text-danger",
        icon: "fa fa-trash",
      },
    ];
    const aChildMod = [
      {
        label: "Modifier",
        title: "Modifer ce type d'opération",
        action: "editActions",
        color: "text-success",
        icon: "fa fa-edit",
      },
      {
        actionCallback: (data: any) => {
          if (data.active) {
            return {
              label: "Désactiver",
              title: "Désactiver ce type d'opération",
              action: "switchActionsDeactivate",
              color: "text-danger ",
              icon: "fa fa-times",
            };
          }
          return {
            label: "Activer",
            title: "Activer ce type d'opération",
            action: "switchActionsActivate",
            color: "text-success",
            icon: "fa fa-check",
          };
        },
      }
    ];
    const aChildDel = [
      {
        label: "Supprimer",
        title: "Supprimmer ce type d'opération",
        action: "deleteActions",
        color: "text-danger",
        icon: "fa fa-trash",
      }
    ];
    const definitions = [
      new Field({
        name: "operationTypeId",
        type: FieldType.NUMBER,
        primary: true,
        creatable: false,
        editable: false,
        serializeNew: false,
        serializeEdit: false,
        label: "#",
        renderKey: RENDER_ID,
        provider: OperationType.instance,
      }),
      new Field({
        name: "libelle",
        label: "Libellé",
        dataPath: "operationTypeId",
        renderKey: RENDER_OPERATION_TYPE,
        provider: OperationType.instance,
        customData: {
          renderOptions: {
            className: "min-w-150",
          },
        },
      }),
      new Field({
        name: "category",
        label: "Catégorie",
        renderKey: RENDER_CATEGORY_OPERATION_TYPE,
        provider: OperationType.instance,
        inputType: InputType.INPUT_SELECT,
        relationKey: "categories",
        customData: {
          renderOptions: {
            className: "min-w-150",
          },
          checkBoxData: {
            id: "hasFees",
            cls: "form-check-label",
            customLabel: "Peux t-on y ajouter des frais ?",
            styles: {fontSize: "80%", marginLeft: "19px"},
            checked: true,
            eventName: "hasFees_change",
            customInlineStyle: {marginLeft: "1px", marginTop: "7px"},
            customLayoutInlineStyle: {
              marginTop: "6px",
              padding: 0,
            },
          },
        },
        listeners: [
          {
            type: "hasFees_change",
            behaviour: "customAction",
            resolve: (v: any, customData?: any) => {
              OperationType.instance.updateValue("hasFees", v?.value);
              return {
                ...customData,
                checkBoxData: {...customData.checkBoxData, checked: v?.value},
              };
            },
          },
        ],
        serializeCallback: (item: any, v: any) => {
          console.log(item,v);
          console.log(OperationType.instance);
          const hasFees = OperationType.instance.getValue("hasFees", true);
          const active = OperationType.instance.getValue("active", false);
          return {...item, category: v, hasFees, active};
        },
      }),
      new Field({
        name: "hasFees",
        label: "Peux t-on y ajouter des frais ?",
        renderKey: RENDER_OUI_NON,
        creatable: false,
        editable: false,
        provider: OperationType.instance,
      }),
      new Field({
        name: "active",
        label: "Status",
        renderKey: RENDER_STATUS,
        creatable: false,
        editable: false,
        provider: OperationType.instance,
      }),
      new Field({
        name: "actions",
        label: "Actions",
        dataPath: "operationTypeId",
        creatable: false,
        editable: false,
        serializeNew: false,
        serializeEdit: false,
        serializeView: false,
        renderKey: RENDER_ACTIONS,
        provider: OperationType.instance,
        customData: {
          renderOptions: {
            clsActions: "justify-content-center",
          },
          actions: [
            {
              label: "Détails",
              title: "Afficher les détails",
              action: "detailsActions",
              color: "btn-info",
              icon: "fa fa-eye",
            },
            {
              type: "btnGroup",
              color: "btn-primary",
              children:model.user.user?.profile===null?aChildAll:
                  model.confCrud.modification && model.confCrud.suppression?aChildAll:
                      model.confCrud.modification?aChildMod:model.confCrud.suppression?aChildDel:[] ,
            },
          ],
        },
      }),
    ];
    return definitions;
  }

  toView() {
    return [
      {
        title: false,
        rows: [
          { label: "Operation type ID", value: this.operationTypeId },
          { label: "Libellé", value: this.libelle },
          {
            label: "Catégorie",
            value:
              this.category === 1
                ? "Transferts"
                : this.category === 2
                ? "Paiements"
                : "Autres",
          },
          {
            label: "Peut-on y ajouter des frais ? ",
            value: this.hasFees ? "Oui" : "Non",
          },
          { label: "Actif ? ", value: this.active ? "Oui" : "Non" },
        ],
      },
    ];
  }

  initNewCallBack() {
    this.updateValue("hasFees", true);
    this.updateValue("active", true);
    this.updateValue("categories", [
      {
        label: "Transferts",
        value: 1,
      },
      {
        label: "Paiements",
        value: 2,
      },
      {
        label: "Autres",
        value: 3,
      },
    ]);
  }

  getSwitchActionConfig(status: boolean) {
    return {
      path: `/operation/type/change/${this.operationTypeId}/${status}`,
      resolve: (res: any) => res?.data?.operationTypeId > 0,
    };
  }

  getDeleteActionConfig() {
    return {
      path: `/operation/type/delete/${this.operationTypeId}`,
      resolve: (res: any) => res?.success,
    };
  }

  public static getSelectOperationType(v: any) {
    return {
      label: v.libelle,
      value: v.operationTypeId,
      taux: "",
    };
  }
}

export { OperationType };

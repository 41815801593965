import React from "react";
import { history } from "../../../_helpers";
import { PropsFromRedux } from "../../../_reducers";

export function ErrorPage(props: PropsFromRedux & any) {
  const msg =
    props?.errmsg || "La page que vous éssayez de consulter n'existe pas.";
  return (
    <div>
      <h3 className="text-center pt-5 text-danger">Une érreur est survenu !</h3>
      <p className="text-muted text-center">{msg}</p>
      <span className="d-flex justify-content-center">
        <button
          className="btn btn-primary btn-sm"
          onClick={(e) => {
            e.preventDefault();
            history.goBack();
          }}
        >
          <i className="fa fa-arrow-left mr-1" aria-hidden="true"></i>
          Retour
        </button>
      </span>
    </div>
  );
}

import { appConstants } from "../_constants";
import { restClient, utils } from "../_helpers";
import { BaseModel } from "../_models";
import { BaseEntity } from "./BaseEntity";
import { FieldType, InputType } from "./enumerations";
import { Field } from "./Field";

const {
  RENDER_ACTIONS,
  RENDER_ID,
  RENDER_OPERATION_TYPE_BULK,
  RENDER_DATETIME,
  RENDER_DEVISE_BULK,
} = appConstants.keys.renderKeys;

class BulkPayment extends BaseEntity {
  public static readonly addTitle = "Initier un paiement multiple";
  public static readonly showTitle = "Détails d'un paiement multiple";
  public static readonly saveBtnTitle = "Initier les transferts";
  public static readonly idField = "bulkPaymentId";
  public static readonly processNewSuccessMsg =
    "Le paiement multple a été enregistré avec succès !";
  public static readonly processEditSuccessMsg =
    "Le paiement multple a été enregistré avec succès !";
  public static readonly processNewPath = "/operation/BulkPayment/payment";
  public static instance: BulkPayment = new BulkPayment();
  public static instances = [];
  public static relations = [
    {
      dataPath: "operation/type/all",
      key: "operationTypes",
      resolve: (res: any) => {
        if (Array.isArray(res?.data)) {
          const data = res?.data.filter(
            (o: any) => o.operationTypeId === 3 || o.operationTypeId === 4 || o.operationTypeId === 7
          );
          return data.map((v: any) => ({
            label: v.libelle,
            value: v.operationTypeId,
          }));
        }
        return [];
      },
    },
    {
      dataPath: "currency/all",
      key: "currencies",
      resolve: (res: any) => {
        if (Array.isArray(res?.data)) {
          return res.data.map((v: any) => ({
            label: v.nom,
            value: v.deviseId,
            taux: v.taux,
            code: v.code,
          }));
        }
        return [];
      },
    },
  ];

  public static definitions = [
    new Field({
      name: "bulkPaymentId",
      type: FieldType.NUMBER,
      primary: true,
      creatable: false,
      editable: false,
      serializeNew: false,
      serializeEdit: false,
      label: "#",
      renderKey: RENDER_ID,
      provider: BulkPayment.instance,
    }),
    new Field({
      name: "inputFile",
      label: "Fichier source",
      formRowIndex: 0,
      inputType: InputType.INPUT_FILE,
      provider: BulkPayment.instance,
      customData: {
        hasFileTemplateDownloadLink: true
      }
    }),
    new Field({
      name: "state",
      label: "Status",
      creatable: false,
      editable: false,
      provider: BulkPayment.instance,
    }),
    new Field({
      name: "creationDate",
      label: "Date de création",
      creatable: false,
      editable: false,
      renderKey: RENDER_DATETIME,
      provider: BulkPayment.instance,
    }),
    new Field({
      name: "devise",
      label: "Devise",
      renderKey: RENDER_DEVISE_BULK,
      relationKey: "currencies",
      inputType: InputType.INPUT_SELECT,
      formRowIndex: 2,
      provider: BulkPayment.instance,
    }),
    new Field({
      name: "operationType",
      label: "Type d'opération",
      relationKey: "operationTypes",
      renderKey: RENDER_OPERATION_TYPE_BULK,
      provider: BulkPayment.instance,
      inputType: InputType.INPUT_SELECT,
      formRowIndex: 1,
    }),
    new Field({
      name: "description",
      label: "Description",
      onTable: false,
      formRowIndex: 3,
      required: false,
      provider: BulkPayment.instance,
      inputType: InputType.INPUT_TEXTAREA,
    }),
    new Field({
      name: "actions",
      label: "Actions",
      dataPath: "bulkPaymentId",
      creatable: false,
      editable: false,
      serializeNew: false,
      serializeEdit: false,
      serializeView: false,
      renderKey: RENDER_ACTIONS,
      provider: BulkPayment.instance,
      customData: {
        renderOptions: {
          clsActions: "justify-content-center",
        },
        actions: [
          {
            label: "Détails",
            title: "Afficher les détails",
            action: "detailsActions",
            color: "btn-info",
            icon: "fa fa-eye",
          },
        ],
      },
    }),
  ];

  toView() {
    return [
      {
        title: false,
        rows: [
          { label: "Bulk paiement ID", value: this.bulkPaymentId },
          { label: "Fichier source", value: this.inputFile },
          { label: "Status", value: this.state },
          {
            label: "Devise",
            value:
              this.devise == 1
                ? "Franc congolais"
                : this.devise == 2
                ? "Dollar US"
                : this.devise,
          },
          {
            label: "Type d'opération",
            value:
              this.operationType == 3
                ? "Transfert e-mpata vers e-mpata"
                : this.operationType == 4
                ? "Transfert e-mpata vers autres"
                : this.operationType == 7
                ? "Achat de credit" 
                : this.operationType,
          },
          {
            label: "Date de création",
            value: utils().formatDate(
              "DD/MM/YYYY [à] HH:mm:ss",
              this.creationDate
            ),
          },
        ],
      },
    ];
  }

  public get hasNext(): boolean {
    const step = this.getValue("step", 1);
    return step < 2;
  }

  /**
   * processNext
   */
  public processNext(props: any) {
    const step = this.getValue("step", 1);
    this.updateValue("step", step + 1);

    const { mode, content, title, hideFooter, submitted } = props;

    this.updateValue("prevState", {
      mode,
      content,
      title,
      hideFooter,
      submitted,
    });

    return {
      mode: "customActions",
      content: "bulkpaymentpreview",
      title: "Confirmation",
      submitted: false,
      settings: {
        showSaveBtn: true
      }
    };
  }

  /**
   * processPrev
   */
  public processPrev(props: any) {
    const step = this.getValue("step", 1);
    this.updateValue("step", step - 1);
    return this.getValue("prevState", {});
  }

  buildPayload(data: any) {
    let description = data ? data.description.replace(/\\s/g, "+") : "";
    let ws_path = `/operation/bulk/payment?account=${BaseModel.getInstance().user?.accountId}&devise=${data.devise}&operationType=${data.operationType}&description=${description}`;
    console.log("==> buildPayload ws_path ", ws_path);
    const formData = new FormData();
    formData.append("file", data.inputFile);
    formData.append("ws_path", encodeURI(ws_path));
    return formData;
  }

  async processNew(payload: any) {
    console.log("==> payload processNew", payload);
    return new Promise(async (resolve) => {
      const body = this.buildPayload(payload);
      const multipart = "multipart/form-data; boundary=empataSuperCoolBoundary--";
      const res = await restClient.post({ multipart, body, prefix: "/multipart" });
      console.log("==> processNew res", res);
      const id = res?.data?.content?.bulkPaymentId || 0;
      let cls = "alert-danger"; 
      let content = "";
      if (res.success && id > 0) {
        cls = "alert-success";
        content =" Paiement multiple initié avec succès.";
        utils().emitter.emit("alert_message", { content, cls });
        resolve(res.data.content);
      } else {
        content =
          res?.message ||
          "Une erreur s'est produite lors de l'enregistrement. Merci de reéssayer.";
        utils().emitter.emit("alert_message", { content, cls });
        resolve(false);
      }
    });
  }

}

export { BulkPayment };

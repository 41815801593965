import React from "react";

import loadingGif from "../../../_assets/images/loading.gif";

export function LoadingGif(props: any) {
  return (
    <div
      className="loader"
      style={{ display: props?.loading ? "inline" : "none" }}
    >
      <div className="row align-items-center">
        <img src={loadingGif} alt="loading ..." />
        <p className="loading-message" style={{ margin: 0 }}>
          Veuiller patienter
        </p>
      </div>
    </div>
  );
}

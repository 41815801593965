import React, { useEffect, useState } from "react";
import { restClient } from "../../../_helpers";
import ProductList from "./ProductList";

export function ProductWrapper(props: any) {
  console.log(props.products);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    restClient.get(`/product/all`).then((res) => {
      setLoading(false);
      if (res.success) {
        props.model.refreshDataTableData(props, res);
        setProducts(res.data);
      }
    });
  }, []);
  if (loading) {
    return <h4 className="py-3 text-muted text-center">Chargement des produits en cour ...</h4>
  }
  if (!loading && products.length === 0) {
    return <h4 className="py-3 text-center">Aucun produit trouvée</h4>
  }
  return (
    <>{products.length > 0 && <ProductList {...props} products={products} />}</>
  );
}

import React, { useEffect, useState } from "react";
import { appConstants } from "../../../_constants";
import { history, utils } from "../../../_helpers";
import { BaseModel } from "../../../_models";

const { USER_BALANCE_CHANGE } = appConstants.keys.eventKeys;

export function Compte(props: any) {
  const { model } = props;
  const { user } = model;
  const [solde, setSolde] = useState(user.solde);
  console.log("PROPS IIIIIIIHHHHH", props);

  useEffect(() => {
    const handleSoldeChangeEvent = (data: any) => {
      setSolde(data);
    };
    utils().emitter.on(USER_BALANCE_CHANGE, (res) => {
      handleSoldeChangeEvent(res);
    });
    return () => {
      utils().emitter.off(USER_BALANCE_CHANGE, handleSoldeChangeEvent);
    };
  }, []);

  return (
    <div id="comptes-list-group" className="list-group box-shadow">
      <div
        className="list-group-item flex-column align-items-start text-center"
        style={{
          padding: "10px",
          borderBottom: "2px solid rgba(0, 0, 0, 0.125)",
        }}
      >
        <span className="d-flex w-100 justify-content-center">
          <h6
            style={{ marginBottom: 0, color: "#0f3256" }}
            className="your_account_text"
          >
            {user && user.accountType ? "Votre compte" : "Pas de compte"}
          </h6>
        </span>
      </div>
      <a
        href="/#"
        className="list-group-item list-group-item-action flex-column align-items-start"
      >
        <div className="d-flex w-100 justify-content-between">
          <small className="account-name" style={{ marginBottom: 0 }}>
            {user ? user.msisdn : "-"} (
            {user && user.accountType ? user.accountType?.libelle : "-"})
          </small>
        </div>
        <hr />
        <div className="d-flex flex-column">
          <div className="d-flex w-100 justify-content-between">
            <small className="account-name" style={{ marginBottom: 0 }}>
              Nom du titulaire :
            </small>
          </div>
          <small className="">
            <span className="balance_text"></span>
            <span className="currency">{getNom(user)} </span>
          </small>
        </div>
        <div className="d-flex flex-column mt-2">
          <div className="d-flex w-100 justify-content-between">
            <small className="account-name" style={{ marginBottom: 0 }}>
              Télépone du titulaire :
            </small>
          </div>
          <small className="">
            {" "}
            <span className="balance_text"></span>
            <span className="currency">{getTel(user)}</span>
          </small>
        </div>
      </a>
      <a
        href="/#"
        className="list-group-item list-group-item-action flex-column align-items-start"
      >
        <div className="d-flex flex-column mt-2">
          <p>Carte d'identité :</p>
          {getCNI(user)}
        </div>
      </a>
      <button
        className="list-group-item flex-column align-items-end"
        style={{ padding: "0.5rem 0.5rem" }}
      >
        <div className="d-block">
          <small
            className="mb-1 text-success font-weight-bold"
            onClick={() => history.push("/dashboard/profile")}
          >
            Afficher mon profil
            <i className="ml-2 fa fa-angle-right"></i>
          </small>
        </div>
      </button>
    </div>
  );
}

const getNom = (user: any) => {
  if (user) {
    if (user.user) {
      if (user.user.firstName) {
        return user.user.firstName + " " + user.user.lastName;
      }
      return "non défini";
    }
  }
  return "-";
};

const getTel = (user: any) => {
  if (user) {
    if (user.user) {
      if (user.user.msisdn) {
        return user.user.msisdn;
      }
      return "non défini";
    }
  }
  return "-";
};

const getCNI = (user: any) => {
  const url = BaseModel.getInstance().wsUrl;
  if (user) {
    if (user.user) {
      if (user.user.cni && user.user.cni !== "string") {
        return (
          <img
            style={{ maxHeight: "211px" }}
            src={`${url}/user/media/download/${user.user.cni}`}
            alt="CNI"
          />
        );
      }
      return <p>aucune image</p>;
    } else {
      if (user.cni && user.cni !== "string") {
        return (
          <img
            style={{ maxHeight: "211px" }}
            src={`${url}/user/media/download/${user.user.cni}`}
            alt="CNI"
          />
        );
      }
      return <p>aucune image</p>;
    }
  }
  return "-";
};

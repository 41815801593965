import firebase from "firebase";
import React from "react";
import { withRouter } from "react-router-dom";
import Auth from "../pages/Auth";
import { PassResetFields, SigninFooter } from "../_components";
import { connector, PropsFromRedux } from "../_reducers";

export function withPassReset(Component: React.LazyExoticComponent<typeof Auth>) {
  return withRouter(
    connector(
      class extends React.Component<PropsFromRedux & any, any> {
        constructor(props: any) {
          super(props);
          this.state = {
            getTitle: (data: any) => this.getTitle(data.step),
            getBtnTitle: (data: any) => this.getBtnTitle(data.step),
            pageTitle: "Mot de passe oublié",
            getExtraAuthClass: (data: any) => "",
            renderFields: (data: any) => <PassResetFields {...data} />,
            renderFooter: (data: any) => <SigninFooter {...data} />,
            handleSubmit: (data: any, provider: any) =>
              this.handleSubmit(data, provider),
          };
          this.handleSubmit = this.handleSubmit.bind(this);
        }

        getBtnTitle(step: number) {
          if (step === 2) {
            return "Valider le code";
          }else if (step === 3) {
            return "Reinitialiser mon mot de passe";
          }
          return "Envoyer le code";
        }

        getTitle(step: number) {
          return "Mot de passe oublié";
        }

        async handleSubmit(data: any, provider: any) {
          
          let res: any = {};
          if (provider.validate()) {
            const payload = provider.serialize();
            res = await provider.processNew({payload, data});
            if (res?.success) {
             
              
            } else {
              
            }
          }
          return res;
        }

        render() {
          return <Component {...this.props} {...this.state} />;
        }
      }
    )
  );
}

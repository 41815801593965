import React, { Component } from "react";
class InputText extends Component<any, any> {

  render() {
    const {type,freeze,label,name,onChange,value,placeholder} = this.props;
    return (
      <div
        className='form-group row'
        style={{ margin: "0 0 1rem 0", width: "90%" }}
      >
        <div className='col-md-4' style={{ padding: "0 1rem 0 0" }}>
          <label
            htmlFor='colFormLabelSm'
            className=' col-form-label col-form-label-sm'
            style={{ float: "right" }}
          >
            {label}
          </label>
        </div>
        <div className='col-md-8' style={{ padding: 0 }}>
          <input
            className='form-control'
            name={name}
            type={type}
            disabled={freeze}
            value={value}
            placeholder={placeholder}
            onChange={(e) => onChange(e)}
            style={{ width: "100%" }}
          />
        </div>
      </div>
    );
  }
}

export { InputText };

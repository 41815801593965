import { Field, InputType } from "../_entities";

export const getEmpata2BankFields = function(provider: any) : Field[] {
  const layout = provider.isBank === true ? "col-md-12" : "col-md-12 d-none";
  return [
    new Field({
      name: "pays",
      label: "Pays*",
      onTable: false,
      required: false,
      formLayout: layout,
      provider: provider,
      relationKey: "countries",
      formRowIndex: 0,
      inputType: InputType.INPUT_SELECT,
      listeners: listeners,
    }),
    new Field({
      name: "nomBenef",
      label: "Nom*",
      onTable: false,
      required: false,
      formLayout: layout,
      provider: provider,
      listeners: listeners,
      customData: {
        placeholder: "Nom du bénéficiaire"
      }
    }),
    new Field({
      name: "prenomBenef",
      label: "Prénom*",
      onTable: false,
      required: false,
      formLayout: layout,
      provider: provider,
      listeners: listeners,
      customData: {
        placeholder: "Prénom du bénéficiaire"
      }
    }),
    new Field({
      name: "codeSwift",
      label: "Code Swift*",
      onTable: false,
      required: false,
      formLayout: layout,
      provider: provider,
      listeners: listeners,
      customData: {
        placeholder: "Code Swift de la banque"
      }
    }),
    new Field({
      name: "rib",
      label: "Numéro du RIB*",
      onTable: false,
      required: false,
      formLayout: layout,
      provider: provider,
      listeners: listeners,
      customData: {
        placeholder: "Numéro du compte bancaire"
      }
    }),
    new Field({
      name: "nomBanque",
      label: "Nom de la banque*",
      onTable: false,
      required: false,
      formLayout: layout,
      provider: provider,
      listeners: listeners,
      customData: {
        placeholder: "Nom de la banque receptrice"
      }
    }),
    new Field({
      name: "origineFonds",
      label: "Origine des fonds*",
      onTable: false,
      required: false,
      formLayout: layout,
      provider: provider,
      listeners: listeners,
      customData: {
        placeholder: "D'où proviennent les fonds?"
      }
    }),
    new Field({
      name: "telephone",
      label: "Téléphone*",
      onTable: false,
      required: false,
      formLayout: layout,
      provider: provider,
      listeners: listeners,
      customData: {
        placeholder: "Numéro de téléphone du bénéficiaire"
      }
    }),
    new Field({
      name: "motif",
      label: "Motif de transfert*",
      onTable: false,
      required: false,
      formLayout: layout,
      provider: provider,
      inputType: InputType.INPUT_TEXTAREA,
      listeners: listeners,
      customData: {
        placeholder: "Raison du virement"
      }
    }),
    new Field({
      name: "sendSMS",
      label: "Envoyer le SMS",
      relationKey: "smss",
      onTable: false,
      required: false,
      formLayout: layout,
      provider: provider,
      inputType: InputType.INPUT_SELECT,
      listeners: listeners,
      
    }),
  ];
}

const listeners = [{
  type: "value_change_operation_type_id",
  behaviour: "toggleLayout",
  resolve: (v: any, customData?: any) => {
    const vv = parseInt(v.value);
    if ([25].indexOf(vv) !== -1) {
      return "col-md-12";
    }
    return "col-md-12 d-none";
  },
}]
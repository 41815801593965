import React from "react";
import Admin from "../pages/Admin";
import { connector, PropsFromRedux } from "../_reducers";
import {
  adminSidebarItems,
  adminNavbarStyles,
  adminKpiItems,
} from "../_config";
import { withRouter } from "react-router-dom";
import {restClient, utils} from "../_helpers";

export function withAdmin(Component: React.LazyExoticComponent<typeof Admin>) {
  return withRouter(
    connector(
      class extends React.Component<PropsFromRedux & any, any> {
        render() {
          const {model} = this.props;
          const {config} = model;
          Object.assign(config, {
            navbarStyles: adminNavbarStyles,
            sidebarItems: utils().filterSidebarItems(
                adminSidebarItems,
                model.user
            ),
            kpiItems: utils().filterSidebarItems(adminKpiItems,model.user)
          });
          if (!config?.fatalError && !config?.pageType) {
            Object.assign(config, {pageType: "table"});
          }
          return <Component {...this.props} {...config} />;
        }
      }
    )
  );
}

import { tableRenderer, utils } from "../_helpers";
import { FieldType, InputType } from "./enumerations";

const isSignin = window.location.pathname.indexOf("signin") !== -1;

export interface FieldInterface {
  name: string;
  type?: FieldType;
  creatable?: boolean;
  primary?: boolean;
  editable?: boolean;
  onTable?: boolean;
  serializeNew?: boolean;
  serializeEdit?: boolean;
  serializeView?: boolean;
  required?: boolean;
  label?: string;
  inputType?: InputType;
  targetEntity?: any;
  renderKey?: string;
  formOrder?: number;
  formRowIndex?: number;
  formColumnIndex?: number;
  formLayout?: string;
  data?: string;
  value?: any;
  dataPath?: string;
  dataPathLabelField?: string;
  inactiveLabel?: string;
  listeners?: any[];
  emitters?: any[];
  filePath?: string;
  inlineStyle?: any;
  provider: any;
  customData?: any;
  defaultValue?: any;
  relationKey?: string;
  customClasses?: string;
  validatorMessage?: string;
  validators?: Array<{ meta?: any; validate: (meta: any) => boolean }>;
  dataSource?: () => Array<any>;
  dataPathLabelCallback?: (item: any) => any;
  serializeCallback?: (item: any, value: any) => any;
}

class Field implements FieldInterface {
  name: string = "";
  type?: FieldType = FieldType.STRING;
  creatable?: boolean = true;
  primary?: boolean = false;
  editable?: boolean = true;
  onTable?: boolean = true;
  label?: string = "";
  inputType?: InputType = InputType.INPUT_TEXT;
  targetEntity?: any = {};
  renderKey?: string = "";
  formOrder?: number = 100;
  formRowIndex?: number = 100;
  formColumnIndex?: number = 100;
  formLayout?: string = isSignin ? "col-md-6" : "col-md-12";
  data?: string = "";
  dataPath?: string = "";
  inactiveLabel?: string = "";
  listeners?: any[] = [];
  emitters?: any[] = [];
  filePath?: string = "";
  inlineStyle: any = { padding: 0 };
  provider: any;
  dataPathLabelField?: string | undefined;
  required?: boolean = true;
  relationKey?: string = "";
  customClasses: string = "";
  customData: any = {};
  serializeNew?: boolean = true;
  serializeEdit?: boolean = true;
  serializeView?: boolean = true;
  validatorMessage?: string = "";
  defaultValue?: any = "";
  dataPathLabelCallback?: (item: any) => any;
  dataSource?: () => Array<any>;
  serializeCallback?: (item: any, value: any) => any;
  validators?: Array<{ meta?: any; validate: (meta: any) => boolean }>;

  constructor(options: FieldInterface) {
    if (typeof options === "object") {
      const entries = Object.entries(options);
      for (const [key, value] of entries) {
        if (this.hasOwnProperty(key)) {
          (this as any)[key] = value;
        }
      }
    }
    this.listeners?.push({
      type: `initNew`,
      behaviour: "resetValue",
    });
    this.listeners?.push({
      type: `initEdit`,
      behaviour: "setValue",
    });
  }

  public get value(): any {
    return this.provider.getValue(this.name);
  }

  public get options(): any[] {
    return this.provider.getValue(this.relationKey, []);
  }

  public get isValid(): boolean {
    if (this.validators?.length) {
      let valid = true;
      for (let index = 0; index < this.validators.length; index++) {
        const validator = this.validators[index];
        valid = validator.validate(validator.meta);
        if (!valid) {
          this.validatorMessage = validator.meta?.msg
            ? validator.meta?.msg
            : "";
          break;
        }
      }

      return valid;
    }
    if (!this.value && this.required) {
      return false;
    }
    return true;
  }

  updateValue(value: any) {
    this.provider.updateValue(this.name, value);
    if (this.emitters?.length) {
      this.emitters.forEach((e) => {
        utils().emitter.emit(e.type, { value });
      });
    }
    return value;
  }

  toTableField() {
    const renderOptions = this.customData?.renderOptions || {};
    return {
      title: this.label,
      data: this.dataPath || this.name,
      render: tableRenderer.getRenderer(this),
      ...renderOptions,
    };
  }
}

export { Field };

import React, { Component } from "react";

class MontantFrais2 extends Component<any, any> {
  render() {
    const {
      fraisCDF,
      fraisDollar,
      totalNetCDF,
      totalNetDollar,
      totalTTCCDF,
      totalTTCDollar,
    } = this.props;

    return (
      <>
        <div id="montant-group2" className="row" style={{ marginTop: "10px" }}>
          <div className="col-md-4 d-flex flex-column pr-0">
            <p
              id="frais-group1"
              data-frais="0"
              className="font-weight-bold"
              style={{ margin: 0, fontSize: "13px" }}
            >
              Frais
            </p>

            <span style={{ fontSize: "13px", font: "bold", color: "red" }}>
              {this.props.applySeparator(fraisCDF)} CDF
            </span>
            <span style={{ fontSize: "13px", font: "bold", color: "red" }}>
              $ {this.props.applySeparator(fraisDollar)}
            </span>
          </div>
          <div className="col-md-4 d-flex flex-column pr-0">
            <p
              id="total-group1"
              data-total="0"
              className="font-weight-bold"
              style={{ margin: 0, fontSize: "13px" }}
            >
              Total Net
            </p>
            <span style={{ fontSize: "13px" }}>
              {this.props.applySeparator(totalNetCDF)} CDF
            </span>
            <span style={{ fontSize: "13px" }}>
              $ {this.props.applySeparator(totalNetDollar)}
            </span>
          </div>
          <div className="col-md-4 d-flex flex-column pr-0">
            <p
              id="total-group2"
              data-total="0"
              className="font-weight-bold"
              style={{ margin: 0, fontSize: "13px" }}
            >
              Total TTC
            </p>
            <span style={{ fontSize: "13px" }}>
              {this.props.applySeparator(totalTTCCDF)} CDF
            </span>
            <span style={{ fontSize: "13px" }}>
              $ {this.props.applySeparator(totalTTCDollar)}
            </span>
          </div>
        </div>
      </>
    );
  }
}

export { MontantFrais2 };

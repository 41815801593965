import React, { useState } from "react";
import loadingGif from "../../../../_assets/images/loading.gif";

export function TableLoader(props: any) {
  const { onRetry, loaderRef, errorRef } = props;

  const onClick = (e: any) => {
    e.preventDefault();
    onRetry();
  };

  return (
    <div className="loader" style={{ width: "100%", display: "block" }}>
      <div className="row align-items-center justify-content-end mr-3">
        <div
          ref={loaderRef}
          className="align-items-center justify-content-center"
          style={{ display: "flex" }}
        >
          <img src={loadingGif} alt="" />
          <p className="loading-message" style={{ margin: 0 }}>
            Chargement des données
          </p>
        </div>
        <div
          ref={errorRef}
          className="alert alert-danger text-small justify-content-center align-items-center"
          role="alert"
          style={{ display: "none" }}
        >
          <small>Impossible de charger les données !</small>
          <a
            href="/#"
            onClick={onClick}
            id="load-data-retry-link"
            className="alert-link text-small ml-1"
          >
            <small className="font-weight-bold">
              {" "}
              <u>Reéssayer.</u>
            </small>
          </a>
          <button
            type="button"
            className="close pl-2"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
    </div>
  );
}

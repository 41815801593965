import { appConstants } from "../_constants";
import { BaseEntity } from "./BaseEntity";
import { FieldType, InputType } from "./enumerations";
import { Field } from "./Field";
import { BaseModel } from "../_models";
import { utils } from "../_helpers";

const { RENDER_ACTIONS, RENDER_ID, RENDER_OPERATION_TYPE, RENDER_STATUS, RENDER_ACCOUNT_TYPE } = appConstants.keys.renderKeys;

class Commission extends BaseEntity {
  public static readonly addTitle = "Ajouter une commission";
  public static readonly showTitle = "Détails d'une commission";
  public static readonly editTitle = "Modifier une commission";
  public static readonly saveBtnTitle = "Enregistrer";
  public static readonly idField = "id";
  public static readonly processNewSuccessMsg =
    "La commission a été enregistrée avec succès !";
  public static readonly processEditSuccessMsg =
    "La commission a été enregistrée avec succès !";
  public static readonly processNewPath = "commission/add";
  public static readonly processEditPath = "commission/update/_ID_";
  public static instance: Commission = new Commission();
  public static instances = [];
  public static relations = [
    {
      dataPath: "operation/type/all",
      key: "operationTypes",
      resolve: (res: any) => {
        if (Array.isArray(res?.data)) {
          return res.data.map((v: any) => ({
            label: v.libelle,
            value: v.operationTypeId,
          }));
        }
        return [];
      },
    },
    {
      dataPath: "account/type/all",
      key: "accountTypes",
      resolve: (res: any) => {
        if (Array.isArray(res?.data)) {
          return res?.data.map((v: any) => ({
            label: v.libelle,
            value: v.accountTypeId,
          }));
        }
        return [];
      },
    },
  ];

  public static get definitions(): Field[] {
    const model: any = BaseModel.getInstance();
    const aChildAll = [
      {
        label: "Modifier",
        title: "Modifer cette commission",
        action: "editActions",
        color: "text-success",
        icon: "fa fa-edit",
      },
      {
        actionCallback: (data: any) => {
          if (data.active) {
            return {
              label: "Désactiver",
              title: "Désactiver ce compte",
              action: "switchActionsDeactivate",
              color: "text-danger ",
              icon: "fa fa-times",
            };
          }
          return {
            label: "Activer",
            title: "Activer ce compte",
            action: "switchActionsActivate",
            color: "text-success",
            icon: "fa fa-check",
          };
        },
      },
      {
        label: "Supprimer",
        title: "Supprimmer cette commission",
        action: "deleteActions",
        color: "text-danger",
        icon: "fa fa-trash",
      },
    ];
    const aChildMod = [
      {
        label: "Modifier",
        title: "Modifer cette commission",
        action: "editActions",
        color: "text-success",
        icon: "fa fa-edit",
      },
      {
        actionCallback: (data: any) => {
          if (data.active) {
            return {
              label: "Désactiver",
              title: "Désactiver ce compte",
              action: "switchActionsDeactivate",
              color: "text-danger ",
              icon: "fa fa-times",
            };
          }
          return {
            label: "Activer",
            title: "Activer ce compte",
            action: "switchActionsActivate",
            color: "text-success",
            icon: "fa fa-check",
          };
        },
      },
    ];
    const aChildDel = [
      {
        label: "Supprimer",
        title: "Supprimmer cette commission",
        action: "deleteActions",
        color: "text-danger",
        icon: "fa fa-trash",
      },
    ];
    const definitions = [
      new Field({
        name: "id",
        type: FieldType.NUMBER,
        primary: true,
        creatable: false,
        editable: false,
        serializeNew: false,
        serializeEdit: false,
        label: "#",
        renderKey: RENDER_ID,
        provider: Commission.instance,
      }),
      new Field({
        name: "accountType",
        label: "Type de compte",
        renderKey: RENDER_ACCOUNT_TYPE,
        dataPath: "accountType.libelle",
        relationKey: "accountTypes",
        formRowIndex: 1,
        inputType: InputType.INPUT_SELECT,
        provider: Commission.instance,
      }),
      new Field({
        name: "operationType",
        label: "Type d'opération",
        renderKey: RENDER_OPERATION_TYPE,
        formRowIndex: 0,
        relationKey: "operationTypes",
        inputType: InputType.INPUT_SELECT,
        provider: Commission.instance,
      }),
      new Field({
        name: "pourcentage",
        label: "Valeur (en %)",
        inputType: InputType.INPUT_NUMBER,
        provider: Commission.instance,
        customData: {
          inputData: {
            attributes: {
              min: 0,
              max: 100
            }
          },
          checkBoxData: {
            id: "commission_active",
            cls: "form-check-label",
            customLabel: "Rendre cette commission actif ?",
            styles: { fontSize: "80%", marginLeft: "19px" },
            checked: true,
            eventName: "commission_active",
            customInlineStyle: { marginLeft: "1px", marginTop: "7px" },
            customLayoutInlineStyle: {
              marginTop: "6px",
              padding: 0,
              display: "",
            },
          },
        },
        listeners: [
          {
            type: "commission_active",
            behaviour: "customAction",
            resolve: (v: any, customData?: any) => {
              Commission.instance.updateValue("active", v?.value);
              return {
                checkBoxData: {
                  ...customData.checkBoxData,
                  checked: v?.value,
                  customLayoutInlineStyle: {
                    ...customData.checkBoxData.customLayoutInlineStyle,
                  },
                },
              };
            },
          },
        ]
      }),
      new Field({
        name: "active",
        label: "Status",
        creatable: false,
        editable: false,
        renderKey: RENDER_STATUS,
        provider: Commission.instance,
      }),
      new Field({
        name: "actions",
        label: "Actions",
        dataPath: "id",
        creatable: false,
        editable: false,
        serializeNew: false,
        serializeEdit: false,
        serializeView: false,
        renderKey: RENDER_ACTIONS,
        provider: Commission.instance,
        customData: {
          renderOptions: {
            clsActions: "justify-content-center",
          },
          actions: [
            {
              label: "Détails",
              title: "Afficher les détails",
              action: "detailsActions",
              color: "btn-info",
              icon: "fa fa-eye",
            },
            {
              type: "btnGroup",
              color: "btn-primary",
              children:
                model.user.user?.profile === null
                  ? aChildAll
                  : model.confCrud.modification && model.confCrud.suppression
                  ? aChildAll
                  : model.confCrud.modification
                  ? aChildMod
                  : model.confCrud.suppression
                  ? aChildDel
                  : [],
            },
          ],
        },
      }),
    ];
    return definitions;
  }

  toView() {
    return [
      {
        title: false,
        rows: [
          { label: "Commission ID", value: this.id },
          { label: "Type de compte", value: this.accountType?.libelle },
          { label: "Type d'opération", value: this.operationType?.libelle },
          { label: "Pourcentage", value: this.pourcentage },
          { label: "Actif ?", value: this.active ? "Oui" : "Non" },
          { label: "Date de création", value: utils().formatDate("DD/MM/YYYY [à] HH:mm:ss", this.createdAt) },
          { label: "Dernière modification", value: this.updatedAt ? utils().formatDate("DD/MM/YYYY [à] HH:mm:ss", this.updatedAt) : "Jamais modifié" },
        ],
      },
    ];
  }

  initEditCallBack(props: any) {
    this.updateValue("active", this.active);
    this.updateValue("accountType", this.accountType?.accountTypeId);
    this.updateValue("operationType", this.operationType?.operationTypeId);
  }

  initNewCallBack(props: any) {
    this.updateValue("active", true);
  }

  getDeleteActionConfig() {
    return {
      path: `/commission/delete/${this.id}`,
      resolve: (res: any) => res?.success,
    };
  }

  getSwitchActionConfig(status: boolean) {
    return {
      path: `/commission/change/${this.id}/${status}`,
      resolve: (res: any) => res?.data?.id,
    };
  }
}

export { Commission };

import React, { useEffect, useState } from "react";
import {restClient, utils} from "../../../_helpers";
import Marquee from "react-css-marquee";
import {appConstants} from "../../../_constants";

const { MODAL_ACTION } = appConstants.keys.eventKeys;
export function Message(props: any) {
  const { isNotifLoading, model } = props;
  const userId = model?.user?.userId || model?.user?.user?.userId || 0;
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    restClient.get(`/user/notification/byUser/${userId}`).then((res) => {
      console.log(res);
      if (res.success) {
        const arr = res.data.filter((a: any) => a.statut !== "LU");
        arr.sort((a, b) => {
          return (new Date(b.createDate) as any) - (new Date(a.createDate) as any);
        });
        setNotifications(arr);
      }
    });
  }, []);
  return (
    <div className="list-group box-shadow">
      <div
        className="list-group-item flex-column align-items-start text-center"
        style={{
          padding: "10px",
          borderBottom: "2px solid rgba(0, 0, 0, 0.125)",
        }}
      >
        <span className="d-flex w-100 flex-column align-items-center justify-content-center">
          <h6
            style={{ marginBottom: 0, color: "#0f3256" }}
            className="your_messages_text"
          >
            Vos messages
          </h6>
          {Array.isArray(notifications) && notifications.length > 0 && (
            <small className="text-muted" style={{ display: "block" }}>
              <Marquee text="hello" />
            </small>
          )}
        </span>
      </div>
      {!Array.isArray(notifications) && !isNotifLoading && (
        <a
          href="/#"
          className="list-group-item flex-column align-items-center"
          style={{ padding: "0.5rem 0.5rem" }}
        >
          <div className="d-flex w-100 justify-content-center">
            <small className="mb-1 messages_text" style={{ color: "#212529" }}>
              Aucun message
            </small>
          </div>
        </a>
      )}
      {isNotifLoading && (
        <a
          href="/#"
          className="list-group-item flex-column align-items-center"
          style={{ padding: "0.5rem 0.5rem" }}
        >
          <div className="d-flex w-100 justify-content-center">
            <small className="mb-1 messages_text" style={{ color: "#212529" }}>
              Chargement ...{" "}
            </small>
          </div>
        </a>
      )}

      {notifications &&
        notifications.map((notif: any, i: number) => {
          if (i < 8) {
            return <Notification notif={notif} key={i} />;
          }
        })}
      {notifications && notifications.length > 8 && (
        <button
          className="list-group-item flex-column align-items-center"
          style={{ padding: "0.5rem 0.5rem" }}
        >
          <div className="d-flex w-100 justify-content-center align-items-center">
            <small className="mb-1 text-success font-weight-bold">
              Afficher tous
              <i className="ml-2 fa fa-angle-right"></i>
            </small>
          </div>
        </button>
      )}
    </div>
  );
}

function Notification(props: any) {
  // const { notif } = props;
  const { notif } = props;
  const onClick = (e: any) => {
    utils().emitter.emit(MODAL_ACTION, {
      btn: "showMessage",
      data: props,
    });
  };

  return (

    // <a
    //   href="/#"
    //   className="list-group-item list-group-item-action flex-column align-items-start msg-link"
    //   style={{ padding: "0.5rem 0.5rem" }}
    //   data-msg={notif.message}
    //   data-dt={formatdate(notif.createDate)}
    //   data-id={notif.notificationId}
    // >
      <button
          type="button"
          onClick={onClick}
          className="list-group-item list-group-item-action flex-column align-items-start msg-link"
          style={{ padding: "0.5rem 0.5rem" }}
          data-action={"showMessage"}
      >
      {notif.statut == "LU" && (
        <React.Fragment>
          <div className="d-flex w-100 justify-content-between">
            <small className="mb-1 text-muted">
              {formatdate(notif.createDate)}
            </small>
          </div>
          <small className="text-muted" style={{ display: "block" }}>
            {notif.message.substring(0, 23) + " ... "}
          </small>
          <span className="badge badge-secondary">lu</span>
        </React.Fragment>
      )}
      {notif.statut !== "LU" && (
        <React.Fragment>
          <div className="d-flex w-100 justify-content-between">
            <small className="mb-1 message">
              {formatdate(notif.createDate)}
            </small>
          </div>
          <small className="font-weight-bold" style={{ display: "block" }}>
            {notif.message.substring(0, 23) + " ... "}
          </small>
          <span className="badge badge-success">non lu</span>
        </React.Fragment>
      )}
    {/*</a>*/}
      </button>
  );

  function formatdate(dt: string) {
    return (
      dt.substring(8, 10) +
      "/" +
      dt.substring(5, 7) +
      "/" +
      dt.substring(0, 4) +
      " " +
      dt.substring(11, 13) +
      ":" +
      dt.substring(14, 16)
    );
  }
}

import React from "react";
import { primaryColor } from "../../../_helpers";

export function CustomButton(props: any) {
  const { loading, onClick, label } = props;
  return (
    <button
      className="ui circular fluid secondary button"
      onClick={onClick}
      disabled={loading ? true : false}
      style={{
        cursor: loading ? "progress" : "pointer",
        backgroundColor: primaryColor(),
      }}
    >
      {loading ? "Chargement ... " : label}
    </button>
  );
}

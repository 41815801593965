import { appConstants } from "../_constants";
import { utils } from "../_helpers";
import { BaseEntity } from "./BaseEntity";
import { FieldType, InputType } from "./enumerations";
import { Field } from "./Field";

const {
  RENDER_ACTIONS,
  RENDER_ID,
  RENDER_STATUS,
  RENDER_DATETIME,
} = appConstants.keys.renderKeys;

class SubscriptionPlan extends BaseEntity {
  public static readonly label: any;
  public static readonly className: any;
  public static readonly art: any;
  public static readonly addTitle = "Créer un plan de souscription";
  public static readonly editTitle = "Modifier un plan de souscription";
  public static readonly showTitle = "Détails d'un plan de souscription";
  public static readonly processNewPath = "subscription/add";
  public static readonly processNewSuccessMsg = "Le plan de souscription a été enregistré avec succès. Merci !";
  public static readonly processEditSuccessMsg = "Le plan de souscription a été modifié avec succès. Merci !";
  public static readonly processEditPath = "subscription/update/_ID_";

  public static instance: SubscriptionPlan = new SubscriptionPlan();
  public static instances = [];
  public static relations = [
    { 
      dataPath: "quota/all",
      key: "quotas",
      resolve: (res: any) => {
        if (Array.isArray(res?.data)) {
          return res?.data.map((v: any) => ({
            label: v.libelle,
            value: v.quotaId,
          }));
        }
        return [];
      },
    },
    {
      dataPath: "currency/all",
      key: "currencies",
      resolve: (res: any) => {
        if (Array.isArray(res?.data)) {
          return res?.data.map((v: any) => ({
            label: v.nom,
            value: v.deviseId,
          }));
        }
        return [];
      },
    },
    {
      dataPath: "product/marchand/96/all?hasAbonnement=1",
      key: "products",
      resolve: (res: any) => {
        if (Array.isArray(res?.data)) {
          return res?.data.map((v: any) => ({
            label: v.libelle,
            value: v.productId,
          }));
        }
        return [];
      },
    },
  ];

  public static definitions = [
    new Field({
      name: "id",
      type: FieldType.NUMBER,
      primary: true,
      creatable: false,
      editable: false,
      serializeNew: false,
      serializeEdit: false,
      label: "#",
      renderKey: RENDER_ID,
      provider: SubscriptionPlan.instance,
    }),
    new Field({
      name: "productId",
      label: "Produit/Service",
      inputType: InputType.INPUT_SELECT,
      relationKey: "products",
      formRowIndex: 1,
      dataPath: "product.libelle",
      provider: SubscriptionPlan.instance,
    }),
    new Field({
      name: "quotaId",
      label: "Périodicité",
      inputType: InputType.INPUT_SELECT,
      relationKey: "quotas",
      formRowIndex: 0,
      dataPath: "quota.libelle",
      provider: SubscriptionPlan.instance,
    }),
    new Field({
      name: "amount",
      label: "Prix",
      provider: SubscriptionPlan.instance,
    }),
    new Field({
      name: "state",
      label: "Status",
      creatable: false,
      editable: false,
      renderKey: RENDER_STATUS,
      provider: SubscriptionPlan.instance,
    }),
    new Field({
      name: "currency",
      label: "Devise",
      inputType: InputType.INPUT_SELECT,
      relationKey: "currencies",
      onTable: false,
      formRowIndex: 2,
      provider: SubscriptionPlan.instance,
    }),
    new Field({
      name: "createdAt",
      label: "Date création",
      renderKey: RENDER_DATETIME,
      creatable: false,
      editable: false,
      serializeNew: false,
      serializeEdit: false,
      serializeView: false,
      provider: SubscriptionPlan.instance,
    }),
    new Field({
      name: "actions",
      label: "Actions",
      dataPath: "id",
      creatable: false,
      editable: false,
      serializeNew: false,
      serializeEdit: false,
      serializeView: false,
      renderKey: RENDER_ACTIONS,
      provider: SubscriptionPlan.instance,
      customData: {
        renderOptions: {
          clsActions: "justify-content-center",
        },
        actions: [
          {
            label: "Détails",
            title: "Afficher les détails",
            action: "detailsActions",
            color: "btn-info",
            icon: "fa fa-eye",
          },
          {
            type: "btnGroup",
            color: "btn-primary",
            children:
              [
                {
                  label: "Modifier",
                  title: "Modifer ce plan",
                  action: "editActions",
                  color: "text-success",
                  icon: "fa fa-edit",
                },
                {
                  label: "Supprimer",
                  title: "Supprimmer ce plan",
                  action: "deleteActions",
                  color: "text-danger",
                  icon: "fa fa-trash",
                },
              ]
          },
          
        ],
      },
    }),
  ];

  toView() {
    return [
      {
        title: false,
        rows: [
          { label: "Subscription Plan ID", value: this.id },
          { label: "Product/Service", value: this.product.libelle },
          { label: "Périodicité", value: this.quota.libelle },
          { label: "Montant", value: utils().formatAmount(this.devise ? this.devise.code : "CDF", this.amount) },
          { label: "Devise", value: this.devise?this.devise?.nom:"Franc Congolais" },
          { label: "Date de création", value: utils().formatDate("DD/MM/YYYY [à] HH:mm:ss", this.createdAt) },
        ],
      },
    ];
  }

  getDeleteActionConfig() {
    return {
      path: `/subscription/delete/${this.id}`,
      resolve: (res: any) => res?.success,
    };
  }

  initEditCallBack(props: any) {
    this.updateValue("currency", this.devise?.deviseId);
    this.updateValue("quotaId", this.quota?.quotaId);
    this.updateValue("productId", this.product?.productId);
  }

}

export { SubscriptionPlan };

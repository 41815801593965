import React, { Component } from "react";
import { InputSelect } from "./InputSelect";
import { primaryColor, restClient } from "../../../_helpers";
import { InputText2 } from "./InputText2";
import { LoadingGif } from "../Loader/LoadingGif";

const { protocol, hostname, port, href } = window.location;
const basePath = `${protocol}//${hostname}:${port}`;
const isEntreprise = href.indexOf("/entreprise") !== -1;

const accepturlETS = `${basePath}/entreprise/transactions/bonvirtuel`;
const accepturlPART = `${basePath}/dashboard`;

const cancelurlETS = `${basePath}/entreprise/transactions/bonvirtuel`;
const cancelurlPART = `${basePath}/dashboard`;

const declineurlETS = `${basePath}/entreprise/transactions/bonvirtuel`;
const declineurlPART = `${basePath}/dashboard`;

const notifyurlETS = `${basePath}/entreprise/transactions/bonvirtuel`;
const notifyurlPART = `${basePath}/dashboard`;

class CentralPayForm extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      freeze:
        typeof this.props.freeze !== "undefined" ? this.props.freeze : true,
      err: false,
      errmsg: false,
      finish: false,
      success: false,
      data: {},
      nom: "",
      id: 0,
      feesCDF: 0.0,
      feesUSD: 0.0,
      amount: 0.0,
      tauxUsdToCDF: 0.0,
      currency: "USD",
      jesuisben: true,
      account: "",
      operationId: "",
      operationType: 16,
      totalNetCDF: 0.0,
      totalNetDollar: 0.0,
      totalTTCCDF: 0.0,
      totalTTCDollar: 0.0,
      amountForMaxiCash: 0.0,
      totalMontantDollar: "",
      accepturl: "",
      cancelurl: "",
      declineurl: "",
      notifyurl: "",
      tel: "",
      email: "",
      merchantID: "",
      merchantPassword: "",
      maxiEndPoint: "",
      fraisinclus: false,
      cpay: -1,
      Nom: "",
      Prenom: "",
      Adresse1: "",
      Adresse2: "",
      Email: "",
      Ville: "",
      Pays: "",

      currencies: [
        { id: "CDF", name: "Franc congolais", taux: 1500 },
        { id: "USD", name: "Dollar US", taux: 1 },
      ],
      
      isoPays: [
        { id: 1, name: "CM" },
        { id: 2, name: "CG" },
      ]
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    let obj = this;
    let id = 0;

    $(document).on("shown.bs.modal", "#bonModal", function (e) {
      restClient.get(`/currency/all`).then((res) => {
        console.log(res);
        if (res.data && Array.isArray(res.data)) {
          obj.setState({
            currencies: res.data.map((d) => ({ ...d, id: d.code })),
            freeze: false,
          });
        } else {
          obj.setState({ freeze: false });
        }
      });
    });
    $(document).on("hidden.bs.modal", "#bonModal", function () {
      console.log(obj.props);
      if (obj.state.success) {
        window.location.reload();
        return;
      }
      obj.setState(
        {
          freeze: true,
          err: false,
          errmsg: false,
          finish: false,
          success: false,
          nom: "",
          id: 0,
          account: "",
          totalMontantDollar: "",
          tel: "",
          email: "",
        },
        () => {
          $("#recapitulatif-group").empty();
          $("#error-group").empty();
          $("#bon-form").slideDown();
          $("#recapitulatif-group").slideUp();
          $("#accountType").parent().find(".invalid-feedback").hide();
          $("#bonMin").parent().find(".invalid-feedback").hide();
          $("#bonMax").parent().find(".invalid-feedback").hide();
          $("#bon").parent().find(".invalid-feedback").hide();
          $("#bonModal").attr("data-action", "");
          $("#bonModal").attr("data-id", "");
        }
      );
    });
  }

  handleChange(e) {
    e.preventDefault();
    const { name, value } = e.target;
    console.log("Montant ou devise? ", name);
    this.setState({ [name]: value });
    if (name === "amount") {
      this.getFees(value, this.state.operationType, this.state.currency);
    }
    if (name === "currency") {
      this.getFees(this.state.amount, this.state.operationType, value);
    }
    console.log("Le contenu du state après change", this.state);
    if (name === "account") {
      if (value.length > 7) {
        $("#ben-infos").slideUp();
        this.registerBeneficiareChange({ node: $("#account") });
      } else {
        $("#ben-infos").slideUp();
      }
    }
  }

  handleChangePays(e) {
    e.preventDefault();
    const { name, value } = e.target;
    console.log("Pays value ", value);
    console.log("Pays name ", name);
    this.setState({ [name]: value });
  }

  registerBeneficiareChange(field) {
    const { protocol, hostname, port } = window.location;
    const basePath = `${protocol}//${hostname}:${port}`;
    let path = `${basePath}/api/get/?path=/account/find/byMsisdn/${field.node.val()}`;
    var request = $.get(path);
    let obj = this;
    request.done(function (results) {
      console.log(results);
      if (results.data && results.data.user) {
        $("#ben-infos-nom").html(results.data.user.firstName);
        $("#ben-infos-prenom").html(results.data.user.lastName);
        $("#ben-infos-email").html(results.data.user.email);
        $(field.node)
          .parent()
          .find(".invalid-feedback")
          .removeClass("text-secondary");
        $(field.node)
          .parent()
          .find(".invalid-feedback")
          .css("font-size", "80%");
        $("#ben-infos").slideDown();
        $(field.node).parent().find(".invalid-feedback").hide();
        obj.setState({
          nom: `${results.data.user.firstName} ${results.data.user.lastName}`,
        });
      } else {
        $("#ben-infos-nom").html("");
        $("#ben-infos-prenom").html("");
        $("#ben-infos-email").html("");
        $("#ben-infos").slideUp();
        $(field.node)
          .parent()
          .find(".invalid-feedback")
          .html(
            "Ce compte bénéficiaire n'est pas connu dans la base de donnée e-Mpata."
          );
        $(field.node)
          .parent()
          .find(".invalid-feedback")
          .addClass("text-warning");
        $(field.node)
          .parent()
          .find(".invalid-feedback")
          .css("font-size", "70%");
        $(field.node).parent().find(".invalid-feedback").show();
      }
    });
    request.fail(function (results) {
      console.log(results);
      $("#ben-infos").slideUp();
    });
  }

  handleClick(e) {
    e.preventDefault();
    this.setState({ freeze: true });
    const isCpay = Number(this.state.cpay) === 1;
    const {
      currency,
      amount,
      feesCDF,
      feesUSD,
      amountForMaxiCash,
      account,
      jesuisben,
    } = this.state;
    console.log("Le contenu du state après click", this.state);
    if (!isCpay) {
      let comp = amountForMaxiCash / 100 - feesUSD;
      console.log("comp click", comp);
      if (comp < 5) {
        alert(
          "Le montant de la transaction ne doit pas etre inferieur a 5 dollars."
        );
        this.setState({ freeze: false });
        return;
      }
    } else {
      if (Number($("#Montant").val()) < 5) {
        alert(
          "Le montant de la transaction ne doit pas etre inferieur a 5 dollars."
        );
        this.setState({ freeze: false });
        return;
      }
    }

    let payload = {
      adminId: 0,
      amount: isCpay ? Number($("#Montant").val()) : amount,
      currency,
      date: new Date().toJSON(),
      description: "",
      operationType: 16,
      receiver: isCpay ? this.getBen() : jesuisben ? this.getBen() : account,
      sender: this.getBen(),
    };
    // console.log(payload);
    //return;
    restClient.post(`/operation/getReference`, payload).then((res) => {
      console.log(res);
      console.log("amountForMaxiCash", amountForMaxiCash);
      console.log("feesUSD", feesUSD);
      console.log(
        "NOuveau montant pour Maxicash",
        Number(amountForMaxiCash) + Number(feesUSD) * 100
      );
      if (res?.data?.reference) {
        console.log(
          "solde supérieur aux frais?",
          Number(res.data.senderBalance) > Number(feesCDF)
        );
        this.setState({
          operationId: res?.data?.reference,
          data: res.data,
          tel: res?.data.receiver,
          merchantID: res.data.merchantID,
          merchantPassword: res.data.merchantPassword,
          maxiEndPoint: res.data.maxiEndPoint,
          email: res.data.senderEmail,
          amountForMaxiCash:
            Number(res.data.senderBalance) >= Number(feesCDF)
              ? amountForMaxiCash
              : Number(amountForMaxiCash) + Number(feesUSD) * 100,
          accepturl: isEntreprise ? accepturlETS : accepturlPART,
          cancelurl: isEntreprise ? cancelurlETS : cancelurlPART,
          declineurl: isEntreprise ? declineurlETS : declineurlPART,
          notifyurl: isEntreprise ? notifyurlETS : notifyurlPART,
        });
        console.log("le state du click", this.state);
        localStorage.setItem("payload", $("#pay-form").serialize());
        sessionStorage.setItem("operationId", res?.data?.reference);

        if (isCpay) {
          setTimeout(() => {
            $("#cpay-form").trigger("submit");
          }, 500);
          return;
        }
        setTimeout(() => {
          $("#pay-form").trigger("submit");
        }, 500);
      } else {
        this.setState({ freeze: false });
        alert("Erreur lors de l'enregistrement Merci de reessayer plus tard.");
      }
    });
  }

  getBen() {
    if (this.props.user?.msisdn) {
      return this.props.user?.msisdn;
    }
    if (this.props.model?.user?.msisdn) {
      return this.props.model?.user?.msisdn;
    }
    let msisdn = $("#title").data("msisdn");
    if (msisdn) {
      return msisdn;
    }
    return false;
  }

  render() {
    const {
      success,
      freeze,
      finish,
      currency,
      currencies,
      amount,
      account,
      nom,
      jesuisben,
      operationId,
      feesCDF,
      feesUSD,
      tel,
      email,
      totalNetCDF,
      totalNetDollar,
      totalTTCCDF,
      totalTTCDollar,
      merchantID,
      merchantPassword,
      maxiEndPoint,
      amountForMaxiCash,
      accepturl,
      cancelurl,
      declineurl,
      notifyurl,
      cpay,
      isoPays,

      Nom,
      Prenom,
      Adresse1,
      Adresse2,
      Email,
      Ville,
      pays,
    } = this.state;

    return (
      <div
        className="modal fade"
        id="bonModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="bonModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog"
          role="document"
          style={{ maxWidth: "600px" }}
        >
          <div className="modal-content" style={{ padding: "30px" }}>
            <div className="modal-header d-flex flex-row justify-content-between">
              <h5 className="modal-title" id="modalLabel">
                Achat Voucher
              </h5>
              <LoadingGif {...this.props} loading={freeze} />
            </div>
            <div
              id="create-modal-body"
              className="modal-body"
              style={{ padding: "20px 0 0" }}
            >
              <div className="" id="recapitulatif-group"></div>
              <div className="" id="error-group" data-shown="0">
                {success && (
                  <div
                    className="alert alert-success alert-dismissible fade show"
                    role="alert"
                  >
                    <span id="ajaxSuccess" style={{ fontSize: "80%" }}>
                      Enregistrement reussi
                    </span>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                )}
              </div>
              <form
                id="select-form"
                className="ui error form auth-form"
                style={{ fontSize: "80%", display: finish ? "none" : "" }}
              >
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <InputSelect
                    onChange={this.handleChange}
                    value={cpay}
                    label={"Méthode"}
                    name={"cpay"}
                    freeze={freeze}
                    type={"number"}
                    items={[
                      { id: 0, name: "MaxiCash" },
                      { id: 1, name: "CentralPay" },
                    ]}
                  />
                </div>
              </form>
              <form
                id="bon-form"
                className="ui error form auth-form"
                style={{
                  fontSize: "80%",
                  display: finish || Number(cpay) !== 0 ? "none" : "",
                }}
              >
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <InputSelect
                    onChange={this.handleChange}
                    value={currency}
                    label={"Devise"}
                    name={"currency"}
                    freeze={freeze}
                    type={"number"}
                    items={currencies}
                  />
                  <InputText2
                    onChange={this.handleChange}
                    value={amount}
                    currency={currency}
                    label={"Montant"}
                    name={"amount"}
                    freeze={freeze}
                    type={"number"}
                    fraisCDF={feesCDF}
                    fraisDollar={feesUSD}
                    totalNetCDF={totalNetCDF}
                    totalNetDollar={totalNetDollar}
                    totalTTCCDF={totalTTCCDF}
                    totalTTCDollar={totalTTCDollar}
                    {...this.getUtils()}
                  />
                  <div className="form-check mb-3" style={{ marginTop: "6px" }}>
                    <input
                      type="checkbox"
                      onChange={(e) =>
                        this.setState({ jesuisben: e.target.checked })
                      }
                      value={jesuisben}
                      name={"jesuisben"}
                      disabled={freeze}
                      className="form-check-input"
                      id="jesuisben"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="jesuisben"
                      style={{ fontSize: "12px" }}
                    >
                      Je suis le beneficaire
                    </label>
                  </div>
                  <div
                    className="col-md-12"
                    style={{ paddingTop: "3px", marginBottom: "3px" }}
                  >
                    {!jesuisben && (
                      <>
                        <div className="card">
                          <div className="container">
                            <p>
                              <b>
                                Envoyer le bon virtuel à quelqu'un d'autre :
                              </b>
                            </p>{" "}
                            <InputText2
                              onChange={this.handleChange}
                              value={account}
                              label={"Numéro ou Email"}
                              name={"account"}
                              freeze={freeze}
                              type={"text"}
                            />
                            <div
                              className="form-group row"
                              id="ben-infos"
                              style={{
                                margin: "0px 0px 1rem",
                                width: "90%",
                                display: "none",
                              }}
                            >
                              <div
                                className="col-md-4"
                                style={{ padding: "0px 1rem 0px 0px" }}
                              >
                                <label
                                  htmlFor="colFormLabelSm"
                                  className="col-form-label col-form-label-sm"
                                  style={{ float: "right" }}
                                >
                                  Détails bénéficiaire
                                </label>
                              </div>
                              <div
                                className="col-md-8 beneficiare"
                                style={{ padding: "0px" }}
                              >
                                <table className="w-100">
                                  <tbody>
                                    <tr>
                                      <td>
                                        <span className="d-flex p-1">
                                          <small>
                                            <span className="font-weight-bold mr-2">
                                              Nom :{" "}
                                            </span>
                                            <span id="ben-infos-nom"></span>
                                          </small>
                                        </span>
                                      </td>
                                      <td>
                                        <span className="d-flex">
                                          <small>
                                            <span className="font-weight-bold mr-2">
                                              Prénom :{" "}
                                            </span>
                                            <span id="ben-infos-prenom"></span>
                                          </small>
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colSpan={2}>
                                        <span className="d-flex p-1">
                                          <small>
                                            <span className="font-weight-bold mr-2">
                                              Email :{" "}
                                            </span>
                                            <span id="ben-infos-email"></span>
                                          </small>
                                        </span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <InputText2
                              onChange={this.handleChange}
                              value={nom}
                              label={"Nom et prénom"}
                              name={"nom"}
                              freeze={freeze}
                              type={"text"}
                            />{" "}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </form>

              <form
                id="cpay-form"
                action="https://demo.hologram.cd/centralpay/pay_confirmation"
                method="post"
                className="ui error form auth-form"
                style={{
                  fontSize: "80%",
                  display: finish || Number(cpay) !== 1 ? "none" : "",
                }}
              >
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <input
                    type="number"
                    step={"0.01"}
                    name="Montant"
                    id="Montant"
                    placeholder="Montant en Dollar USD (Supérieur ou égal à 5)"
                    required
                    className="form-control"
                  />
                  <br />
                  <input
                    type="text"
                    name="Nom"
                    placeholder="Votre Nom"
                    required
                    className="form-control"
                    //value={Nom}
                  />
                  <br />
                  <input
                    type="text"
                    name="Prenom"
                    placeholder="Votre Prenom"
                    required
                    className="form-control"
                    // value={Prenom}
                  />
                  <br />
                  <input
                    type="text"
                    name="Adresse1"
                    placeholder="Adresse1"
                    required
                    className="form-control"
                    //value={Adresse1}
                  />
                  <br />
                  <input
                    type="text"
                    name="Adresse2"
                    placeholder="Adresse2"
                    required
                    className="form-control"
                    //value={Adresse2}
                  />
                  <br />
                  <input
                    type="email"
                    name="Email"
                    placeholder="Adresse Email"
                    required
                    className="form-control"
                    //value={Email}
                  />
                  <br />
                  <input
                    type="text"
                    name="Ville"
                    placeholder="Votre ville"
                    required
                    className="form-control"
                    //value={Ville}
                  />
                  <br />


                  <br />
                 
                
                  <input
                    type="text"
                    name="Pays"
                    placeholder="Veuillez saisi votre pays"
                    required
                    className="form-control"
                    //value={Ville}
                  />
          
                    
                  <br />
                  <div className="row form-group">
                    <div className="col-md-8">
                      <input
                        type="hidden"
                        name="Id"
                        placeholder="Id"
                        required
                        className="form-control"
                        value="APP0000000000022022"
                      />
                    </div>
                    <div className="col-md-8">
                      <input
                        type="hidden"
                        name="Token"
                        placeholder="Token"
                        required
                        className="form-control"
                        value="Q0xUMDAwMDAwMDAwMDAzMjAyMjtob2xvZ3JhbTsxNjQ3MzQ2OT"
                      />
                    </div>
                  </div>
                  <div className="row form-group">
                    <div className="col-md-8"></div>

                    <div className="col-md-8">
                      <input
                        type="hidden"
                        name="Devise"
                        placeholder="Devise"
                        required
                        className="form-control"
                        value="USD"
                      />
                    </div>
                  </div>
                  <div className="row form-group">
                    <div className="col-md-8">
                      <input
                        type="hidden"
                        name="Reference"
                        required
                        className="form-control"
                        value={operationId}
                      />
                    </div>
                    <div className="col-md-8">
                      <input
                        type="hidden"
                        name="IntitulePaiement"
                        placeholder="L’intitulé du paiement"
                        required
                        className="form-control"
                        value="Intitule du paiment"
                      />
                    </div>
                    
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                id="cancel-bon-btn"
                type="button"
                className="btn btn-danger box-shadow"
                data-dismiss="modal"
                style={{ fontSize: "90%" }}
              >
                <i className="fa fa-times mr-1"></i>
                {finish && "Fermer"}
                {!finish && "Annuler"}
              </button>

              {!finish && (
                <button
                  id="create-btn"
                  type="button"
                  onClick={this.handleClick}
                  className="btn  box-shadow"
                  disabled={freeze}
                  style={{ fontSize: "90%",backgroundColor:primaryColor(),color:"white" }}
                >
                  <i className="fa fa-check mr-1"></i>
                  Payer
                </button>
              )}
            </div>
          </div>
          <form id="pay-form" action={maxiEndPoint} method="POST">
            <input type="hidden" name="PayType" value="MaxiCash" />
            <input type="hidden" name="Amount" value={amountForMaxiCash} />
            <input type="hidden" name="Currency" value="USD" />
            <input type="hidden" name="Telephone" value={tel} />
            <input type="hidden" name="Email" value={email} />
            <input type="hidden" name="MerchantID" value={merchantID} />
            <input
              type="hidden"
              name="MerchantPassword"
              value={merchantPassword}
            />
            <input type="hidden" name="Language" value="En" />
            <input type="hidden" name="Reference" value={operationId} />
            <input type="hidden" name="accepturl" value={accepturl} />
            <input type="hidden" name="cancelurl" value={cancelurl} />
            <input type="hidden" name="declineurl" value={declineurl} />
            <input type="hidden" name="notifyurl" value={notifyurl} />
          </form>
        </div>
      </div>
    );
  }

  getTotalAmount() {
    const { data } = this.state;
    console.log("Les DATA de la reference ", data);
    if (data?.senderBalance > data?.fees) {
      console.log("Solde est supérieur aux frais");
      return this.getTotalNetDollar();
    } else {
      console.log("Solde est inférieur aux frais");
      return this.getTotalTTCDollar();
    }
  }

  getFraisCDF() {
    const { currency, amount, currencies } = this.state;
    const value = this.removeSeparator(amount);
    if ((currency !== "CDF" && currency !== "USD") || value === 0) {
      return 0;
    }
    if (currency === "CDF") {
      const frais = this.state.frais;
      // return (value * 3) / 100;
      return frais;
    }
    if (currency === "USD") {
      // return ((value * 3) / 100) * this.getTaux("CDF");
      const frais = this.state.frais / this.getTaux("CDF");
      return frais;
    }
  }

  getTaux(val) {
    const { currencies } = this.state;

    const i = currencies.findIndex((c) => c.id === val);
    console.log(i);
    if (i > -1) {
      return currencies[i].taux;
    }
    return 1;
  }

  getTotalNetCDF() {
    const { currency, feesCDF, amount, tauxUsdToCDF } = this.state;
    const value = this.removeSeparator(amount);

    if ((currency !== "CDF" && currency !== "USD") || value === 0) {
      return 0;
    }
    if (currency == "CDF") {
      return value;
    }
    if (currency == "USD") {
      return value * tauxUsdToCDF;
    }
  }

  // getTotalTTCCDF() {
  //   const { currency, fraisinclus, amount } = this.state;
  //   const value = this.removeSeparator(amount);
  //
  //   if ((currency !== "CDF" && currency !== "USD") || value === 0) {
  //     return 0;
  //   }
  //   let frais = this.getFraisCDF();
  //
  //   if (fraisinclus) {
  //     if (currency === "CDF") {
  //       return value;
  //     }
  //     if (currency === "USD") {
  //       return value * this.getTaux("CDF");
  //     }
  //   } else {
  //     if (currency === "CDF") {
  //       return value + frais;
  //     }
  //     if (currency === "USD") {
  //       return value * this.getTaux("CDF") + frais;
  //     }
  //   }
  // }

  getTotalTTCCDF() {
    const { currency, feesUSD, amount, tauxUsdToCDF } = this.state;
    const value = this.removeSeparator(amount);
    if ((currency !== "CDF" && currency !== "USD") || value === 0) {
      return 0;
    }
    if (currency === "CDF") {
      return value + feesUSD;
    }
    if (currency === "USD") {
      return value * tauxUsdToCDF + feesUSD;
    }
  }

  // getFraisDollar() {
  //   const { currency, amount } = this.state;
  //   const value = this.removeSeparator(amount);
  //   if ((currency !== "CDF" && currency !== "USD") || value === 0) {
  //     return 0;
  //   }
  //   if (currency === "CDF") {
  //     // return (value * 3) / 100 / this.getTaux("CDF");
  //     return (this.state.frais / this.getTaux("CDF"));
  //   }
  //   if (currency === "USD") {
  //     // return (value * 3) / 100;
  //     return (this.state.frais / this.getTaux("CDF"));
  //   }
  // }

  // getTotalNetDollar() {
  //   const { currency, fraisinclus, amount } = this.state;
  //   const value = this.removeSeparator(amount);
  //   if ((currency !== "CDF" && currency !== "USD") || value === 0) {
  //     return 0;
  //   }
  //   const taux = this.getTaux("CDF");
  //   const frais = this.getFraisDollar();
  //
  //   if (fraisinclus) {
  //     if (currency == "CDF") {
  //       return value / taux - frais;
  //     }
  //     if (currency == "USD") {
  //       return value - frais;
  //     }
  //   } else {
  //     if (currency == "CDF") {
  //       return value / taux;
  //     }
  //     if (currency == "USD") {
  //       return value;
  //     }
  //   }
  // }

  getTotalNetDollar() {
    const { currency, amount, tauxUsdToCDF, feesCDF, feesUSD } = this.state;
    const value = this.removeSeparator(amount);
    if ((currency !== "CDF" && currency !== "USD") || value === 0) {
      return 0;
    }
    console.log("Le taux est", this.state.tauxUsdToCDF);
    // const taux = this.state.tauxUsdToCDF

    if (currency == "CDF") {
      console.log(
        "Le montant à envoyer à MaxiCash en centime de Dollar",
        (value / tauxUsdToCDF) * 100
      );
      this.setState({
        amountForMaxiCash: (value / tauxUsdToCDF) * 100,
        totalNetCDF: value,
        totalTTCCDF: value + feesCDF,
      });
      return value / tauxUsdToCDF;
    }
    if (currency == "USD") {
      console.log(
        "Le montant à envoyer à MaxiCash en centime de Dollar MaxiCash",
        value * 100
      );
      this.setState({
        amountForMaxiCash: value * 100,
        totalNetDollar: value,
        totalTTCDollar: value + feesUSD,
      });
      return value;
    }
  }

  getTotalTTCDollar() {
    const { currency, feesCDF, amount, tauxUsdToCDF, feesUSD } = this.state;
    const value = this.removeSeparator(amount);
    if ((currency !== "CDF" && currency !== "USD") || value === 0) {
      return 0;
    }
    console.log("Le taux est", this.state.tauxUsdToCDF);
    // const taux = this.state.tauxUsdToCDF;

    if (currency == "CDF") {
      console.log(
        "Le montant à envoyer à MaxiCash en centime de Dollar",
        ((value + feesCDF) / tauxUsdToCDF) * 100
      );
      this.setState({
        amountForMaxiCash: ((value + feesCDF) / tauxUsdToCDF) * 100,
        totalNetCDF: value,
        totalTTCCDF: value + feesCDF,
      });
      return (value + feesCDF) / tauxUsdToCDF;
    }
    if (currency == "USD") {
      console.log(
        "Le montant à envoyer à MaxiCash en centime de Dollar",
        (value + feesUSD) * 100
      );
      this.setState({
        amountForMaxiCash: (value + feesUSD) * 100,
        totalNetDollar: value,
        totalTTCDollar: value + feesUSD,
      });
      return value + feesUSD;
    }
  }

  // getTotalTTCDollar() {
  //   const { currency, fraisinclus, amount } = this.state;
  //   const value = this.removeSeparator(amount);
  //   if ((currency !== "CDF" && currency !== "USD") || value === 0) {
  //     return 0;
  //   }
  //   const taux = this.getTaux("CDF");
  //   const frais = this.getFraisDollar();
  //
  //   if (fraisinclus) {
  //     if (currency == "CDF") {
  //       return value / taux;
  //     }
  //     if (currency == "USD") {
  //       return value;
  //     }
  //   } else {
  //     if (currency == "CDF") {
  //       return value / taux + frais;
  //     }
  //     if (currency == "USD") {
  //       return value + frais;
  //     }
  //   }
  // }

  applySeparator(mt) {
    try {
      let mtt = parseFloat(mt);
      return mtt
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    } catch (error) {}
    return mt;
  }

  removeSeparator(value) {
    if (value) {
      try {
        return parseInt(value.replace(/\s/g, ""));
      } catch (error) {}
    }
    return 0;
  }

  // getUtils() {
  //   return {
  //     removeSeparator: (val) => this.removeSeparator(val),
  //     applySeparator: (val) => this.applySeparator(val),
  //     getTotalTTCDollar: () => this.getTotalTTCDollar(),
  //     getTotalNetDollar: () => this.getTotalNetDollar(),
  //     getFraisDollar: () => this.getFraisDollar(),
  //     getTotalTTCCDF: () => this.getTotalTTCCDF(),
  //     getTotalNetCDF: () => this.getTotalNetCDF(),
  //     getFraisCDF: () => this.getFraisCDF(),
  //   };
  // }

  getUtils() {
    return {
      removeSeparator: (val) => this.removeSeparator(val),
      applySeparator: (val) => this.applySeparator(val),
    };
  }

  getReturnLink() {
    const { protocol, hostname, port, href } = window.location;
    const basePath = `${protocol}//${hostname}:${port}`;
    const isEntreprise = href.indexOf("/entreprise") !== -1;
    const { data } = this.state;
    if (isEntreprise) {
      if (data?.senderBalance > data?.fees) {
        return `${basePath}/entreprise/transactions/bonvirtuel?fees=true`;
      } else {
        return `${basePath}/entreprise/transactions/bonvirtuel`;
      }
    }
    if (data?.senderBalance > data?.fees) {
      return `${basePath}/dashboard?fees=true`;
    } else {
      return `${basePath}/dashboard`;
    }
  }

  getFees(amount, optype, currency) {
    let payload = {
      operationType: optype,
      currency: currency,
      amount: amount,
      sender: this.getBen(),
    };
    console.log("Les élements pour FEES reçus", payload);
    restClient.post(`/operation/fees`, payload).then((res) => {
      if (res?.data) {
        this.setState({
          feesCDF: res.data.feesInCDF,
          feesUSD: res.data.feesInUSD,
          tauxUsdToCDF: res.data.tauxUsdToCDF,
          amountForMaxiCash:
            currency == "CDF"
              ? (Number(amount) / Number(res.data.tauxUsdToCDF)) * 100
              : Number(amount) * 100,
          totalNetCDF:
            currency == "CDF"
              ? Number(amount)
              : Number(amount) / Number(res.data.tauxUsdToCDF),
          totalTTCCDF:
            currency == "CDF"
              ? Number(amount) + Number(res.data.feesInCDF)
              : Number(amount) / Number(res.data.tauxUsdToCDF) +
                Number(res.data.feesInCDF),
          totalNetDollar:
            currency == "CDF"
              ? Number(amount) / Number(res.data.tauxUsdToCDF)
              : Number(amount),
          totalTTCDollar:
            currency == "CDF"
              ? Number(amount) / Number(res.data.tauxUsdToCDF) +
                Number(res.data.feesInUSD)
              : Number(amount) + Number(res.data.feesInUSD),
        });
        console.log("Le nouveau state :", this.state);
      } else {
        this.setState({
          feesCDF: 0.0,
          feesUSD: 0.0,
          tauxUsdToCDF: 0.0,
        });
      }
    });
  }
}

export { CentralPayForm };

import { appConstants } from "../_constants";
import { getEmpata2BankFields } from "../_fields";
import { restClient, utils } from "../_helpers";
import { BaseModel } from "../_models";
import { BaseEntity } from "./BaseEntity";
import { FieldType, InputType } from "./enumerations";
import { Field } from "./Field";

const statuses = {
  WITHDRAWN: "Retiré",
  WAITING_WITHDRAW: "En attente de retrait",
  PENDING: "En cours",
  SUCCESS: "Succès",
  TRAITÉ: "Traité",
  FAILED: "Échoué",
};

const {
  RENDER_EMETTEUR,
  RENDER_OPERATION_TYPE,
  RENDER_AMOUNT_TRANSACTION,
  RENDER_ID,
  RENDER_DATETIME,
  RENDER_CLIENT_TRANSACTION,
  RENDER_OPERATION_CURRENCY,
  RENDER_DOUBLE,
} = appConstants.keys.renderKeys;

const { USER_BALANCES_CHANGE } =
  appConstants.keys.eventKeys;

let processing = false;

class BankTransaction extends BaseEntity {
  public static readonly label: any;
  public static readonly addTitle = "Transfert eMpata to Bank";
  public static readonly showTitle = "Détails de la transaction";
  public static readonly saveBtnTitle = "Initier la transaction";
  public static readonly processNewSuccessMsg =
    "Votre transaction a été créée avec succès. Merci !";
  public static readonly className: any;
  public static readonly art: any;
  public static instance: BankTransaction = new BankTransaction();
  public static instances = [];

  public isBank = true;

  public static get relations() {
    const model = BaseModel.getInstance();
    return [
      {
        dataPath: "operation/type/all",
        key: "operationTypes",
        resolve: (res: any) => {
          if (Array.isArray(res?.data)) {
            return res?.data
              .filter((o) => {
                if (model.isAdminErecette) {
                  return [4, 25].indexOf(o.operationTypeId) !== -1;
                }
                return  o.active && o.operationTypeId === 25;
              })
              .map((v: any) => ({
                label: v.libelle,
                value: v.operationTypeId,
              }));
          }
          return [];
        },
      },
      {
        dataPath: "currency/all",
        key: "currencies",
        resolve: (res: any) => {
          if (Array.isArray(res?.data)) {
            return res.data.map((v: any) =>
              BankTransaction.getSelectCurrency(v)
            );
          }
          return [];
        },
      },
      {
        dataPath: "country/all",
        key: "countries",
        resolve: (res: any) => {
          if (Array.isArray(res?.data)) {
            return res.data.map((v: any) => ({
              label: v.nomFr,
              value: v.nomFr,
            }));
          }
          return [];
        },
      },
      {
        dataPath: "account/all",
        key: "accounts",
        resolve: (res: any) => {
          if (Array.isArray(res?.data)) {
            return res.data.map((v: any) => ({
              label: v.msisdn,
              value: v.msisdn,
              accountTypeId: v.accountType?.accountTypeId || 0,
            }));
          }
          return [];
        },
      },
      {
        key: "prices",
        dataPath: (meta?: any) => ({
          path: "account/type/all",
          children: {
            path: "account/type/find/_PLACE_HOLDER_",
            id_field: "accountTypeId",
          },
        }),
        resolve: (res: any) => {
          const data = Array.isArray(res.data) ? res.data : [];
          const prices: any[] = [];
          data.forEach((accountTypesDetail: any) => {
            accountTypesDetail?.data?.content?.accountTypePrices?.forEach(
              (accountTypePrice: any) => {
                prices.push(accountTypePrice);
              }
            );
          });
          return prices.sort((a, b) => a.price.valMin - b.price.valMin);
        },
      },
    ];
  }

  public static get definitions(): Field[] {
    const model = BaseModel.getInstance();
    const isClient = model.isClient;
    const isAdmin = model.isAdmin;

    const defs = [
      new Field({
        name: "operationId",
        type: FieldType.NUMBER,
        primary: true,
        creatable: false,
        editable: false,
        serializeNew: false,
        serializeEdit: false,
        label: "#",
        renderKey: isClient ? RENDER_CLIENT_TRANSACTION : RENDER_ID,
        provider: BankTransaction.instance,
      }),
      new Field({
        name: "reference",
        label: "Référence",
        creatable: false,
        editable: false,
        serializeNew: false,
        onTable: !isClient,
        provider: BankTransaction.instance,
      }),
      new Field({
        name: "sender",
        label: "Emetteur",
        renderKey: RENDER_EMETTEUR,
        dataPath: "operationId",
        provider: BankTransaction.instance,
        relationKey: "accounts",
        onTable: !isClient,
        creatable: isAdmin,
        inputType: isAdmin ? InputType.INPUT_SELECT : InputType.INPUT_TEXT,
        listeners: [
          {
            type: "value_change_operation_type_id",
            behaviour: "toggleLayout",
            resolve: (v: any, customData?: any) => {
              if (parseInt(v.value) === 1) {
                return "col-md-12 d-none";
              }
              return "col-md-12";
            },
          },
        ],
        validators: [
          {
            meta: {
              key: "sender",
              otherKey: "operationType",
              expetedVal: 1,
              checkCondition: (v: any) => parseInt(v) === 1,
            },
            validate: (meta: any) =>
              BankTransaction.instance.assertNotNullConditionnal(meta),
          },
        ],
        serializeCallback: (item: any, v: any) => {
          const operationType = BankTransaction.instance.getValue(
            "operationType",
            0
          );
          const model = BaseModel.getInstance();
          const sender = model.user?.msisdn || 0;
          if (operationType === 1) {
            return {
              ...item,
              sender,
            };
          }
          let p = { ...item };
          if (v !== "") {
            Object.assign(p, { sender: v });
          }
          return p;
        },
      }),

      new Field({
        name: "amount",
        label: "Montant",
        renderKey: RENDER_AMOUNT_TRANSACTION,
        provider: BankTransaction.instance,
        onTable: !isClient,
        emitters: [{ type: "value_change_amount" }],
        customData: {
          renderOptions: {
            className: "min-w-150",
          },
          inputFraisData: {
            frais: 0,
            total: 0,
            tva: 0,
            cls: "d-none",
          },
          inputUniteData: {
            frais: 0,
            total: 0,
            cls: "d-none",
          },
          checkBoxData: {
            id: "fees_in",
            // cls: "form-check-label",
            cls: "form-check-label",
            customLabel: "Frais inclus ?",
            styles: { fontSize: "80%", marginLeft: "19px" },
            checked: false,
            eventName: "fees_in_change",
            customInlineStyle: { marginLeft: "1px", marginTop: "7px" },
            customLayoutInlineStyle: {
              marginTop: "6px",
              padding: 0,
            },
          },
        },
        listeners: [
          {
            type: "value_change_amount",
            behaviour: "customActionAsync",
            resolve: async (v: any, customData?: any) =>
              BankTransaction.handleAmountChange({ v, customData }),
          },
          {
            type: "value_change_operation_type_id",
            behaviour: "changeField",
            resolve: async (v: any, field: Field) => {
              const display =
                v?.value !== 7 &&
                v?.value !== 17 &&
                v?.value !== 18 &&
                v?.value !== 19 &&
                v?.value !== 20
                  ? ""
                  : "none";

              const label = v?.value !== 7 ? "Montant" : "Unité";
              const customData = {
                ...field.customData,

                checkBoxData: {
                  ...field.customData.checkBoxData,
                  customLayoutInlineStyle: {
                    ...field.customData.checkBoxData.customLayoutInlineStyle,
                    display,
                  },
                },
              };

              field.customData = customData;
              field.label = label;
              return field;
            },
          },
          {
            type: "fees_in_change",
            behaviour: "customAction",
            resolve: (v: any, customData?: any) => {
              BankTransaction.instance.updateValue("feesIn", v?.value);
              const amount = BankTransaction.instance.getValue("amount", 0);
              return {
                type: "value_change_amount",
                customData,
                data: { value: amount },
              };
            },
          },
          {
            type: "value_change_operation_type_id",
            behaviour: "toggleLayout",
            resolve: (v: any, customData?: any) => {
              const vv = parseInt(v.value);
              if ([15].indexOf(vv) !== -1) {
                return "col-md-12 d-none";
              }
              return "col-md-12";
            },
          },
        ],
        serializeCallback: (item: any, v: any) => {
          const feesIn = BankTransaction.instance.getValue("feesIn", false);

          return {
            ...item,
            amount: parseFloat(v),
            feesIn,
          };
        },
      }),

      new Field({
        name: "fees",
        creatable: false,
        editable: false,
        serializeNew: false,
        serializeEdit: false,
        label: "Frais",
        onTable: !isClient,
        renderKey: RENDER_DOUBLE,
        provider: BankTransaction.instance,
      }),

      new Field({
        name: "currency",
        label: "Dévise",
        dataPath: "operationId",
        formRowIndex: 1,
        onTable: !isClient,
        relationKey: "currencies",
        renderKey: RENDER_OPERATION_CURRENCY,
        inputType: InputType.INPUT_SELECT,
        emitters: [{ type: "value_change_devise" }],
        provider: BankTransaction.instance,
        listeners: [
          {
            type: "value_change_devise",
            behaviour: "customActionAsync",
            resolve: async (v: any, customData?: any) =>
              BankTransaction.handleDeviseChange({ v, customData }),
          },
          {
            type: "value_change_operation_type_id",
            behaviour: "toggleLayout",
            resolve: (v: any, customData?: any) => {
              const vv = parseInt(v.value);
              if ([15].indexOf(vv) !== -1) {
                return "col-md-12 d-none";
              }
              return "col-md-12";
            },
          },
        ],
      }),
      new Field({
        name: "operationType",
        label: "Type d'opération",
        renderKey: RENDER_OPERATION_TYPE,
        formRowIndex: 0,
        required: false,
        relationKey: "operationTypes",
        formLayout: model.isAdminErecette ? "col-md-12" : "col-md-12 d-none",
        inputType: InputType.INPUT_SELECT,
        provider: BankTransaction.instance,
        onTable: !isClient,
        emitters: [{ type: "value_change_operation_type_id" }],
        customData: {
          checkBoxData: {
            id: "depot_entreprise",
            cls: "form-check-label",
            customLabel: "Dépot entreprise ?",
            styles: { fontSize: "80%", marginLeft: "19px" },
            checked: false,
            eventName: "depot_entreprise_change",
            customInlineStyle: { marginLeft: "1px", marginTop: "7px" },
            customLayoutInlineStyle: {
              marginTop: "6px",
              padding: 0,
              display: !isClient ? "" : "none",
            },
          },
        },
        listeners: [
          {
            type: "value_change_operation_type_id",
            behaviour: "customAction",
            resolve: (v: any, customData?: any) => {
              const display = v?.value === 1 ? "" : "none";
              return {
                checkBoxData: {
                  ...customData.checkBoxData,
                  customLayoutInlineStyle: {
                    ...customData.checkBoxData.customLayoutInlineStyle,
                    display,
                  },
                },
              };
            },
          },
          {
            type: "depot_entreprise_change",
            behaviour: "customAction",
            resolve: (v: any, customData?: any) => {
              BankTransaction.instance.updateValue("entreprise", v?.value);
              return { customData };
            },
          },
          {
            type: "value_change_operation_type_id",
            behaviour: "customAction",
            resolve: (v: any, customData?: any) => {
              if (v.value === 20) {
                BankTransaction.instance.updateValue(
                  "receiver",
                  BaseModel.getInstance().user.msisdn
                );
              }
              return { customData };
            },
          },
        ],

        serializeCallback: (item: any, v: any) => {
          const entreprise = BankTransaction.instance.getValue(
            "entreprise",
            false
          );

          return { ...item, operationType: v, entreprise };
        },
      }),

      new Field({
        name: "operationDate",
        renderKey: RENDER_DATETIME,
        label: "Date",
        creatable: false,
        editable: false,
        onTable: !isClient,
        provider: BankTransaction.instance,
        customData: {
          renderOptions: {
            className: "min-w-150",
          },
        },
        serializeCallback: (item: any, v: any) => {
          const date = new Date().toJSON();
          return {
            ...item,
            date,
          };
        },
      }),
    ];

    return [...defs, ...getEmpata2BankFields(BankTransaction.instance)];
  }

  /**
   * getSelectCurrency
   */
  public static getSelectCurrency(v: any) {
    return {
      label: v.nom,
      value: v.code,
      taux: v.taux,
    };
  }

  public static getSelectCountry(v: any) {
    return {
      label: v.nomFr,
      value: v.code,
      id: v.id,
    };
  }

  /**
   * handleChangeReceiver
   */
  public static async handleChangeReceiver(v: any, customData?: any) {
    if (v?.value?.length > 7) {
      const res = await restClient.get(`account/find/byMsisdn/${v.value}`);
      const id = res?.data?.accountId || 0;
      if (!(id > 0)) {
        return {
          type: "value_change_receiver_callback",
          customData: {
            validateData: {
              force: true,
              cls: "text-warning text-70",
              msg: "Ce compte bénéficiaire n'est pas connu dans la base de donnée e-Mpata.",
            },
          },
        };
      }
      return {
        type: "value_change_receiver_callback",
        data: res.data,
        customData: {
          validateData: {
            force: false,
            cls: "text-danger",
            msg: "Ce champ est requis.",
          },
        },
      };
    }
    return {
      type: "value_change_receiver_callback",
      customData: {
        validateData: {
          force: false,
          cls: "text-danger",
          msg: "Ce champ est requis",
        },
      },
    };
  }

  /**
   * handleChangeTnx
   */
  public static async handleChangeTnx(v: any, customData?: any) {
    if (v?.value?.length > 7) {
      const res = await restClient.get(`operation/findbyreference/${v.value}`);
      const data = Array.isArray(res?.data) ? res.data[0] : {};
      const id = data?.operationId || 0;
      if (!(id > 0)) {
        return {
          type: "value_change_tnx_callback",
          customData: {
            validateData: {
              force: true,
              cls: "text-warning text-70",
              msg: "Cette reférence de transaction n'est pas connu dans la base de donnée e-Mpata.",
            },
          },
        };
      }
      if (data?.status === "WITHDRAWN") {
        utils().emitter.emit("hide_next", true);
      } else {
        utils().emitter.emit("hide_next", false);
      }
      return {
        type: "value_change_tnx_callback",
        data: data,
        customData: {
          validateData: {
            force: false,
            cls: "text-danger",
            msg: "Ce champ est requis.",
          },
        },
      };
    }

    return {
      type: "value_change_tnx_callback",
      customData: {
        validateData: {
          force: false,
          cls: "text-danger",
          msg: "Ce champ est requis",
        },
      },
    };
  }

  /**
   * handleDeviseChange
   */
  public static async handleDeviseChange(data: any) {
    const { v, customData } = data;
    let operationTypeId = BankTransaction.instance.getValue("operationType", 0);
    if (operationTypeId === 20) {
      customData.checkBoxData = {
        ...customData.checkBoxData,
        current: v?.value === "USD" ? "CDF" : "USD",
      };
      return customData;
    }
  }

  /**
   * handleAmountChange
   */
  public static async handleAmountChange(data: any) {
    const { v, customData } = data;
    let from = "";
    let to = "";
    let operationTypeId = BankTransaction.instance.getValue("operationType", 0);
    let currentCode = BankTransaction.instance.getValue("currency", "");
    if (currentCode === "CDF") {
      to = "USD";
    } else {
      to = "CDF";
    }
    if (!processing) {
      processing = true;
      let data: any = {};
      let data2: any = {};
      let frais: any = {};

      if (operationTypeId === 7) {
        data2 = await BankTransaction.instance.convertUnitToCDF(v);
        data = await BankTransaction.instance.getFraisData({
          value: data2.total,
        });
      } else {
        frais = await BankTransaction.instance.getFees(
          v,
          currentCode,
          operationTypeId
        );
        let fees = currentCode === "USD" ? frais.feesInUSD : frais.feesInCDF;
        let total = Number(fees) + Number(v.value);
        BankTransaction.instance.updateValue("tva", frais.tva);
        data = { frais: fees, total: total, tva: frais.tva };
      }

      if (operationTypeId === 20) {
        data2 = await BankTransaction.instance.convertAmount(v, currentCode);
        data = await BankTransaction.instance.getFraisData({
          value: data2.total,
        });
        customData.checkBoxData = {
          ...customData.checkBoxData,
          current: to,
          operationType: operationTypeId,
          customLayoutInlineStyle: {
            ...customData.checkBoxData.customLayoutInlineStyle,
            display: "none",
          },
        };
      }
      processing = false;
      return {
        customData: {
          ...customData,
          inputFraisData: {
            ...data,
            cls: v?.value ? "" : "",
          },
          current: currentCode,
          inputUniteData: {
            ...data2,
            cls: v?.value && operationTypeId === 7 ? "" : "d-none",
          },
        },
      };
    }

    return { customData };
  }

  public get hasNext(): boolean {
    const model = BaseModel.getInstance();
    if (model.isAdmin) {
      return false;
    }
    const step = this.getValue("step", 1);
    const hideNext = this.getValue("hideNext", false);
    return step < 2 && !hideNext;
  }

  /**
   * processNext
   */
  public processNext(props: any) {
    const step = this.getValue("step", 1);
    this.updateValue("step", step + 1);

    const { mode, content, title, hideFooter, submitted } = props;

    this.updateValue("prevState", {
      mode,
      content,
      title,
      hideFooter,
      submitted,
    });

    return {
      mode: "customActions",
      content: "pinvalidator",
      title: "Validation",
      hideFooter: true,
      submitted: false,
    };
  }

  /**
   * processPrev
   */
  public processPrev(props: any) {
    const step = this.getValue("step", 1);
    this.updateValue("step", step - 1);
    return this.getValue("prevState", {});
  }

  /**
   * processValidatePin
   */
  public async processValidatePin(props: any, codePin: string) {
    const res = await restClient.get(
      `/operation/pin/${codePin}/${utils().getUserAccountId()}`
    );
    return res?.data?.content || false;
  }

  /**
   * initNewCallBack
   */
  public initNewCallBack() {
    this.updateValue("operationType", 25);
    this.updateValue("currency", "CDF");
    this.updateValue("pays", 52);
    
    this.updateValue("sender", utils().getUserAccountMsisdn());
    this.updateValue("sendSMS", false);
    this.updateValue("smss", [
      {
        label: "Non",
        value: false
      },
      {
        label: "Oui",
        value: true
      }
    ]);
  }

  /**
   * processNewCallBack
   */
  public processNewCallBack(res: any) {
    this.refreshBalances();
    if (Array.isArray(res.data)) {
      return res.data[0] || res.data;
    }
    return res.data;
  }

  /**
   * refreshBalances
   */
  public refreshBalances() {
    utils().emitter.emit(USER_BALANCES_CHANGE, -1);
    restClient
      .get(`/account/soldes/${utils().getUserAccountId() || {}}`)
      .then((res) => {
        utils().emitter.emit(USER_BALANCES_CHANGE, res?.data.content || {});
      });
  }

  toView() {
    const status = (statuses as any)[this.status] || this.status;
    const section1 = [
      { label: "BankTransaction ID", value: this.operationId },
      { label: "Reférence", value: this.reference },
      {
        label: "Montant",
        value: utils().formatAmount(
          this.devise ? this.devise.code : "CDF",
          this.amount
        ),
      },
      { label: "Status", value: status },
      {
        label: "Type de transaction",
        value: this.operationType ? this.operationType.libelle : "Frais",
      },
      {
        label: "Date de l'opération",
        value: utils().formatDate("DD/MM/YYYY [à] H:mm:ss", this.operationDate),
      },
      {
        label: "Frais",
        value: utils().formatAmount(
          this.devise ? this.devise.code : "CDF",
          this.fees
        ),
      },
    ];
    if (
      this.operationType?.operationTypeId === 14 ||
      this.operationType?.operationTypeId === 15
    ) {
      section1.push({
        label: "TVA",
        value: utils().formatAmount(
          this.devise ? this.devise.code : "CDF",
          this.tva
        ),
      });
    }
    section1.push({ label: "Description", value: this.description });
    let data = [
      {
        title: "Informations générales",
        rows: section1,
      },
    ];

    const model = BaseModel.getInstance();
    if (model.isEntrepriseStandart) {
      data.push({
        title: "Localisation",
        rows: [
          { label: "Pays", value: this.country?.nomFr || "Inconnu" },
          { label: "Ville", value: this.city || "Inconnu" },
        ],
      });
    }
    const typeOp = this.operationType?.operationTypeId || 0;
    data = this.getUsersInfos(data, typeOp);
    data = this.getAdminInfos(data);
    return data;
  }

  getAdminInfos(data: any[]) {
    const model = BaseModel.getInstance();
    const isAdmin = model.isAdmin;
    if (isAdmin) {
      if (this.administrator) {
        data.push(
          this.getSimpleSection(
            "Informations sur l'administrateur",
            this.administrator
          )
        );
      }
    }
    return data;
  }

  getSimpleSection(title: string, value: any) {
    return {
      title,
      rows: [{ label: false, value }],
    };
  }

  getUsersInfos(data: any[], typeOp: number) {
    const amount = this.amount;
    const receiver = amount > 0 ? this.account : this.other;
    const sender = amount <= 0 ? this.account : this.other;
    if (typeOp === 0) {
      data.push(
        this.getSimpleSection("Informations sur le bénéficiaire", "eMpata")
      );
    } else if (typeOp === 1) {
      if (amount > 0) {
        data.push(
          this.getUserSection(receiver, "Informations sur le bénéficiaire")
        );
      } else {
        data.push(this.getUserSection(sender, "Informations sur l'émetteur"));

        data.push(
          this.getUserSection(this.other, "Informations sur le bénéficiaire")
        );
      }
    } else if (typeOp === 2) {
      if (amount <= 0) {
        if (receiver) {
          data.push(
            this.getUserSection(receiver, "Informations sur le bénéficiaire")
          );
        }
        data.push(this.getUserSection(sender, "Informations sur l'émetteur"));
      } else {
        data.push(
          this.getUserSection(receiver, "Informations sur le bénéficiaire")
        );
        if (sender) {
          data.push(this.getUserSection(sender, "Informations sur l'émetteur"));
        }
      }
    } else if (typeOp === 4) {
      data.push(
        this.getUserSection(this.account, "Informations sur l'émetteur")
      );
      data.push(
        this.getSimpleSection("Informations sur le bénéficiaire", this.receiver)
      );
    } else if (typeOp === 14 || typeOp === 15) {
      data.push(
        this.getUserSection(this.guestSender, "Informations sur l'émetteur")
      );
      data.push(
        this.getUserSection(
          this.guestReceiver,
          "Informations sur le bénéficiaire"
        )
      );
    } else {
      if (receiver) {
        data.push(
          this.getUserSection(receiver, "Informations sur le bénéficiaire")
        );
      } else {
        if (this.other) {
          console.log("Je suis dans OTHER", this.other);
          data.push(
            this.getUserSection(this.other, "Informations sur le bénéficiaire")
          );
        } else {
          if (this.receiver) {
            console.log("Je suis dans RECEIVER", this.receiver);
            data.push(
              this.getSimpleSection(
                "Informations sur le bénéficiaire",
                this.receiver
              )
            );
          }
        }
      }
      if (sender) {
        data.push(this.getUserSection(sender, "Informations sur l'émetteur"));
      }
    }

    if (this.operationBank) {
      const ob = this.operationBank;
      data.push({
        title: "Informations bancaire",
        rows: [
          { label: "Nom", value: ob.nomBenef },
          {
            label: "Prénom",
            value: ob.prenomBenef,
          },
          { label: "Code Swift", value: ob.codeSwift },
          { label: "Numéro du RIB", value: ob.rib },
          { label: "Nom de la banque", value: ob.nomBanque },
          { label: "Origine des fonds", value: ob.origineFonds },
          { label: "Téléphone", value: ob.telephone },
          { label: "Motif de transfert", value: ob.motif },
          { label: "Pays", value: ob.pays },
        ],
      });
    }
    return data;
  }

  getUserSection(account: any, title: string) {
    if (account?.user) {
      const user = account.user;
      return {
        title,
        rows: [
          { label: "User ID", value: user.userId },
          {
            label: "Nom et prénom",
            value: `${user.firstName} ${user.lastName}`,
          },
          { label: "Email", value: user.email },
          { label: "Téléphone", value: user.msisdn },
        ],
      };
    } else {
      if (account) {
        return {
          title,
          rows: [
            { label: "User ID", value: account.id },
            { label: "Nom et prénom", value: account.name },
            { label: "Email", value: account.email },
            { label: "Téléphone", value: account.tel },
          ],
        };
      } else {
        return {
          title,
          rows: [{ label: false, value: this.receiver }],
        };
      }
    }
  }

  public async getFraisData(v: any) {
    let operationTypeId = BankTransaction.instance.getValue("operationType", 0);
    let currentCode = BankTransaction.instance.getValue("currency", "");
    let frais = await BankTransaction.instance.getFees(
      v,
      currentCode,
      operationTypeId
    );
    let fees = currentCode === "USD" ? frais.feesInUSD : frais.feesInCDF;
    let total = Number(fees) + Number(v.value);
    BankTransaction.instance.updateValue("tva", frais.tva);
    return { frais: fees, total: total, tva: frais.tva };
  }

  public async getFees(v: any, code: any, opTyp: any) {
    let amount = isNaN(parseFloat(v?.value)) ? 0 : parseFloat(v?.value);
    const model = BaseModel.getInstance();
    let total = { feesInCDF: 0, feesInUSD: 0, tauxUsdToCDF: 0 };
    const path = `operation/fees`;

    var data = {
      amount: amount,
      currency: code,
      operationType: opTyp,
      sender: model.user.msisdn,
    };

    const res = await restClient.post(path, data);
    if (res) {
      return res.data;
    }
    return { total };
  }

  public async convertAmount(v: any, code: any) {
    let amount = isNaN(parseFloat(v?.value)) ? 0 : parseFloat(v?.value);
    let description = BankTransaction.instance.getValue("description", "");
    const model = BaseModel.getInstance();
    let total = amount;
    const path = `operation/simulate`;
    var data = {
      account: model.user.msisdn,
      adminId: 0,
      amount: amount,
      currency: code,
      description: description,
      entreprise: false,
      feesIn: false,
      operationType: 20,
      sender: model.user.msisdn,
    };

    const res = await restClient.post(path, data);
    console.log("==> result", res.data);
    if (res) {
      total = res.data;
    }
    return { total };
  }

  public async convertUnitToCDF(v: any) {
    let amount = isNaN(parseFloat(v?.value)) ? 0 : parseFloat(v?.value);
    let total = amount;
    const path = `/currency/unit/convert/${amount}`;
    const res = await restClient.get(path);
    if (res?.data?.content) {
      total = res.data.content;
    }
    return { total };
  }

  public serializeCallback(payload: any, props: any) {
    const model = BaseModel.getInstance();
    if (model.isEntrepriseStandart) {
      const enterpriseId = model.user?.accountId || 0;
      return { ...payload, enterpriseId };
    }
    const adminId = model.isAdmin ? model.user?.user?.userId || 0 : 0;
    return { ...payload, adminId };
  }

  getCustomProcessNewPath(payload: any) {
    return `/operation/empatatobank`;
  }
}

export { BankTransaction };

import React from "react"; 
import { appConstants } from "../../../../_constants";
import { primaryColor, utils } from "../../../../_helpers";
import { PropsFromRedux } from "../../../../_reducers";

const { MODAL_ACTION } = appConstants.keys.eventKeys;

export function AddButton(props: PropsFromRedux & any) {
  const { addBtnTitle, addBtnAction } = props;

  const onClick = (e: any) => {
    utils().emitter.emit(MODAL_ACTION, {
      btn: addBtnAction || "createActions",
      data: props,
    });
  };

  return (
    <button
      type="button"
      onClick={onClick}
      className="btn  btn-sm d-flex actionBtn align-items-center py-1 box-shadow"
      style={{ float: "right",backgroundColor:primaryColor() ,color:"white"}}
      data-action={addBtnAction || "createActions"}
    >
      <i className="fa fa-plus mr-1"></i>
      <p
        className="d-flex align-items-center"
        style={{
          textTransform: "none",
          float: "right",
          margin: 0,
        }}
      >
        <small style={{ fontWeight: 600 }}>{addBtnTitle}</small>
      </p>
    </button>
  );
}

import React from "react";

export function Input(props: any) {
  const {
    loading,
    field,
    value,
    setValue,
    customData,
    customType,
    customName,
    customCls,
    customInlineStyle,
    customOnChange,
    customValue,
    customAttr,
  } = props;
  const styles = customInlineStyle
    ? customInlineStyle
    : customData?.inputInlineSyle
    ? customData?.inputInlineSyle
    : {};
  const background = customData?.bgColor
    ? customData?.bgColor
    : loading
    ? "#e9ecef"
    : "#fff";
  const type = customType || field?.inputType;
  const name = customName || field?.name;
  const cls = customCls || "form-control form-control-sm";
  const val = customValue || value || "";

  let attr = {};
  
  if (type !== "file") {
    (attr as any).value = val;
  }
  if (customData?.placeholder) {
    (attr as any).placeholder = customData?.placeholder;
  }
  if (customData?.inputData?.attributes) {
    (attr as any) = { ...attr, ...customData?.inputData?.attributes };
  }
  if (customAttr) {
    attr =  { ...attr, ...customAttr}
  }

  return (
    <input
      name={name}
      id={name}
      type={type}
      {...attr}
      onChange={(e) =>
        customOnChange
          ? customOnChange(e)
          : setValue(field.updateValue(e.target.value))
      }
      readOnly={loading ? true : false}
      disabled={customData?.disabled}
      className={cls}
      style={{
        ...styles,
        background,
      }}
    />
  );
}

import React, { Component } from "react";
import { Avantage, avantages } from "../../../_config";
import AvantageItem from "./AvantageItem";

class HomeAvantages extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="features-wrapperr container">
          <div className="features-content">
            <h1 className="features-title">Avantages de la solution</h1>
          </div>
        </div>
        <div className="container">
          <section className="pt-8 pb-4">
            <div className="row">
              {avantages.map(function (item: Avantage, i: number) {
                return <AvantageItem key={i} {...item} />;
              })}
            </div>
          </section>
        </div>
      </React.Fragment>
    );
  }
}

export { HomeAvantages };

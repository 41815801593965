import React, { Component } from "react";
import Product from "./Product";

class ProductList extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      products: this.props.products,
      productsToDisplay: this.props.products,
    };
  }
  componentDidMount() {
    let total_product = parseInt($("#total").html());
    let products = $("#products-wrapper").children().clone();
    let pagination = $("#pagination-product");
    let obj = this;
    let total_pages = Math.ceil(this.props.products.length / 6);
    let optsPagination = {
      totalPages: total_pages == 0 ? 1 : total_pages,
      visiblePages: 3,
      first: "Premier",
      prev: "Précédent",
      next: "Suivant",
      last: "Dernier",
      onPageClick: function (event: any, page: any) {
        let products2: any[] = [];
        $.each(obj.state.products, (i: any, item) => {
          if (i < page * 6 && i >= page * 6 - 6) {
            products2.push(item);
          }
        });
        obj.setState({ productsToDisplay: products2 });
        // $("#products-wrapper").empty();
        // let products2 = obj.getProductToDisplay(products, $("#search_input").val(), $("#category_select").val())
        // console.log(products2)
        // $.each(products2, function (i, item) {
        //   if (i < page * 6 && i >= (page * 6) - 6) {
        //     $("#products-wrapper").append($(item));
        //   }
        // });
        // $(".details-produit-link").click(function (e) {
        //   e.preventDefault();
        //   alert("ok")
        //   //loadProductDetails($(this).data("id"), clientTransactionForm);
        // });
      },
    };
    console.log(optsPagination);
    (pagination as any).twbsPagination(optsPagination);

    $("#search_input").on("keyup", function () {
      obj.refreshProductsGridList(
        products,
        $(this).val(),
        pagination,
        $("#category_select").val(),
        optsPagination
      );
    });

    $("#category_select").change(function (e) {
      e.preventDefault();
      obj.refreshProductsGridList(
        products,
        $("#search_input").val(),
        pagination,
        $(this).val(),
        optsPagination
      );
    });
  }

  getProductToDisplay(products: any, val: any, selectval: any) {
    let results: any[] = [];
    val = val ?? "";
    selectval = selectval ?? "";
    if (val === "" && selectval === "") {
      return products;
    } else {
      $.each(products, function (i, item) {
        let id = $(item).find(".libproduct").data("catgid");
        let txt = $(item).find(".libproduct").html().trim();
        if (val === "") {
          if (id == selectval) {
            results.push(item);
          }
        } else if (selectval === "") {
          if (txt.toLowerCase().indexOf(val.toLowerCase()) != -1) {
            results.push(item);
          }
        } else {
          if (
            txt.toLowerCase().indexOf(val.toLowerCase()) != -1 &&
            id == selectval
          ) {
            results.push(item);
          }
        }
      });
      return results;
    }
  }

  refreshProductsGridList(
    products: any,
    val: any,
    pagination: any,
    selectval: any,
    optsPagination: any
  ) {
    $("#products-wrapper").empty();
    let products2 = this.getProductToDisplay(products, val, selectval);

    $.each(products2, function (i, item) {
      $("#products-wrapper").append(item);
    });
    pagination.twbsPagination("destroy");
    $("#total_product").html(
      $("#products-wrapper").children().length.toString()
    );
    let total_pages = Math.ceil($("#products-wrapper").children().length / 6);
    pagination.twbsPagination(
      $.extend({}, optsPagination, {
        totalPages: total_pages == 0 ? 1 : total_pages,
      })
    );
  }

  render() {
    const { productsToDisplay } = this.state;
    return (
      <div
        className="list-group-item d-flex product-list-wrapper flex-column d-flex"
        style={{ borderTop: "none" }}
      >
        <div className="d-flex product-list-item flex-column d-flex">
          <div className="card-deck" id="products-wrapper">
            {productsToDisplay.map((produit: any, i: any) => {
              return <Product produit={produit} key={i} />;
            })}
          </div>
          <div className="d-flex flex-row justify-content-end">
            <ul
              id="pagination-product"
              className="pagination-sm"
              style={{ float: "right" }}
            ></ul>
          </div>
        </div>
      </div>
    );
  }
}

export default ProductList;

import { appConstants } from "../_constants";
import { BaseEntity } from "./BaseEntity";
import { FieldType } from "./enumerations";
import { Field } from "./Field";
import {BaseModel} from "../_models";

const {
  RENDER_ID,
  RENDER_ACTIONS,
  RENDER_OUI_NON,
} = appConstants.keys.renderKeys;

class Country extends BaseEntity {
  public static readonly label: any;
  public static readonly className: any;
  public static readonly art: any;
  public static readonly editTitle = "Modifier un pays";
  public static readonly showTitle = "Détails d'un pays";
  public static instance: Country = new Country();
  public static instances = [];
  public static relations = [];

  public static get definitions(): Field[] {
    const model: any = BaseModel.getInstance();
    const aCHild = [
      {
        label: "Modifier",
        title: "Modifer ce pays",
        action: "editActions",
        color: "text-dark",
        icon: "fa fa-edit",
      },
      {
        actionCallback: (data: any) => {
          if (!data.blacklist) {
            return {
              label: "Blacklist on",
              title: "Mettre dans la liste noire",
              action: "switchActionsActivate",
              color: "text-danger ",
              icon: "fa fa-times",
            };
          }
          return {
            label: "Blacklist off",
            title: " Retirer de la liste noire",
            action: "switchActionsDeactivate",
            color: "text-success",
            icon: "fa fa-check",
          };
        },
      },
    ];

    const definitions = [
      new Field({
        name: "id",
        type: FieldType.NUMBER,
        primary: true,
        creatable: false,
        editable: false,
        serializeNew: false,
        serializeEdit: false,
        label: "#",
        renderKey: RENDER_ID,
        provider: Country.instance,
      }),
      new Field({
        name: "nomFr",
        label: "Intitulé",
        provider: Country.instance,
        customData: {
          renderOptions: {
            className: "min-w-150",
          },
        },
      }),
      new Field({
        name: "symbole",
        label: "Symbole",
        provider: Country.instance,
      }),
      new Field({
        name: "code",
        label: "Code Pays",
        provider: Country.instance,
      }),
      new Field({
        name: "blacklist",
        label: "Liste noire",
        renderKey: RENDER_OUI_NON,
        creatable: false,
        editable: false,
        serializeNew: false,
        serializeEdit: false,
        provider: Country.instance,
      }),
      new Field({
        name: "actions",
        label: "Actions",
        dataPath: "id",
        creatable: false,
        editable: false,
        serializeNew: false,
        serializeEdit: false,
        serializeView: false,
        renderKey: RENDER_ACTIONS,
        provider: Country.instance,
        customData: {
          renderOptions: {
            clsActions: "justify-content-center",
          },
          actions: [
            {
              label: "Détails",
              title: "Afficher les détails",
              action: "detailsActions",
              color: "btn-info",
              icon: "fa fa-eye",
            },
            {
              type: "btnGroup",
              color: "btn-primary",
              children:model.user.user?.profile===null?aCHild:model.i18Crud.modification?aCHild:[],
            },
          ],
        },
      }),
    ];
    return definitions;
  }

  toView() {
    return [
      {
        title: false,
        rows: [
          { label: "Country ID", value: this.id },
          { label: "Nom", value: this.nomFr },
          { label: "Country Code", value: this.countryCode || "-" },
          { label: "Symbole", value: this.symbole || "-" },
          { label: "Liste noire", value: this.blacklist ? "Oui" : "Non" },
        ],
      },
    ];
  }

  getCustomProcessEditPath(payload: any) {
    return encodeURI(
      `/country/update/${this.id}?nom=${payload.nomFr}_AMP_blacklist=${this.blacklist}_AMP_countrycode=${payload.code}_AMP_countrysymbole=${payload.symbole}`
    );
  }

  getSwitchActionConfig(status: boolean) {
    return {
      path: `/country/change/${this.countrycode || this.code}/${status}`,
      resolve: (res: any) => res?.data?.status === "OK",
    };
  }
}

export { Country };

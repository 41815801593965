import { appConstants } from "../_constants";
import { utils } from "../_helpers";
import { BaseModel } from "../_models";
import { BaseEntity } from "./BaseEntity";
import { FieldType } from "./enumerations";
import { Field } from "./Field";

const {
  RENDER_ACTIONS,
  RENDER_ID,
  RENDER_STATUS,
  RENDER_DATETIME,
  RENDER_AMOUNT,
  RENDER_CLIENT_ORDER
} = appConstants.keys.renderKeys;

class Subscription extends BaseEntity {
  public static readonly label: any;
  public static readonly className: any;
  public static readonly art: any;
  public static readonly showTitle = "Détails d'une souscription";
  public static instance: Subscription = new Subscription();
  public static instances = [];
  public static relations = [];

  public static get definitions(): Field[] {
    const model: any = BaseModel.getInstance();
    const isClient = model.isClient;
    return [
    new Field({
      name: "id",
      type: FieldType.NUMBER,
      primary: true,
      creatable: false,
      editable: false,
      serializeNew: false,
      serializeEdit: false,
      label: "#",
      renderKey: isClient ? RENDER_CLIENT_ORDER : RENDER_ID,
      provider: Subscription.instance,
    }),
    new Field({
      name: "reference",
      label: "Reférence",
      onTable: !isClient,
      provider: Subscription.instance,
    }),
    new Field({
      name: "product",
      label: "Produit/Service",
      onTable: !isClient,
      dataPath: "subscriptionPlan.product.libelle",
      provider: Subscription.instance,
    }),
    new Field({
      name: "quota",
      label: "Périodicité",
      onTable: !isClient,
      dataPath: "subscriptionPlan.quota.libelle",
      provider: Subscription.instance,
    }),
    new Field({
      name: "amount",
      label: "Prix",
      renderKey: RENDER_AMOUNT,
      onTable: !isClient,
      dataPath: "subscriptionPlan.amount",
      provider: Subscription.instance,
      customData: {
        renderOptions: {
          className: "min-w-100",
        },
      },
    }),
    new Field({
      name: "state",
      label: "Status",
      onTable: !isClient,
      renderKey: RENDER_STATUS,
      provider: Subscription.instance,
    }),
    new Field({
      name: "account",
      label: "Compte",
      onTable: !isClient,
      dataPath: "account.msisdn",
      provider: Subscription.instance,
    }),
    new Field({
      name: "createdAt",
      onTable: !isClient,
      label: "Date création",
      renderKey: RENDER_DATETIME,
      provider: Subscription.instance,
    }),
    new Field({
      name: "actions",
      label: "Actions",
      dataPath: "id",
      creatable: false,
      editable: false,
      onTable: !isClient,
      serializeNew: false,
      serializeEdit: false,
      serializeView: false,
      renderKey: RENDER_ACTIONS,
      provider: Subscription.instance,
      customData: {
        renderOptions: {
          clsActions: "justify-content-center",
        },
        actions: [
          {
            label: "Détails",
            title: "Afficher les détails",
            action: "detailsActions",
            color: "btn-info",
            icon: "fa fa-eye",
          },

          /*{
            label: "Supprimer",
            title: "Supprimmer ce type de compte",
            action: "deleteActions",
            color: "btn-danger",
            icon: "fa fa-trash",
          },*/
        ],
      },
    }),
  ];
  }
  toView() {
    return [
      {
        title: false,
        rows: [
          { label: "Subscription ID", value: this.id },
          { label: "Reference", value: this.reference },
          { label: "Renouvellement automatique ?", value: this.auto ? "OUI" : "NON" },
          { label: "Date de création", value: utils().formatDate("DD/MM/YYYY [à] HH:mm:ss", this.subscriptionPlanPeriodFile?.createdAt) },
        ],
      },
    ];
  }
}

export { Subscription };

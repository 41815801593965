import { adminFinancierPrefix } from "../_config";
import { Transaction } from "../_entities";

export const adminFinancierRoutes = [
  {
    mainPath: adminFinancierPrefix,
    pageTitle: "Tableau de board Admin financier",
    pageType: "kpi",
    provider: undefined,
  },
  {
    mainPath: `${adminFinancierPrefix}/transactions`,
    pageTitle: "Transactions",
    addBtnTitle: "Faire une transaction",
    listTitle: "Liste des transactions réalisées",
    provider: Transaction.instance,
    dataPath: (meta: any) => {
      return Transaction.instance.applyFilters("operation/all", meta);
    },
  },
  {
    mainPath: `${adminFinancierPrefix}/transactions/depots`,
    pageTitle: "Dépôts",
    addBtnTitle: "Faire une transaction",
    listTitle: "Liste des dépôts d'argent",
    provider: Transaction.instance,
    dataPath: (meta: any) => {
      return Transaction.instance.applyFilters("operation/all", meta);
    },
    dataPathCallBack: (res: any, props: any) => {
      const data = Array.isArray(res.data) ? res.data : [];
      return data.filter((d: any) => d.operationType?.operationTypeId === 1);
    },
  },
  {
    mainPath: `${adminFinancierPrefix}/transactions/retraits`,
    pageTitle: "Retraits",
    addBtnTitle: "Faire une transaction",
    listTitle: "Liste des retraits de fonds",
    provider: Transaction.instance,
    dataPath: (meta: any) => {
      return Transaction.instance.applyFilters("operation/all", meta);
    },
    dataPathCallBack: (res: any, props: any) => {
      const data = Array.isArray(res.data) ? res.data : [];
      return data.filter((d: any) => d.operationType?.operationTypeId === 2);
    },
  },
  {
    mainPath: `${adminFinancierPrefix}/transactions/transferts`,
    pageTitle: "Transferts",
    addBtnTitle: "Faire une transaction",
    listTitle: "Liste des transferts éffectués",
    provider: Transaction.instance,
    dataPath: (meta: any) => {
      return Transaction.instance.applyFilters("operation/all", meta);
    },
    dataPathCallBack: (res: any, props: any) => {
      const data = Array.isArray(res.data) ? res.data : [];
      return data.filter(
        (d: any) =>
          d.operationType?.operationTypeId === 3 ||
          d.operationType?.operationTypeId === 4
      );
    },
  },
  {
    mainPath: `${adminFinancierPrefix}/transactions/empatatobank`,
    pageTitle: "eMpata to Bank",
    addBtnTitle: false,
    listTitle: "Liste des transferts eMpata to Bank éffectués",
    provider: Transaction.instance,
    dataPath: (meta: any) => {
      return Transaction.instance.applyFilters(
        "operation/all",
        meta
      );
    },
    dataPathCallBack: (res: any, props: any) => {
      const data = Array.isArray(res.data) ? res.data : [];
      return data.filter((d: any) => d.operationType?.operationTypeId === 25);
    },
  },
  {
    mainPath: `${adminFinancierPrefix}/frais`,
    pageTitle: "Frais",
    addBtnTitle: "Faire une transaction",
    listTitle: "Liste des frais prelevés",
    provider: Transaction.instance,
    dataPath: (meta: any) => {
      return Transaction.instance.applyFilters(
        `/admin/financier/fees/all`,
        meta
      );
    },
  },
  {
    mainPath: `${adminFinancierPrefix}/frais/transferts_credits`,
    pageTitle: "Frais Transferts/Achats crédits",
    addBtnTitle: "Faire une transaction",
    listTitle: "Liste des frais prelevés pour les transferts et achats crédits",
    provider: Transaction.instance,
    dataPath: (meta: any) => {
      const pth = Transaction.instance.applyFilters(
        `/admin/financier/fees/all`,
        meta
      );
      return `${pth}_AMP_type=transferts_credits`;
    },
  },
  {
    mainPath: `${adminFinancierPrefix}/profile`,
    pageTitle: "Mon profile",
    provider: null,
    pageType: "accountProfil",
  },
];

import { BaseModel } from "../_models";
import { TableRenderer } from "./tableRenderer";
import { Utils } from "./utils";

export * from "./history";
export * from "./store";
export * from "./restClient";

export function utils() {
  return Utils.getInstance();
}
export function model() {
  return BaseModel.getInstance();
}

export const primaryColor = () => model().couleur;

export const tableRenderer = TableRenderer.getInstance();

import { appConstants } from "../_constants";
import { BaseEntity } from "./BaseEntity";
import { FieldType, InputType } from "./enumerations";
import { Field } from "./Field";
import { BaseModel } from "../_models";
import { PriceIntervalle } from "./PriceIntervalle";
import { Account } from "./Account";
import { OperationType } from "./OperationType";
import { restClient, utils } from "../_helpers";

const { RENDER_ID, RENDER_ACTIONS, RENDER_STATUS } =
  appConstants.keys.renderKeys;

class Price extends BaseEntity {
  public static readonly label: any;
  public static readonly addTitle = "Ajouter un frais d'entreprise";
  public static readonly className: any;
  public static readonly art: any;
  public static readonly editTitle = "Modifier un frais";
  public static readonly showTitle = "Détails d'un frais";
  public static readonly processNewPath = "price/partner/add";
  public static readonly processEditPath = "price/update/partner/_ID_";
  public static instance: Price = new Price();
  public static instances = [];
  public static relations = [
    {
      dataPath: "currency/all",
      key: "currencies",
      resolve: (res: any) => {
        if (Array.isArray(res?.data)) {
          return res.data.map((v: any) => PriceIntervalle.getSelectCurrency(v));
        }
        return [];
      },
    },
    {
      dataPath: "account/find/byAccountType/4",
      key: "accountType",
      resolve: (res: any) => {
        if (Array.isArray(res?.data)) {
          return res.data.map((v: any) => Account.getSelectAccount(v));
        }
        return [];
      },
    },
    {
      dataPath: "account/find/byAccountType",
      key: "accountEts",
      resolve: (res: any) => {
        if (Array.isArray(res?.data)) {
          return res.data.map((v: any) => Account.getSelectAccount(v));
        }
        return [];
      },
    },
    {
      dataPath: "operation/type/all",
      key: "operationType",
      resolve: (res: any) => {
        if (Array.isArray(res?.data)) {
          return res.data.map((v: any) =>
            OperationType.getSelectOperationType(v)
          );
        }
        return [];
      },
    },
  ];

  public static get definitions(): Field[] {
    const model: any = BaseModel.getInstance();
    const aChildMod = [
      {
        label: "Modifier",
        title: "Modifer ce frais",
        action: "editActions",
        color: "text-dark",
        icon: "fa fa-edit",
      },
    ];
    const aChildDel = [
      {
        label: "Supprimer",
        title: "Supprimmer ce frais",
        action: "deleteActions",
        color: "text-danger",
        icon: "fa fa-trash",
      },
    ];
    const aChildAll = [
      {
        label: "Modifier",
        title: "Modifer ce frais",
        action: "editActions",
        color: "text-dark",
        icon: "fa fa-edit",
      },
      {
        actionCallback: (data: any) => {
          if (data.actif) {
            return {
              label: "Désactiver",
              title: "Désactiver ce frais",
              action: "switchActionsDeactivate",
              color: "text-danger ",
              icon: "fa fa-times",
            };
          }
          return {
            label: "Activer",
            title: "Activer ce frais",
            action: "switchActionsActivate",
            color: "text-success",
            icon: "fa fa-check",
          };
        },
      },
      {
        label: "Supprimer",
        title: "Supprimmer ce frais",
        action: "deleteActions",
        color: "text-danger",
        icon: "fa fa-trash",
      },
    ];

    const definitions = [
      new Field({
        name: "tarifId",
        type: FieldType.NUMBER,
        primary: true,
        creatable: false,
        editable: false,
        serializeNew: false,
        serializeEdit: false,
        label: "#",
        renderKey: RENDER_ID,
        provider: Price.instance,
      }),
      new Field({
        name: "amount",
        label: "Montant",
        onTable: true,
        formRowIndex: 0,
        provider: Price.instance,
        customData: {
          renderOptions: {
            className: "min-w-150",
          },
          checkBoxData: {
            id: "pourcent",
            cls: "form-check-label",
            customLabel: "En pourcentage?",
            styles: { fontSize: "80%", marginLeft: "19px" },
            checked: false,
            eventName: "hasFees_change",
            customInlineStyle: { marginLeft: "1px", marginTop: "7px" },
            customLayoutInlineStyle: {
              marginTop: "6px",
              padding: 0,
            },
          },
        },
        listeners: [
          {
            type: "hasFees_change",
            behaviour: "customAction",
            resolve: (v: any, customData?: any) => {
              Price.instance.updateValue("pourcent", v?.value);
              return {
                ...customData,
                checkBoxData: { ...customData.checkBoxData, checked: v?.value },
              };
            },
          },
        ],
        serializeCallback: (item: any, v: any) => {
          console.log(item, v);
          console.log("Les props", this);
          console.log(Price.instance);
          const pourcent = Price.instance.getValue("pourcent", false);
          const amount = pourcent ? 0 : Price.instance.getValue("amount", 0);
          const percent = pourcent ? Price.instance.getValue("amount", 0) : 0;
          const active = Price.instance.getValue("active", true);
          // const accountId = Price.instance.data[4].value;
          return { ...item, valMax: v, amount, percent, active };
        },
      }),
      new Field({
        name: "percent",
        label: "Pourcentage",
        formRowIndex: 1,
        creatable: false,
        editable: false,
        serializeNew: false,
        serializeEdit: false,
        serializeView: false,
        type: FieldType.NUMBER,
        provider: Price.instance,
      }),
      new Field({
        name: "valMin",
        label: "Valeur min",
        type: FieldType.NUMBER,
        formRowIndex: 2,
        provider: Price.instance,
      }),
      new Field({
        name: "valMax",
        label: "Valeur max",
        type: FieldType.NUMBER,
        formRowIndex: 3,
        provider: Price.instance,
      }),
      new Field({
        name: "devise",
        label: "Devise",
        dataPath: "devise.code",
        inputType: InputType.INPUT_SELECT,
        relationKey: "currencies",
        formRowIndex: 4,
        provider: Price.instance,
      }),
      new Field({
        name: "accountId",
        label: "Compte",
        dataPath: "account.msisdn",
        inputType: InputType.INPUT_SELECT,
        onTable: true,
        relationKey: "accountType",
        formRowIndex: 5,
        provider: Price.instance,
      }),
      new Field({
        name: "operationTypeId",
        label: "Opération",
        dataPath: "operationType.libelle",
        inputType: InputType.INPUT_SELECT,
        onTable: true,
        relationKey: "operationType",
        formRowIndex: 6,
        provider: Price.instance,
      }),
      new Field({
        name: "actif",
        label: "Status",
        creatable: false,
        // formRowIndex: 7,
        renderKey: RENDER_STATUS,
        provider: Price.instance,
        serializeCallback: (item: any, v: any) => {
          return { ...item, actif: true };
        },
      }),
      new Field({
        name: "actions",
        label: "Actions",
        dataPath: "tarifId",
        creatable: false,
        editable: false,
        serializeNew: false,
        serializeEdit: false,
        serializeView: false,
        renderKey: RENDER_ACTIONS,
        provider: Price.instance,
        customData: {
          renderOptions: {
            clsActions: "justify-content-center",
          },
          actions: [
            {
              label: "Détails",
              title: "Afficher les détails",
              action: "detailsActions",
              color: "btn-info",
              icon: "fa fa-eye",
            },
            {
              type: "btnGroup",
              color: "btn-primary",
              children:
                model.user.user?.profile === null
                  ? aChildAll
                  : model.confCrud.modification && model.confCrud.suppression
                  ? aChildAll
                  : model.confCrud.modification
                  ? aChildMod
                  : model.confCrud.suppression
                  ? aChildDel
                  : [],
            },
          ],
        },
      }),
    ];
    return definitions;
  }

  // toView() {
  //   console.log("THISSSSS",this);
  //   return [
  //     {
  //       title: false,
  //       rows: [
  //         { label: "Price ID", value: this.tarifId },
  //         { label: "Montant", value: this.amount},
  //         { label: "Pourcentage", value: this.percent},
  //         // { label: "Compte", value: this.accountId},
  //         // { label: "Type d'opération", value: this.operationTypeId},
  //         { label: "Valeur maximale", value: this.valMax },
  //         { label: "Valeur maximale", value: this.valMax },
  //         { label: "Devise", value: this.devise.code},
  //       ],
  //     },
  //   ];
  // }

  toView() {
    return [
      {
        title: "Information générales",
        rows: [
          {
            label: "Intervalle de prix",
            value: `De ${utils().formatAmount(
              this.devise ? this.devise.code : "CDF",
              this.valMin
            )} à ${utils().formatAmount(
              this.devise ? this.devise.code : "CDF",
              this.valMax
            )}`,
          },
          {
            label: "Valeur des frais ",
            value:
              this.amount > 0
                ? `${utils().formatAmount(
                    this.devise ? this.devise.code : "CDF",
                    this.amount
                  )}`
                : `${this.percent}%`,
          },
          { label: "Status ", value: this.actif ? "Actif" : "Inactif" },
        ],
      },
      {
        title: "Type d'opération",
        rows: [{ label: false, value: this.operationType.libelle }],
      },
      {
        title: "Compte (Entreprise)",
        rows: [
          {
            label: false,
            value:
              this.account.msisdn +
              " || " +
              this.account.user?.firstName +
              " " +
              this.account.user?.lastName,
          },
        ],
      },
    ];
  }
  getDeleteActionConfig() {
    return {
      path: `/price/delete/partner/${this.tarifId}`,
      resolve: (res: any) => res?.success,
    };
  }
  getCustomProcessEditPath(payload: any) {
    return encodeURI(
      `/price/update/partner/${this.tarifId}?accountId=${payload.accountId}
      active=${payload.active}amount=${payload.amount}devise=${payload.devise}
      operationTypeId=${payload.operationTypeId}percent=${payload.percent}valMax=${payload.valMax}
      valMin=${payload.valMin}`
    );
  }

  getSwitchActionConfig(status: boolean) {
    return {
      path: `/price/change/partner/${this.tarifId}/${status}`,
      resolve: (res: any) => res?.message === "OK",
    };
  }
}

export { Price };

import React, { Component } from "react";
import Slider from "react-slick";

import slidev1 from "../../_assets/images/slides/slidev1.png";
import slidev2 from "../../_assets/images/slides/slidev2.png";
import dashboardClient2 from "../../_assets/images/slides/dashboard-client2.png";
import eWalet from "../../_assets/images/e-walet.png";
import { model } from "../../_helpers";

function SampleNextArrow(props: any) {
  const { onClick } = props;
  return (
    <span className="slider-btn next" onClick={onClick}>
      <i className="fa fa-chevron-right fa-lg"></i>
    </span>
  );
}

function SamplePrevArrow(props: any) {
  const { onClick } = props;
  return (
    <span className="slider-btn prev" onClick={onClick}>
      <i className="fa fa-chevron-left fa-lg"></i>
    </span>
  );
}

class HomeSlider extends Component<any, any> {
  slider1: any;
  slider2: any;

  constructor(props: {} | Readonly<{}>) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
    };
  }
  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }

  render() {
    let opts1 = {
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      dots: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    };
    let opts2 = {
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      fade: true,
      cssEase: "linear",
      arrows: true,
    };

    return (
      <React.Fragment>
        <div className="features-wrapperr container">
          <div className="features-content">
            <h1 className="features-title">
              Que pouvez vous faire avec {model().appTitle || "e-Mpata"} ?
            </h1>
            <p className="features-subtitle">
              {model().appTitle || "e-Mpata"} dispose d'un ensemble de
              fonctionnalités qui font de lui le meilleur choix pour vos
              transactions électroniques.
            </p>
            <div className="w-100">
              <div className="row">
                <div className="col-md-5">
                  <div className="features-description">
                    <Slider
                      {...opts2}
                      ref={(slider) => (this.slider1 = slider)}
                      asNavFor={this.state.nav2}
                    >
                      {this.getDescriptions().map((item, i) => {
                        return (
                          <div key={i}>
                            <span className="features-description-item">
                              <h3>{item.title}</h3>
                              <p>{item.desc}</p>
                            </span>
                          </div>
                        );
                      })}
                    </Slider>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="features-slider">
                    <Slider
                      {...opts1}
                      ref={(slider) => (this.slider2 = slider)}
                      asNavFor={this.state.nav1}
                    >
                      {this.getSlides().map((item, i) => {
                        return (
                          <div key={i}>
                            <div className="slider-item-wrapper">
                              <div className="slide-item">
                                <div className="slide-image-container">
                                  <img
                                    className="slide-image"
                                    src={item.img}
                                    alt={item.alt}
                                  />
                                </div>
                                <div className="text-block">
                                  <p>{item.desc}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  getSlides() {
    return [
      {
        img: slidev1,
        alt: "Slide image 2",
        desc: "Solde, historique et statistiques",
      },
      {
        img: slidev2,
        alt: "Slide image 2",
        desc:
          "Transferts " +
          (model().appTitle || "e-Mpata") +
          " vers " +
          (model().appTitle || "e-Mpata") +
          " et " +
          (model().appTitle || "e-Mpata") +
          " vers autres",
      },
      {
        img: dashboardClient2,
        alt: "Slide image 3",
        desc: (model().appTitle || "e-Mpata") + ": dashboard client",
      },
      {
        img: eWalet,
        alt: "Slide image 7",
        desc: "Support du USSD ",
      },
    ];
  }

  getDescriptions() {
    return [
      {
        title: "Solde, historique et statistiques",
        link: "/#",
        desc: `Consultez l'historique complet et détaillé de chacune de vos opérations,
                Suivez en temps réel l'évolution de votre bon.`,
      },
      {
        title:
          "Transferts " +
          (model().appTitle || "e-Mpata") +
          " vers " +
          (model().appTitle || "e-Mpata") +
          " et " +
          (model().appTitle || "e-Mpata") +
          " vers autres",
        link: "/#",
        desc: `Effectuez vos transferts vers d'autres comptes `+(model().appTitle || "e-Mpata") +`en toute sécurité
                et à prix bas. Que ce soit pour régler une dette ou faire plaisir à un proche,`
               + (model().appTitle || "e-Mpata") +` vous simplifie la vie.`,
      },
      {
        title: "Espace web " + (model().appTitle || "e-Mpata"),
        link: "/#",
        desc: `Connectez vous via votre navigateur et gérer votre bon virtuel sur un grand écran. L'espace web vous assure une visibilité complète sur votre bon.`,
      },
      {
        title: "USSD",
        link: "/#",
        desc: `Pour les utilisteurs désirant utiliser le USSD, 
                ${
                  model().appTitle || "e-Mpata"
                } met à votre disposition un code dédié pour vous permettre de gérer votre bon via USSD`,
      },
    ];
  }
}

export { HomeSlider };

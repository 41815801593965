import React from "react";
import { utils } from "../../../_helpers";

export function InputUniteFrais(props: any) {
  const inputUniteData = props.customData?.inputUniteData || {};
  const { total, cls } = inputUniteData;
  
  
  return (
    <span style={{ float: "right" }}>
      <small className={`text-info ${cls}`}>
        {props.value} unités = {utils().formatAmount('CDF', total)}
      </small>
    </span>
  );
}

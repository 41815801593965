import { appConstants } from "../_constants";
import { utils } from "../_helpers";
import { BaseEntity } from "./BaseEntity";
import { FieldType } from "./enumerations";
import { Field } from "./Field";

const {
  RENDER_ACTIONS,
  RENDER_ID,
  RENDER_AMOUNT,
  RENDER_STATUS,
} = appConstants.keys.renderKeys;

class Abonnement extends BaseEntity {
  public static readonly label: any;
  public static readonly className: any;
  public static readonly art: any;
  public static instance: Abonnement = new Abonnement();
  public static instances = [];
  public static relations = [];

  public static definitions = [
    new Field({
      name: "pk",
      type: FieldType.NUMBER,
      primary: true,
      creatable: false,
      editable: false,
      serializeNew: false,
      serializeEdit: false,
      label: "#",
      dataPath: "pk.productId",
      renderKey: RENDER_ID,
      provider: Abonnement.instance,
    }),
    new Field({
      name: "produit",
      label: "Produit",
      dataPath: "product.libelle",
      provider: Abonnement.instance,
    }),
    new Field({
      name: "quota",
      label: "Periodicité",
      dataPath: "quota.libelle",
      provider: Abonnement.instance,
    }),
    new Field({
      name: "amount",
      label: "Montant",
      renderKey: RENDER_AMOUNT,
      provider: Abonnement.instance,
      customData: {
        renderOptions: {
          className: "min-w-150",
        },
      },
    }),
    new Field({
      name: "actif",
      label: "Status",
      renderKey: RENDER_STATUS,
      provider: Abonnement.instance,
    }),

    new Field({
      name: "actions",
      label: "Actions",
      dataPath: "pk.productId",
      creatable: false,
      editable: false,
      serializeNew: false,
      serializeEdit: false,
      serializeView: false,
      renderKey: RENDER_ACTIONS,
      provider: Abonnement.instance,
      customData: {
        renderOptions: {
          clsActions: "justify-content-center",
        },
        actions: [
          {
            label: "Détails",
            title: "Afficher les détails",
            action: "detailsActions",
            color: "btn-info",
            icon: "fa fa-eye",
          },
          /*{
            label: "Modifier",
            title: "Modifer ce type de compte",
            action: "editActions",
            color: "btn-success",
            icon: "fa fa-edit",
          },
          {
            label: "Supprimer",
            title: "Supprimmer ce type de compte",
            action: "deleteActions",
            color: "btn-danger",
            icon: "fa fa-trash",
          },*/
        ],
      },
    }),
  ];

  toView() {
    return [
      {
        title: "Information générales",
        rows: [
          {
            label: "Montant ",
            value: utils().formatAmount("CDF", this.amount),
          },
        ],
      },
      {
        title: "Périodicité",
        rows: [{ label: false, value: this.quota.libelle }],
      },
      {
        title: "Produit/Service",
        rows: [
          { label: "Product ID", value: this.product.productId },
          { label: "Reférence du produit", value: this.product.reference },
          { label: "Libellé", value: this.product.libelle },
        ],
      },
    ];
  }
}

export { Abonnement };

import { appConstants } from "../_constants";
import { restClient, utils } from "../_helpers";
import { BaseEntity } from "./BaseEntity";
import { FieldType } from "./enumerations";
import { Field } from "./Field";
import { User } from "./User";
import { BaseModel } from "../_models";

const {
  RENDER_ID,
  RENDER_STATUS,
  RENDER_ACCOUNT_TYPE,
  RENDER_AMOUNT_USD,
  RENDER_DATE,
  RENDER_AMOUNT,
  RENDER_LONG_TEXT,
  RENDER_ACTIONS,
} = appConstants.keys.renderKeys;

class Account extends BaseEntity {
  public static readonly label: any;
  public static readonly className: any;
  public static readonly art: any;
  public static instance: Account = new Account();
  public static instances = [];
  public static relations = [];

  public static get definitions(): Field[] {
    const model: any = BaseModel.getInstance();
    const aChild = [
      {
        label: "Modifier",
        title: "Modifer ce type de compte",
        action: "navigateActions",
        link: "/admin/profiles/affectations",
        color: "text-dark",
        icon: "fa fa-edit",
      },
      {
        label: "Hiérarchiser",
        title: "Définir le parent de ce compte",
        action: "customActions",
        color: "text-dark",
        icon: "fa fa-edit",
      },
      {
        actionCallback: (data: any) => {
          if (data.active) {
            return {
              label: "Désactiver",
              title: "Désactiver ce compte",
              action: "switchActionsDeactivate",
              color: "text-danger ",
              icon: "fa fa-times",
            };
          }
          return {
            label: "Activer",
            title: "Activer ce compte",
            action: "switchActionsActivate",
            color: "text-success",
            icon: "fa fa-check",
          };
        },
      },
    ];

    const definitions = [
      new Field({
        name: "accountId",
        type: FieldType.NUMBER,
        primary: true,
        creatable: false,
        editable: false,
        serializeNew: false,
        serializeEdit: false,
        label: "#",
        renderKey: RENDER_ID,
        provider: Account.instance,
      }),
      new Field({
        name: "numero",
        label: "N° compte",
        provider: Account.instance,
      }),
      new Field({
        name: "msisdn",
        label: "MSISDN",
        provider: Account.instance,
      }),
      new Field({
        name: "email",
        label: "Email",
        dataPath: "user.email",
        renderKey: RENDER_LONG_TEXT,
        customData: {
          renderOptions: {
            cuttoff: 14,
          },
        },
        provider: Account.instance,
      }),
      new Field({
        name: "solde",
        label: "Solde CDF",
        renderKey: RENDER_AMOUNT,
        provider: Account.instance,
        customData: {
          renderOptions: {
            className: "min-w-150",
          },
        },
      }),
      new Field({
        name: "soldeUsd",
        label: "Solde USD",
        renderKey: RENDER_AMOUNT_USD,
        provider: Account.instance,
        customData: {
          renderOptions: {
            className: "min-w-150",
          },
        },
      }),
      new Field({
        name: "accountType",
        label: "Type de compte",
        renderKey: RENDER_ACCOUNT_TYPE,
        dataPath: "accountType.libelle",
        provider: Account.instance,
      }),
      // new Field({
      //   name: "profile_id",
      //   label: "Profile",
      //   renderKey: RENDER_PROFILE,
      //   dataPath: "profile.libelle",
      //   provider: Account.instance,
      // }),
      new Field({
        name: "createDate",
        renderKey: RENDER_DATE,
        label: "Date création",
        provider: Account.instance,
      }),
      new Field({
        name: "cni",
        label: "CNI",
        renderKey: RENDER_STATUS,
        dataPath: "user.cni",
        onTable: false,
        provider: Account.instance,
      }),
      new Field({
        name: "active",
        label: "Status",
        renderKey: RENDER_STATUS,
        provider: Account.instance,
      }),
      new Field({
        name: "actions",
        label: "Actions",
        dataPath: "accountId",
        creatable: false,
        editable: false,
        serializeNew: false,
        serializeEdit: false,
        serializeView: false,
        renderKey: RENDER_ACTIONS,
        provider: Account.instance,
        customData: {
          actions: [
            {
              actionCallback: (data: any) => {
                return {
                  label: "Détails",
                  title: "Afficher les détails",
                  action: "navigateActions",
                  link: `${window.location.pathname}/details/?id=${data.accountId}`,
                  color: "btn-info",
                  icon: "fa fa-eye",
                };
              },
            },
            {
              type: "btnGroup",
              color: "btn-primary",
              children:
                model.user.user?.profile === null
                  ? aChild
                  : model.accCrud.modification
                  ? aChild
                  : [],
            },
          ],
        },
      }),
    ];
    return definitions;
  }

  toView() {
    return [
      {
        title: false,
        rows: [
          { label: "Account ID", value: this.accountId },
          { label: "Nom", value: this.user.firstName },
          { label: "Prénom", value: this.user.lastName },
          { label: "Email", value: this.user.email },
          { label: "Téléphone", value: this.user.msisdn },
          { label: "Type de compte", value: this.accountType.libelle },
          { label: "Profile", value: this.profile?.libelle },
          { label: "Code PIN", value: this.pin },
          { label: "Status", value: this.active ? "Actif" : "Inactif" },
        ],
      },
    ];
  }

  switchAction(status: boolean) {
    return new Promise(async (resolve, reject) => {
      const res = await restClient.get(
        `/account/change/${this.accountId}/${status}`
      );
      if (res?.success && res?.data?.accountId > 0) {
        resolve(true);
        return;
      }
      resolve(false);
    });
  }

  getAccountFields() {
    const fields: Field[] = [];
    const rows = User.definitions;
    const countryField = rows.find((f) => f.name === "country");
    const accountTypeField = rows.find((f) => f.name === "account_type_id");
    const profileIdField = rows.find((f) => f.name === "profile_id");
    const deviseIdField = rows.find((f) => f.name === "devise");
    const enterpriseNameField = rows.find((f) => f.name === "nomEntreprise");
    const rccmField = rows.find((f) => f.name === "rccm");
    const idNationalField = rows.find((f) => f.name === "idNational");
    if (countryField) {
      fields.push(countryField);
    }
    if (accountTypeField) {
      if (utils().isProfil()) {
        accountTypeField.customData.isDisabled = true;
      }
      fields.push(accountTypeField);
    }
    if (enterpriseNameField) {
      fields.push(enterpriseNameField);
    }
    if (rccmField) {
      fields.push(rccmField);
    }
    if (idNationalField) {
      fields.push(idNationalField);
    }

    if (profileIdField) {
      if (utils().isProfil()) {
        profileIdField.customData.isDisabled = true;
      }
      fields.push(profileIdField);
    }

    if (deviseIdField) {
      fields.push(deviseIdField);
    }
    return fields;
  }

  getProfilFields() {
    const fields: Field[] = [];
    const rows = User.definitions;
    const countryField = rows.find((f) => f.name === "firstName");
    const accountTypeField = rows.find((f) => f.name === "lastName");
    const enterpriseNameField = rows.find((f) => f.name === "nickName");
    const rccmField = rows.find((f) => f.name === "email");
    const telField = rows.find((f) => f.name === "secondaryAddress");
    const msisdn = rows.find((f) => f.name === "msisdn");
    const idNationalField = rows.find((f) => f.name === "address");
    if (countryField) {
      fields.push(countryField);
    }
    if (accountTypeField) {
      fields.push(accountTypeField);
    }
    if (enterpriseNameField) {
      fields.push(enterpriseNameField);
    }
    if (rccmField) {
      fields.push(rccmField);
    }
    if (idNationalField) {
      fields.push(idNationalField);
    }
    if (telField) {
      fields.push(telField);
    }
    if (msisdn) {
      fields.push(msisdn);
    }
    return fields;
  }

  initEditCallBack(props: any) {
    const user = User.instance;
    user.data.push({
      name: "account_type_id",
      value: props?.accountType?.accountTypeId,
    });
    user.data.push({
      name: "profile_id",
      value: props?.user?.profile?.id,
    });
    user.data.push({
      name: "devise_id",
      value: props?.devise?.id,
    });
    user.data.push({
      name: "country",
      value: props?.country,
    });
    user.data.push({
      name: "nomEntreprise",
      value: props?.nomEntreprise,
    });
    user.data.push({
      name: "idNational",
      value: props?.idNational,
    });
    user.data.push({
      name: "rccm",
      value: props?.rccm,
    });
  }

  async proccessEditProfil() {
    return new Promise(async (resolve) => {
      const parent = this.getValue("parent", false);
      if (parent) {
        const r = await restClient.get(`/account/find/byMsisdn/${parent}`);
        if (r.data?.accountId) {
          await restClient.get(
            `/account/link/${r.data.accountId}/${this.accountId}`
          );
        } else {
          alert("Impossible de définir le compte parent. Merci de réessayer !");
          resolve(false);
        }
      }
      const user = User.instance;
      let p1: any = {
        account_type_id: user.getValue(
          "account_type_id",
          this.accountType?.accountTypeId
        ),
        idNational: user.getValue("idNational", this.idNational),
        nomEntreprise: user.getValue("nomEntreprise", this.nomEntreprise),
        rccm: user.getValue("rccm", this.rccm),
        expire: "",
        // msisdn: this.msisdn,
        msisdn: user.getValue("msisdn", this.msisdn),
        pin: utils().isProfil()
          ? this.getValue("pin", this.rccm) || this.pin
          : this.pin,
        user_id: this.user?.userId,
      };
      const v = user.getValue("country", "");
      const country = v.split("-")[0] || this.country;
      const currency = v.split("-")[1] || this.currency;
      p1 = {
        ...p1,
        country,
        currency,
      };
      let p2 = {
        actif: this.user.actif,
        address: user.getValue("address", ""),
        cni: "",
        email: this.user.email,
        firstName: user.getValue("firstName", ""),
        lastName: user.getValue("lastName", ""),
        // msisdn: this.user.msisdn,
        msisdn: user.getValue("msisdn", ""),
        nickName: user.getValue("nickName", ""),
        password: "",
        platform: "WEB",
        profil: "",
        profile_id: user.getValue("profile_id", this.profile?.id),
        raisonSocial: this.user.raisonSocial,
        secondaryAddress: user.getValue("secondaryAddress", ""),
      };
      console.log(p1, p2);
      const res = await restClient.put(`/user/update`, p2);
      if (res?.success) {
        const res2 = await restClient.put(
          `/account/update/${this.accountId}`,
          p1
        );
        if (res2?.data?.accountId) {
          this.populate(res2?.data);
          return resolve(this);
        } else {
          window.alert("Une erreur s'est produite lors de l'enregistrement !");
        }
      }
      resolve(false);
    });
  }

  async refreshTableDataCallBack(props: any, data: any[]) {
    return data;
  }
  async initCustom(props: any) {
    let accountParent = "";
    let parentAccountId = 0;
    let resAcc = await restClient.get(`/account/find/` + props.data.parentId);
    console.log("Compte parent de initCustom", resAcc);
    if (resAcc?.data?.user) {
      accountParent = resAcc.data.numero;
      parentAccountId = resAcc.data.accountId;
    }
    return {
      settings: {
        content: "hierCompte",
        title: "Définir le parent de ce compte",
        showSaveBtn: true,
        action: "hierarchiser",
        message: "",
        account: accountParent,
        parentAccountId: parentAccountId,
      },
    };
  }

  public static getSelectAccount(v: any) {
    return {
      label: v.msisdn,
      value: v.accountId
    };
  }

  public static getSelectAccount2(v: any) {
    return {
      label: v.msisdn +' ('+v.user.firstName+' '+v.user.lastName+')',
      value: v.msisdn
    };
  }
}

export { Account };

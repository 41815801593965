import React, { Component } from "react";

class TextFieldAPG extends Component<any, any> {
  render() {
    const {
      label,
      name,
      onChange,
      value,
      placeholder,
    } = this.props;
    return (
      <div
        className='form-group row'
        style={{ margin: "0 0 1rem 0", width: "90%" }}
      >
        <div className='col-md-4' style={{ padding: "0 1rem 0 0" }}>
          <label
            htmlFor='colFormLabelSm'
            className=' col-form-label col-form-label-sm'
            style={{ float: "right" }}
          >
            {label}
          </label>
        </div>
        <div className='col-md-8' style={{ padding: 0 }}>
          <textarea
              className="form-control"
              name={name}
              value={value}
              placeholder={placeholder}
              onChange={(e) => onChange(e)}
          ></textarea>
        </div>
      </div>
    );
  }
}

export { TextFieldAPG };

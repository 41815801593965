import React, { useEffect, useState } from "react";
import { appConstants } from "../../../../_constants";
import { Affectation } from "../../../../_entities";
import { utils } from "../../../../_helpers";

const { TABLE_DATA_CHANGE } = appConstants.keys.eventKeys;

export function SaveBtn(props: any) {
  const [cls, setCls] = useState("d-none");
  useEffect(() => {
    const handleTableDataChangeEvent = (res: any) => {
      if (res?.data?.length) {
        setCls("");
      } else {
        setCls("d-none");
      }
    };

    utils().emitter.on(TABLE_DATA_CHANGE, (res) => {
      handleTableDataChangeEvent(res);
    });

    return () => {
      utils().emitter.off(TABLE_DATA_CHANGE, handleTableDataChangeEvent);
    };
  }, []);
  return (
    <div style={{ height: "30px" }} className={cls}>
      <button
        data-placement="top"
        data-toggle="tooltip"
        title="Appliquer les modifications"
        className={`btn btn-primary btn-sm d-flex align-items-center mr-1 actionBtn box-shadow pull-right`}
        style={{ fontSize: "11px" }}
        onClick={(e) => onClick(e, props)}
      >
        <i className={`fa fa-save mr-1`}></i>
        Appliquer les modifications
      </button>
    </div>
  );
}

const onClick = async (e: any, props: any) => {
  utils().showLoader();
  const res = await Affectation.instance.saveResources();
  utils().hideLoader();
  setTimeout(() => {
    if (res === true) {
      alert("Mises à jour enregistrées avec succès !");
    } else {
      if (res?.message) {
        alert(res.message);
      } else {
        alert(
          "Une érreur s'est produite lors de l'enregistrement. Merci de reéssayer !"
        );
      }
    }
  }, 50);
};

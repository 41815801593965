import React, { Component } from "react";
import { MontantFrais2 } from "./MontantFrais2";

class InputText2 extends Component<any, any> {
  render() {
    const { type, freeze, label, name, onChange, value } = this.props;
    return (
      <div
        className="form-group row"
        style={{ margin: "0 0 1rem 0", width: "90%" }}
      >
        <div className="col-md-4" style={{ padding: "0 1rem 0 0" }}>
          <label
            htmlFor="colFormLabelSm"
            className=" col-form-label col-form-label-sm"
            style={{ float: "right" }}
          >
            {label}
          </label>
        </div>
        <div className="col-md-8" style={{ padding: 0 }}>
          <input
            className="form-control"
            name={name}
            id={name}
            type={type}
            disabled={freeze}
            value={value}
            onChange={(e) => onChange(e)}
            style={{ width: "100%" }}
          />
          <div className="invalid-feedback">Ce champ est requis.</div>
          {name === "amount" && <MontantFrais2 {...this.props} />}
          <input name="valid" type="hidden" value="true" />
        </div>
      </div>
    );
  }

  isBonVirtuel() {
    return window.location.href.indexOf("/bonvirtuel") !== -1;
  }
}

export { InputText2 };

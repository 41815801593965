import React, { useEffect } from "react";
import { restClient, utils } from "../../../../_helpers";
import { PropsFromRedux } from "../../../../_reducers";
import { Kpiitem } from "./Kpiitem";

export function Kpi(props: PropsFromRedux & any) {
  const { kpiItems, model } = props;

  const kpis = kpiItems.map((k: any) => ({ ...k, ...model.getKpiMeta(k) }));
  useEffect(() => {
    utils().resolveKpis(kpiItems, props);
  }, []);
  return (
    <div className="row">
      {kpis.map((item: any, i: number) => {
        return <Kpiitem kpi={item} key={i} {...props} />;
      })}
    </div>
  );
}

import { appConstants } from "../_constants";
import { BaseEntity } from "./BaseEntity";
import { FieldType } from "./enumerations";
import { Field } from "./Field";
import { BaseModel } from "../_models";

const {
  RENDER_ACTIONS,
  RENDER_ID,
  RENDER_STATUS,
} = appConstants.keys.renderKeys;

class Fonctionnalite extends BaseEntity {
  public static readonly label: any;
  public static readonly className: any;
  public static readonly art: any;
  public static readonly addTitle = "Ajouter une fonctionnalité";
  public static readonly showTitle = "Détails de la fonctionnalité";
  public static readonly editTitle = "Modifier une fonctionnalité";
  public static readonly saveBtnTitle = "Enregistrer la fonctionnalité";
  public static readonly processNewSuccessMsg =
    "La fonctionnalité a été créée avec succès. Merci !";
  public static readonly processEditSuccessMsg =
    "La fonctionnalité a été modifié avec succès. Merci !";
  public static readonly processNewPath = "profile/add/resource";
  public static readonly processEditPath = "profile/update/resource/_ID_";
  public static instance: Fonctionnalite = new Fonctionnalite();
  public static instances = [];
  public static relations = [];

  public static get definitions(): Field[] {
    const model: any = BaseModel.getInstance();
    const aChildAll = [
      {
        label: "Modifier",
        title: "Modifer ce type de compte",
        action: "editActions",
        color: "btn-success",
        icon: "fa fa-edit",
      },
      {
        label: "Supprimer",
        title: "Supprimmer ce type de compte",
        action: "deleteActions",
        color: "btn-danger",
        icon: "fa fa-trash",
      },
    ];
    const aChildMod = [
      {
        label: "Modifier",
        title: "Modifer ce type de compte",
        action: "editActions",
        color: "btn-success",
        icon: "fa fa-edit",
      },
    ];
    const aChildDel = [
      {
        label: "Supprimer",
        title: "Supprimmer ce type de compte",
        action: "deleteActions",
        color: "btn-danger",
        icon: "fa fa-trash",
      },
    ];

    const definitions = [
      new Field({
        name: "id",
        type: FieldType.NUMBER,
        primary: true,
        creatable: false,
        editable: false,
        serializeNew: false,
        serializeEdit: false,
        label: "#",
        renderKey: RENDER_ID,
        provider: Fonctionnalite.instance,
      }),
      new Field({
        name: "code",
        primary: true,
        creatable: false,
        editable: false,
        serializeNew: false,
        serializeEdit: false,
        label: "Code",
        provider: Fonctionnalite.instance,
      }),
      new Field({
        name: "libelle",
        label: "Intitulé",
        provider: Fonctionnalite.instance,
      }),
      new Field({
        name: "status",
        label: "Status",
        creatable: false,
        editable: false,
        renderKey: RENDER_STATUS,
        serializeCallback: (item: any, value: any) => {
          return { ...item, status: true };
        },
        provider: Fonctionnalite.instance,
      }),
      new Field({
        name: "actions",
        label: "Actions",
        dataPath: "id",
        creatable: false,
        editable: false,
        serializeNew: false,
        serializeEdit: false,
        serializeView: false,
        renderKey: RENDER_ACTIONS,
        provider: Fonctionnalite.instance,
        customData: {
          renderOptions: {
            clsActions: "justify-content-center",
          },
          actions: [
            {
              label: "Détails",
              title: "Afficher les détails",
              action: "detailsActions",
              color: "btn-info",
              icon: "fa fa-eye",
            },
            {
              type: "btnGroup",
              color: "btn-primary",
              children:
                model.user.user?.profile === null
                  ? aChildAll
                  : model.profCrud.modification && model.profCrud.suppression
                  ? aChildAll
                  : model.profCrud.modification
                  ? aChildMod
                  : model.profCrud.suppression
                  ? aChildDel
                  : [],
            },
          ],
        },
      }),
    ];
    return definitions;
  }

  toView() {
    return [
      {
        title: false,
        rows: [
          { label: "Identifiant", value: this.id },
          { label: "Libellé", value: this.libelle },
          { label: "Status", value: this.status ? "Actif" : "Inactif" },
        ],
      },
    ];
  }

  getDeleteActionConfig() {
    return {
      path: `/profile/delete/resource/${this.id}`,
      resolve: (res: any) => res?.message === "OK",
    };
  }
}

export { Fonctionnalite };

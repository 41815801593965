import React from "react";

export function Label(props: any) {
  const { field, label, customLabel } = props;
  const id = props.id ? props.id : "";
  const cls = props.cls ? props.cls : "";
  const styles = props.styles ? props.styles : {};
  const lbl = customLabel ? customLabel : field?.label ? field?.label : label;
  return (
    <label className={cls} htmlFor={id} style={styles}>
      {lbl}
    </label>
  );
}

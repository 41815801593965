import { groupBy } from "lodash";
import { restClient, utils } from "../_helpers";
import { BaseModel } from "../_models";
import { Field } from "./Field";

class BaseEntity {
  public static readonly addTitle: string;
  public static readonly editTitle: string;
  public static readonly showTitle: string;
  public static readonly saveBtnTitle: string;
  public static readonly processNewPath: string;
  public static readonly processEditPath: string;
  public static readonly processNewSuccessMsg: string;
  public static readonly processEditSuccessMsg: string;
  public static readonly idField: string;
  public static instance: any;
  public static instances = [];
  public static definitions: Field[];
  public static staticData: any[] = [];

  data: any[] = [];
  [key: string]: any;

  public get rows(): Array<Field[]> {
    const definitions = (this.constructor as any).definitions;
    const fields: Array<Field[]> = [];
    let filteredFields = definitions.filter((f: Field) => f.creatable);
    if (typeof this.filterFieldCallback === "function") {
      filteredFields = this.filterFieldCallback(filteredFields);
    }
    const groupedFields = groupBy(filteredFields, "formRowIndex");
    for (const key in groupedFields) {
      if (Object.prototype.hasOwnProperty.call(groupedFields, key)) {
        const element = groupedFields[key] as Field[];
        element.sort((a: Field, b: Field) => {
          if (a.formColumnIndex && b.formColumnIndex) {
            return a.formColumnIndex - b.formColumnIndex;
          }
          return 0;
        });
        fields.push(element);
      }
    }
    return fields;
  }

  public get addTitle(): string {
    return (this.constructor as any).addTitle;
  }

  public get staticData(): any[] {
    return (this.constructor as any).staticData;
  }

  public get editTitle(): string {
    return (this.constructor as any).editTitle;
  }

  public get showTitle(): string {
    return (this.constructor as any).showTitle || "Détails";
  }

  public get saveBtnTitle(): string {
    return (this.constructor as any).saveBtnTitle;
  }

  public get idField(): string {
    return (this.constructor as any).idField || "id";
  }

  public get headers(): Field[] {
    return (this.constructor as any).definitions
      .filter((f: Field) => f.onTable)
      .map((f: Field) => f.toTableField());
  }

  public get tableData(): any[] {
    return (this.constructor as any).instances;
  }

  public get processNewSuccessMsg(): string {
    return (this.constructor as any).processNewSuccessMsg;
  }

  public get processEditSuccessMsg(): string {
    return (this.constructor as any).processEditSuccessMsg;
  }

  getDataIndex(key: string): number {
    return this.data.findIndex((d: any) => d.name === key);
  }

  getStaticDataIndex(key: string): number {
    return this.staticData.findIndex((d: any) => d.name === key);
  }

  public getValue(
    key: string,
    defaultValue: any = "",
    isStatic = false,
    ignoreStatic = false
  ): any {
    let i = -1;
    if (isStatic) {
      i = this.getStaticDataIndex(key);
      if (i > -1) {
        return this.staticData[i].value;
      }
    } else {
      i = this.getDataIndex(key);
      if (i > -1) {
        return this.data[i].value;
      } else {
        if (!ignoreStatic) {
          i = this.getStaticDataIndex(key);
          if (i > -1) {
            return this.staticData[i].value;
          }
        }
      }
    }
    return defaultValue;
  }

  public deleteValue(key: string, isStatic = false) {
    let i = -1;
    if (isStatic) {
      i = this.getStaticDataIndex(key);
      if (i > -1) {
        this.staticData.splice(i, 1);
      }
    } else {
      i = this.getDataIndex(key);
      if (i > -1) {
        this.data.splice(i, 1);
      }
    }
  }

  populate(d: any) {
    const data = Array.isArray(d) && d?.length ? d[0] : d;
    for (const [name, value] of Object.entries(data)) {
      if (name !== "data") {
        Object.assign(this, { [name]: value });
      }
    }
  }

  updateValue(key: string, value: any, isStatic = false) {
    let i = -1;
    if (isStatic) {
      i = this.getStaticDataIndex(key);
      if (i > -1) {
        this.staticData[i].value = value;
      } else {
        this.staticData.push({
          name: key,
          value,
        });
      }
    } else {
      i = this.getDataIndex(key);
      if (i > -1) {
        this.data[i].value = value;
      } else {
        this.data.push({
          name: key,
          value,
        });
      }
    }
  }

  updateTableData(data: any[]) {
    if (Array.isArray(data)) {
      (this.constructor as any).instances = data.map((d) => {
        const entity = new (this.constructor as any)();
        return Object.assign(entity, d);
      });
    }

    return { success: true, data: this.tableData };
  }

  toView(): any[] {
    return [];
  }

  public assertNotNullConditionnal(meta: any): boolean {
    const val = this.getValue(meta.key);
    const otherVal = this.getValue(meta.otherKey);
    if (val && val !== "") {
      return true;
    }
    if (!otherVal && otherVal !== "") {
      return true;
    }
    if (otherVal === meta?.expetedVal) {
      return true;
    }
    if (!meta.checkCondition(otherVal)) {
      return true;
    }
    return false;
  }

  public assertEquals(meta: any): boolean {
    const val = this.getValue(meta.key);
    const otherVal = this.getValue(meta.otherKey);
    if (otherVal === val) {
      return true;
    }
    return false;
  }
  public assertNotNull(meta: any): boolean {
    const val = this.getValue(meta.key);
    if (val) {
      return true;
    }
    return false;
  }

  assertPasswordStrenght(meta: any) {
    const val = this.getValue(meta.key);
    if (
      val.match(/[a-z]/g) &&
      val.match(/[A-Z]/g) &&
      val.match(/[0-9]/g) &&
      val.match(/[^a-zA-Z\d]/g) &&
      val.length >= 8
    ) {
      return true;
    }
    return false;
  }

  validate(props?: any) {
    console.log(this);
    if (typeof this.validateCallback === "function") {
      return this.validateCallback(props);
    }
    let valid = true;
    this.rows.forEach((row) => {
      row.forEach((f) => {
        if (!f.isValid) {
          console.log("==> not valid ", f.name);
          valid = false;
          return false;
        }
      });
      if (!valid) {
        return false;
      }
    });
    return valid;
  }

  serialize(scope: string = "new", props?: any) {
    const definitions = (this.constructor as any).definitions;
    const filteredFields = definitions.filter((f: Field) => f.serializeNew);
    let payload = {};
    filteredFields.forEach((element: Field) => {
      const val = this.getValue(element.name, element.defaultValue);
      if (typeof element.serializeCallback === "function") {
        payload = element.serializeCallback(payload, val);
      } else {
        Object.assign(payload, { [element.name]: val });
      }
    });
    if (typeof this.serializeCallback === "function") {
      return this.serializeCallback(payload, props);
    }

    return payload;
  }

  initNew() {
    this.data = [];
    return new Promise(async (resolve, reject) => {
      const relations = (this.constructor as any).relations;
      if (typeof this.initNewCallBack === "function") {
        await this.initNewCallBack();
      }
      if (relations.length) {
        return Promise.all(
          relations.map((relation: any) => {
            return new Promise(async (resolve2, reject2) => {
              const path =
                typeof relation.dataPath === "function"
                  ? relation.dataPath({})
                  : relation.dataPath;
              const response =
                typeof path === "object" && typeof path.children !== "undefined"
                  ? await restClient.post(path.path, path.children)
                  : await restClient.get(path);
              resolve2({ relation, response });
            });
          })
        ).then((responses) => {
          responses.forEach((item: any, i) => {
            const data = item.relation.resolve(item.response);
            this.data.push({
              name: item.relation.key,
              value: data,
            });
          });
          utils().emitter.emit("initNew", "");
          return resolve(true);
        });
      }
      utils().emitter.emit("initNew", "");
      resolve(true);
    });
  }

  initEdit(props: any) {
    return new Promise(async (resolve, reject) => {
      await this.initNew();
      this.populate(props);
      for (const [name, value] of Object.entries(props)) {
        if (name !== "data") {
          this.data.push({ name, value });
        }
      }
      if (typeof this.initEditCallBack === "function") {
        await this.initEditCallBack(props);
      }
      utils().emitter.emit("initEdit", "");
      resolve(true);
    });
  }

  initCustom(props: any): any {
    return {};
  }

  getProcessNewPath(payload: any) {
    if (typeof this.getCustomProcessNewPath === "function") {
      return this.getCustomProcessNewPath(payload);
    }
    return (this.constructor as any).processNewPath;
  }

  getProcessEditPath(payload: any, id: any) {
    console.log("Le payload edit", payload);
    if (typeof this.getCustomProcessEditPath === "function") {
      return this.getCustomProcessEditPath(payload);
    }
    return (
      (this.constructor as any).processEditPath?.split("_ID_").join(id) || ""
    );
  }

  async processNew(payload: any) {
    return new Promise(async (resolve, reject) => {
      console.log("==> Processing object creation ...");
      console.log("==> payload ", payload);
      if (typeof this.customProcessNew === "function") {
        return resolve(await this.customProcessNew(payload));
      }
      const path = this.getProcessNewPath(payload);
      let content = "Erreur lors de l'enregistrement ! Merci de reéssayer.";
      let cls = "alert-danger";
      let res: any;
      let payloadApgCTW;
      if (payload.operationType === 26) {
        res = await restClient.get(`operation/withdraw-cash/${BaseModel.getInstance().user.accountId}/${payload.token}`)
      } else if (payload.operationType === 17) {
        payloadApgCTW = {
          senderAccount: payload.sender,
          amount: payload.amount,
          toWalletId: payload.walletId,
          toCountry: payload.country,
          currency: payload.currency,
        };
        res = await restClient.post(path, payloadApgCTW);
      } else {
        if (payload.operationType === 18) {
          let payloadApgCTB = {
            senderAccount: payload.sender,
            amount: payload.amount,
            beneficiaryLastName: payload.nom,
            beneficiaryFirstName: payload.prenom,
            beneficiaryPhone: payload.phone,
            sourceOfIncome: payload.soinc,
            toBankAccountNumber: payload.rib,
            toBankSwift: payload.swift,
            toCountry: payload.country,
            currency: payload.currency,
          };
          res = await restClient.post(path, payloadApgCTB);
        } else {
          if (payload.operationType === 19) {
            let payloadApgCTC = {
              senderAccount: payload.senderAccount,
              idEntreprise: payload.idEntreprise,
              amount: payload.amount,
              beneficiaryLastName: payload.nom,
              beneficiaryFirstName: payload.prenom,
              beneficiaryEmail: payload.emailben,
              beneficiaryPhone: payload.phone,
              senderLastName: payload.nomem,
              senderFirstName: payload.prenomem,
              senderEmail: payload.emailem,
              senderPhone: payload.phoneem,
              toCountry: payload.country,
              currency: payload.currency,

            };
            res = await restClient.post(path, payloadApgCTC);
          } else {
            if(typeof payload.creation !== 'undefined'){
              let payloadNFC = {
                account: payload.account,
                expiration: payload.expiration,
                number: payload.number,
                pin: payload.pin,
                description: payload.description,
                status: payload.active
              };
              res = await restClient.post(path, payloadNFC);
            }else{
              res = await restClient.post(path, payload);
            }
          }
        }
      }
      if (payload.operationType === 26) {
        if (res?.data?.operationId) {
          return resolve(res?.data);
        }
      } else if (res?.success) {
         if (Array.isArray(res?.data?.errors) && res?.data?.errors.length > 0) {
          content =
          res?.data?.errors[0] ||
          "Erreur lors de l'enregistrement ! Merci de reéssayer.";
          utils().emitter.emit("alert_message", { content, cls });
          return resolve(false);
        } else  if (
          res.data?.code !== "0000" &&
          (payload.operationType === 19 ||
            payload.operationType === 18 ||
            payload.operationType === 17)
        ) {
          console.log("La reponose APG", res);
          if (Array.isArray(res?.data?.errors)) {
            content = res?.data?.errors[0];
          } else {
            content =
              res.data?.message ||
              "Erreur lors de l'enregistrement ! Merci de reéssayer.";
          }

          utils().emitter.emit("alert_message", { content, cls });
          return resolve(false);
        } else {
          content =
            this.processNewSuccessMsg || "Opération d'enregistrement reussie !";
          cls = "alert-success";
          utils().emitter.emit("alert_message", { content, cls });
          if (typeof this.processNewCallBack === "function") {
            const res2 = await this.processNewCallBack(res);
            return resolve(res2);
          }
          let d: any = {};
          if (res?.data?.message === "ok" && res?.data?.item) {
            d = res?.data?.item;
          } else {
            d = res?.data;
          }
          return resolve(d);
        }
      } 

      content =
        res?.message || "Erreur lors de l'enregistrement ! Merci de reéssayer.";
      utils().emitter.emit("alert_message", { content, cls });
      resolve(false);
    });
  }

  async processEdit(payload: any) {
    return new Promise(async (resolve, reject) => {
      console.log("==> Processing object modification ...");
      console.log("==> payload ", payload);
      const path = this.getProcessEditPath(payload, this[this.idField]);
      let content = "Erreur lors de l'enregistrement ! Merci de reéssayer.";
      let cls = "alert-danger";
      let res = await restClient.put(path, payload);
      if (res?.success) {
        content =
          this.processEditSuccessMsg || "Opération d'enregistrement reussie !";
        cls = "alert-success";
        utils().emitter.emit("alert_message", { content, cls });
        if (typeof this.processEditCallBack === "function") {
          this.processEditCallBack();
        }
        let d: any = {};
        if (res?.data?.message === "ok" && res?.data?.item) {
          d = res?.data?.item;
        } else {
          d = res?.data;
        }
        return resolve(d);
      }
      if (Array.isArray(res?.data?.errors)) {
        content = res?.data?.errors[0];
      } else {
        content =
        res?.message || res.data?.message ||
          "Erreur lors de l'enregistrement ! Merci de reéssayer.";
      }
     
      utils().emitter.emit("alert_message", { content, cls });
      resolve(false);
    });
  }

  async correcTx(payload: any) {
    return new Promise(async (resolve, reject) => {
      const res = await restClient.put(`/operation/correct`,payload);
      return resolve(res?.data);
    });
  }

  async declineValidation(payload: any, workflowStepId: Number) {
    return new Promise(async (resolve, reject) => {
      const res = await restClient.post(`/workflowstep/decline/${workflowStepId}`,payload);
      return resolve(res?.data);
    });
  }

  async hierTx(parent: any, child: any) {
    return new Promise(async (resolve, reject) => {
      const res = await restClient.get(
        "/account/link/" + parent + "/" + child + "/"
      );
      console.log("==> res parent défini ", res);
      return resolve(res?.data);
    });
  }

  async refreshTableData(props: any) {
    const { model } = props;
    const { config } = model;
    if (typeof config.dataPath === "function") {
      const path = config.dataPath(props);
      const res =
        typeof path === "object" && typeof path.children !== "undefined"
          ? await restClient.post(path.path, path.children)
          : await restClient.get(path);
      if (res.success) {
        const data =
          typeof config.dataPathCallBack === "function"
            ? config.dataPathCallBack(res, props)
            : res.data;
        let filteredData =
          typeof this.handleFilter === "function"
            ? await this.handleFilter(data, props)
            : data;
        if (typeof this.refreshTableDataCallBack === "function") {
          filteredData = await this.refreshTableDataCallBack(
            props,
            filteredData
          );
        }
        return this.updateTableData(filteredData);
      }
      return res;
    }
    return { success: false };
  }

  switchAction(status: boolean) {
    return new Promise(async (resolve, reject) => {
      if (typeof this.getSwitchActionConfig !== "function") {
        return resolve(false);
      }
      const config = this.getSwitchActionConfig(status);
      const res = await restClient.get(config.path);
      if (config.resolve(res)) {
        resolve(true);
        return;
      }
      resolve(false);
    });
  }

  deleteAction() {
    return new Promise(async (resolve, reject) => {
      if (typeof this.getDeleteActionConfig !== "function") {
        return resolve(false);
      }
      const config = this.getDeleteActionConfig();
      let res: any = {};
      if (config.method === "get") {
        res = await restClient.get(config.path);
      } else {
        res = await restClient.remove(config.path, config.body || {});
      }
      if (config.resolve(res)) {
        resolve(true);
        return;
      }
      resolve(false);
    });
  }

  async processAction(props: any, processKey: string): Promise<any> {
    return false;
  }

  reset() {}

  getDefaultValue(field: Field, value: any): any {
    return undefined;
  }

  /**
   * applyFilters
   */
  public applyFilters(path: string, meta: any) {
    const model = BaseModel.getInstance();
    const { filter } = meta;
    if (filter) {
      const { period, datedebut, datefin } = filter;
      if (period !== "0") {
        return `${path}?period=${period}`;
      }
      if (datedebut !== "" && datefin !== "") {
        return `${path}?datedebut=${datedebut}_AMP_datefin=${datefin}`;
      }
    }
    return model.isClient ? `${path}` : `${path}?period=today`;
  }
}

export { BaseEntity };

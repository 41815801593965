import React, { useEffect, useState } from "react";
import { appConstants } from "../../../_constants";
import { utils } from "../../../_helpers";
import { CloseBtn, NextBtn, PrintBtn, SaveBtn } from "./ModalBtns";

export function ModalFooter(props: any) {
  const { hasNext, isNew, hideFooter, isEdit, settings, isDetails, isMsg } = props;
  const [hideBtns, setHideBtns ] = useState(false);
  useEffect(() => {
    const handleHideFooterBtnsEvent = (e: any) => {
      setHideBtns(e);
    }
    utils().emitter.on(appConstants.keys.eventKeys.HIDE_FOOTER_BTNS, handleHideFooterBtnsEvent);

    return () => {
      utils().emitter.off(appConstants.keys.eventKeys.HIDE_FOOTER_BTNS, handleHideFooterBtnsEvent);
    }
  }, []);
  return (
    <div className={`modal-footer ${hideFooter ? "d-none" : ""}`}>
      {!hideBtns && <>
      <CloseBtn {...props} />
      {!hasNext && (isNew || isEdit || settings?.showSaveBtn) && (
        <SaveBtn {...props} />
      )}
      {hasNext && !isDetails && <NextBtn {...props} />}
      {isDetails &&
        props?.data?.operationId && (
          <PrintBtn {...props} />
        )}
        </>}
    </div>
  );
}
